<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/accounting/costcenter" class="active">{{
              $t("ccenter")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="#" class="active"
              >{{ $t("add") }} {{ $t("ccenter") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("ccenter") }}</h3>
        </div>
        <div class="head2">
          <button @click="submit">{{ $t("save") }}</button>
        </div>
      </div>
      <div class="body">
        <div class="group">
          <label for="basic-url" class="form-label"
            >{{ $t("name") }} {{ $t("ccenter") }}
            <span class="error-feedback" v-if="v$.namear.$error">{{
              "*"
            }}</span>
          </label>
          <div class="input-group mb-3">
            <input type="text" v-model="namear" />
          </div>
          <label for="basic-url" class="form-label"
            >{{ $t("name") }} {{ $t("ccenter") }} {{ $t("parent") }}
            <span class="error-feedback" v-if="v$.parentaccount.$error">{{
              "*"
            }}</span>
          </label>
          <div class="input-group mb-3">
            <v-select
              class="select"
              :options="data"
              label="namear"
              v-model="parentaccount"
            >
              <template #option="option">
                <span>{{ option.namear }} - {{ option.actnum }}</span>
              </template>
            </v-select>
          </div>
          <label for="basic-url" class="form-label" style="width: 40%">
            {{ $t("accepte") }} {{ $t("transaction") }}

            <span class="error-feedback" v-if="v$.namear.$error">{{
              "*"
            }}</span>
            <input type="checkbox" id="gridCheck1" v-model="sup" />
          </label>

          <div class="input-group mb-3"></div>
          <label for="basic-url" class="form-label"
            >{{ $t("number") }} {{ $t("ccenter") }}
            <span class="error-feedback" v-if="v$.actnum.$error">{{
              "*"
            }}</span></label
          >
          <div style="display: flex">
            <input style="width: 25%" type="text" v-model="actnum" /><input
              style="width: 25%"
              type="number"
              disabled
              v-model="parentnumber"
            />
          </div>
          <label for="basic-url" class="form-label">{{
            $t("description")
          }}</label>
          <div class="input-group mb-3">
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="description"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      namear: "",
      actnum: "",
      data: [],
      data2: [],
      parentaccount: "",
      parentnumber: "",
      parentname: "",
      level: "",
      description: "",
      sup: false,
    };
  },
  validations() {
    return {
      namear: { required },
      actnum: { required },
      parentaccount: { required },
      parentnumber: { required },
    };
  },
  async mounted() {
    const result = await axios.get("/data/accountingccenter");
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post("/data/accountingccenter", {
          username: store.state.randp.username,
          namear: this.namear,
          actnum: this.parentnumber + this.actnum,
          parent: this.parentnumber + "-" + this.parentname,
          description: this.description,
          sup: this.sup,
          level: this.level,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "costcenter" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    parentaccount() {
      this.parentnumber = this.parentaccount.actnum;
      this.level = Number(this.parentaccount.level) + Number(1);
      this.parentname = this.parentaccount.namear;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}
.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}
.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}
.head1 {
  width: 70%;
}
.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.head2 {
  width: 30%;
  text-align: center;
}
.head2 button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}
.group {
  padding: 1%;
  width: 60%;
}
.group input {
  width: 50%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.checkbox {
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.group .select {
  width: 50%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
</style>
