<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/tasks" class="active"
              >{{ $t("management") }} {{ $t("tasks") }}</router-link
            >
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">
              {{ $t("task") }} - {{ this.$route.params.id }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("task") }} {{ this.$route.params.id }}</h3>
        </div>
        <div class="head2">
          <!-- <button
            class="buttonconfirm"
            type="submit"
            :disabled="disabled"
            @click="submite"
          >
            {{ $t("save") }}
          </button> -->
        </div>
      </div>
      <div class="body">
        <div class="part1">
          <form>
            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-2 col-form-label">{{
                $t("type")
              }}</label>
              <div class="col-sm-5">
                {{ $t(type) }}
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-2 col-form-label"
                >{{ $t("assign") }}
              </label>
              <div class="col-sm-5">
                {{ $t(assign) }}
                <!-- Button trigger modal -->
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {{ $t("assign") }}
                </button>

                <!-- Modal -->
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          اعادة تعيين
                        </h5>
                      </div>
                      <div class="modal-body">
                        <div class="row mb-3">
                          <label
                            for="inputPassword3"
                            class="col-sm-2 col-form-label"
                            >{{ $t("assign") }}
                          </label>

                          <div>
                            <select v-model="reassign">
                              <option value="accounting">الحسابات</option>
                              <option value="purchases">المشتريات</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                              >{{ $t("comment") }}</label
                            >
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              v-model="comment"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="reassignaction"
                        >
                          {{ $t("save") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          {{ $t("cancel") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="body2" v-if="type == 'supplyorder'">
        <h3>{{ $t("supply order") }}</h3>
        <div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("date") }}
            </label>

            {{ date }}
          </div>
          <div class="row mb-2">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("ccenter") }}
            </label>
            <div class="col-sm-5" style="display: flex">
              {{ costname }}
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <td colspan="7">{{ $t("details") }} {{ $t("materials") }}</td>
              </tr>
              <tr>
                <td></td>
                <td>{{ $t("materials") }}</td>
                <td>{{ $t("unit") }}</td>
                <td>
                  {{ $t("quantity") }}
                </td>
                <td>{{ $t("comment") }}</td>
                <!-- <td>
                  {{ $t("stock") }}
                </td>
                <td>
                  {{ $t("purchases") }}
                </td>
                <td v-if="assign == 'purchases'">السعر بحسب عرض السعر</td>
                <td v-if="assign == 'purchases'">{{ $t("total") }}</td> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(x, id) in data2" :key="x">
                <td>{{ id + 1 }}</td>
                <td style="width: 25%" v-if="css.EN == 'rtl'">
                  {{ x.namear }} - {{ x.code }}
                </td>
                <td style="width: 25%" v-if="css.EN == 'ltr'">
                  {{ x.nameen }} - {{ x.code }}
                </td>
                <td>
                  {{ $t(x.unittype) }}
                </td>
                <td>
                  {{ x.quantity }}
                </td>
                <td>
                  {{ x.comment }}
                </td>
                <!-- <td>
                  {{ x.stock }}
                </td>
                <td>
                  <input
                    v-if="dep == 'accounting'"
                    type="number"
                    v-model="x.purchases"
                  />
                  <span v-else>{{ x.purchases }}</span>
                </td>
                <td>
                  <input
                    v-if="assign == 'purchases'"
                    type="number"
                    v-model="x.offerprice"
                  />
                </td>
                <td>
                  <span
                    v-if="
                      x.offerprice * x.purchases > 0 && assign == 'purchases'
                    "
                    >{{ x.offerprice * x.purchases }}</span
                  >
                </td> -->
              </tr>
            </tbody>
          </table>
          <button
            @click="reseivetask"
            style="padding: 1%"
            v-if="dep == this.assign && status == 'waiting'"
          >
            إستلام الطلب
          </button>
          <button @click="pdf" style="padding: 1%">
            <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
          </button>
          <!-- 
          <button @click="createinvoice" style="padding: 1%" v-if="dep == 'purchases'">
            تسجيل فاتورة مشتريات
          </button> -->
          <hr />

          <div class="activity">
            <ul v-for="x in activity" :key="x">
              <li style="width: 150px">
                {{ x.action }} <br />
                <span style="font-size: x-small"
                  >{{ x.date }} - {{ x.time }}</span
                >
                <br v-if="x.comment" />{{ x.comment }} <br />{{ x.username }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="main" style="padding: 2%; display: none">
        <div style="padding: 2%; direction: rtl; font-size: small">
          <img
            :src="this.url"
            crossorigin="anonymous"
            style="height: 100px; width: 25%; float: left"
          />
          <div style="padding: 5%; float: right">
            <h4>{{ $t("supply order") }}</h4>
            <h6>{{ $t("number") }} : {{ this.$route.params.id }}</h6>
            <h6>{{ $t("date") }} : {{ date }}</h6>
            <h6>{{ description }}</h6>
            <h6></h6>
          </div>
          <div style="clear: both">
            <table class="table">
              <thead>
                <tr>
                  <td colspan="7">{{ $t("details") }} {{ $t("materials") }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{{ $t("materials") }}</td>
                  <td>{{ $t("unit") }}</td>
                  <td>
                    {{ $t("quantity") }}
                  </td>
                  <td>
                    {{ $t("receive") }}
                  </td>
                  <td>{{ $t("comment") }}</td>

                  <!-- 
                <td>
                  {{ $t("purchases") }}
                </td>
                <td v-if="assign == 'purchases'">السعر بحسب عرض السعر</td>
                <td v-if="assign == 'purchases'">{{ $t("total") }}</td> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, id) in data2" :key="x">
                  <td>{{ id + 1 }}</td>
                  <td style="width: 25%" v-if="css.EN == 'rtl'">
                    {{ x.namear }} - {{ x.code }}
                  </td>
                  <td style="width: 25%" v-if="css.EN == 'ltr'">
                    {{ x.nameen }} - {{ x.code }}
                  </td>
                  <td>
                    {{ $t(x.unittype) }}
                  </td>
                  <td>
                    {{ x.quantity }}
                  </td>
                  <td style="border: black solid 1px"></td>
                  <td>
                    {{ x.comment }}
                  </td>

                  <!--
                <td>
                  <input
                    v-if="dep == 'accounting'"
                    type="number"
                    v-model="x.purchases"
                  />
                  <span v-else>{{ x.purchases }}</span>
                </td>
                <td>
                  <input
                    v-if="assign == 'purchases'"
                    type="number"
                    v-model="x.offerprice"
                  />
                </td>
                <td>
                  <span
                    v-if="
                      x.offerprice * x.purchases > 0 && assign == 'purchases'
                    "
                    >{{ x.offerprice * x.purchases }}</span
                  >
                </td> -->
                </tr>
              </tbody>
            </table>
            <div style="float: left; padding: 5%">
              <h6>الختم</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
// import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  components: {},
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      date: "",
      assign: "",
      mobile: "",
      code: "",
      data: [],
      data2: [],
      type: "supplyorder",
      products: [],
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
      costn: "",
      costname: "",
      address: "",
      activity: "",
      status: "",
      dep: store.state.dep,
      reassign: "",
      comment: "",
      url: "",
      description: "",
    };
  },
  validations() {
    return {};
  },
  async mounted() {
    const result2 = await axios.get(`/data/taskview/${this.$route.params.id}`);
    if (result2.status == 200) {
      const data = result2.data.data[0];
      this.data = JSON.parse(data.details);
      this.alldata = data;
      this.type = data.type;
      this.assign = data.assign;
      this.date = data.date;
      this.costname = data.costname;
      this.address = data.address;
      this.status = data.status;
      this.description = data.description;
      // if (this.status == "waiting") {
      for (let i = 0; i < this.data.length; i++) {
        this.data2.push({
          namear: this.data[i].namear,
          nameen: this.data[i].nameen,
          code: this.data[i].sn,
          unittype: this.data[i].unittype,
          quantity: this.data[i].quantity,
          comment: this.data[i].comment,
          stock: "",
          purchases: "",
        });
      }
      // }

      // if (this.dep == "accounting") {
      //   for (let i = 0; i < this.data2.length; i++) {
      //     const stockvalidationbalance = await axios.get(
      //       `/reports/stockvalidationbalance/${this.data2[i].code}`
      //     );
      //     // console.log(stockvalidationbalance.data.data[1]);
      //     // this.data2.set(i, stockvalidationbalance.data.data[1]);
      //     this.data2[i].stock = stockvalidationbalance.data.data[1];
      //   }
      // }
      this.activity = result2.data.data2;
      this.url =
        store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
    }
    // const result7 = await axios.get("/data/getcostcenter");
    // if (result7.status == 200) {
    //   this.branches = result7.data.data;
    //   this.level1 = this.branches.filter((x) => x.level == 1);
    // }
  },
  methods: {
    purchaseorder() {
      Swal.fire({
        title: this.$t("send") + " " + this.$t("task"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/sendpurchaseorder", {
            id: this.$route.params.id,
            assign: "purchases",
            data: JSON.stringify(this.data2),
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    createinvoice() {
      Swal.fire({
        title: this.$t("create") + " " + this.$t("invoice"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/createinvoicetask", {
            id: this.$route.params.id,
            assign: "purchase",
            data: JSON.stringify(this.data2),
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    reassignaction() {
      Swal.fire({
        title: "اعادة توجية المهمة",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/reassigntask", {
            id: this.$route.params.id,
            assign: this.reassign,
            username: store.state.randp.username,
            comment: this.comment,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    reseivetask() {
      Swal.fire({
        title: "استلام المهمة",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/reseivetask", {
            id: this.$route.params.id,
            assign: this.reassign,
            username: store.state.randp.username,
            comment: this.comment,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "AllTasksView" });
          }
        }
      });
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: `Supply Order-${this.$route.params.id}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    parent() {
      this.level2 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent.actnum) &&
          x.actnum != this.parent.actnum
      );
      this.costname = this.parent.namear;
      this.costn = this.parent.actnum;
    },
    parent2() {
      this.costname = this.parent2.namear;
      this.costn = this.parent2.actnum;
    },
    ccenter() {
      if (this.ccenter == false) {
        this.costname = "";
        this.costn = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 3%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  // width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.body {
  display: flex;
  margin: 1%;
}

.part1 {
  width: 50%;
}

.body2 {
  width: 100%;
  padding: 3%;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
}

.activity {
  // display: flex;
  margin-top: 2%;
  width: 75%;
  font-size: small;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
