<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link class="active" to="/stock/HomeStock">{{
            $t("stock")
          }}</router-link>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#"
            >{{ $t("summary") }} {{ $t("invoices") }} {{ $t("sales") }}
            {{ $t("and") }} {{ $t("purchases") }}</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <span>{{ $t("type") }}</span>
        <select v-model="type">
          <option value="purchases">{{ $t("purchases") }}</option>
          <option value="sales">{{ $t("sales") }}</option>
        </select>
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
        <!-- <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select> -->
      </div>
      <div class="head2"></div>
    </div>
    <div class="body" v-if="this.data1.length == 0 && this.data2.length == 0">
      برجاء تحديد المدة المطلوبة
    </div>
    <div class="body" v-if="this.data1.length > 0 || this.data2.length > 0">
      <div id="main">
        <table
          class="table"
          v-if="this.type == 'purchases'"
          :style="{ direction: css.EN }"
        >
          <thead>
            <tr style="text-align: center">
              <th colspan="5">
                {{ $t("purchases") }} {{ $t("from") }} : {{ fromdate }}
                {{ $t("to") }} : {{ todate }}
              </th>
            </tr>
            <tr>
              <th>{{ $t("sn") }}</th>
              <th>{{ $t("product") }}</th>
              <th>{{ $t("quantity") }}</th>
              <th>{{ $t("amount") }}</th>
              <th>{{ $t("price") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in data1" :key="x">
              <td>{{ x.sn }}</td>
              <td>
                <span v-if="css.lang == 'ar'">{{ x.pnamear }}</span
                ><span v-if="css.lang == 'en'">{{ x.pnameen }}</span>
              </td>
              <td>{{ x.totalq }} {{ x.unittype }}</td>
              <td>{{ x.totalp }}</td>
              <td>{{ (x.totalp / x.totalq).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <table
          class="table"
          v-if="this.type == 'sales'"
          :style="{ direction: css.EN }"
        >
          <thead>
            <tr style="text-align: center">
              <th colspan="5">
                {{ $t("sales") }} {{ $t("from") }} : {{ fromdate }}
                {{ $t("to") }} : {{ todate }}
              </th>
            </tr>
            <tr>
              <th>{{ $t("sn") }}</th>
              <th>{{ $t("product") }}</th>
              <th>{{ $t("quantity") }}</th>
              <th>{{ $t("amount") }}</th>
              <th>{{ $t("price") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in data2" :key="x">
              <td>{{ x.sn }}</td>
              <td>
                <span v-if="css.lang == 'ar'">{{ x.pnamear }}</span
                ><span v-if="css.lang == 'en'">{{ x.pnameen }}</span>
              </td>
              <td>{{ x.totalq }} {{ x.unittype }}</td>
              <td>{{ x.totalp }}</td>
              <td>{{ (x.totalp / x.totalq).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-primary" type="submit" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import html2pdf from "html2pdf.js";

export default {
  //   async mounted() {
  //     const result = await axios.get(
  //       `/reports/allbuyandsales/${this.fromdate}/${this.todate}`
  //     );
  //     if (result.status == 200) {
  //       const data1 = result.data.data;
  //       const data2 = result.data.data2;

  //       const uniqueIds = new Set();
  //       const data11 = data1.filter((element) => {
  //         const isDuplicate = uniqueIds.has(element.transnum);
  //         uniqueIds.add(element.transnum);
  //         if (!isDuplicate) {
  //           return true;
  //         }
  //         return false;
  //       });
  //       for (let i = 0; i < data11.length; i++) {
  //         const alldata = data1.filter((x) => x.transnum == data11[i].transnum);
  //         const total = alldata.reduce(
  //           (acc, array) => acc + Number(array.pdpt),
  //           0
  //         );
  //         this.data1.push({
  //           transnum: data11[i].transnum,
  //           data: alldata,
  //           total: total,
  //         });
  //       }
  //       const data22 = data2.filter((element) => {
  //         const isDuplicate = uniqueIds.has(element.transnum);
  //         uniqueIds.add(element.transnum);
  //         if (!isDuplicate) {
  //           return true;
  //         }
  //         return false;
  //       });
  //       for (let i = 0; i < data22.length; i++) {
  //         const alldata = data2.filter((x) => x.transnum === data22[i].transnum);
  //         const total = alldata.reduce(
  //           (acc, array) => acc + Number(array.amount),
  //           0
  //         );
  //         this.data2.push({
  //           transnum: data22[i].transnum,
  //           data: alldata,
  //           total: total,
  //         });
  //       }

  //       this.purchasenum = this.data1.length;
  //       this.salenum = this.data2.length;
  //     }
  //   },
  props: ["css"],
  data() {
    return {
      fromdate: moment().startOf("month").format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      data2: [],
      from: "",
      to: "",
      purchasenum: 0,
      type: "purchases",
      file: null,
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllReports/GeneralLedgerSummaryMonthly/${val.actnum}/${val.namear}/${val.nameen}/${val.typeact}`,
      });
    },
    async submit() {
      this.data1 = [];
      this.data2 = [];
      const result = await axios.get(
        `/reports/allbuyandsales/${this.from}/${this.to}`
      );
      if (result.status == 200) {
        const data1 = result.data.data;
        const data2 = result.data.data2;

        const uniqueIds = new Set();
        const data11 = data1.filter((element) => {
          const isDuplicate = uniqueIds.has(element.psn);
          uniqueIds.add(element.psn);
          if (!isDuplicate) {
            return true;
          }
          return false;
        });
        for (let i = 0; i < data11.length; i++) {
          const alldata = data1.filter((x) => x.psn === data11[i].psn);
          const totalq = alldata.reduce(
            (acc, array) => acc + Number(array.qdpt),
            0
          );
          const totalp = alldata
            .reduce((acc, array) => acc + Number(array.pdpt), 0)
            .toFixed(2);
          this.data1.push({
            sn: data11[i].psn,
            pnamear: data11[i].pnamear,
            pnameen: data11[i].pnameen,
            totalp: totalp,
            totalq: totalq,
          });
        }
        const uniqueIds2 = new Set();
        const data22 = data2.filter((element) => {
          const isDuplicate = uniqueIds2.has(element.psn);
          uniqueIds2.add(element.psn);
          if (!isDuplicate) {
            return true;
          }
          return false;
        });
        for (let i = 0; i < data22.length; i++) {
          const alldata = data2.filter((x) => x.psn === data22[i].psn);
          const totalq = alldata.reduce(
            (acc, array) => acc + Number(array.qcrd),
            0
          );
          const totalp = alldata
            .reduce((acc, array) => acc + Number(array.amount), 0)
            .toFixed(2);
          this.data2.push({
            sn: data22[i].psn,
            pnamear: data22[i].pnamear,
            pnameen: data22[i].pnameen,
            totalp: totalp,
            totalq: totalq,
          });
        }
        this.fromdate = this.from;
        this.todate = this.to;
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "barcode.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
