<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("Accounts") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active">{{ $t("balance") }} {{ $t("opening") }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                  <a href="#" class="btn-download">
                    <span class="text">Download PDF</span>
                  </a>
                </li> -->
        <li>
          <router-link
            to="/AdminDashboard/JournalEntry/OpeningBalance"
            class="btn-download"
          >
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("balance") }} {{ $t("opening") }}</h3>
      </div>
      <div class="body">
        <div class="stitle">
          <div to="/AdminDashboard/AllProducts" class="sstitle">
            {{ $t("step") }} {{ $t("first") }}<br />
            <small
              >{{ $t("type") }} {{ $t("balance") }} {{ $t("opening") }}</small
            >
          </div>
          <div to="/AdminDashboard/AllProducts" class="sstitle2">
            {{ $t("step") }} {{ $t("second") }}<br />
            <small> {{ $t("details") }}</small>
          </div>
          <div class="arrow2"></div>
        </div>

        <div class="body2" v-if="view == 1">
          <h3>{{ $t("Accounts") }}</h3>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="date"
                />
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("balance") }} {{ $t("opening") }}
              </label>

              <div class="col-sm-1">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="actnum"
                  @change="
                    this.namear = actnum.namear;
                    this.nameen = actnum.nameen;
                    this.actnum = actnum.actnum;
                    this.typeact = actnum.typeact;
                  "
                >
                  <option v-for="val in data1" :key="val" :value="val">
                    {{ val.namear }}
                  </option>
                </select>
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ actnum }} - {{ namear }}
              </label>
            </div>
            <!--  -->
            <br />
            <div class="">
              <h6>{{ $t("details") }}</h6>
              <button
                class="btn btn-primary"
                @click="add"
                style="padding: 0.25%"
              >
                {{ $t("add") }} <i class="bi bi-plus"></i>
              </button>
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("Account") }}</th>
                    <th>{{ $t("balance") }} {{ $t("opening") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="value of data" :key="value">
                    <td>
                      <select
                        class="form-control form-control-sm"
                        @change="
                          if (
                            this.data.length != 1 &&
                            value.data.typeact != this.data[0].data.typeact
                          ) {
                            value.data = {};
                            alert('');
                          }
                        "
                        v-model="value.data"
                      >
                        <option v-for="val in data1" :key="val" :value="val">
                          {{ val.namear }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        @change="total"
                        v-model="value.amount"
                      />
                    </td>
                    <td>
                      <a href="#" @click.prevent="del(value)"
                        ><i class="bi bi-x-circle-fill"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>{{ $t("total") }}</td>
                    <td>{{ totalamount }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!--  -->
            <div class="buttom">
              <button
                class="btn btn-success"
                :disabled="disabled"
                type="submit"
                @click="submit1"
              >
                {{ $t("save") }}
              </button>
              <button class="btn btn-dark" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div class="body2" v-if="view == 2">
          <h3>{{ $t("products") }} {{ $t("and") }} {{ $t("costs") }}</h3>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="date"
                />
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("balance") }} {{ $t("opening") }}
              </label>

              <div class="col-sm-3">
                <v-select :options="data1" label="namear" v-model="actnum">
                  <template #option="option">
                    <span>{{ option.actnum }} {{ option.namear }}</span>
                  </template>
                </v-select>
              </div>
            </div>

            <!--  -->
            <br />
            <div class="">
              <h6>{{ $t("details") }}</h6>
              <button
                class="btn btn-primary"
                @click="add"
                style="padding: 0.25%"
              >
                {{ $t("add") }} <i class="bi bi-plus"></i>
              </button>
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("branch") }}</th>
                    <th>{{ $t("product") }}</th>
                    <th>{{ $t("balance") }} {{ $t("opening") }}</th>
                    <th>{{ $t("quantity") }}</th>
                    <th>{{ $t("cancel") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="value of data" :key="value">
                    <td>
                      <select
                        class="form-control form-control-sm"
                        v-model="value.branch"
                      >
                        <option
                          v-for="val in branches"
                          :key="val"
                          :value="val.bid"
                        >
                          {{ val.bid }} - {{ val.namear }}
                        </option>
                      </select>
                    </td>
                    <td style="width: 20%">
                      <v-select
                        :options="data2"
                        label="namear"
                        v-model="value.product"
                      >
                        <template #option="option">
                          <span> {{ option.namear }}</span>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        @change="total"
                        v-model="value.amount"
                      />
                    </td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        v-model="value.quantity"
                      />
                    </td>
                    <td>
                      <a href="#" @click.prevent="del(value)"
                        ><i class="bi bi-x-circle-fill"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td>{{ $t("total") }}</td>
                    <td>{{ totalamount }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="buttom">
              <button
                class="btn btn-success"
                :disabled="disabled"
                type="submit"
                @click="submit2"
              >
                {{ $t("save") }}
              </button>
              <button class="btn btn-dark" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div class="body2" v-if="view == 3">
          <h3>{{ $t("customers") }}</h3>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="date"
                />
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("balance") }} {{ $t("opening") }}
              </label>

              <div class="col-sm-3">
                <v-select :options="data1" label="namear" v-model="actnum">
                  <template #option="option">
                    <span>{{ option.actnum }} {{ option.namear }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <!--  -->
            <br />
            <div class="">
              <h6>{{ $t("details") }}</h6>
              <button
                class="btn btn-primary"
                @click="add"
                style="padding: 0.25%"
              >
                {{ $t("add") }} <i class="bi bi-plus"></i>
              </button>
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("customers") }}</th>
                    <th>{{ $t("balance") }} {{ $t("opening") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="value of data" :key="value">
                    <td style="width: 60%">
                      <v-select
                        :options="customers"
                        label="name"
                        v-model="value.cdata"
                      >
                        <template #option="option">
                          <span>{{ option.name }}</span>
                        </template>
                      </v-select>
                    </td>
                    <td style="width: 20%">
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        @change="total"
                        v-model="value.amount"
                      />
                    </td>
                    <td>
                      <a href="#" @click.prevent="del(value)"
                        ><i class="bi bi-x-circle-fill"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>{{ $t("total") }}</td>
                    <td>{{ totalamount }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!--  -->
            <div class="buttom">
              <button
                class="btn btn-primary"
                :disabled="disabled"
                type="submit"
                @click="submit3"
              >
                {{ $t("save") }}
              </button>
              <button class="btn btn-dark" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div class="body2" v-if="view == 4">
          <h3>{{ $t("suppliers") }}</h3>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="date"
                />
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("balance") }} {{ $t("opening") }}
              </label>

              <div class="col-sm-3">
                <v-select :options="data1" label="namear" v-model="actnum">
                  <template #option="option">
                    <span>{{ option.actnum }} {{ option.namear }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <!--  -->
            <br />
            <div class="">
              <h6>{{ $t("details") }}</h6>
              <button
                class="btn btn-primary"
                @click="add"
                style="padding: 0.25%"
              >
                {{ $t("add") }} <i class="bi bi-plus"></i>
              </button>
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("suppliers") }}</th>
                    <th>{{ $t("balance") }} {{ $t("opening") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="value of data" :key="value">
                    <td style="width: 60%">
                      <v-select
                        :options="customers"
                        label="name"
                        v-model="value.cdata"
                      >
                        <template #option="option">
                          <span>{{ option.name }}</span>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        @change="total"
                        v-model="value.amount"
                      />
                    </td>
                    <td>
                      <a href="#" @click.prevent="del(value)"
                        ><i class="bi bi-x-circle-fill"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>{{ $t("total") }}</td>
                    <td>{{ totalamount }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!--  -->
            <div class="buttom">
              <button
                class="btn btn-primary"
                :disabled="disabled"
                type="submit"
                @click="submit4"
              >
                {{ $t("save") }}
              </button>
              <button class="btn btn-dark" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
import vSelect from "vue-select";
export default {
  props: ["css"],
  components: {
    vSelect,
  },
  async mounted() {
    this.view = this.$route.params.id;
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    if (this.view == 1) {
      const result = await axios.get("/data/getaccounttrue");
      if (result.status == 200) {
        this.data1 = result.data.data;
      }
    }
    if (this.view == 2) {
      const result = await axios.get("/data/getaccounttrue");
      if (result.status == 200) {
        this.data1 = result.data.data;
      }
      const result2 = await axios.get("/data/allbranches");
      if (result2.status == 200) {
        this.branches = result2.data.data;
      }
      const result3 = await axios.get("/data/allproducts");
      if (result3.status == 200) {
        this.data2 = result3.data.data;
      }
    }
    if (this.view == 3) {
      const result = await axios.get("/data/getaccounttrue");
      if (result.status == 200) {
        this.data1 = result.data.data;
      }
      const result3 = await axios.get("/data/allcustomer");
      if (result3.status == 200) {
        this.customers = result3.data.data;
      }
    }
    if (this.view == 4) {
      const result = await axios.get("/data/getaccounttrue");
      if (result.status == 200) {
        this.data1 = result.data.data;
      }
      const result3 = await axios.get("/data/allsupplier");
      if (result3.status == 200) {
        this.customers = result3.data.data;
      }
    }
  },
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      view: 0,
      date: "",
      data: [{}],
      description: "",
      unittype: "",
      tax: "",
      actnum: "",
      namear: "",
      nameen: "",
      typeact: "",
      totalamount: 0,
      data1: [],
      data2: [],
      branches: [],
      customers: [],
      selection: false,
      filter: "",
      item: "",
    };
  },
  validations() {
    return {
      date: { required },
      description: { required },
      actnum: { required },
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/JournalEntry",
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async submit1() {
      this.v$.$validate();
      if (!this.v$.$error) {
        Swal.fire({
          title:
            this.$t("confirm") +
            " " +
            this.$t("balance") +
            " " +
            this.$t("opening"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.disabled = true;
            const result = await axios.post("/data/accountopenbalance", {
              date: this.date,
              username: store.state.randp.username,
              description: this.description,
              namear: this.namear,
              nameen: this.nameen,
              actnum: this.actnum,
              total: this.totalamount,
              detail: JSON.stringify(this.data),
              typeact: this.data[0].data.typeact,
            });
            if (result.status == 200) {
              this.redirectTo({ val: "JournalEntry" });
              const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Data Entry successfully",
              });
              // this.redirectTo({ val: "Home" });
            } else if (result.status == 401) {
              alert("Account Already Created Before");
            }
          }
        });
      }
    },
    async submit2() {
      this.v$.$validate();
      if (!this.v$.$error) {
        Swal.fire({
          title:
            this.$t("confirm") +
            " " +
            this.$t("balance") +
            " " +
            this.$t("opening"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.disabled = true;
            const result = await axios.post("/data/productopenbalance", {
              date: this.date,
              username: store.state.randp.username,
              description: this.description,
              namear: this.actnum.namear,
              nameen: this.actnum.nameen,
              actnum: this.actnum.actnum,
              total: this.totalamount,
              detail: JSON.stringify(this.data),
            });
            if (result.status == 200) {
              this.redirectTo({ val: "JournalEntry" });
              const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Data Entry successfully",
              });
              // this.redirectTo({ val: "Home" });
            } else if (result.status == 401) {
              alert("Account Already Created Before");
            }
          }
        });
      }
    },
    async submit3() {
      this.v$.$validate();
      if (!this.v$.$error) {
        Swal.fire({
          title:
            this.$t("confirm") +
            " " +
            this.$t("balance") +
            " " +
            this.$t("opening"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.disabled = true;
            const result = await axios.post("/data/customeropenbalance", {
              date: this.date,
              username: store.state.randp.username,
              description: this.description,
              namear: this.actnum.namear,
              nameen: this.actnum.nameen,
              actnum: this.actnum.actnum,
              total: this.totalamount,
              detail: JSON.stringify(this.data),
            });
            if (result.status == 200) {
              this.redirectTo({ val: "JournalEntry" });
              const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Data Entry successfully",
              });
              // this.redirectTo({ val: "Home" });
            } else if (result.status == 401) {
              alert("Account Already Created Before");
            }
          }
        });
      }
    },
    async submit4() {
      this.v$.$validate();
      if (!this.v$.$error) {
        Swal.fire({
          title:
            this.$t("confirm") +
            " " +
            this.$t("balance") +
            " " +
            this.$t("opening"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.disabled = true;
            const result = await axios.post("/data/supplieropenbalance", {
              date: this.date,
              username: store.state.randp.username,
              description: this.description,
              namear: this.actnum.namear,
              nameen: this.actnum.nameen,
              actnum: this.actnum.actnum,
              total: this.totalamount,
              detail: JSON.stringify(this.data),
            });
            if (result.status == 200) {
              this.redirectTo({ val: "JournalEntry" });
              const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Data Entry successfully",
              });
              // this.redirectTo({ val: "Home" });
            } else if (result.status == 401) {
              alert("Account Already Created Before");
            }
          }
        });
      }
    },
    add() {
      this.data.push({});
    },
    del(value) {
      const indexOfObject = this.data.findIndex((object) => {
        return (
          object.namear === value.namear &&
          object.nameen === value.nameen &&
          object.details === value.details
        );
      });
      this.data.splice(indexOfObject, 1);
    },
    total() {
      this.totalamount = this.data.reduce(
        (acc, array) => acc + Number(array.amount),
        0
      );
    },
    print() {
      html2pdf(document.getElementById("barcode"), {
        margin: 0,
        filename: "barcode.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data1 = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;
  padding: 0.25%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.15%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.table {
  text-align: center;
}
// .table tr {
//   background: rgba(191, 214, 219, 0.534);
//   line-height: 5px;
// }
button {
  margin: 1%;
  text-align: center;
}
.buttom {
  text-align: center;
}

.bg-gold {
  background: gold !important;
}
.bg-gray {
  background: gray !important;
}
.color-red {
  color: red !important;
}
.stitle {
  background-color: #aaaaaa;
  display: flex;
  text-align: center;
  //   padding: 1%;
}
.sstitle {
  text-align: center;
  background-color: v-bind("css.color");
  //   border: solid 2px rgba(128, 128, 128, 0.589);
  //   margin: 0.25%;
  color: white;
  padding: 1%;
}
.arrow {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 20px solid v-bind("css.color");
}
.sstitle2 {
  text-align: center;
  background-color: v-bind("css.color");
  //   border: solid 2px rgba(128, 128, 128, 0.589);
  color: white;
  padding: 1%;
  color: black;
}
.arrow2 {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 20px solid v-bind("css.color");
  color: black;
}
.main .body2 {
  padding: 2%;
  text-align: center;
  background-color: white;
}
.main .body3 {
  display: flex;
}
.comment {
  font-size: small;
}
.form1 {
  padding: 1%;
  // border: black solid 1px;
  text-align: right;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  // border: black solid 1px;
}

#preview img {
  max-width: 100%;
  max-height: 400px;
}
.prodetails {
  text-align: v-bind("css.dirn");
}
.prodetails .table {
  text-align: v-bind("css.dirn");
  margin: 1%;
  width: 50%;
}
img {
  border: black 1px solid;
  padding: 0.5%;
  width: 400px;
  height: 200px;
  border-radius: 20px;
}
.options {
  height: 150px;
  margin: 1%;
  background-color: v-bind("css.light");
  color: v-bind("css.dark");
  padding: 2%;
  overflow-y: auto;
}
.options ul li {
  margin: 0.5%;
}
.options ul li:hover {
  color: white;
  background-color: black;
}
.table {
  width: 70%;
}
@media screen and (max-width: 576px) {
  .table {
    width: 100%;
  }
}
</style>
