<template>
    <div class="page">
      <div class="head-title">
        <div class="left">
          <ul class="breadcrumb">
            <li>
              <a href="#">{{ $t("roles") }}</a>
            </li>
            <li>-</li>
            <li>
              <a class="active" href="#"> {{ $t("add") }} {{ $t("roles") }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="head-option">
        <ul>
          <li>
            <router-link to="/AdminDashboard/roles" class="btn-download">
              <span class="text">
                <i class="bi bi-arrow-left-square-fill"></i> Back</span
              >
            </router-link>
          </li>
        </ul>
      </div>
      <div class="main">
        <div class="head">
          <h3>{{ $t("add") }} {{ $t("roles") }}</h3>
        </div>
        <div class="body">
          <div class="part1">
            <div class="input-group">
              <span class="input-group-text">{{ $t("name") }}

                <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span>
              </span>
              <input type="text" class="form-control" v-model="name" />
            </div>
          </div>
          <div class="permissions">
            <h4>{{ $t("permissions") }}</h4>
            <div v-for="(module, index) in groupedPermissions" :key="index">
              <h5>{{ module.module }}</h5>
              <ul>
                <li v-for="permission in module.permissions" :key="permission.id">
                  <input
                    type="checkbox"
                    :id="`permission-${permission.id}`"
                    v-model="selectedPermissions"
                    :value="permission.id"
                  />
                  <label class="check-label" :for="`permission-${permission.id}`">
                    {{ permission.name }} - {{ permission.description }}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="buttom">
          <button class="buttonconfirm" type="submit" @click="create">
            {{ $t("add") }}
          </button>
        </div>
      </div>
    </div>
  </template>

  <script>
//   import store from "@/store";
  import useValidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import axios from "axios";
  import Swal from "sweetalert2";
  import { mapActions } from "vuex";

  export default {
    async mounted() {
      await this.fetchPermissions();
    },
    props: ["css"],
    data() {
      return {
        v$: useValidate(),
        data: "",
        permissions: [],
        groupedPermissions: [],
        selectedPermissions: [],
        name: "",

      };
    },
    validations() {
      return {
        name: { required },

      };
    },
    methods: {
      async fetchPermissions() {
        const result = await axios.get("roles/permissions");
        if (result.status == 200) {
          this.groupedPermissions = result.data.data;
        }
      },
      async create() {
        console.log('d');
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const result = await axios.post(`/roles`, {
            name: this.name,
            permissions: this.selectedPermissions,
          });
          if (result.status === 200) {
            // Success handling
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: "Role created successfully",
            });
            // Reset form or redirect as needed
            this.rolename = "";
            this.selectedPermissions = [];
          } else if (result.status === 401) {
            alert("Unauthorized");
          } else {
            alert("Error creating role");
          }
        } catch (error) {
          console.error("Error creating role:", error);
          alert("Failed to create role");
        }
      }
    },
      ...mapActions(["redirectTo"]),
    },
    watch: {
      branch() {
        this.branchname = this.branch.namear;
        this.branchno = this.branch.id;
      },
      async typeofuser() {
        if (this.typeofuser == "customer") {
          const result1 = await axios.get(`/data/getcustomers/${this.cnum.cnum}`);
          if (result1.status == 200) {
            this.allcustomer = result1.data.data;
          }
        }
      },
    },
  };
  </script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  padding: 0.5%;
}
.head-option ul li {
  display: inline;
  padding: 0.5%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  display: flex;
  flex-direction: column;
}

.main .body .permissions {
  width: 100%;
  margin: 1%;
  background: v-bind("css.light");
}
.main .body .permissions h4 {
  margin-bottom: 1%;
}
.main .body .permissions h5 {
  margin-top: 1%;
}
.main .body .permissions ul {
  list-style-type: none;
  padding: 0;
}
.main .body .permissions ul li {
  margin: 0.5% 0;
  display: flex;
  align-items: center;
}
.main .body .permissions ul li input[type="checkbox"] {
  margin-right: 8px;
}
.main .body .permissions ul li label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #555;
}
.main .body .permissions ul li label .permission-name {
  font-weight: bold;
  color: #333;
}
.main .body .permissions ul li label .permission-description {
  font-size: 12px;
  color: #777;
}
.buttom {
  margin: 1%;
  text-align: center;
}
.table {
  background-color: white;
}

img {
  border: black 1px solid;
  padding: 0.5%;
  width: 200px;
  height: 300px;
  border-radius: 20px;
  margin-right: 25%;
}
@media screen and (max-width: 576px) {
  .main .body {
    background: v-bind("css.light");
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
    display: block;
  }

}
</style>
