<template>
  <LoadingPage v-if="loading"></LoadingPage>
  <div class="page" v-else>
    <h1>برجاء اختيار الشركة</h1>

    <div v-for="x in data1" :key="x" class="cards">
      <a href="#" @click="change2(x)">
        <img
          :src="`${this.$store.state.imgurl}/clogo/${x.cnum}.png`"
          crossorigin="anonymous"
          style="width: 100%"
        />
        <h6>{{ x.cname }}</h6></a
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LoadingPage from "../components/LoadingPage.vue";
import store from "@/store";
const CryptoJS = require("crypto-js");
export default {
  components: { LoadingPage },
  async mounted() {
    this.loading = true;
    const result = await axios.post(`/admin/allcontracts`, {
      username: store.state.randp.username,
    });
    if (result.status == 200) {
      this.data1 = result.data.data;
    }
    this.loading = false;
  },
  props: ["css"],
  data() {
    return {
      loading: false,
      data1: [],
      data2: "",
    };
  },
  methods: {
    change(x) {
    //   const data = this.$cookies.get("data");
      const data = localStorage.getItem("data");
      const passphrase = process.env.VUE_APP_SOCKET_ENDPOINT;
      const decrept = CryptoJS.AES.decrypt(data, passphrase);
      this.data2 = JSON.parse(decrept.toString(CryptoJS.enc.Utf8));
      this.data2[0].cnum = JSON.stringify([
        {
          cnum: x.cnum,
          cname: x.name,
          caddress: x.address,
          taxnum: x.taxnum,
        },
      ]);
      const data3 = JSON.stringify(this.data2);
      const crept = CryptoJS.AES.encrypt(data3, passphrase).toString();
      this.$cookies.set("data", crept);
      window.location.href = `${this.$store.state.surl}/AdminDashboard/Home`;
    },
    async change2(x) {
      let result = await axios.post("/user/authenticate2", {
        data: JSON.stringify({
          cnum: x.cnum,
          cname: x.cname,
          caddress: x.address,
          taxnum: x.taxnum,
        }),
      });
      if (result.status == 200) {
        var user = [result.data.data];

        const passphrase = process.env.VUE_APP_SOCKET_ENDPOINT;
        const data = JSON.stringify(user);
        const crept = CryptoJS.AES.encrypt(data, passphrase).toString();

        // this.$cookies.set("data", crept);
        localStorage.setItem('data',crept);
        this.$cookies.set("token", result.data.data.token);

        window.location.href = `${this.$store.state.surl}/AdminDashboard/Home`;
      }
    },
  },
};
</script>
<style scoped>
.cards {
  width: 10%;
  height: 150px;
  float: right;
  padding: 1%;
  margin: 2%;
  /* border: 1px solid v-bind("css.color"); */
  border-radius: 5%;
  background-color: white;
  color: black;
}
.cards img {
  width: 200px;
  height: 100px;
  padding: 1%;
}
.cards:hover {
  width: 10%;
  height: 150px;
  float: right;
  padding: 1%;
  margin: 2%;
  border: 1px solid v-bind("css.color");
  border-radius: 5%;
  background-color: black;
  color: white;
}
h6 {
  text-align: center;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 576px) {
  .cards {
    width: 25%;
    height: 150px;
    float: right;
    padding: 1%;
    margin: 1%;
    /* border: 1px solid v-bind("css.color"); */
    border-radius: 5%;
    background-color: white;
    color: black;
  }
  .cards img {
    width: 200px;
    height: 80px;
    padding: 3%;
  }
  h1 {
    text-align: center;
    font-size: small;
  }
  .cards:hover {
    width: 25%;
    height: 110px;
    float: right;
    padding: 1%;
    margin: 1%;
    border-radius: 5%;
    background-color: black;
    color: white;
  }
}
</style>
