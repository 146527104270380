<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#">{{ $t("users") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#"> {{ $t("add") }} {{ $t("user") }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="head-option">
            <ul>
                <!-- <li>
                    <a href="#" class="btn-download">
                      <span class="text">Download PDF</span>
                    </a>
                  </li> -->
                <li>
                    <router-link to="/AdminDashboard/RAndP" class="btn-download">
                        <span class="text">
                            <i class="bi bi-arrow-left-square-fill"></i> Back</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="main">
            <div class="head">
                <h3>{{ $t("add") }} {{ $t("user") }}</h3>
            </div>
            <div class="body">
                <div class="part1">
                    <div class="input-group">
                        <span class="input-group-text">{{ $t("name") }}</span>
                        <input type="text" class="form-control" v-model="fullname" />
                    </div>
                    <div class="input-group">
                        <span class="input-group-text">{{ $t("username") }}</span>
                        <input type="text" class="form-control" v-model="username" />
                    </div>

                    <div class="input-group">
                        <span class="input-group-text">
                            {{ $t("company") }}
                        </span>
                        <select type="text" class="form-control" v-model="cnum">
                            <option v-for="x in companies" :key="x" :value="x">
                                {{ x.cname }}
                            </option>
                        </select>
                    </div>

                    <div class="input-group">
                        <span class="input-group-text">
                            {{ $t("type") }} {{ $t("user") }}
                        </span>
                        <select type="text" class="form-control" v-model="typeofuser">
                            <option value="Admin">Admin</option>
                            <option value="الحسابات">الحسابات</option>
                            <option value="user">مستخدم</option>
                            <option value="customer">عميل</option>
                        </select>
                    </div>
                    <div class="input-group" v-if="typeofuser == 'customer'">
                        <span class="input-group-text">
                            {{ $t("customer") }}
                        </span>
                        <select type="text" class="form-control" v-model="dep">
                            <option v-for="x in allcustomer" :key="x" :value="x.cid">
                                {{ x.name }}
                            </option>
                        </select>
                    </div>
                    <div class="input-group">
                        <span class="input-group-text">
                            {{ $t("branch") }}
                        </span>
                        <select type="text" class="form-control" v-model="branch">
                            <option v-for="x in branches" :key="x" :value="x">
                                {{ x.namear }}
                            </option>
                        </select>
                    </div>
                    <div class="input-group">
                        <span class="input-group-text">{{ $t("empno") }}</span>
                        <input type="text" class="form-control" v-model="empno" />
                    </div>
                </div>
                <div class="part1">
                    <div class="input-group">
                        <span class="input-group-text">{{ $t("residence") }}
                            <span class="error-feedback" v-if="v$.residence.$error">{{
                                "*"
                            }}</span></span>
                        <input type="text" class="form-control" v-model="residence" />
                        <span class="input-group-text">{{ $t("residence") }} {{ $t("date") }}
                            <span class="error-feedback" v-if="v$.residencedate.$error">{{
                                "*"
                            }}</span></span>
                        <input type="date" class="form-control" v-model="residencedate" />
                    </div>
                    <div class="input-group">
                        <span class="input-group-text">{{ $t("nationalid") }}
                            <span class="error-feedback" v-if="v$.nationalid.$error">{{
                                "*"
                            }}</span></span>
                        <input type="text" class="form-control" v-model="nationalid" />
                    </div>
                    <!-- <div class="input-group"> -->
                        <v-select multiple :options="roles" label="name" v-model="selectedRoles">
                            <template #option="option">
                                <span>{{ option.name }}</span>
                            </template>
                        </v-select>
                    <!-- </div> -->

                </div>
                <div class="part1">
                    <div class="input-group">
                        <span class="input-group-text">{{ $t("image") }}</span>
                        <input type="file" class="form-control form-control-sm" id="image" :name="this.sn"
                            @change="onFileChange" />
                    </div>
                    <div id="preview">
                        <img v-if="url" :src="url" />
                    </div>
                </div>
            </div>
            <div class="buttom">
                <button class="buttonconfirm" type="submit" @click="Edite">
                    {{ $t("add") }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";

export default {

    async mounted() {
        const result1 = await axios.get("/data/allbranches");
        if (result1.status == 200) {
            this.branches = result1.data.data;
        }
        const result2 = await axios.get("/admin/allcompanycontracts");
        if (result2.status == 200) {
            this.companies = result2.data.data;
        }
        const roles = await axios.get("/roles");
        if (roles.status == 200) {
            this.roles = roles.data.data;
        }

    },
    components: {
        vSelect,
    },

    props: ["css"],
    data() {
        return {
            v$: useValidate(),
            data: "",
            branches: [],
            companies: [],
            branch: "",
            branchname: "",
            branchno: "",
            fullname: "",
            username: "",
            typeofuser: "",
            empno: "",
            residence: "",
            residencedate: "",
            nationalid: "",
            url: "",
            allcustomer: [],
            dep: "",
            roles: [],
            selectedRoles: [],

        };
    },
    validations() {
        return {
            fullname: { required },
            residence: { required },
            residencedate: { required },
            nationalid: { required },
            selectedRoles: {
                required,
                minLength: minLength(1),
            },
        };
    },
    methods: {
        async Edite() {
            console.log('d');
            this.v$.$validate();
            if (!this.v$.$error) {
                const roleIds = this.selectedRoles.map(role => role.id);

                const result = await axios.post(`/user/adduser`, {
                    usern: store.state.randp.username,
                    fullname: this.fullname,
                    username: this.username,
                    typeofuser: this.typeofuser,
                    branchname: this.branchname,
                    branchno: this.branchno,
                    empno: this.empno,
                    residence: this.residence,
                    residencedate: this.residencedate,
                    nationalid: this.nationalid,
                    cnum: this.cnum,
                    dep: this.dep,
                    roleIds: roleIds,

                });
                if (result.status == 200) {
                    let formData = new FormData();
                    formData.append("file", this.file);
                    axios.patch(`/profile/${this.empno}`, formData);
                    this.redirectTo({ val: "RAndP" });
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: "success",
                        title: "Data Entry successfully",
                    });
                    // this.redirectTo({ val: "Home" });
                } else if (result.status == 401) {
                    alert("Account Already Created Before");
                }
            }
        },
        onFileChange(e) {
            const file = e.target.files[0];
            this.file = e.target.files[0];
            this.url = URL.createObjectURL(file);
        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
        branch() {
            this.branchname = this.branch.namear;
            this.branchno = this.branch.id;
        },
        async typeofuser() {
            if (this.typeofuser == "customer") {
                const result1 = await axios.get(`/data/getcustomers/${this.cnum.cnum}`);
                if (result1.status == 200) {
                    this.allcustomer = result1.data.data;
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 1%;
}

a {
    text-decoration: none;
}

.head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    flex-wrap: wrap;
}

.head-title .left h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #342e37;
}

.head-title .left .breadcrumb {
    display: flex;
    align-items: center;
    grid-gap: 16px;
}

.head-title .left .breadcrumb li {
    color: #342e37;
}

.head-title .left .breadcrumb li a {
    color: #aaaaaa;
    pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
    color: v-bind("css.color");
    pointer-events: unset;
}

.head-option {
    direction: v-bind("css.AR");
    width: 100%;
    // border: #342e37 1px solid;
    padding: 0.5%;
}

.head-option ul li {
    display: inline;
    padding: 0.5%;
}

.head-option .btn-download {
    width: 20%;
    padding: 0.5%;
    border-radius: 5px;
    background: v-bind("css.color");
    color: #f9f9f9;
}

.main .head {
    border-top: v-bind("css.color") 5px solid;
    background: v-bind("css.color");
    color: #f9f9f9;
    border-radius: 3px 3px 0px 0px;
    padding: 0.25%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
    background: v-bind("css.light");
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
    display: flex;
}

.main .body .part1 {
    width: 33%;
    margin-right: 1%;
    margin-left: 1%;
    background: v-bind("css.light");
}

.main .body .part1 .input-group {
    margin: 1%;
}

.main .body .part1 .input-group span {
    width: 25%;
    font-size: small;
}

.main .body .part2 {
    width: 25%;
    background: v-bind("css.light");
    padding-right: 2%;
    padding-left: 2%;
}

.main .body .part2 .part2head {
    background: rgba(191, 214, 219, 0.534);
    //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.main .body .part2 .part2body {
    box-shadow: rgba(75, 68, 68, 0.774) 0.25px 0.25px 1px;
    padding: 3%;
}

input:disabled {
    background-color: white;
}

.main .body .part3 {
    width: 18%;
    //   border: black 1px solid;
    padding: 1%;
    background-color: #ebe9e9;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
    border-radius: 10px;
}

.buttom {
    margin: 1%;
    text-align: center;
}

.table {
    background-color: white;
}

img {
    border: black 1px solid;
    padding: 0.5%;
    width: 200px;
    height: 300px;
    border-radius: 20px;
    margin-right: 25%;
}

@media screen and (max-width: 576px) {
    .main .body {
        background: v-bind("css.light");
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 1%;
        display: block;
    }

    .main .body .part1 {
        width: 90%;
        margin-right: 1%;
        margin-left: 1%;
        background: v-bind("css.light");
    }
}
</style>
