<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/stock/HomeStock" class="active">{{
              $t("stock")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ $t("damaged") }} {{ $t("products") }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("damaged") }} {{ $t("products") }}</h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submite">
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="part1"></div>
      </div>
      <div class="body2" v-if="type == 'supplyorder'">
        <div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("date") }}
              <span class="error-feedback" v-if="v$.date.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <input type="date" class="form-control" id="inputPassword3" v-model="date" />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("Account") }} {{ $t("cost") }}
              <span class="error-feedback" v-if="v$.account.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <v-select :options="accounts" label="namear" v-model="account">
                <template #option="option">
                  <div style="text-align: center">
                    <span>{{ option.namear }} </span>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("ccenter") }}
            </label>
            <div class="col-sm-3" style="display: flex">
              <select v-model="parent" class="form-control">
                <option v-for="x in level1" :key="x" :value="x">
                  {{ x.namear }}
                </option>
              </select>
              <select v-model="parent2" v-if="level2.length > 0" class="form-control">
                <option v-for="x in level2" :key="x" :value="x">
                  {{ x.namear }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{
              $t("product")
            }}</label>
            <div class="col-sm-3">
              <v-select :options="products" label="namear" v-model="product">
                <template #option="option">
                  <div style="text-align: center">
                    <span>{{ option.namear }} - {{ option.barcode }} -
                      {{ option.unittype }}
                    </span>
                  </div>
                </template>
              </v-select>
            </div>
          </div>

          <table class="table">
            <thead>
              <tr>
                <td colspan="7">{{ $t("details") }} {{ $t("materials") }}</td>
              </tr>
              <tr>
                <td></td>
                <td>{{ $t("materials") }}</td>
                <td>{{ $t("code") }}</td>
                <td>{{ $t("unit") }}</td>
                <td>{{ $t("stock") }}</td>
                <td>{{ $t("cost") }}</td>
                <td>
                  {{ $t("quantity") }}
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(x, id) in data" :key="x">
                <td>{{ id + 1 }}</td>
                <td>
                  {{ x.namear }}
                </td>
                <td>
                  {{ x.sn }}
                </td>
                <td>
                  {{ x.unittype }}
                </td>
                <td>
                  {{ x.stock }}
                </td>
                <td>
                  {{ x.cost }}
                </td>
                <td>
                  <input type="floating" v-model="x.quantity" />
                </td>
                <td>
                    <a href="#" @click.prevent="del(id)"
                      ><i class="bi bi-x-circle-fill"></i
                    ></a>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from "moment";

export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      date: moment().format("YYYY-MM-DD"),
      assign: "purchases",
      mobile: "",
      code: "",
      data: [],
      type: "supplyorder",
      products: [],
      product: {},
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      accounts: [],
      account: {},
      costn: "",
      costname: "",
      address: "",
      dep: store.state.dep,
    };
  },
  validations() {
    return {
      date: { required },
      account: { required },
    };
  },
  async mounted() {
    const result2 = await axios.get(`/data/allproducts`);
    const result = await axios.get("/data/getaccounttrue");
    if (result2.status == 200) {
      this.products = result2.data.data;
      this.accounts = result.data.data;
    }
    const result7 = await axios.get("/data/getcostcenter");
    if (result7.status == 200) {
      this.branches = result7.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
    }
  },
  methods: {
    submite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        Swal.fire({
          title: this.$t("create") + " " + this.$t("damaged"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            const action = await axios.post("/data/damagedproducts", {
              date: this.date,
              data: this.data,
              account: this.account,
              username: store.state.randp.username,
              costn: this.costn,
              costname: this.costname,
            });
            if (action.status == 200) {
              Swal.fire(this.$t("create") + " " + this.$t("damaged"));
              this.redirectTo({ val: "HomeStock" });
            }
          }
        });
      }
    },
    async del(id) {
        this.data.splice(id, 1);

      },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    parent() {
      this.level2 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent.actnum) &&
          x.actnum != this.parent.actnum
      );
      this.costname = this.parent.namear;
      this.costn = this.parent.actnum;
    },
    parent2() {
      this.costname = this.parent2.namear;
      this.costn = this.parent2.actnum;
    },
    ccenter() {
      if (this.ccenter == false) {
        this.costname = "";
        this.costn = "";
      }
    },
    type() {
      if (this.type == "supplyorder") {
        this.assign = "purchases";
      }
    },
    async product() {
      const filter = this.data.filter((x) => x.sn == this.product.sn);
      if (filter.length == 0) {
        const stockvalidationbalance = await axios.get(
          `/reports/stockvalidationbalance/${this.product.sn}`
        );
        if (stockvalidationbalance.status == 200) {
          const data = stockvalidationbalance.data.data[0][0];
          const stockbalance = data.dpt - data.crd;
          if (stockbalance > 0) {
            this.data.push({
              namear: this.product.namear,
              sn: this.product.sn,
              unittype: this.product.unittype,
              stock: stockbalance,
              cost: stockvalidationbalance.data.data[1],
              quantity: 0,
              comment: `تسجيل مخزون تالف لمنتج ${this.product.namear}`,
            });
            this.total = (this.data.reduce((acc, array) => acc + Number(array.cost), 0)
            ).toFixed(2)
          } else {
            alert("لا يوجد رصيد بالمخزون");
          }
        } else {
          alert("تم اضافة المنتج من قبل");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 2%;
  padding: 5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.body {
  display: flex;
  margin: 1%;
}

.part1 {
  width: 50%;
}

.body2 {
  width: 100%;
  padding: 1%;
  //   border: 1px solid black;
  border-radius: 5px;
  text-align: center;
}

table input {
  text-align: center;
  width: 50px;
  border: none;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: x-small;
  }
}
</style>
