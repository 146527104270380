<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("supply order") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"
              >{{ $t("add") }} {{ $t("supply order") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <li>
          <router-link to="/AdminDashboard/AllInvoices" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("supply order") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="part11">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("number") }}
              </label>
              <div class="col-sm-7">SO-{{ id }}</div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="date"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("description") }}
              </label>
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="description"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("customer") }}
              </label>
              <div class="col-sm-7">
                <v-select :options="customers" label="name" v-model="cdata">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("date") }} {{ $t("due") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="duedate"
                />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("date") }} {{ $t("supply") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="supplydate"
                />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("supply order") }}
              </label>
              <div class="col-sm-7">
                <v-select :options="data1" label="namear" v-model="soact">
                  <template #option="option">
                    <span>{{ option.namear }}</span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="part12">
            <h4>{{ $t("data") }} {{ $t("customer") }}</h4>

            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.name }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("mobile") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.mobile }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("email") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.email }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("tax") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.taxnumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="part2">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
              >{{ $t("barcode") }}
            </label>
            <div class="col-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                @change="getbarcode"
                v-model="barcode"
              />
            </div>
            <div style="width: 10%">
              <!-- Button trigger modal -->
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                @click="this.cam = !this.cam"
              >
                Scanner
              </button>

              <!-- Modal -->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Scanner
                      </h5>
                    </div>
                    <div class="modal-body" v-if="cam == true">
                      <qrcode-stream @detect="onDetect"></qrcode-stream>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        @click="this.cam = !this.cam"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
              >{{ $t("product") }}
            </label>
            <div class="col-sm-3">
              <v-select :options="products" label="namear" v-model="product">
                <template #option="option">
                  <span>{{ option.barcode }} - {{ option.namear }}</span>
                </template>
              </v-select>
            </div>
            <!-- <div class="col-sm-3">
              <button
                class="btn btn-primary"
                @click="Addproduct"
                style="padding: 0.25%"
              >
                {{ $t("add") }} <i class="bi bi-plus"></i>
              </button>
            </div> -->
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th style="width: 12%">{{ $t("product") }}</th>
                <th>{{ $t("stock") }}</th>
                <th>{{ $t("average") }} {{ $t("cost") }} {{ $t("stock") }}</th>
                <th>{{ $t("last") }} {{ $t("invoice") }}</th>
                <th>{{ $t("description") }}</th>
                <th style="width: 10%">{{ $t("quantity") }}</th>
                <th style="width: 10%">{{ $t("unit type") }}</th>
                <th>{{ $t("price") }}</th>
                <th>{{ $t("discount") }}</th>
                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                <th>{{ $t("tax") }}</th>
                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                <th>{{ $t("total") }}</th>
                <th>{{ $t("cancel") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) in data" :key="value">
                <td>{{ id + 1 }}</td>
                <td style="width: 12%">
                  <span>{{ value.barcode }} - {{ value.pronamear }}</span>
                </td>
                <td
                  style="background-color: red; color: white"
                  v-if="value.stockbalance <= 0"
                >
                  {{ value.stockbalance }}
                </td>
                <td
                  style="background-color: green; color: white"
                  v-if="value.stockbalance > 0"
                >
                  {{ value.stockbalance }} {{ value.unittype }}
                </td>
                <td
                  style="
                    background-color: green;
                    color: white;
                    font-size: smaller;
                  "
                  v-if="typeof value.stockbalance == 'string'"
                >
                  {{ value.stockbalance }}
                </td>
                <td>
                  <span v-if="value.stock == true">{{ value.avcost }}</span>
                </td>
                <td>{{ value.lastinvprice }}</td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="value.description"
                  />
                </td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="value.quantity"
                    @change="
                      value.tbt = Number(
                        value.salep * value.quantity -
                          (value.salep * value.quantity * value.discount) / 100
                      ).toFixed(2);
                      value.taxamount = Number(
                        (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.total = Number(
                        Number(value.tbt) + (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.costbt = Number(
                        value.buyp * value.quantity
                      ).toFixed(2);
                      value.cost = Number(
                        value.avcost * value.quantity
                      ).toFixed(2);
                      this.calc();
                    "
                  />
                </td>

                <td>{{ value.unittype }}</td>
                <td>
                  <input
                    type="number"
                    v-model="value.salep"
                    @change="
                      value.tbt = Number(
                        value.salep * value.quantity -
                          (value.salep * value.quantity * value.discount) / 100
                      ).toFixed(2);
                      value.taxamount = Number(
                        (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.total = Number(
                        Number(value.tbt) + (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.costbt = Number(
                        value.buyp * value.quantity
                      ).toFixed(2);
                      value.cost = Number(
                        value.avcost * value.quantity
                      ).toFixed(2);
                      this.calc();
                    "
                  />
                </td>
                <td style="display: flex">
                  <input
                    type="number"
                    v-model="value.discount"
                    style="width: 70%"
                    @change="
                      value.tbt = Number(
                        value.salep * value.quantity -
                          (value.salep * value.quantity * value.discount) / 100
                      ).toFixed(2);
                      value.taxamount = Number(
                        (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.total = Number(
                        Number(value.tbt) + (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.costbt = Number(
                        value.buyp * value.quantity
                      ).toFixed(2);
                      value.cost = Number(
                        value.avcost * value.quantity
                      ).toFixed(2);
                      this.calc();
                    "
                  />
                  %
                </td>
                <td>{{ value.tbt }}</td>
                <td style="display: flex">
                  <input
                    type="number"
                    v-model="value.tax"
                    style="width: 100px"
                    @change="
                      value.tbt = Number(
                        value.salep * value.quantity -
                          (value.salep * value.quantity * value.discount) / 100
                      ).toFixed(2);
                      value.taxamount = Number(
                        (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.total = Number(
                        Number(value.tbt) + (value.tbt * value.tax) / 100
                      ).toFixed(2);
                      value.costbt = Number(
                        value.buyp * value.quantity
                      ).toFixed(2);
                      value.cost = Number(
                        value.avcost * value.quantity
                      ).toFixed(2);
                      this.calc();
                    "
                  />
                  %
                </td>
                <td>{{ value.taxamount }}</td>
                <td>{{ value.total }}</td>
                <td>
                  <a href="#" @click.prevent="del(id)"
                    ><i class="bi bi-x-circle-fill"></i
                  ></a>
                </td>
                <td>
                  <!-- Button trigger modal -->
                  <button
                    type="button"
                    style="font-size: xx-small"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    @click="getcusttrans(value)"
                  >
                    {{ $t("lastinv") }}
                  </button>

                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            {{ $t("lastinv") }}
                            {{ $t("product") }}
                          </h5>
                        </div>
                        <div class="modal-body">
                          <div class="part11">
                            <div class="row mb-2">
                              <label
                                for="inputEmail3"
                                class="col-sm-2 col-form-label-sm"
                                >{{ $t("number") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  INV-{{ alldata2.invid }}
                                </div>
                              </div>
                            </div>
                            <div class="row mb-2" v-if="alldata2.qte != null">
                              <label
                                for="inputEmail3"
                                class="col-sm-2 col-form-label-sm"
                                >{{ $t("offer") }} {{ $t("price") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  QTE-{{ alldata2.qte }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label
                                for="inputEmail3"
                                class="col-sm-2 col-form-label-sm"
                                >{{ $t("date") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.due }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label
                                for="inputEmail3"
                                class="col-sm-2 col-form-label-sm"
                                >{{ $t("description") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.description }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label
                                for="inputEmail3"
                                class="col-sm-2 col-form-label-sm"
                                >{{ $t("date") }} {{ $t("due") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.duedate }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label
                                for="inputEmail3"
                                class="col-sm-2 col-form-label-sm"
                                >{{ $t("date") }} {{ $t("supply") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.supplydate }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <table class="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">
                                  {{ $t("unit type") }}
                                </th>
                                <th>{{ $t("price") }}</th>
                                <th>{{ $t("discount") }}</th>
                                <th>
                                  {{ $t("total") }} {{ $t("befor") }}
                                  {{ $t("tax") }}
                                </th>
                                <th>{{ $t("tax") }}</th>
                                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, id) in allgetcusttrans"
                                :key="value"
                              >
                                <td>{{ id + 1 }}</td>
                                <td>{{ value.pronamear }}</td>
                                <td>
                                  {{ value.description }}
                                </td>
                                <td>
                                  {{ value.quantity }}
                                </td>
                                <td>{{ value.unittype }}</td>
                                <td>{{ value.salep }}</td>
                                <td>{{ value.discount }} %</td>
                                <td>{{ value.tbt }}</td>
                                <td>{{ value.tax }} %</td>
                                <td>{{ value.taxamount }}</td>
                                <td>{{ value.total }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6"></td>
                <td>{{ $t("total") }}</td>
                <td>{{ tbt }}</td>
                <td></td>
                <td>{{ taxamount }}</td>
                <td>{{ total }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="row mb-2">
          <label class="col-sm-1 col-form-label-sm">{{ $t("branch") }} </label>
          <div class="col-sm-2">
            <select
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="branchno"
            >
              <option v-for="val in branches" :key="val" :value="val.bid">
                {{ val.namear }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("add") }} {{ $t("to") }}
          </label>

          <div class="col-sm-1">
            <input
              class="form-check-input"
              type="radio"
              value="project"
              v-model="addto"
            />
            &nbsp;
            <label class="form-check-label" for="gridRadios2">
              {{ $t("project") }}
            </label>
          </div>
          <div class="col-sm-1">
            <input
              class="form-check-input"
              type="radio"
              v-model="addto"
              value="task"
            />
            &nbsp;
            <label class="form-check-label" for="gridRadios2">
              {{ $t("task") }}
            </label>
          </div>
          <div class="row mb-2" v-if="addto == 'project'">
            <label class="col-sm-1 col-form-label-sm"
              >{{ $t("project") }}
            </label>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="projectno"
              >
                <option v-for="val in allprojects" :key="val" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="addto == 'task'">
            <label class="col-sm-1 col-form-label-sm">{{ $t("task") }} </label>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="taskno"
                @change="task"
              >
                <option v-for="val in alltasks" :key="val.id" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("notes") }}
          </label>
          <div class="col-sm-5">
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="notes"
            ></textarea>
          </div>
        </div>
        <div class="buttom">
          <button
            class="buttonconfirm"
            type="submit"
            :disabled="disabled"
            @click="submit"
          >
            {{ $t("save") }}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button class="buttoncancel" type="submit" @click="back">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import moment from "moment";
import QrcodeStream from "../../QR.vue";

export default {
  title: `Create Supply Order`,
  components: {
    vSelect,
    QrcodeStream,
  },

  props: ["css"],
  async mounted() {
    const result3 = await axios.get(`/data/allcustomer`);
    if (result3.status == 200) {
      this.customers = result3.data.data;
    }
    const result2 = await axios.get(`/data/allproducts`);
    if (result2.status == 200) {
      this.products = result2.data.data;
    }
    const result4 = await axios.get(`/data/allprojects`);
    const result5 = await axios.get(`/data/alltasks`);
    const result1 = await axios.get(`/data/allbranches`);
    const result6 = await axios.get(`/data/SupplyOrderID`);
    const result = await axios.get("/data/getaccounttrue");
    if (result4.status == 200) {
      this.data1 = result.data.data;
      this.allprojects = result4.data.data;
      this.alltasks = result5.data.data;
      this.branches = result1.data.data;
      this.id = Number(result6.data.data[0].count) + Number(1);
    }
  },
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      id: "",
      data: [],
      number: "",
      date: moment().format("YYYY-MM-DD"),
      description: "",
      customers: [],
      products: [],
      product: "",
      selection: false,
      customerid: "",
      customername: "",
      customerdata: "",
      taxamount: 0,
      tbt: 0,
      total: 0,
      addto: "",
      allprojects: [],
      alltasks: [],
      branches: [],
      taskno: "",
      projectno: "",
      branchno: "",
      duedate: moment().format("YYYY-MM-DD"),
      supplydate: moment().format("YYYY-MM-DD"),
      barcode: "",
      cdata: "",
      avcost: "",
      allgetcusttrans: "",
      alldata2: "",
      cam: false,
      data1: "",
      soact: "",
    };
  },
  validations() {
    return {
      date: { required },
      cdata: { required },
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllInvoices/CreateInvoice",
      });
    },
    calc() {
      this.tbt = Number(
        this.data.reduce((acc, array) => acc + Number(array.tbt), 0)
      ).toFixed(2);
      this.taxamount = Number(
        this.data.reduce((acc, array) => acc + Number(array.taxamount), 0)
      ).toFixed(2);
      this.total = Number(
        this.data.reduce((acc, array) => acc + Number(array.total), 0)
      ).toFixed(2);
    },
    async del(id) {
      this.data.splice(id, 1);
      this.calc();
    },
    task() {
      const project = this.alltasks.filter((x) => x.id == this.taskno)[0]
        .projectid;
      this.projectno = project;
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disabled = true;
        const result = await axios.post("/data/CreateSupplyOrder", {
          username: store.state.randp.username,
          due: this.date,
          description: this.description,
          duedate: this.duedate,
          supplydate: this.supplydate,
          cid: this.cdata.cid,
          cname: this.cdata.name,
          cmobile: this.cdata.mobile,
          cmail: this.cdata.email,
          ctax: this.cdata.taxnumber,
          details: JSON.stringify(this.data),
          tbt: this.tbt,
          tax: Number(this.taxamount).toFixed(2),
          tat: Number(this.total).toFixed(2),
          branch: this.branchno,
          project: this.projectno,
          task: this.taskno,
          notes: this.notes,
          cnum: store.state.cnum[0].cnum,
          soact: this.soact,
        });
        if (result.status == 200) {
          this.$root.$router.push({
            path: `/AdminDashboard/SupplyOrderView/${this.id}`,
          });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    async getbarcode() {
      const result = await axios.get(`/data/getbarcode/${this.barcode}`);
      if (result.status == 200) {
        const val2 = result.data.data[0];
        this.product = val2;
        this.data.push({
          proid: val2.pid,
          pronamear: val2.namear,
          pronameen: val2.nameen,
          salep: val2.salep,
          salec: val2.salec,
          snamear: val2.snamear,
          snameen: val2.snameen,
          buyp: val2.buyp,
          buyc: val2.buyc,
          bnamear: val2.bnamear,
          bnameen: val2.bnameen,
          discount: val2.discount,
          unittype: val2.unittype,
          tax: val2.tax,
          sn: val2.sn,
          barcode: val2.barcode,
          stock: val2.stock,
          quantity: 1,
          tbt: Number(
            val2.salep - (val2.salep * 1 * val2.discount) / 100
          ).toFixed(2),
          taxamount: Number(
            ((val2.salep - (val2.salep * 1 * val2.discount) / 100) * val2.tax) /
              100
          ).toFixed(2),
          total: Number(
            val2.salep -
              (val2.salep * 1 * val2.discount) / 100 +
              ((val2.salep - (val2.salep * 1 * val2.discount) / 100) *
                val2.tax) /
                100
          ).toFixed(2),
          costbt: val2.buyp,
          cost: val2.buyp,
        });
        this.calc();
      }
    },
    async getcusttrans(value) {
      if (this.cdata.cid != null) {
        const result3 = await axios.get(
          `/data/getcusttrans/${this.cdata.cid}/${value.sn}`
        );
        if (result3.status == 200) {
          this.allgetcusttrans = JSON.parse(result3.data.data[0].details);
          this.alldata2 = result3.data.data[0];
        }
      }
      if (this.cdata.cid == null) {
        alert("برجاء اختيار العميل");
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    async customerid() {
      const result3 = await axios.get(`/data/delcustomer/${this.customerid}`);
      if (result3.status == 200) {
        this.customerdata = result3.data.data[0];
      }
    },
    addto() {
      this.projectno = null;
      this.taskno = null;
    },

    async product() {
      if (this.cdata.cid != null) {
        const result3 = await axios.get(
          `/data/getcusttrans/${this.cdata.cid}/${this.product.sn}`
        );
        if (result3.status == 200) {
          if (result3.data.data.length > 0) {
            this.allgetcusttrans = JSON.parse(result3.data.data[0].details);
            this.alldata2 = result3.data.data[0];
          }
          if (result3.data.data.length == 0) {
            this.allgetcusttrans = [{ salep: 0 }];
          }
        }
        if (this.cdata.cid == null) {
          alert("برجاء اختيار العميل");
        }

        const data2 = this.data.filter(
          (x) => x.pronamear == this.product.namear
        );
        if (data2.length == 0) {
          if (this.product.stock == true) {
            const stockvalidationbalance = await axios.get(
              `/reports/stockvalidationbalance/${this.product.sn}`
            );
            const data = stockvalidationbalance.data.data[0][0];
            this.stockbalance = data.dpt - data.crd;
            if (stockvalidationbalance.data.data[1] > 0) {
              this.avcost = stockvalidationbalance.data.data[1];
            } else if (stockvalidationbalance.data.data[1] == "NaN") {
              this.avcost = this.product.buyp;
            } else {
              this.stockbalance = "ليس منتج مخزون";
              this.avcost = this.product.buyp;
            }
            this.data.push({
              proid: this.product.pid,
              pronamear: this.product.namear,
              pronameen: this.product.nameen,
              salep: this.product.salep,
              salec: this.product.salec,
              snamear: this.product.snamear,
              snameen: this.product.snameen,
              buyp: this.product.buyp,
              buyc: this.product.buyc,
              bnamear: this.product.bnamear,
              bnameen: this.product.bnameen,
              discount: this.product.discount,
              unittype: this.product.unittype,
              tax: this.product.tax,
              sn: this.product.sn,
              barcode: this.product.barcode,
              stock: this.product.stock,
              stockbalance: this.stockbalance,
              avcost: this.avcost,
              lastinvprice: this.allgetcusttrans[0].salep,
              quantity: 1,
              tbt:
                this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100,
              taxamount:
                ((this.product.salep -
                  (this.product.salep * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                100,
              total:
                this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100 +
                ((this.product.salep -
                  (this.product.salep * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                  100,
              costbt: this.avcost,
              cost: this.avcost,
            });
            this.calc();
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}
.head-option ul li {
  display: inline;
  padding: 0.5%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top: v-bind("css.color");
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  //   display: flex;
}
.main .body .part1 {
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  display: flex;
  width: 100%;
}
.main .body .part11 {
  width: 50%;
}
.main .body .part12 {
  width: 40%;
  //   border: solid rgba(0, 0, 0, 0.274) 00.5px;
  padding: 2%;
  background-color: white;
}
.main .body .part2 {
  background: v-bind("css.light");
  padding: 1%;
  width: 100%;
}
.options {
  height: 150px;
  margin: 1%;
  background-color: v-bind("css.light");
  color: v-bind("css.dark");
  padding: 2%;
  overflow-y: auto;
}
.options ul li {
  margin: 0.5%;
}
.options ul li:hover {
  color: white;
  background-color: black;
}
.buttom {
  text-align: center;
}
table {
  background-color: white;
  text-align: center;
  font-size: smaller;
}
input {
  text-align: center;
}
@media screen and (max-width: 576px) {
  .main .body {
    background: v-bind("css.light");
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
    //   display: flex;
  }
  .main .body .part1 {
    background: v-bind("css.light");
    //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
    display: inline-block;
    width: 100%;
  }
  .main .body .part11 {
    width: 100%;
  }
  .main .body .part12 {
    width: 100%;
    //   border: solid rgba(0, 0, 0, 0.274) 00.5px;
  }
  .main .body .part2 {
    background: v-bind("css.light");
    padding: 1%;
    width: 100%;
  }
  .table {
    font-size: xx-small;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  #exampleModal {
    width: 85%;
  }
}
</style>
