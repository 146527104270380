<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("customers") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"
              >{{ $t("data") }} {{ $t("customer") }} - {{ name }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                <a href="#" class="btn-download">
                  <span class="text">Download PDF</span>
                </a>
              </li> -->
        <li>
          <router-link to="/AdminDashboard/Customers" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i> Back</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("data") }} {{ $t("customer") }} - {{ name }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("mobile") }}</span>
            <input type="text" class="form-control" v-model="mobile" disabled />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("email") }}</span>
            <input type="text" class="form-control" v-model="email" disabled />
          </div>
          <div class="input-group">
            <span class="input-group-text"
              >{{ $t("name") }} {{ $t("company") }}
              <span class="error-feedback" v-if="v$.companyname.$error">{{
                "*"
              }}</span></span
            >
            <input
              type="text"
              class="form-control"
              v-model="companyname"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("website") }}</span>
            <input
              type="text"
              class="form-control"
              v-model="website"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">
              {{ $t("number") }} {{ $t("tax") }}
            </span>
            <input
              type="text"
              class="form-control"
              v-model="taxnumber"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">
              {{ $t("Categorie") }}
            </span>
            <input
              type="text"
              class="form-control"
              v-model="categorie"
              disabled
            />
          </div>
        </div>
        <div class="part2">
          <div class="part2head">{{ $t("address") }} {{ $t("billing") }}</div>
          <div class="part2body">
            <div class="row g-3">
              <div class="col-sm-12">{{ $t("street") }} : {{ street }}</div>
              <div class="row g-2">
                <div class="col-sm-6">{{ $t("city") }} : {{ city }}</div>
                <div class="col-sm">{{ $t("area") }} : {{ area }}</div>
              </div>
              <div class="row g-2">
                <div class="col-sm-6">{{ $t("zipcode") }} : {{ zipcode }}</div>
                <div class="col-sm">{{ $t("building") }} : {{ building }}</div>
              </div>
              <div class="col-sm-12">{{ $t("country") }} : {{ country }}</div>
            </div>
          </div>
        </div>
        <div class="part2">
          <div class="part2head">{{ $t("address") }} {{ $t("shipping") }}</div>

          <div class="part2body">
            <div class="row g-3">
              <div class="col-sm-12">{{ $t("street") }} : {{ streetsh }}</div>
              <div class="row g-2">
                <div class="col-sm-6">{{ $t("city") }} : {{ citysh }}</div>
                <div class="col-sm">{{ $t("area") }} : {{ areash }}</div>
              </div>
              <div class="row g-2">
                <div class="col-sm-6">
                  {{ $t("zipcode") }} : {{ zipcodesh }}
                </div>
                <div class="col-sm">
                  {{ $t("building") }} : {{ buildingsh }}
                </div>
              </div>
              <div class="col-sm-12">{{ $t("country") }} : {{ countrysh }}</div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="body">
        <div class="part3">
          <h5>{{ $t("amount") }} {{ $t("invoices") }}</h5>
          {{ totalinvoice }}
        </div>
        <div class="part3">
          <h5>{{ $t("numbers") }} {{ $t("invoices") }}</h5>
          {{ invoice.length }}
        </div>
        <div class="part3">
          <h5>{{ $t("amount") }} {{ $t("receipts") }}</h5>
          {{ totalreceipt }}
        </div>
        <div class="part3">
          <h5>{{ $t("numbers") }} {{ $t("receipts") }}</h5>
          {{ payment.length }}
        </div>
        <div class="part3">
          <h5>{{ $t("balance") }} {{ $t("final") }}</h5>
          {{ totalbalance }}
        </div>
      </div>
      <br />
      <div class="body2">
        <div class="option">
          <ul>
            <li class="" :class="isActive ? 'active' : ''">
              <a
                href="#"
                @click="
                  isActive = true;
                  isActive2 = false;
                "
                >{{ $t("invoices") }}</a
              >
            </li>
            <li class="" :class="isActive2 ? 'active' : ''">
              <a
                href="#"
                @click="
                  isActive2 = true;
                  isActive = false;
                "
                >{{ $t("receipts") }}</a
              >
            </li>
          </ul>
        </div>
        <div class="body-option">
          <div v-if="isActive == true">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t("details") }}</th>
                  <th>{{ $t("amount") }}</th>
                  <th>{{ $t("date") }}</th>
                  <th>{{ $t("time") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in invoice" :key="i">
                  <td>{{ i.details }}</td>
                  <td>{{ i.dpt }}</td>
                  <td>{{ i.date }}</td>
                  <td>{{ i.time }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="isActive2 == true">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t("details") }}</th>
                  <th>{{ $t("amount") }}</th>
                  <th>{{ $t("date") }}</th>
                  <th>{{ $t("time") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in payment" :key="i">
                  <td>{{ i.details }}</td>
                  <td>{{ i.crd }}</td>
                  <td>{{ i.date }}</td>
                  <td>{{ i.time }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  async mounted() {
    const data2 = await axios.get(`/data/delcustomer/${this.$route.params.id}`);
    if (data2.status == 200) {
      const data = data2.data.data[0];
      this.name = data.name;
      this.email = data.email;
      this.mobile = data.mobile;
      this.companyname = data.companyname;
      this.website = data.website;
      this.taxnumber = data.taxnumber;
      this.street = data.street;
      this.city = data.city;
      this.area = data.area;
      this.zipcode = data.zipcode;
      this.building = data.building;
      this.country = data.country;
      this.streetsh = data.streetsh;
      this.citysh = data.citysh;
      this.areash = data.areash;
      this.zipcodesh = data.zipcodesh;
      this.buildingsh = data.buildingsh;
      this.countrysh = data.countrysh;
      this.categorie = data.categorie;
    }
    const result = await axios.get(
      `/data/customertrans/${this.$route.params.id}`
    );

    if (result.status == 200) {
      this.trans = result.data.data;
      const dpt = this.trans.reduce((acc, array) => acc + Number(array.dpt), 0);
      const crd = this.trans.reduce((acc, array) => acc + Number(array.crd), 0);
      this.totalbalance = dpt - crd;
      this.invoice = this.trans.filter((x) => x.journalid.includes("INV"));
      const invoicedpt = this.invoice.reduce(
        (acc, array) => acc + Number(array.dpt),
        0
      );
      const invoicecrd = this.invoice.reduce(
        (acc, array) => acc + Number(array.crd),
        0
      );
      this.totalinvoice = invoicedpt - invoicecrd;
      this.payment = this.trans.filter((x) => x.journalid.includes("PYT"));
      const paymentdpt = this.payment.reduce(
        (acc, array) => acc + Number(array.dpt),
        0
      );
      const paymentcrd = this.payment.reduce(
        (acc, array) => acc + Number(array.crd),
        0
      );
      this.totalreceipt = paymentcrd - paymentdpt;
    }
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      name: "",
      email: "",
      mobile: "",
      companyname: "",
      website: "",
      taxnumber: "",
      street: "",
      city: "",
      area: "",
      zipcode: "",
      building: "",
      country: "",
      streetsh: "",
      citysh: "",
      areash: "",
      zipcodesh: "",
      buildingsh: "",
      countrysh: "",
      copy: false,
      trans: [],
      categorie: "",
      totalbalance: 0,
      invoice: [],
      totalinvoice: 0,
      payment: [],
      totalreceipt: 0,
      isActive: true,
      isActive2: false,
    };
  },
  validations() {
    return {
      name: { required },
      companyname: { required },
    };
  },
  methods: {
    async confirm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.patch(
          `/data/delcustomer/${this.$route.params.id}`,
          {
            username: store.state.randp.username,
            name: this.name,
            email: this.email,
            mobile: this.mobile,
            companyname: this.companyname,
            website: this.website,
            taxnumber: this.taxnumber,
            street: this.street,
            city: this.city,
            area: this.area,
            zipcode: this.zipcode,
            building: this.building,
            country: this.country,
            streetsh: this.streetsh,
            citysh: this.citysh,
            areash: this.areash,
            zipcodesh: this.zipcodesh,
            buildingsh: this.buildingsh,
            countrysh: this.countrysh,
            categorie: this.categorie,
          }
        );
        if (result.status == 200) {
          this.redirectTo({ val: "Customers" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}
.head-option ul li {
  display: inline;
  padding: 0.5%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top:  v-bind("css.color") 5px solid;
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  display: flex;
}
.main .body .part1 {
  width: 40%;
  background: v-bind("css.light");
}
.main .body .part1 .input-group {
  margin: 1%;
}
.main .body .part1 .input-group span {
  width: 25%;
  font-size: small;
}

.main .body .part2 {
  width: 25%;
  background: v-bind("css.light");
  padding-right: 2%;
  padding-left: 2%;
}
.main .body .part2 .part2head {
  background: rgba(191, 214, 219, 0.534);
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.main .body .part2 .part2body {
  box-shadow: rgba(75, 68, 68, 0.774) 0.25px 0.25px 1px;
  padding: 3%;
}
input:disabled {
  background-color: white;
}
.main .body .part3 {
  width: 18%;
  //   border: black 1px solid;
  padding: 1%;
  background-color: #ebe9e9;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  border-radius: 10px;
}
.option ul {
  margin: 0px;
}
.option ul li {
  display: inline;
  padding-right: 1%;
  padding-left: 1%;
  color: black;
  // background-color: #ebe9e9;
}
.option ul .active {
  display: inline;
  color: black;
  background-color: #ebe9e9;
}
.option a {
  color: black;
}
.body-option {
  margin: 0px;
  padding: 2%;
  width: 100%;
  background-color: #ebe9e9;
}
</style>
