<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("Property") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allpayment" class="active"
              >{{ $t("orders") }} {{ $t("payment") }} /
              {{ $t("collection") }}</router-link
            >
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/createpayment" class="active"
              >{{ $t("add") }} {{ $t("payment") }} / {{ $t("collection") }}
              {{ $t("new") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            <i class="bi bi-credit-card"></i> {{ $t("payment") }} /
            {{ $t("collection") }}
          </h3>
        </div>
        <div class="head2">
          <button
            class="buttonconfirm"
            type="submit"
            :disabled="disabled"
            @click="submit"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="body1">
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("realty") }}
                <span class="error-feedback" v-if="v$.property.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <v-select :options="properties" label="name" v-model="property">
                  <template #option="option">
                    <div class="option">
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("renter") }}
                <span class="error-feedback" v-if="v$.contract.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <v-select
                  :options="contracts"
                  label="rentername"
                  v-model="contract"
                >
                  <template #option="option">
                    <div class="option">
                      <span
                        >{{ option.rentername }} - {{ $t("unit") }}
                        {{ option.unite }}</span
                      >
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("status") }} {{ $t("contract") }}</label
              >
              <div class="col-sm-4">
                <div type="text" class="form-control" disabled>
                  {{ $t(contstatus) }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("type") }} {{ $t("contract") }}</label
              >
              <div class="col-sm-4">
                <div type="text" class="form-control" disabled>
                  {{ $t(conttype) }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("date") }} {{ $t("start") }} {{ $t("contract") }}</label
              >
              <div class="col-sm-4">
                <div type="text" class="form-control" disabled>
                  {{ $t(contstart) }}
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("type") }} {{ $t("Commission") }}
                {{ $t("collection") }}
              </label>
              <div class="col-sm-4">
                <select
                  type="number"
                  class="form-control"
                  v-model="commissiontype"
                  disabled
                >
                  <option value="1">العمولة شامل الضريبة</option>
                  <option value="2">العمولة غير شامل الضريبة</option>
                </select>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("duration") }} {{ $t("contract") }}</label
              >
              <div class="col-sm-4">
                <div type="text" class="form-control" disabled>
                  {{ $t(contduration) }} شهر
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("amount") }} {{ $t("contract") }}</label
              >
              <div class="col-sm-4">
                <div type="text" class="form-control" disabled>
                  {{ $t(contamount) }}
                </div>
              </div>
            </div>

            <!-- <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("amount") }} {{ $t("payment") }}
                <span class="error-feedback" v-if="v$.paymentamount.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-4">
                <input type="text" class="form-control" @change="cal" v-model="paymentamount" />
              </div>
            </div> -->
          </div>
        </div>
        <br />
        <div class="body1">
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("Method of Payment") }}
                <span class="error-feedback" v-if="v$.Method.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <v-select :options="methods" label="category" v-model="Method">
                  <template #option="option">
                    <div class="option">
                      <span>{{ option.category }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("Account") }} {{ $t("payment") }}
                <span class="error-feedback" v-if="v$.account.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <v-select
                  :options="accountpayment"
                  label="namear"
                  v-model="account"
                >
                  <template #option="option">
                    <div class="option">
                      <span>{{ option.namear }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("date") }} {{ $t("payment") }}
                <span class="error-feedback" v-if="v$.paymentdate.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input type="date" class="form-control" v-model="paymentdate" />
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label"
                >{{ $t("conductor") }}
              </label>
              <div class="col-sm-7">
                <input type="text" class="form-control" v-model="conductor" />
              </div>
            </div>
          </div>
        </div>
        <div class="group">
          <br />
          <h5>الاقساط المتبقية</h5>
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>مبلغ القسط</th>
                <th>المطلوب سدادة</th>
                <th>تاريخ الاستحقاق</th>
                <th>مبلغ السداد</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="y in installment" :key="y">
                <td>
                  <input
                    type="checkbox"
                    v-model="y.check"
                    id="flexCheckDefault"
                    @change="
                      if (y.check == true) {
                        y.payamount = Number(y.change);
                        colinstall();
                      } else {
                        y.payamount = 0;
                        colinstall();
                      }
                    "
                  />
                </td>
                <td>{{ y.dueamount }}</td>
                <td>{{ y.change }}</td>
                <td>{{ y.duedate }}</td>
                <td>
                  <input
                    type="text"
                    v-model="y.payamount"
                    @change="colinstall"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="group">
          <br />
          <h5>العمولات</h5>
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>العمولة</th>
                <th>تاريخ الاستحقاق</th>
                <th>نسبة العمولة</th>
                <th>مبلغ العمولة</th>
                <th>ضريبة العملة</th>
                <th>المبلغ</th>
                <th>مدفوع</th>
                <th>المتبقى</th>
                <th>دفع</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="y in commission" :key="y">
                <td>
                  <input
                    type="checkbox"
                    v-model="y.check"
                    id="flexCheckDefault"
                    @change="
                      if (y.check == true) {
                        y.paymentamount = Number(y.amount - y.paied);
                        y.taxamount = Number(
                          y.paymentamount -
                            y.paymentamount / Number(1 + '.' + y.tax)
                        ).toFixed(2);
                        calcommission();
                      } else {
                        y.paymentamount = 0;
                        y.taxamount = 0;
                        calcommission();
                      }
                    "
                  />
                </td>

                <td>{{ y.name }}</td>
                <td>{{ y.duedate }}</td>
                <td>{{ y.num }} %</td>
                <td>{{ y.comamount }}</td>
                <td>{{ y.tax }} %</td>
                <td>
                  {{ y.amount }}
                </td>
                <td>{{ y.paied }}</td>
                <td>{{ y.amount - y.paied }}</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    @change="
                      y.taxamount = Number(
                        y.paymentamount -
                          y.paymentamount / Number(1 + '.' + y.tax)
                      ).toFixed(2);
                      calcommission();
                    "
                    v-model="y.paymentamount"
                  />
                </td>
                <!-- <td>{{ Number(y.taxamount).toFixed(2) }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="group">
          <br />
          <h5>{{ $t("details") }}</h5>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>مبلغ سداد الايجار السنوى</th>
              <th>قيمة الضريبة 15%</th>
              <th>اجمالى العمولات</th>
              <th>اجمالى الضرايب على العمولات</th>
              <th>عمولة التحصيل</th>
              <th>الاجمالى</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ Number(totalrentpayment).toFixed(2) }}
              </td>
              <td>
                {{ Number(totalrentpaymenttax).toFixed(2) }}
              </td>
              <td>
                {{ Number(totalcommission - totalcommissiontax).toFixed(2) }}
              </td>
              <td>
                {{ Number(totalcommissiontax).toFixed(2) }}
              </td>
              <td v-if="this.commissiontype == 2">
                {{ Number((totalrentpayment * comm) / 100).toFixed(2) }}
              </td>
              <td v-if="this.commissiontype == 1">
                {{
                  Number(
                    ((Number(totalrentpayment) + Number(totalrentpaymenttax)) *
                      comm) /
                      100
                  ).toFixed(2)
                }}
              </td>
              <td style="border: 2px solid black">
                {{
                  Number(totalrentpayment) +
                  Number(totalrentpaymenttax) +
                  Number(totalcommission)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  props: ["css"],
  components: {
    vSelect,
  },
  async mounted() {
    const result = await axios.get("/data/getallproperty");
    if (result.status == 200) {
      this.properties = result.data.data;
    }
    const result2 = await axios.get("/data/accountpayment");
    if (result2.status == 200) {
      this.accountpayment = result2.data.data;
    }
    if (this.$route.params.pid != 0) {
      this.property = this.properties.filter(
        (x) => x.pid == this.$route.params.pid
      );

      this.comm = this.property[0].commission;
      this.propertyaccount = JSON.parse(this.property[0].account);
      this.propertytaxaccount = JSON.parse(this.property[0].taxaccount);
      this.commissionaccount = JSON.parse(this.property[0].commissionaccount);
      this.ownerid = this.property[0].ownerid;
      const result3 = await axios.get(
        `/data/getcontractid/${this.$route.params.conid}`
      );
      if (result3.status == 200) {
        this.contract = result3.data.data[0];
      }
    }
  },
  data() {
    return {
      v$: useValidate(),
      properties: [],
      property: {},
      comm: "",
      contracts: [],
      contract: {},
      contstatus: "",
      contstart: "",
      conttype: "",
      contduration: "",
      contamount: "",
      methods: [
        { category: "نقدى", value: "cash" },
        { category: "حوالة بنكية", value: "bank transfer" },
        { category: "لحساب المالك", value: "owner account" },
        { category: "شيك", value: "check" },
        { category: "خصم من التامين", value: "insurance discount" },
        {
          category: "خصم من دفعة مقدمة",
          value: "discount from advance payment",
        },
        { category: "عهدة نقدية", value: "monetary contract" },
      ],
      Method: {},
      accountpayment: [],
      account: "",
      paymentdate: "",
      conductor: "",
      commission: [],
      paymentamount: "",
      propertyaccount: "",
      propertytaxaccount: "",
      renteraccount: "",
      totalcommission: 0,
      totalcommissiontax: 0,
      unit: "",
      description: "",
      commissionaccount: "",
      ownerid: "",
      totalrentpayment: 0,
      totalrentpaymenttax: 0,
      installment: [],
      igarnumber: "",
      commissiontype: "",
      periodtime: "",
      installmentnum: "",
      duedate: "",
      change: "",
      paiedinstallments: "",
    };
  },
  validations() {
    return {
      property: { required },
      contract: { required },
      Method: { required },
      account: { required },
      paymentdate: { required },
    };
  },
  methods: {
    calcommission() {
      for (let i = 0; i < this.commission.length; i++) {
        const element = this.commission[i].paymentamount;
        if (element == 0 || element == null) {
          this.commission[i].taxamount = 0;
        }
      }
      this.totalcommission = Number(
        this.commission.reduce(
          (acc, array) => acc + Number(array.paymentamount),
          0
        )
      ).toFixed(2);
      this.totalcommissiontax = Number(
        this.commission.reduce((acc, array) => acc + Number(array.taxamount), 0)
      ).toFixed(2);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        Swal.fire({
          title: this.$t("confirm") + "-" + this.$t("payment"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            const action = await axios.post("/data/createwaitpayment", {
              username: store.state.randp.username,
              proid: this.property.pid,
              proname: this.property.name,
              conid: this.contract.conid,
              rentername: this.contract.rentername,
              renterid: this.contract.renterid,
              amount: this.paymentamount,
              paytype: this.Method.category,
              payaccount: this.account,
              paydate: this.paymentdate,
              conductor: this.conductor,
              commission: this.commission,
              description: this.description,
              payamount: Number(this.totalrentpayment).toFixed(2),
              paytax: Number(this.totalrentpaymenttax).toFixed(2),
              comtotal: Number(
                this.totalcommission - this.totalcommissiontax
              ).toFixed(2),
              comtax: Number(this.totalcommissiontax).toFixed(2),
              collamount: Number(
                (this.totalrentpayment * this.comm) / 100
              ).toFixed(2),
              renteraccount: this.renteraccount,
              propertyaccount: JSON.stringify(this.propertyaccount),
              propertytaxaccount: JSON.stringify(this.propertytaxaccount),
              commissionaccount: this.commissionaccount,
              totalcommission: this.totalcommission,
              totalcommissiontax: this.totalcommissiontax,
              ownerid: this.ownerid,
              igarnumber: this.igarnumber,
              commissiontype: this.commissiontype,
              unit: this.unit,
              periodtime: this.periodtime,
              installmentnum: this.installmentnum,
              duedate: this.duedate,
              change: this.change,
              comm: this.comm,
              paiedinstallments: JSON.stringify(this.paiedinstallments),
            });
            if (action.status == 200) {
              Swal.fire(this.$t("done") + " " + this.$t("payment"));
              this.redirectTo({ val: "allpayments" });
            }
          }
        });
      }
    },
    cal() {
      if (this.conttype == "residential") {
        this.totalrentpayment = Number(this.paymentamount).toFixed(2);
        this.totalrentpaymenttax = 0;
      } else {
        this.totalrentpayment = (
          Number(this.paymentamount) -
          Number(this.paymentamount - this.paymentamount / 1.15)
        ).toFixed(2);
        this.totalrentpaymenttax = Number(
          this.paymentamount -
            (this.paymentamount - this.totalcommission) / 1.15
        ).toFixed(2);
      }
    },
    colinstall() {
      const data = this.installment.filter((x) => x.check == true);
      this.paiedinstallments = data;
      this.installmentnum = data.length;
      this.duedate = data[0].duedate;
      const total = data
        .reduce((acc, array) => acc + Number(array.payamount), 0)
        .toFixed(2);
      const change = data
        .reduce((acc, array) => acc + Number(array.change), 0)
        .toFixed(2);
      this.change = change - total;
      this.paymentamount = Number(total);
      this.cal();
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    async property() {
      if (this.$route.params.pid == 0) {
        const result = await axios.get(
          `/data/getpropertycontracts/${this.property.pid}`
        );
        if (result.status == 200) {
          this.totalrentpayment = 0;
          this.totalrentpaymenttax = 0;
          this.totalcommission = 0;
          this.totalcommissiontax = 0;
          this.installment = [];
          this.commission = [];
          this.contracts = result.data.data;
          this.comm = this.property.commission;
          this.propertyaccount = JSON.parse(this.property.account);
          this.propertytaxaccount = JSON.parse(this.property.taxaccount);
          this.commissionaccount = JSON.parse(this.property.commissionaccount);
          this.ownerid = this.property.ownerid;
        }
      }
    },
    async contract() {
      this.unit = this.contract.unite;
      if (this.$route.params.pid == 0) {
        const period = await axios.get(
          `/data/getunitperiod/${this.property.pid}/${this.unit}`
        );
        this.periodtime = period.data.data[0].due;
      }
      if (this.$route.params.pid != 0) {
        const period = await axios.get(
          `/data/getunitperiod/${this.$route.params.pid}/${this.unit}`
        );
        this.periodtime = period.data.data[0].due;
      }
      this.totalrentpayment = 0;
      this.totalrentpaymenttax = 0;
      this.totalcommission = 0;
      this.totalcommissiontax = 0;
      this.installment = [];
      this.commission = [];
      this.contstatus = this.contract.status;
      this.contstart = this.contract.contstart;
      this.conttype = this.contract.conttype;
      this.contduration = this.contract.contduration;
      this.contamount = this.contract.contamount;
      this.commission = JSON.parse(this.contract.commission);
      this.renteraccount = this.contract.renteraccount;
      this.igarnumber = this.contract.igarnumber;

      this.commissiontype = this.contract.commissiontype;
      this.description = `سداد قسط وحدة رقم ${this.unit}`;
      const result = await axios.get(
        `/reports/getallinstallment/${this.contract.conid}`
      );
      if (result.status == 200) {
        this.installment = result.data.data;
      }

      const result3 = await axios.get(
        `/reports/getallcommissions/${this.contract.conid}`
      );
      if (result3.status == 200) {
        this.commission = result3.data.data;
      }

      for (let i = 0; i < this.commission.length; i++) {
        this.commission[i].change = 0;
        // this.commission[i].amount - this.commission[i].paied;
        this.commission[i].paymentamount = 0;
        this.commission[i].taxamount = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 70%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.body4 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}
</style>
