<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#"
              >{{ $t("add") }} {{ $t("products") }} {{ $t("customers") }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("products") }} {{ $t("customers") }}</h3>
      </div>
      <div class="head">
        <div class="head1">
          <div style="padding: 1%; display: flex">
            {{ $t("customer") }} :
            <v-select
              :options="customers"
              label="name"
              v-model="customer"
              style="width: 50%"
            >
              <template #option="option">
                <div style="text-align: center">
                  <span>{{ option.name }} </span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="head2">
          <button @click="submit">
            + {{ $t("add") }} {{ $t("products") }}
          </button>
        </div>
      </div>
      <div class="body" v-if="this.customer">
        <div style="width: 25%; padding: 1%">
          <v-select
            :options="data"
            label="namear"
            v-model="product"
            v-if="css.EN == 'rtl'"
          >
            <template #option="option">
              <div style="text-align: center">
                <span
                  >{{ option.namear }} - {{ option.barcode }} -
                  {{ option.unittype }}
                </span>
              </div>
            </template>
          </v-select>
          <v-select
            :options="data"
            label="nameen"
            v-model="product"
            v-if="css.EN == 'ltr'"
          >
            <template #option="option">
              <div style="text-align: center">
                <span
                  >{{ option.nameen }} - {{ option.barcode }} -
                  {{ $t(option.unittype) }}
                </span>
              </div>
            </template>
          </v-select>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("code") }}</th>
                <th>{{ $t("name") }}</th>
                <th>{{ $t("sn") }}</th>
                <th>{{ $t("item") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="val in customerproducts" :key="val">
                <td>
                  {{ val.pid }}
                </td>
                <td>
                  <router-link
                    :to="`/purchases/AllProducts/productview/${val.pid}`"
                    >{{ val.namear }}</router-link
                  >
                </td>
                <td>
                  {{ val.sn }}
                </td>
                <td>
                  {{ val.unittype }}
                </td>
                <td>
                  <a href="#" @click.prevent="del(id)"
                    ><i class="bi bi-x-circle-fill"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      name: "",
      sn: "",
      barcode: "",
      kind: "",
      data: [],
      data3: [],
      customers: [],
      customerproducts: [],
      product: {},
      customer: "",
    };
  },
  async mounted() {
    const result = await axios.get("/data/allproducts");
    if (result.status == 200) {
      this.data = result.data.data;
    }
    const result2 = await axios.get("/data/allcustomer");
    if (result2.status == 200) {
      this.customers = result2.data.data;
    }
  },
  methods: {
    async del(id) {
      this.customerproducts.splice(id, 1);
      this.calc();
    },
    eslam(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/AllProducts/productedite/${row.id}`,
        params: { id: row.id },
      });
    },
    View(row) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllProducts/productview/${row.id}`,
        params: { id: row.id },
      });
    },
    async submit() {
      Swal.fire({
        title: this.$t("update") + " " + this.$t("products"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/reports/updatecustomerproducts", {
            cid: this.customer.cid,
            data: JSON.stringify(this.customerproducts),
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("products"));
            this.redirectTo({ val: "homeSales" });
          }
        }
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    product() {
      const filter = this.customerproducts.filter(
        (x) => x.sn == this.product.sn
      );
      if (filter.length == 0) {
        this.customerproducts.push({
          pid: this.product.pid,
          namear: this.product.namear,
          nameen: this.product.nameen,
          sn: this.product.sn,
          unittype: this.product.unittype,
        });
      } else {
        alert("تم اضافة المنتج من قبل");
      }
    },
    async customer() {
      const result = await axios.get(
        `/reports/allproductsforcustomer/${this.customer.cid}`
      );
      if (result.status == 200) {
        this.customerproducts = JSON.parse(result.data.data[0].products);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
