<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("Accounts") }}</a>
          </li>
          <li>-</li>
          <li>
            <a> {{ $t("JL") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active">{{ $t("add") }} {{ $t("JL") }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                    <a href="#" class="btn-download">
                      <span class="text">Download PDF</span>
                    </a>
                  </li> -->

        <li>
          <router-link to="/AdminDashboard/AllFastJL" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("JL") }} - {{ title }} - {{ id }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("date") }}
              <span class="error-feedback" v-if="v$.date.$error">{{
                "*"
              }}</span></label>
            <div class="col-sm-2">
              <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="date" />
            </div>
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("branch") }}
              <span class="error-feedback" v-if="v$.branch.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-2">
              <select class="form-control form-control-sm" id="inputEmail3" v-model="branch">
                <option v-for="val in branches" :key="val" :value="val.bid">
                  {{ val.namear }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("description") }}
              <span class="error-feedback" v-if="v$.description.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-5">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="description"></textarea>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("amount") }}
            </label>

            <div class="col-sm-1">
              <input class="form-control" id="exampleFormControlTextarea1" v-model="amount" />
            </div>
          </div>
          <br />
          <h4>{{ $t("details") }} {{ $t("JL") }}</h4>
          <button class="btn btn-primary" @click="add" style="padding: 0.25%">
            {{ $t("add") }} <i class="bi bi-plus"></i>
          </button>
          <br /><br />
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t("dpt") }}</th>
                <th scope="col">{{ $t("crd") }}</th>
                <th scope="col">{{ $t("Account") }}</th>
                <td scope="col">{{ $t("details") }}</td>
                <td scope="col">{{ $t("ccenter") }}</td>

                <td scope="col">{{ $t("Cancel") }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="value of data" :key="value">
                <td scope="row" style="width: 10%">
                  <!-- <div v-if="value.dptcry > 0">{{ value.dptcry }}</div> -->
                  <input class="form-control form-control-sm" type="number" @change="cal" v-model="value.dpt" />
                </td>
                <td scope="row" style="width: 10%">
                  <!-- <div v-if="value.crdcry > 0">{{ value.crdcry }}</div> -->
                  <input class="form-control form-control-sm" type="number" @change="cal" v-model="value.crd" />
                </td>
                <td scope="row" style="width: 30%">
                  <v-select :options="data1" label="namear" v-model="value.act">
                    <template #option="option">
                      <span>{{ option.namear }}</span>
                    </template>
                  </v-select>
                </td>
                <td scope="row">
                  <input class="form-control form-control-sm" type="text" v-model="value.details" />
                </td>
                <td scope="row">
                  <select class="form-control" id="exampleFormControlTextarea1" v-model="value.delegate">
                    <option v-for="val in alltasks" :key="val.id" :value="val.name">
                      {{ val.name }}
                    </option>
                  </select>
                </td>
                <td>
                  <a href="#" @click.prevent="del(value)"><i class="bi bi-x-circle-fill"></i></a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  {{ totaldpt }}
                </td>
                <td>
                  {{ totalcrd }}
                </td>
              </tr>
            </tfoot>
          </table>
          <!-- <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("add") }} {{ $t("to") }}
            </label>

            <div class="col-sm-1">
              <input
                class="form-check-input"
                type="radio"
                value="project"
                v-model="addto"
              />
              &nbsp;
              <label class="form-check-label" for="gridRadios2">
                {{ $t("customer") }}
              </label>
            </div>
            <div class="col-sm-1">
              <input
                class="form-check-input"
                type="radio"
                v-model="addto"
                value="task"
              />
              &nbsp;
              <label class="form-check-label" for="gridRadios2">
                {{ $t("task") }}
              </label>
            </div>
          </div> -->
          <div class="row mb-2">
            <label class="col-sm-1 col-form-label-sm">{{ $t("customer") }}
            </label>
            <div class="col-sm-3">
              <select class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="project">
                <option v-for="val in allprojects" :key="val" :value="val.name">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
          <!-- <div class="row mb-2">
            <label class="col-sm-1 col-form-label-sm"
              >{{ $t("delegate") }}
            </label>
            <div class="col-sm-3">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="task"
              >
                <option v-for="val in alltasks" :key="val.id" :value="val.name">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div> -->
          <br />
          <div class="row mb-2">
            <label class="col-sm-2 col-form-label-sm">
              <i class="bi bi-pin-angle-fill"></i> {{ $t("add") }}
              {{ $t("attachments") }}
            </label>
            <div class="col-sm-8">
              <div>
                <div>
                  <input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()" />
                </div>
                <br />
                <!-- <div v-if="files.length > 0">
                    <div v-for="(file, key) in files" :key="file">
                      {{ file.name }} &nbsp;
                      <a href="#" class="remove-file" v-on:click="removeFile(key)"
                        >Remove</a
                      >
                      </div>
                    </div> -->
                <table class="table" style="width: 25%">
                  <thead>
                    <tr>
                      <th>{{ $t("name") }}</th>
                      <th>{{ $t("cancel") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, key) in files" :key="file">
                      <td>{{ file.name }}</td>
                      <td>
                        <a href="#" class="remove-file" v-on:click="removeFile(key)"><i
                            class="bi bi-x-circle-fill"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />
              </div>
            </div>
          </div>

          <div class="buttom">
            <button class="buttonconfirm" type="submit" @click="submit">
              {{ $t("save") }}
            </button>
            &nbsp;&nbsp;&nbsp;

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      data: [],
      data1: [],
      date: "",
      branches: [],
      allprojects: [],
      alltasks: [],
      project: "",
      task: "",
      branch: "",
      description: "",
      totaldpt: 0,
      totalcrd: 0,
      addto: "",
      url: null,
      files: [],
      fileURL: [],
      id: "",
      title: "",
      amount: 0,
      dpt: "",
      crd: "",
    };
  },
  validations() {
    return {
      date: { required },
      branch: { required },
      description: { required },
    };
  },
  async mounted() {
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/getaccounttrue");
    const result2 = await axios.get("/data/allbranches");
    const result4 = await axios.get("/data/allcustomer");
    const result6 = await axios.get("/data/JournalEntryID");
    const result7 = await axios.get(
      `/data/JournalEntryFastId/${this.$route.params.id}`
    );
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data;
      this.branches = result2.data.data;
      this.allprojects = result4.data.data;
      this.id = Number(result6.data.data[0].count) + Number(1);
      this.description = result7.data.data.description;
      this.title = result7.data.data.title;
      const dpt = JSON.parse(result7.data.data.dpt);
      const crd = JSON.parse(result7.data.data.crd);
      Array.prototype.push.apply(dpt, crd);
      this.dpt = JSON.parse(result7.data.data.dpt);
      this.crd = JSON.parse(result7.data.data.crd);
      for (let i = 0; i < dpt.length; i++) {
        this.data.push({
          dpt: 0,
          crd: 0,
          act: dpt[i],
          details: "",
        });
      }
    }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/accounting/AllFastJL",
      });
    },
    cal() {
      this.totaldpt = Number(
        this.data.reduce((acc, array) => acc + Number(array.dpt), 0)
      ).toFixed(2);
      this.totalcrd = Number(
        this.data.reduce((acc, array) => acc + Number(array.crd), 0)
      ).toFixed(2);
    },
    async del(value) {
      const indexOfObject = this.data.findIndex((object) => {
        return object.act === value.act;
      });
      this.data.splice(indexOfObject, 1);
    },
    add() {
      this.data.push({
        dpt: 0,
        crd: 0,
        act: 0,
        actnum: 0,
        namear: "",
        nameen: "",
        details: "",
        totaldpt: 0,
        totalcrd: 0,
      });
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.totalcrd == this.totaldpt && this.totalcrd != 0) {
          for (var i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            let formData = new FormData();
            let name = this.files[i].name.split(".");
            let newname = this.id + "_" + i + "." + name[name.length - 1];
            this.fileURL.push({ name: newname });
            formData.append("file", file, newname);
            await axios.patch(`/uploads/${i}`, formData);
          }
          const result = await axios.post("/data/createnewjournalenty", {
            username: store.state.randp.username,
            trandate: this.date,
            branch: this.branch,
            description: this.description,
            details: JSON.stringify(this.data),
            project: this.project,
            task: this.task,
            fileurl: JSON.stringify(this.fileURL),
          });
          if (result.status == 200) {
            this.redirectTo({ val: "JournalEntry" });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Data Entry successfully",
            });
            // this.redirectTo({ val: "Home" });
          } else if (result.status == 401) {
            alert("Account Already Created Before");
          }
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "برجاء مراجعة القيد",
          });
        }
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    submitFiles() {
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        let formData = new FormData();
        let name = this.files[i].name.split(".");
        let newname = i + "." + name[name.length - 1];
        this.fileURL.push({ name: i + "." + newname });
        formData.append("file", file, newname);
        axios.patch(`/uploads/${i}`, formData);
      }
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data1 = this.data2.filter((x) =>
        x.nameact.includes(this.filter.toLowerCase())
      );
    },
    // addto() {
    //   if (this.addto == "project") {
    //     this.task = null;
    //   }
    //   if (this.addto == "task") {
    //     this.project = null;
    //   }
    // },
    // task() {
    //   const project = this.alltasks.filter((x) => x.id == this.task)[0]
    //     .projectid;
    //   this.project = project;
    // },
    amount() {
      for (let i = 0; i < this.dpt.length; i++) {
        for (let x = 0; x < this.data.length; x++) {
          if (this.dpt[i].actnum == this.data[x].act.actnum) {
            this.data[x].dpt = this.amount;
          }
        }
      }
      for (let i = 0; i < this.crd.length; i++) {
        for (let x = 0; x < this.data.length; x++) {
          if (this.crd[i].actnum == this.data[x].act.actnum) {
            this.data[x].crd = this.amount;
          }
        }
      }
      this.cal();
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

a {
  text-decoration: none;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}

.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.head-title .left .breadcrumb li {
  color: #342e37;
}

.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}

.head-option ul li {
  display: inline;

  margin: 0.15%;
}

.head-option .btn-download {
  // width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.part1 {
  //   width: 50%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.buttom {
  text-align: center;
}

.options {
  height: 150px;
  margin: 1%;
  background-color: v-bind("css.light");
  color: v-bind("css.dark");
  padding: 2%;
  overflow-y: auto;
}

.options ul li {
  margin: 0.5%;
}

.options ul li:hover {
  color: white;
  background-color: black;
}

.files {
  border: black 1px solid;
  padding: 0.5%;
  width: 400px;
  height: 200px;
  border-radius: 20px;
}

table {
  background-color: white;
}
</style>
