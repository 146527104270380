<template>
  <div v-if="this.Loading == true">
    <LoadingPage />
  </div>

  <div v-else>
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("Trial Balance") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <span>{{ $t("from") }}</span>
          <input type="date" :placeholder="$t('from')" v-model="from" />
          <span>{{ $t("to") }}</span>
          <input type="date" :placeholder="$t('to')" v-model="to" />
          <button type="submit" @click="submit">
            <i class="bi bi-search"></i> {{ $t("search") }}
          </button>
        </div>
        <div class="head2">
          <button type="submit" @click="pdf">
            <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
          </button>
          <button type="submit" @click="exportToExcel"><i class="bi bi-file-earmark-excel"></i> Excel </button>
        </div>
      </div>
      <div class="body">
        <br />
        <h3>{{ $t("Trial Balance") }}</h3>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead style="position: static">
            <tr>
              <th></th>
              <th colspan="2">الارصدة الافتتاحية</th>
              <th colspan="2">الحركات</th>
              <th colspan="2">صافى الحركات</th>
              <th colspan="2">الرصيد الختامى</th>
            </tr>
            <tr>
              <th>{{ $t("Account") }}</th>
              <th>{{ $t("dpt") }}</th>
              <th>{{ $t("crd") }}</th>
              <th>{{ $t("dpt") }}</th>
              <th>{{ $t("crd") }}</th>
              <th>{{ $t("dpt") }}</th>
              <th>{{ $t("crd") }}</th>
              <th>{{ $t("dpt") }}</th>
              <th>{{ $t("crd") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in data1" :key="i">
              <td v-if="i.sup == 'true'" class="childname">
                <router-link to="" @click="summary(i)">
                  {{ i.actnum }} -
                  <span v-if="css.lang == 'ar'">{{ i.namear }}</span><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
                </router-link>
              </td>

              <td v-if="i.sup == 'false'">
                {{ i.actnum }} -
                <span v-if="css.lang == 'ar'">{{ i.namear }}</span><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.opendpt).toFixed(2)
                }}<span v-if="i.opendpt == null">{{ (i.opendpt = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.opendpt != 0">{{
                  Number(i.opendpt).toFixed(2)
                }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.opencrd).toFixed(2)
                }}<span v-if="i.opencrd == null">{{ (i.opencrd = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.opencrd != 0">{{
                  Number(i.opencrd).toFixed(2)
                }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.dpt).toFixed(2)
                }}<span v-if="i.dpt == null">{{ (i.dpt = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">{{ Number(i.dpt).toFixed(2) }}</td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.crd).toFixed(2)
                }}<span v-if="i.crd == null">{{ (i.crd = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">{{ Number(i.crd).toFixed(2) }}</td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.transdpt).toFixed(2)
                }}<span v-if="i.transdpt == null">{{ (i.transdpt = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.transdpt != 0">{{
                  Number(i.transdpt).toFixed(2)
                }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.transcrd).toFixed(2)
                }}<span v-if="i.transcrd == null">{{ (i.transcrd = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.transcrd != 0">{{
                  Number(i.transcrd).toFixed(2)
                }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.balancedpt).toFixed(2)
                }}<span v-if="i.balancedpt == null">{{
                  (i.balancedpt = 0)
                }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.balancedpt != 0">{{
                  Number(i.balancedpt).toFixed(2)
                }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ Number(i.balancecrd).toFixed(2)
                }}<span v-if="i.balancecrd == null">{{
                  (i.balancecrd = 0)
                }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.balancecrd != 0">{{
                  Number(i.balancecrd).toFixed(2)
                }}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t("total") }}</td>
              <td>{{ Number(totalopendpt).toFixed(2) }}</td>
              <td>{{ Number(totalopencrd).toFixed(2) }}</td>
              <td>{{ Number(totaldpt).toFixed(2) }}</td>
              <td>{{ Number(totalcrd).toFixed(2) }}</td>
              <td>{{ Number(totaltransdpt).toFixed(2) }}</td>
              <td>{{ Number(totaltranscrd).toFixed(2) }}</td>
              <td>{{ Number(totalbalancedpt).toFixed(2) }}</td>
              <td>{{ Number(totalbalancecrd).toFixed(2) }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import LoadingPage from "@/components/LoadingPage.vue";
import html2pdf from "html2pdf.js";
import * as XLSX from 'xlsx';
export default {
  components: {
    LoadingPage,
  },
  async mounted() {
    this.Loading = true;
    const result = await axios.get(
      `/reports/TrialBalance/${this.fromdate}/${this.todate}`
    );
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.totalopendpt = Number(
        this.data1.reduce((acc, array) => acc + Number(array.opendpt), 0)
      ).toFixed(2);
      this.totalopencrd = Number(
        this.data1.reduce((acc, array) => acc + Number(array.opencrd), 0)
      ).toFixed(2);
      this.totaldpt = Number(
        this.data1.reduce((acc, array) => acc + Number(array.dpt), 0)
      ).toFixed(2);
      this.totalcrd = Number(
        this.data1.reduce((acc, array) => acc + Number(array.crd), 0)
      ).toFixed(2);
      this.totaltransdpt = Number(
        this.data1.reduce((acc, array) => acc + Number(array.transdpt), 0)
      ).toFixed(2);
      this.totaltranscrd = Number(
        this.data1.reduce((acc, array) => acc + Number(array.transcrd), 0)
      ).toFixed(2);
      this.totalbalancedpt = Number(
        this.data1.reduce((acc, array) => acc + Number(array.balancedpt), 0)
      ).toFixed(2);
      this.totalbalancedpt = Number(
        this.data1.reduce((acc, array) => acc + Number(array.balancedpt), 0)
      ).toFixed(2);
    }
    this.Loading = false;
  },
  props: ["css"],
  data() {
    return {
      Loading: false,
      fromdate: moment().startOf("year").format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      from: "",
      to: "",
      totalopendpt: 0,
      totalopencrd: 0,
      totaldpt: 0,
      totalcrd: 0,
      totaltransdpt: 0,
      totaltranscrd: 0,
      totalbalancedpt: 0,
      totalbalancecrd: 0,
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllReports/GeneralLedgerSummaryMonthly/${val.actnum}/${val.namear}/${val.nameen}/${val.typeact}`,
      });
    },
    async submit() {
      if (this.from && this.to) {
        this.Loading = true;
        const result = await axios.get(
          `/reports/TrialBalance/${this.from}/${this.to}`
        );
        if (result.status == 200) {
          this.fromdate = this.from;
          this.todate = this.to;
          this.data1 = result.data.data;
          this.totalopendpt = this.data1
            .reduce((acc, array) => acc + Number(array.opendpt), 0)
            .toFixed(2);
          this.totalopencrd = this.data1
            .reduce((acc, array) => acc + Number(array.opencrd), 0)
            .toFixed(2);
          this.totaldpt = this.data1
            .reduce((acc, array) => acc + Number(array.dpt), 0)
            .toFixed(2);
          this.totalcrd = this.data1
            .reduce((acc, array) => acc + Number(array.crd), 0)
            .toFixed(2);
          this.totaltransdpt = this.data1
            .reduce((acc, array) => acc + Number(array.transdpt), 0)
            .toFixed(2);
          this.totaltranscrd = this.data1
            .reduce((acc, array) => acc + Number(array.transcrd), 0)
            .toFixed(2);
          this.totalbalancedpt = this.data1
            .reduce((acc, array) => acc + Number(array.balancedpt), 0)
            .toFixed(2);
          this.totalbalancedpt = this.data1
            .reduce((acc, array) => acc + Number(array.balancedpt), 0)
            .toFixed(2);
        }
        this.Loading = false;
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "JournalReport.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.data1.length; i++) {
        const name = this.data1[i]
        data.push({
          رقم_الحساب: name.actnum,
          اسم_الحساب: name.namear,
          الارصدة_الافتتاحية_مدين: Number(name.opendpt).toFixed(2),
          الارصدة_الافتتاحية_دائن: Number(name.opencrd).toFixed(2),
          الحركات_مدين: Number(name.dpt).toFixed(2),
          الحركات_دائن: Number(name.crd).toFixed(2),
          صافى_الحركات_مدين: Number(name.transdpt).toFixed(2),
          صافى_الحركات_دائن: Number(name.transcrd).toFixed(2),
          الرصيد_مدين: Number(name.balancedpt).toFixed(2),
          الرصيد_دائن: Number(name.balancecrd).toFixed(2),
        })
      }
      data.push({
        رقم_الحساب: "",
        اسم_الحساب: "الاجمالى",
        الارصدة_الافتتاحية_مدين: this.totalopendpt,
        الارصدة_الافتتاحية_دائن: this.totalopencrd,
        الحركات_مدين: this.totaldpt,
        الحركات_دائن: this.totalcrd,
        صافى_الحركات_مدين: this.totaltransdpt,
        صافى_الحركات_دائن: this.totaltranscrd,
        الرصيد_مدين: this.totalbalancedpt,
        الرصيد_دائن: this.totalbalancedpt,
      })
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, 'TrialBalance.xlsx');
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
