<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/accounting/JournalEntry" class="active">{{
              $t("JL")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <a class="active"> {{ `MJ-${alldata2.jid}` }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("JL") }} ({{ `MJ-${alldata2.jid}` }})</h3>
        </div>
        <div class="head2">
          <button @click="pdf" v-if="alldata2.review == 'confirmed'"><i class="bi bi-filetype-pdf"></i> {{ $t("PDF")
            }}</button>
          <button @click="cancel" v-if="alldata2.review == 'confirmed' && hasPermission('property_delete')"
            style="background-color: red">
            {{ $t("delete") }}
          </button>
          <button @click="Edite" v-if="alldata2.review == 'waiting'">
            {{ $t("Edite") }}
          </button>
          <button @click="submit" v-if="alldata2.review == 'waiting'">
            {{ $t("confirm") }}
          </button>
          <button>
            <router-link style="color: white" to="/accounting/JournalEntry" class="active">{{ $t("back") }}
            </router-link>
          </button>
        </div>
      </div>
      <div class="body" id="main">
        <div style="direction: rtl">
          <div class="body1" style="display: flex;">
            <!-- <div class="group">
              <label for="basic-url" class="form-label">{{ $t("type") }} {{ $t("journal") }} : {{ alldata2.type }}
              </label>
              <br />
              <label for="basic-url" class="form-label">{{ $t("date") }} </label> : {{ alldata2.date }}
              <br />
            </div> -->
            <div class="group">
              <label for="basic-url" class="form-label">{{ $t("type") }} {{ $t("journal") }}
                <span class="error-feedback" v-if="v$.jltype.$error">{{
                  "*"
                }}</span>
              </label>
              <div>
                <input type="text" v-model="alldata2.type" />
              </div>
              <label for="basic-url" class="form-label">{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span>
              </label>
              <div>
                <input type="date" v-model="alldata2.date" />
              </div>
            </div>
            <div class="group">
              <label for="basic-url" class="form-label">{{ $t("ccenter") }} {{ $t("parent") }} : {{ alldata2.ccenter }}
              </label>
            </div>
          </div>
          <hr />
          <h6>{{ $t("details") }} {{ $t("JL") }}</h6>

          <table class="table" v-if="alldata2.review == 'waiting'">
            <thead>
              <tr>
                <th></th>
                <th scope="col">{{ $t("dpt") }}</th>
                <th scope="col">{{ $t("crd") }}</th>
                <th scope="col">{{ $t("Account") }}</th>
                <td scope="col">{{ $t("ccenter") }}</td>
                <td scope="col">{{ $t("details") }}</td>
                <td scope="col">{{ $t("Cancel") }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) of details" :key="value">
                <td>{{ id + 1 }}</td>
                <td scope="row" style="width: 10%">
                  <input class="form-control form-control-sm" type="number" @change="cal" v-model="value.dpt" />
                </td>
                <td scope="row" style="width: 10%">
                  <input class="form-control form-control-sm" type="number" @change="cal" v-model="value.crd" />
                </td>
                <td scope="row" style="width: 20%">
                  <v-select :options="data1" label="namear" v-model="value.act">
                    <template #option="option">
                      <span>{{ option.namear }}</span>
                    </template>
                  </v-select>
                </td>
                <td>
                  <select v-model="value.costcenter">
                    <option v-for="x in level3" :key="x" :value="x">
                      {{ x.namear }}
                    </option>
                  </select>
                </td>
                <td scope="row" style="width: 30%"><input type="text" v-model="value.details" style="width: 100%;"></td>
                <td>
                  <a href="#" @click.prevent="del(value)"><i class="bi bi-x-circle-fill"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="add" style="padding: 0.25%" v-if="alldata2.review == 'waiting'">
            {{ $t("add") }} <i class="bi bi-plus"></i>
          </button>
          <table class="table" v-if="alldata2.review == 'confirmed'">
            <thead>
              <tr>
                <th scope="col">{{ $t("dpt") }}</th>
                <th scope="col">{{ $t("crd") }}</th>
                <th scope="col">{{ $t("Account") }}</th>

                <td scope="col">{{ $t("delegate") }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="value of trans" :key="value">
                <td>{{ value.dpt }}</td>
                <td>{{ value.crd }}</td>
                <td v-if="css.lang == 'ar'">
                  {{ value.namear }}
                </td>
                <td v-if="css.lang == 'en'">
                  {{ value.nameen }}
                </td>

                <td>{{ value.delegate }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  {{ totaldpt }}
                </td>
                <td>
                  {{ totalcrd }}
                </td>
              </tr>
            </tfoot>
          </table>

          <hr />
          <div class="body1" style="display: flex">
            <div class="group">
              <label for="basic-url" class="form-label">{{ $t("total") }} {{ $t("dpt") }} : {{ totaldpt }}
              </label>
              <br />
              <label for="basic-url" class="form-label">{{ $t("total") }} {{ $t("crd") }} : {{ totalcrd }}
              </label>
            </div>
            <!-- <div class="group">
              <label for="basic-url" class="form-label">{{
                $t("description")
              }} : {{ alldata2.description }}</label>

            </div> -->
            <div class="group">
              <label for="basic-url" class="form-label">{{
                $t("description")
              }}</label>
              <div class="input-group mb-3">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                  v-model="alldata2.description"></textarea>
              </div>
            </div>
          </div>
          <br />
          <div class="row mb-2">
            <label class="col-sm-2 col-form-label-sm">
              <i class="bi bi-pin-angle-fill"></i>
              {{ $t("attachments") }}
            </label>
            <div class="breadcrumb">
              <ul v-for="value of fileurl" :key="value">
                <li>
                  <a :href="this.url + value.name" target="_blank" :download="value.name"><i class="bi bi-download"></i>
                    &nbsp; {{ value.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="row mb-2">
          <label class="col-sm-1 col-form-label-sm"
            >{{ $t("customer") }}
          </label>
          <div class="col-sm-3">
            <select
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="project"
            >
              <option v-for="val in allprojects" :key="val" :value="val.name">
                {{ val.name }}
              </option>
            </select>
          </div>
        </div> -->
          <!-- <div class="row mb-2">
            <label class="col-sm-1 col-form-label-sm"
              >{{ $t("delegate") }}
            </label>
            <div class="col-sm-3">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="task"
              >
                <option v-for="val in alltasks" :key="val.id" :value="val.name">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div> -->
          <br />
          <div class="user">
            <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("created") }}
            {{ $t("by") }} : {{ alldata2.username }}
            <div class="udate">
              {{ $t("date") }} : {{ alldata2.date }} - {{ $t("time") }} :
              {{ alldata2.time }}
            </div>
          </div>
          <div class="user" v-if="alldata2.review == 'confirmed'">
            <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("confirmed") }}
            {{ $t("by") }} : {{ alldata2.reviewusername }}
            <div class="udate">
              {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
              {{ alldata2.reviewtime }}
            </div>
          </div>
          <div class="user" v-if="alldata2.review == 'cancel'">
            <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("cancel") }}
            {{ $t("by") }} : {{ alldata2.reviewusername }}
            <div class="udate">
              {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
              {{ alldata2.reviewtime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  async mounted() {
    const result = await axios.get("/data/getaccounttrue");
    const result2 = await axios.get("/data/getcostcenter");
    const result4 = await axios.get("/data/allcustomer");
    const result6 = await axios.get("/data/JournalEntryID");
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data;
      this.branches = result2.data.data;
      this.allprojects = result4.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
      this.id = Number(result6.data.data[0].count) + Number(1);
    }
    const data2 = await axios.get(
      `/data/JournalEntryReview/${this.$route.params.id}`
    );
    if (data2.status == 200) {
      const data = data2.data.data[0];
      this.alldata2 = data;
      this.trans = data2.data.data2;
      this.details = JSON.parse(this.alldata2.details);
      this.fileurl = JSON.parse(this.alldata2.fileurl);
      this.url = store.state.imgurl + `/journalentry/`;
    }
    if (this.alldata2.review == "waiting") {
      this.totaldpt = Number(
        this.details.reduce((acc, array) => acc + Number(array.dpt), 0)
      ).toFixed(2);
      this.totalcrd = Number(
        this.details.reduce((acc, array) => acc + Number(array.crd), 0)
      ).toFixed(2);
    }
    if (this.alldata2.review == "confirmed") {
      this.totaldpt = Number(
        this.trans.reduce((acc, array) => acc + Number(array.dpt), 0)
      ).toFixed(2);
      this.totalcrd = Number(
        this.trans.reduce((acc, array) => acc + Number(array.crd), 0)
      ).toFixed(2);
    }
  },
  data() {
    return {
      v$: useValidate(),
      alldata2: "",
      details: [],
      fileurl: [],
      url: "",
      totaldpt: 0,
      totalcrd: 0,
    };
  },
  validations() {
    return {
      date: { required },
      description: { required },
      jltype: { required },
    };
  },
  methods: {
    add() {
      this.details.push({
        dpt: 0,
        crd: 0,
        act: "",
        actnum: "",
        namear: "",
        nameen: "",
        details: "",
        totaldpt: 0,
        totalcrd: 0,
        costcenter: { actnum: "", namear: "" },
        err: false
      });
    },
    cal() {
      this.totaldpt = Number(
        this.details.reduce((acc, array) => acc + Number(array.dpt), 0)
      ).toFixed(2);
      this.totalcrd = Number(
        this.details.reduce((acc, array) => acc + Number(array.crd), 0)
      ).toFixed(2);
    },
    async submit() {
      for (let i = 0; i < this.details.length; i++) {
        const element = this.details[i];
        if (!element.act) {
          this.err = true
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "برجاء مراجعة القيد",
          });
          break
        } else {
          this.err = false
        }
      }
      if (this.totalcrd == this.totaldpt && this.totalcrd != 0 && this.err == false) {
        Swal.fire({
          title: this.$t("confirm") + "-" + this.$t("JL"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            const action = await axios.post("/data/confirmjournalentry", {
              id: this.alldata2.jid,
              username: store.state.randp.username,
              trandate: this.alldata2.trandate,
              branch: this.alldata2.branch,
              description: this.alldata2.description,
              details: this.details,
              project: this.alldata2.project,
              task: this.alldata2.task,
            });
            if (action.status == 200) {
              Swal.fire(
                this.$t("done") + " " + this.$t("confirm"),
                this.$t("JL")
              );
              this.redirectTo({ val: "JournalEntry" });
            }
          }
        });
      }
    },
    async cancel() {
      Swal.fire({
        title: this.$t("delete") + "-" + this.$t("JL"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("delete"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/canceljournalentry", {
            id: this.alldata2.jid,
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("done") + " " + this.$t("cancel"), this.$t("JL"));
            this.redirectTo({ val: "JournalEntry" });
          }
        }
      });
    },
    async Edite() {
      Swal.fire({
        title: this.$t("Edite") + "-" + this.$t("JL"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("Edite"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/editejournalentry", {
            id: this.alldata2.jid,
            details: JSON.stringify(this.details),
            username: store.state.randp.username,
            type: this.alldata2.type,
            date: this.alldata2.date,
            description: this.alldata2.description
          });
          if (action.status == 200) {
            Swal.fire(this.$t("done") + " " + this.$t("Edite"), this.$t("JL"));
            this.redirectTo({ val: "JournalEntry" });
          }
        }
      });
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "JournalReport.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.main .body {
  padding: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.group {
  padding: 1%;
  width: 50%;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}
</style>
