<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link to="/accounting/home" class="active">{{
            $t("Property")
          }}</router-link>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#">{{ $t("report") }} {{ $t("revenues") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
      <div class="head2">
        <button type="submit" @click="pdf" v-if="this.data1.length > 0">
          <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
        </button>
        <button
          type="submit"
          @click="exportToExcel"
          v-if="this.data1.length > 0"
        >
          <i class="bi bi-file-earmark-excel"></i> Excel
        </button>
      </div>
    </div>
    <div class="body" id="main" v-if="fromdate && todate">
      <br />
      <div :style="{ direction: css.EN }">
        <h3>{{ name }}</h3>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead style="position: static">
            <tr>
              <th>{{ $t("details") }}</th>
              <th>{{ $t("dpt") }}</th>
              <th>{{ $t("crd") }}</th>
              <th>{{ $t("customer") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("time") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in data1" :key="x">
              <td>
                {{ x.details }}
              </td>
              <td>{{ x.dpt }}</td>
              <td>{{ x.crd }}</td>
              <td>{{ x.taskname }}</td>
              <td>{{ x.trandate }}</td>
              <td>{{ x.time }}</td>
            </tr>
          </tbody>
          <tfoot v-if="totalamount > 0">
            <tr>
              <td>{{ $t("total") }}</td>
              <td></td>
              <td>{{ Number(totalamount).toLocaleString() }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <!-- <div class="buttom" v-if="fromdate && todate">
      <button class="btn btn-primary" type="submit" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
export default {
  async mounted() {
    this.fromdate = this.$route.params.from;
    this.todate = this.$route.params.to;
    this.name = this.$route.params.name;
    const result2 = await axios.get(
      `/reports/GetRevenuesDetails/${this.fromdate}/${this.todate}/${this.$route.params.id}`
    );

    if (result2.status == 200) {
      this.data1 = result2.data.data;
    }
    this.totalamount = this.data1.reduce(
      (acc, array) => acc + Number(array.crd),
      0
    );
  },
  props: ["css"],
  components: {},
  data() {
    return {
      fromdate: "",
      todate: "",
      data1: [],
      from: "",
      to: "",
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
      totalamount: 0,
      accounts: "",
      account: "",
      name: "",
    };
  },
  methods: {
    async submit() {
      if (this.from && this.to) {
        const result = await axios.get(
          `/reports/GetRevenuesDetails/${this.from}/${this.to}/${this.$route.params.id}`,
          {}
        );
        if (result.status == 200) {
          (this.parent = ""), (this.parent2 = "");
          this.todate = this.to;
          this.fromdate = this.from;
          this.data1 = result.data.data;
          this.totalamount = this.data1.reduce(
            (acc, array) => acc + Number(array.crd),
            0
          );
        }
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "revenuesstatement.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.data1.length; i++) {
        const name = this.data1[i];
        data.push({
          رقم_القيد: name.journalid,
          اسم_الحساب: name.namear,
          التفاصيل: name.details,
          العميل: name.taskname,
          المدين: name.dpt,
          الدائن: name.crd,
          اليوم: name.trandate,
          الساعة: name.time,
        });
      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, "revenuesstatement.xlsx");
    },
  },
  watch: {
    async parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
      const result = await axios.get(
        `/reports/IncomeStatementcenter/${this.from}/${this.to}/${this.parent}`
      );
      if (result.status == 200) {
        this.todate = this.to;
        this.data1 = result.data.data;
        this.data2 = result.data.data2;
        this.data3 = result.data.data3;
        this.data4 = result.data.data4;
        this.data5 = result.data.data5;
        this.tincome = Number(
          this.data1[0].amount - this.data2[0].amount
        ).toFixed(2);
        this.tincomenot = Number(
          Number(this.tincome) +
            Number(this.data3[0].amount) -
            this.data4[0].amount
        ).toFixed(2);
        this.tincomet = Number(this.tincomenot - this.data5[0].amount).toFixed(
          2
        );
      }
    },
    async parent2() {
      const result = await axios.get(
        `/reports/IncomeStatementcenter/${this.from}/${this.to}/${this.parent2}`
      );
      if (result.status == 200) {
        this.todate = this.to;
        this.data1 = result.data.data;
        this.data2 = result.data.data2;
        this.data3 = result.data.data3;
        this.data4 = result.data.data4;
        this.data5 = result.data.data5;
        this.tincome = Number(
          this.data1[0].amount - this.data2[0].amount
        ).toFixed(2);
        this.tincomenot = Number(
          Number(this.tincome) +
            Number(this.data3[0].amount) -
            this.data4[0].amount
        ).toFixed(2);
        this.tincomet = Number(this.tincomenot - this.data5[0].amount).toFixed(
          2
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
