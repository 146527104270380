<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a>{{ $t("productions") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"
              >{{ $t("add") }} {{ $t("productions") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                    <a href="#" class="btn-download">
                      <span class="text">Download PDF</span>
                    </a>
                  </li> -->

        <li>
          <router-link
            to="/AdminDashboard/AllProducts/AllProductions"
            class="btn-download"
          >
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("productions") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("date") }}
              <span class="error-feedback" v-if="v$.date.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <input
                type="date"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="date"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("branch") }}
              <span class="error-feedback" v-if="v$.branch.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <select class="form-control form-control-sm" v-model="branch">
                <option v-for="val in data" :key="val" :value="val.id">
                  {{ val.namear }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">
              {{ $t("Account") }} {{ $t("productions") }}
              <span class="error-feedback" v-if="v$.acctproduction.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-2">
              <select
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="acctproduction"
              >
                <option v-for="val in selllist" :key="val" :value="val.actnum">
                  {{ val.actnum }} - {{ val.namear }}
                </option>
                <option value="space">{{ $t("space") }}</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("description") }}
              <span class="error-feedback" v-if="v$.description.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-3">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="description"
              ></textarea>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("add") }} {{ $t("to") }}
              <span class="error-feedback" v-if="v$.description.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-1">
              <input
                class="form-check-input"
                type="radio"
                value="project"
                v-model="addto"
              />
              &nbsp;
              <label class="form-check-label" for="gridRadios2">
                {{ $t("project") }}
              </label>
            </div>
            <div class="col-sm-1">
              <input
                class="form-check-input"
                type="radio"
                v-model="addto"
                value="task"
              />
              &nbsp;
              <label class="form-check-label" for="gridRadios2">
                {{ $t("task") }}
              </label>
            </div>
          </div>
          <div class="row mb-2" v-if="addto == 'project'">
            <label class="col-sm-1 col-form-label-sm"
              >{{ $t("project") }}
            </label>
            <div class="col-sm-3">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="project"
              >
                <option v-for="val in allprojects" :key="val" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="addto == 'task'">
            <label class="col-sm-1 col-form-label-sm">{{ $t("task") }} </label>
            <div class="col-sm-3">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="task"
              >
                <option v-for="val in alltasks" :key="val.id" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
          <br />
          <div class="prodetails">
            {{ $t("add") }} {{ $t("product") }} <br /><br />
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm">
                {{ $t("product") }} :
              </label>

              <div class="col-sm-3">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="rawmaterialselect"
                >
                  <option v-for="val in rawmaterial" :key="val" :value="val">
                    {{ val.namear }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <button
                  class="btn btn-success"
                  @click="add"
                  style="padding: 0.25%"
                >
                  {{ $t("add") }} <i class="bi bi-plus"></i>
                </button>
              </div>
            </div>

            <table class="table" style="width: 30%">
              <thead>
                <tr>
                  <td>{{ $t("product") }}</td>
                  <td>{{ $t("quantity") }}</td>
                  <td>{{ $t("unit") }}</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="val in pddata" :key="val">
                  <td>{{ val.namear }}</td>
                  <td>
                    <input
                      class="form-control form-control-sm"
                      v-model="val.pquon"
                    />
                  </td>
                  <td>{{ val.unittype }}</td>
                  <td>
                    <button
                      class="btn btn-primary"
                      @click="remove(val.id)"
                      style="padding: 0"
                    >
                      <i class="bi bi-dash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="buttom">
          <button class="buttonconfirm" type="submit" @click="submit">
            {{ $t("save") }}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button class="buttoncancel" type="submit" @click="back">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      date: "",
      branch: "",
      acctproduction: "",
      code: "",
      show: "",
      r2: store.state.randp.r2,
      data: [],
      data2: [],
      selllist: [],
      pddata: [],
      description: "",
      rawmaterial: "",
      rawmaterialselect: "",
      alert: false,
      addto: "",
      allprojects: [],
      alltasks: [],
      task: "",
      project: "",
    };
  },
  validations() {
    return {
      date: { required },
      branch: { required },
      acctproduction: { required },
      description: { required },
    };
  },
  async mounted() {
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/allbranches");
    const result2 = await axios.get("/data/allproducts2");
    const result3 = await axios.get("/data/accountingmanual");
    const result4 = await axios.get("/data/allprojects");
    const result5 = await axios.get("/data/alltasks");
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
      this.selllist = result3.data.data;
      this.rawmaterial = result2.data.data;
      this.allprojects = result4.data.data;
      this.alltasks = result5.data.data;
    }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllProducts/AllProductions",
      });
    },
    add() {
      const id = this.rawmaterialselect.id;
      const filtter = this.pddata.filter((x) => x.id == id);
      if (filtter.length == 0) {
        this.pddata.push({
          id: this.rawmaterialselect.id,
          namear: this.rawmaterialselect.namear,
          nameen: this.rawmaterialselect.nameen,
          unittype: this.rawmaterialselect.unittype,
          pquon: 1,
          pprice: this.rawmaterialselect.buyp,
        });
        this.rawmaterialcost = 0;
      } else {
        alert("تم اضافة المنتج ");
      }
    },
    remove(id) {
      const indexOfObject = this.pddata.findIndex((object) => {
        return object.id === id;
      });
      this.pddata.splice(indexOfObject, 1);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error && this.alert == false) {
        const result = await axios.post("/data/createnewproduction", {
          username: store.state.randp.username,
          due: this.date,
          branch: this.branch,
          acctproduction: this.acctproduction,
          description: this.description,
          project: this.project,
          task: this.task,
          data: JSON.stringify(this.pddata),
          imgurl: "",
        });
        if (result.status == 200) {
          this.redirectTo({ val: "AllProduction" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
    addto() {
      if (this.addto == "project") {
        this.task = null;
      }
      if (this.addto == "task") {
        this.project = null;
      }
    },
    task() {
      const project = this.alltasks.filter((x) => x.id == this.task)[0]
        .projectid;
      this.project = project;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;

  margin: 0.15%;
}
.head-option .btn-download {
  // width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top:  v-bind("css.color") 5px solid;
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.part1 {
  //   width: 80%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.buttom {
  text-align: center;
}
</style>
