<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/accounting/ChartOfAccounts" class="active">{{
              $t("chart of accounts")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="#" class="active"
              >{{ $t("add") }} {{ $t("Account") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <li>
          <router-link
            to="/AdminDashboard/ChartOfAccounts"
            class="btn-download"
          >
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("Account") }}</h3>
        </div>
        <div class="head2">
          <button @click="submit">{{ $t("save") }}</button>
          <button @click="back">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="group">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("Account") }} {{ $t("main") }} </label
            ><span class="error-feedback" v-if="v$.typeaccount.$error">{{
              "*"
            }}</span>
            <div class="col-sm-2">
              <v-select :options="data1" label="namear" v-model="filter">
                <template #option="option">
                  <span>{{ option.namear }}</span>
                </template>
              </v-select>
            </div>

            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("Accounts") }} {{ $t("sub") }} :
            </label>
            <div class="col-sm-4">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("name") }} {{ $t("ar") }}</th>
                    <th>{{ $t("code") }} {{ $t("Account") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="val in datalevel" :key="val">
                    <td>{{ val.namear }}</td>
                    <td>{{ val.actnum }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mb-5" v-if="typeaccountselect">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("nature") }} {{ $t("Account") }} </label
            ><span class="error-feedback" v-if="v$.typeaccount.$error">{{
              "*"
            }}</span>
            <div class="col-sm-2">
              <select class="form-select" v-model="typeact">
                <option value="debit" id="select">{{ $t("debit") }}</option>
                <option value="credit" id="select">{{ $t("credit") }}</option>
              </select>
            </div>
          </div>
          <div class="row mb-5" v-if="!typeaccountselect">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("nature") }} {{ $t("Account") }} </label
            ><span class="error-feedback" v-if="v$.typeaccount.$error">{{
              "*"
            }}</span>
            <div class="col-sm-2">
              <select class="form-select" v-model="typeact" disabled>
                <option value="debit" id="select">{{ $t("debit") }}</option>
                <option value="credit" id="select">{{ $t("credit") }}</option>
              </select>
            </div>
          </div>
          <div class="row mb-5">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("accepte") }} {{ $t("transaction") }} </label
            ><span class="error-feedback" v-if="v$.typeaccount.$error">{{
              "*"
            }}</span>
            <div class="col-sm-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck1"
                v-model="sup"
              />
            </div>
          </div>
          <div class="row mb-5">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("name") }} {{ $t("ar") }}
              <span class="error-feedback" v-if="v$.namear.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="namear"
              />
            </div>
          </div>
          <div class="row mb-5">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("name") }} {{ $t("en") }}
              <span class="error-feedback" v-if="v$.nameen.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="nameen"
              />
            </div>
          </div>
          <div class="row mb-5">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("code") }} {{ $t("Account") }}
              <span class="error-feedback" v-if="v$.accountcode.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="accountcode"
              />
            </div>
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("description") }}
              <span class="error-feedback" v-if="v$.description.$error">{{
                "*"
              }}</span>
            </label>

            <div class="col-sm-5">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="description"
              ></textarea>
            </div>
          </div>
          <div></div>
          <div class="buttom"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      namear: "",
      nameen: "",
      description: "",
      r2: store.state.randp.r2,
      datalevel: [],
      data1: [],
      data2: [],
      unit: [],
      filter: "",
      selection: false,
      alert: false,
      item: "",
      itemnum: "",
      typeaccountselect: false,
      accountcode: "1",
      typeaccount: "",
      typeact: "",
      sup: false,
      parent: "",
      level: "",
    };
  },
  validations() {
    return {
      namear: { required },
      nameen: { required },
      accountcode: { required },
      description: { required },
      typeaccount: { required },
      typeact: { required },
    };
  },
  async mounted() {
    const result = await axios.get("/data/accountingmanual");
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/accounting/ChartOfAccounts",
      });
    },
    async checkname() {
      const result = await axios.get(`/data/productcheckname/${this.name}`);
      if (result.status == 200) {
        const data = result.data.data;
        if (data.length > 0) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      }
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post("/data/accountingmanual", {
          username: store.state.randp.username,
          namear: this.namear,
          nameen: this.nameen,
          actnum: this.accountcode,
          parent: this.parent,
          description: this.description,
          typeaccount: this.typeaccount,
          typeact: this.typeact,
          sup: this.sup,
          level: this.level,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "ChartOfAccounts" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.item = this.filter.namear;
      this.itemnum = this.filter.actnum;
      this.level = Number(this.filter.level) + Number(1);
    },
    itemnum() {
      if (this.itemnum == "main") {
        this.datalevel = this.data2.filter((x) => x.actnum.length == 1);
        this.typeaccountselect = true;
        const last = this.datalevel[this.datalevel.length - 1];
        const lastnu = last.actnum;
        this.accountcode = Number(lastnu) + 1;
      } else {
        this.datalevel = this.data2.filter((x) =>
          x.actnum.startsWith(this.itemnum.toLowerCase())
        );
        this.typeaccountselect = false;
        this.typeaccount = this.datalevel[0].typeaccount;
        this.typeact = this.datalevel[0].typeact;
        this.parent = this.datalevel[0].actnum;
        if (this.datalevel.length > 1) {
          const last = this.datalevel.slice(1);
          const lastarr = last[last.length - 1];
          const lastnu = lastarr.actnum;
          this.accountcode = Number(lastnu) + 1;
        } else {
          const last = this.datalevel[0];
          const lastnu = last.actnum;
          this.accountcode = lastnu + "01";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}
.main {
  width: 98%;
  // margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}
.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}
.head1 {
  width: 70%;
}
.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.head2 {
  width: 30%;
  text-align: center;
}
.head2 button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}
.group {
  padding: 1%;
  width: 90%;
}

.group .select {
  width: 50%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
</style>
