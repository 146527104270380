<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#">{{ $t("reports") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#">{{ $t("Balance Sheet") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("to") }} :</label
          >
        </div>
        <div class="col-auto">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="to"
          />
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary" @click="submit">
          {{ $t("search") }} <i class="bi bi-search"></i>
        </button>
      </div>
    </div>
    <div class="body" id="main">
      <div :style="{ direction: css.EN }">
        <h3>{{ $t("Balance Sheet") }}</h3>
        <h6>{{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead style="position: static">
            <tr>
              <th colspan="2">{{ todate }}</th>
            </tr>
            <tr>
              <th style="width: 50%">{{ $t("Account") }}</th>
              <th>{{ $t("amount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in data1" :key="i">
              <td v-if="i.sup == 'true'" class="childname">
                <router-link to="" @click="summary(i)">
                  {{ i.actnum }} -
                  <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                  ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
                </router-link>
              </td>

              <td v-if="i.sup == 'false'">
                {{ i.actnum }} -
                <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ i.amount
                }}<span v-if="i.amount == null">{{ (i.amount = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.amount != 0">{{ i.amount }}</span>
              </td>
            </tr>
            <tr class="total">
              <td>اجمالى الاصول</td>
              <td>{{ tincome }}</td>
            </tr>
            <tr>
              <td><hr /></td>
              <td><hr /></td>
            </tr>
            <tr v-for="i in data2" :key="i">
              <td v-if="i.sup == 'true'" class="childname">
                <router-link to="" @click="summary(i)">
                  {{ i.actnum }} -
                  <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                  ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
                </router-link>
              </td>

              <td v-if="i.sup == 'false'">
                {{ i.actnum }} -
                <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ i.amount
                }}<span v-if="i.amount == null">{{ (i.amount = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.amount != 0">{{ i.amount }}</span>
              </td>
            </tr>
            <tr class="total">
              <td>اجمالى الالتزامات</td>
              <td>{{ tincome2 }}</td>
            </tr>
            <tr>
              <td><hr /></td>
              <td><hr /></td>
            </tr>
            <tr v-for="i in data3" :key="i">
              <td v-if="i.sup == 'true'" class="childname">
                <router-link to="" @click="summary(i)">
                  {{ i.actnum }} -
                  <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                  ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
                </router-link>
              </td>

              <td v-if="i.sup == 'false'">
                {{ i.actnum }} -
                <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ i.amount
                }}<span v-if="i.amount == null">{{ (i.amount = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.amount != 0">{{ i.amount }}</span>
              </td>
            </tr>
            <tr class="total">
              <td>اجمالى حقوق الملكية</td>
              <td>{{ tincome3 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="buttom">
      <button class="btn btn-primary" type="submit" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import html2pdf from "html2pdf.js";
export default {
  async mounted() {
    const result2 = await axios.get(
      `/reports/IncomeStatement/${this.fromdate}/${this.todate}`
    );
    if (result2.status == 200) {
      const data1 = result2.data.data;
      const data2 = result2.data.data2;
      const data3 = result2.data.data3;
      const data4 = result2.data.data4;
      const data5 = result2.data.data5;
      const tincome = Number(data1[0].amount - data2[0].amount).toFixed(2);
      const tincomenot = Number(
        Number(tincome) + Number(data3[0].amount) - data4[0].amount
      ).toFixed(2);
      this.incomet = Number(tincomenot - data5[0].amount).toFixed(2);
    }
    const result = await axios.get(
      `/reports/BalanceSheet/${this.fromdate}/${this.todate}`
    );
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data2;
      this.data3 = result.data.data3;
      this.tincome = Number(this.data1[0].amount).toFixed(2);
      this.tincome2 = Number(this.data2[0].amount).toFixed(2);
      this.tincome3 = Number(
        Number(this.data3[0].amount) + Number(this.incomet)
      ).toFixed(2);
    }
  },
  props: ["css"],
  data() {
    return {
      fromdate: "2023-01-01",
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      data2: [],
      from: "",
      to: "",
      tincome: 0,
      tincome2: 0,
      tincomet: 0,
      incomet: 0,
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllReports/GeneralLedgerSummaryMonthly/${val.actnum}/${val.namear}/${val.nameen}/${val.typeact}`,
      });
    },
    async submit() {
      const result = await axios.get(
        `/reports/BalanceSheet/${this.fromdate}/${this.to}`
      );
      if (result.status == 200) {
        this.todate = this.to;
        this.data1 = result.data.data;
        this.data2 = result.data.data2;
        this.data3 = result.data.data3;
        this.tincome = Number(this.data1[0].amount).toFixed(2);
        this.tincome2 = Number(this.data2[0].amount).toFixed(2);
        this.tincome3 = Number(this.data3[0].amount).toFixed(2);
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: "incomestatement.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
  color: black;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.main {
  margin: 1%;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 1%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  text-align: center;
}

.col {
  padding-left: 2%;
  padding-right: 2%;
}
tr {
  text-align: v-bind("css.dirn");
}
.table {
  width: 80%;
  // line-height: 50%;
  background-color: white;
  margin-right: 10%;
  margin-left: 10%;
}
.child {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  background-color: #d6d6d6;
}
.childname {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #d6d6d6;
  width: 30%;
}
table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #c6d7e9;
}
.total {
  font-weight: bold;
  background-color: #c6d7e9;
}
.buttom {
  text-align: center;
}
button {
  margin: 0.5%;
}
@media screen and (max-width: 576px) {
  .table {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
