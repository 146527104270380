<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("projects") }} & {{ $t("tasks") }}</a>
          </li>
          <li>-</li>
          <li>
            <a>{{ $t("projects") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("project") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                        <a href="#" class="btn-download">
                          <span class="text">Download PDF</span>
                        </a>
                      </li> -->

        <li>
          <router-link to="/AdminDashboard/AllTasks" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("project") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("reference") }}
              <span class="error-feedback" v-if="v$.code.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="code"
                disabled
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("name") }}
              <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="name"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("description") }}
              <span class="error-feedback" v-if="v$.description.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="description"
              ></textarea>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("dueon") }}
              <span class="error-feedback" v-if="v$.description.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-2">
              <input
                type="date"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="dueon"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("private") }}
            </label>

            <div class="col-sm-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck1"
                v-model="privat"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("priority") }}
            </label>

            <div class="col-sm-2">
              <select
                class="form-control form-control-sm"
                type="checkbox"
                id="gridCheck1"
                v-model="priority"
              >
                <option value="very high">{{ $t("very high") }}</option>
                <option value="high">{{ $t("high") }}</option>
                <option value="medium">{{ $t("medium") }}</option>
                <option value="very low">{{ $t("very low") }}</option>
                <option value="low">{{ $t("low") }}</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("add") }} {{ $t("to") }} {{ $t("project") }}
            </label>

            <div class="col-sm-2">
              <select
                class="form-control form-control-sm"
                type="checkbox"
                id="gridCheck1"
                v-model="project"
              >
                <option
                  v-for="val in projects"
                  :key="val"
                  :value="{ id: val.id, name: val.name }"
                >
                  {{ val.name }} (PRJ-{{ val.pid }})
                </option>
              </select>
            </div>
          </div>

          <div class="buttom">
            <button class="buttonconfirm" type="submit" @click="submit">
              {{ $t("save") }}
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="buttoncancel" type="submit" @click="back">
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      projects: [],
      name: "",
      code: "",
      description: "",
      dueon: "",
      privat: "",
      priority: "",
      project: "",
      projectname: "",
      projectid: "",
      r2: store.state.randp.r2,
    };
  },
  validations() {
    return {
      name: { required },
      code: { required },
      description: { required },
    };
  },
  async mounted() {
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/lasttasknum");
    if (result.status == 200) {
      this.code = "TASK-" + (Number(result.data.data[0].count) + Number(1));
    }
    const result2 = await axios.get("/data/allprojects");
    if (result.status == 200) {
      this.projects = result2.data.data;
    }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllTasks",
      });
    },
    async checkname() {
      const result = await axios.get(`/data/productcheckname/${this.name}`);
      if (result.status == 200) {
        const data = result.data.data;
        if (data.length > 0) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      }
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post("/data/createnewtask", {
          username: store.state.randp.username,
          name: this.name,
          description: this.description,
          due: this.dueon,
          private: this.privat,
          users: "",
          priority: this.priority,
          projectname: this.projectname,
          projectid: this.projectid,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "AllTasks" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
    project() {
      this.projectname = this.project.name;
      this.projectid = this.project.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;

  margin: 0.15%;
}
.head-option .btn-download {
  // width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top:  v-bind("css.color") 5px solid;
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.part1 {
  //   width: 80%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.buttom {
  text-align: center;
}
</style>
