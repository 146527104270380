<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("Accounts") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active">{{ $t("balance") }} {{ $t("opening") }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                <a href="#" class="btn-download">
                  <span class="text">Download PDF</span>
                </a>
              </li> -->
        <li>
          <router-link to="/AdminDashboard/JournalEntry" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("balance") }} {{ $t("opening") }}</h3>
      </div>
      <div class="body">
        <div class="stitle">
          <div to="/AdminDashboard/AllProducts" class="sstitle">
            {{ $t("step") }} {{ $t("first") }}<br />
            <small
              >{{ $t("type") }} {{ $t("balance") }} {{ $t("opening") }}
            </small>
          </div>
          <div class="arrow"></div>
          <!-- <div to="/AdminDashboard/AllProducts" class="sstitle2">
              {{ $t("step") }} {{ $t("second") }}<br />
              <small> {{ $t("details") }}</small>
            </div>
            <div class="arrow2"></div> -->
        </div>
        <div class="body2">
          <h3>{{ $t("type") }} {{ $t("balance") }} {{ $t("opening") }}</h3>
          <div class="body3">
            <div class="body31" @click="step2(1)">
              <i class="bi bi-bar-chart-line-fill"></i><br />
              <small>{{ $t("Accounts") }}</small>
            </div>
            <div class="body31" @click="step2(2)">
              <i class="bi bi-boxes"></i><i class="bi bi-currency-dollar"></i
              ><br /><small
                >{{ $t("products") }} {{ $t("and") }} {{ $t("costs") }}</small
              >
            </div>
            <div class="body31" @click="step2(3)">
              <i class="bi bi-people-fill"></i><br /><small>{{
                $t("customers")
              }}</small>
            </div>
            <div class="body31" @click="step2(4)">
              <i class="bi bi-people-fill"></i><br /><small>{{
                $t("suppliers")
              }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
// import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      r2: store.state.randp.r2,
    };
  },
  async mounted() {
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
  },
  methods: {
    step2(val) {
      this.$root.$router.push({
        path: `/accounting/OpeningBalance2/${val}`,
        params: { id: val },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    mobile() {
      this.table.rows = this.table.data.filter((x) =>
        x.mobile.includes(this.mobile)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;
  padding: 0.25%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.15%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.table {
  text-align: center;
}
.table tr {
  background: rgba(191, 214, 219, 0.534);
  line-height: 5px;
}
button {
  margin: 1%;
}

.stitle {
  background-color: #aaaaaa;
  display: flex;
  text-align: center;
  //   padding: 1%;
}
.sstitle {
  text-align: center;
  background-color: black;
  //   border: solid 2px rgba(128, 128, 128, 0.589);
  //   margin: 0.25%;
  color: v-bind("css.dark");
  padding: 1%;
  color: white;
}
.arrow {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 20px solid black;
}
.main .body2 h3 {
  padding: 2%;
  text-align: center;
  color: v-bind("css.dark");
}
.main .body3 {
  display: flex;
  color: v-bind("css.dark");
}
.main .body3 .body31 {
  font-size: xx-large;
  width: 30%;
  padding: 2%;
  margin: 2%;
  //   border: 1px solid black;
  text-align: center;
}
.main .body3 .body31 .comment {
  font-size: small;
}
.main .body3 .body31:hover {
  font-size: xx-large;
  width: 30%;

  padding: 2%;
  margin: 2%;
  border-radius: 10px;
  text-align: center;
  background-color: gray;
  color: white;
}
@media screen and (max-width: 576px) {
  .main .body3 {
    display: flex;
    color: v-bind("css.dark");
  }
  .main .body3 .body31 {
    font-size: large;
    width: 20%;
    padding: 2%;
    margin: 2%;
    //   border: 1px solid black;
    text-align: center;
  }
}
</style>
