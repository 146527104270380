<template>
<Navbar/>
</template>
<script>
import Navbar from "@/components/AllNavBar.vue";
export default {

  components: {Navbar},
  data() {
    return {};
  },
  async mounted() {
  },

  methods: {
  
  },
  watch: {},
};
</script>