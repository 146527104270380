<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("Property") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allproperties" class="active"
              >{{ $t("all") }} {{ $t("real estate") }} /
              {{ $t("units") }}</router-link
            >
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active"
              >{{ $t("real estate") }} / {{ $t("units") }} :
              {{ name }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("real estate") }} / {{ $t("units") }}</h3>
        </div>
        <div class="head2">
          <button
            class="buttonconfirm"
            type="submit"
            :disabled="disabled"
            @click="submite"
          >
            {{ $t("Edite") }}
          </button>
        </div>
      </div>
      <br />
      <div class="body">
        <div class="body1">
          <div class="group">
            <label for="basic-url" class="form-label"
              >{{ $t("name") }} {{ $t("unit") }}
            </label>
            &nbsp;
            <span class="error-feedback" v-if="v$.name.$error">{{ "*" }}</span>
            <div>
              <input type="text" v-model="name" />
            </div>
          </div>
          <div class="group">
            <label for="basic-url" class="form-label"></label>
            <div style="display: flex">
              <div id="preview">
                <img
                  :src="
                    this.url + this.$route.params.id + '-' + this.cnum + '.png'
                  "
                  @error="$event.target.src = `${this.url}Default.png`"
                  crossorigin="anonymous"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="body3">
          <div class="selection">
            <button
              class=""
              :class="select1 ? 'active' : ''"
              @click="
                (this.select1 = true),
                  (this.select2 = false),
                  (this.select3 = false),
                  (this.select4 = false),
                  (this.select5 = false)
              "
            >
              {{ $t("description") }}
            </button>
            <button
              class=""
              :class="select2 ? 'active' : ''"
              @click="
                (this.select1 = false),
                  (this.select2 = true),
                  (this.select3 = false),
                  (this.select4 = false),
                  (this.select5 = false)
              "
            >
              {{ $t("owners") }}
            </button>
            <button
              class=""
              :class="select3 ? 'active' : ''"
              @click="
                (this.select1 = false),
                  (this.select2 = false),
                  (this.select3 = true),
                  (this.select4 = false),
                  (this.select5 = false)
              "
            >
              {{ $t("spaces") }}
            </button>
            <button
              class=""
              :class="select4 ? 'active' : ''"
              @click="
                (this.select1 = false),
                  (this.select2 = false),
                  (this.select3 = false),
                  (this.select4 = true),
                  (this.select5 = false)
              "
            >
              {{ $t("Accounts") }}
            </button>
            <button
              class=""
              :class="select5 ? 'active' : ''"
              @click="
                (this.select1 = false),
                  (this.select2 = false),
                  (this.select3 = false),
                  (this.select4 = false),
                  (this.select5 = true)
              "
            >
              {{ $t("Commissions") }}
            </button>
          </div>
          <div class="group2" v-if="select1 == true">
            <div class="part">
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">{{ $t("owner") }}</label>
                <div class="col-sm-7">
                  <v-select :options="owners" label="name" v-model="owner">
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.name }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("category") }} {{ $t("realty") }}</label
                >
                <div class="col-sm-7">
                  <v-select
                    :options="categories"
                    label="category"
                    v-model="catitem"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.category }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("type") }} {{ $t("realty") }}</label
                >
                <div class="col-sm-4">
                  <input type="text" class="form-control" v-model="type" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("service") }}
                </label>
                <div class="col-sm-7">
                  <v-select :options="servies" label="servie" v-model="servie">
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.servie }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("Commission") }} {{ $t("management") }}
                </label>
                <div class="col-sm-7">
                  <v-select
                    :options="commissionstype"
                    label="category"
                    v-model="comtype"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.category }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("Commission") }} %
                </label>
                <div class="col-sm-7">
                  <input
                    type="number"
                    class="form-control"
                    v-model="commission"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("type") }} {{ $t("Commission") }}
                  {{ $t("collection") }}
                </label>
                <div class="col-sm-7">
                  <select
                    type="number"
                    class="form-control"
                    v-model="commissiontype"
                  >
                    <option value="1">العمولة شامل الضريبة</option>
                    <option value="2">العمولة غير شامل الضريبة</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("conductor") }}
                </label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" v-model="conductor" />
                </div>
              </div>
            </div>
            <div class="part">
              <GoogleMap
                api-key="AIzaSyDFT8pm_ORjQHfxKsTnBb1c6hM-HVvoN6Q"
                style="width: 100%; height: 300px"
                :center="center"
                :zoom="15"
              >
                <Marker :options="markerOptions" />
              </GoogleMap>
            </div>
          </div>
          <div class="body4" v-if="select1 == true">
            <h5>
              <i class="bi bi-building"></i> &nbsp;{{ $t("real estate units") }}
            </h5>
            <div style="overflow-x: scroll; font-size: x-small">
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>الدور</th>
                    <th>نوع الوحدة</th>
                    <th>الاستخدام</th>
                    <th>رقم الوحدات</th>
                    <th>مساحة الوحدات</th>
                    <th>سعر المتر المربع</th>
                    <th>عدد الغرف لكل وحدة</th>
                    <th>رقم عداد الكهرباء</th>
                    <th>حساب عداد الكهرباء</th>
                    <th>الايجار السنوى للوحدة</th>
                    <th>الدفعات</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, id) in prodata" :key="x">
                    <td>{{ id + 1 }}</td>
                    <td><input type="text" v-model="x.role" /></td>
                    <td>
                      <select v-model="x.type">
                        <option value="شقة">شقة</option>
                        <option value="محل">محل</option>
                        <option value="معرض">معرض</option>
                        <option value="مبسط">مبسط</option>
                        <option value="ورشة">ورشة</option>
                        <option value="مستودع">مستودع</option>
                        <option value="مصنع">مصنع</option>
                        <option value="عمارة">عمارة</option>
                        <option value="جناح">جناح</option>
                        <option value="دور">دور</option>
                        <option value="مطعم">مطعم</option>
                        <option value="مكتب">مكتب</option>
                      </select>
                    </td>
                    <td>
                      <select v-model="x.protype">
                        <option value="residential">سكنى</option>
                        <option value="commercial">تجارى</option>
                      </select>
                    </td>
                    <td><input type="text" v-model="x.unitnum" /></td>
                    <td><input type="text" v-model="x.unitspace" /></td>
                    <td><input type="text" v-model="x.unitspacep" /></td>
                    <td><input type="text" v-model="x.roomsnum" /></td>
                    <td><input type="text" v-model="x.elcnum" /></td>
                    <td><input type="text" v-model="x.elcact" /></td>
                    <td><input type="text" v-model="x.annualrent" /></td>
                    <td>
                      <select v-model="x.due">
                        <option value="1">شهرى</option>
                        <option value="3">ربع سنوى</option>
                        <option value="6">نصف سنوى</option>
                        <option value="12">سنوى</option>
                      </select>
                    </td>
                    <td>
                      <a href="#" @click.prevent="del(id)"
                        ><i class="bi bi-x-circle-fill"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button @click="this.prodata.push({})">اضافة وحدات</button>
            <hr />
            <h5>{{ $t("notes") }}</h5>
            <textarea
              v-model="notes"
              id=""
              style="width: 100%"
              rows="10"
            ></textarea>
          </div>
          <div class="group2" v-if="select2 == true">
            <div class="part">
              <div class="mb-3 row">
                <div class="mb-1 row">
                  <label for="inputPassword" class="col-sm-2 col-form-label"
                    >نوع الوثيقة</label
                  >
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="doctype" />
                  </div>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="inputPassword" class="col-sm-2 col-form-label"
                  >تاريخ الاصدار</label
                >
                <div class="col-sm-10">
                  <input
                    type="date"
                    class="form-control"
                    id="inputPassword"
                    v-model="docdate"
                  />
                </div>
              </div>
            </div>

            <div class="part">
              <div class="mb-3 row">
                <label for="inputPassword" class="col-sm-2 col-form-label">
                  رقم وثيقة
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="docnum"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="body4" v-if="select2 == true">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>المالك</th>
                  <th>الحصة</th>
                  <th>رقم الحساب</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, id) in docdata" :key="x">
                  <td>{{ id + 1 }}</td>
                  <td><input type="text" v-model="x.owner" /></td>
                  <td><input type="text" v-model="x.percent" /></td>
                  <td><input type="text" v-model="x.owneracct" /></td>
                  <td>
                    <a href="#" @click.prevent="del2(id)"
                      ><i class="bi bi-x-circle-fill"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <button @click="this.docdata.push({})">اضافة</button>
          </div>
          <div class="group3" v-if="select3 == true">
            <div class="part">
              <div class="input-group mb-1">
                <span class="input-group-text">مساحة الارض</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.land"
                />
                <span class="input-group-text" id="basic-addon2">متر مربع</span>
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">المساحة التاجيرية</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.rentspace"
                />
                <span class="input-group-text" id="basic-addon2">متر مربع</span>
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">مسطح البناء</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.flatbuild"
                />
                <span class="input-group-text" id="basic-addon2">متر مربع</span>
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">عدد الطوابق</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.flatnum"
                />
              </div>
            </div>
            <div class="part">
              <div class="input-group mb-1">
                <span class="input-group-text"> المخطط</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.scheme"
                />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> قطعة رقم</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.placenum"
                />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> سعر البيع</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.salep"
                />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">المدينة</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.city"
                />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> الحي</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.aria"
                />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> الرمز البريدى</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.zip"
                />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> رقم المبنى </span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.buildnum"
                />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> اسم الشارع </span>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalspace.street"
                />
              </div>
            </div>
          </div>
          <div class="body4" v-if="select3 == true">
            <h4>الحدود و الاطوال</h4>
            <table class="table" style="width: 50%">
              <thead>
                <tr>
                  <th>الجهه</th>
                  <th>الحد</th>
                  <th>بطول</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>شمال</td>
                  <td><input type="text" v-model="totalspace.northl" /></td>
                  <td><input type="text" v-model="totalspace.northh" /></td>
                </tr>
                <tr>
                  <td>جنوب</td>
                  <td><input type="text" v-model="totalspace.southl" /></td>
                  <td><input type="text" v-model="totalspace.southh" /></td>
                </tr>
                <tr>
                  <td>شرق</td>
                  <td><input type="text" v-model="totalspace.eastl" /></td>
                  <td><input type="text" v-model="totalspace.easth" /></td>
                </tr>
                <tr>
                  <td>غرب</td>
                  <td><input type="text" v-model="totalspace.westl" /></td>
                  <td><input type="text" v-model="totalspace.westh" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="group2" v-if="select4 == true">
            <div class="part">
              <div class="mb-3 row">
                <label for="inputPassword" class="col-sm-2 col-form-label"
                  >دورة السداد</label
                >
                <div class="col-sm-5">
                  <select
                    class="form-control"
                    id="inputPassword"
                    v-model="payment"
                  >
                    <option value="monthly">شهرى</option>
                    <option value="quarterly">ربع سنوى</option>
                    <option value="midterm">نصف سنوى</option>
                    <option value="annual">سنوى</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="part">
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("Account") }} {{ $t("payment") }}
                </label>
                <div class="col-sm-7">
                  <v-select
                    :options="accountpayment"
                    label="namear"
                    v-model="account"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.namear }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("Account") }} {{ $t("tax") }}
                </label>
                <div class="col-sm-7">
                  <v-select
                    :options="accountpayment"
                    label="namear"
                    v-model="taxaccount"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.namear }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"
                  >{{ $t("Account") }} {{ $t("Commission") }}
                </label>
                <div class="col-sm-7">
                  <v-select
                    :options="accountpayment"
                    label="namear"
                    v-model="commissionaccount"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.namear }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="group2" v-if="select5 == true">
            <div class="part">
              <h5>العمولات</h5>
              <div style="width: 25%">
                <v-select
                  :options="AllCommissions"
                  label="name"
                  v-model="allcommission"
                >
                  <template #option="option">
                    <div style="text-align: center">
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>العمولة</th>
                    <th>حساب العمولة</th>
                    <th>نسبة العمولة</th>
                    <th>مبلغ العمولة</th>
                    <th>حساب الضريبة</th>
                    <th>ضريبة العمولة</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(y, id) in commissions" :key="y">
                    <td style="width: 20%">
                      {{ y.name }}
                    </td>
                    <td style="width: 30%">
                      <v-select label="namear" v-model="y.act" disabled>
                        <template #option="option">
                          <span>{{ option.namear }}</span>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <div style="display: flex">
                        <input type="text" v-model="y.num" />&nbsp;%
                      </div>
                    </td>
                    <td>
                      <div style="display: flex; width: 120%">
                        <input type="text" v-model="y.comamount" />
                      </div>
                    </td>
                    <td style="width: 30%">
                      <v-select
                        :options="allaccounts"
                        label="namear"
                        v-model="y.taxact"
                        disabled
                      >
                        <template #option="option">
                          <span>{{ option.namear }}</span>
                        </template>
                      </v-select>
                    </td>
                    <td style="display: flex">
                      <div style="display: flex">
                        <input
                          type="text"
                          v-model="y.tax"
                          @change="
                            y.amount = Number(
                              (this.unite.annualrent * y.num) / 100 +
                                (((this.unite.annualrent * y.num) / 100) *
                                  y.tax) /
                                  100
                            ).toFixed(2)
                          "
                        />&nbsp;%
                      </div>
                    </td>

                    <td>
                      <a href="#" @click.prevent="delcom(id)"
                        ><i class="bi bi-x-circle-fill"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import vSelect from "vue-select";
import { GoogleMap, Marker } from "vue3-google-map";

export default {
  props: ["css"],
  components: {
    vSelect,
    GoogleMap,
    Marker,
  },
  data() {
    return {
      cnum: store.state.cnum[0].cnum,
      disabled: false,
      v$: useValidate(),
      url: "",
      select1: true,
      select2: false,
      select3: false,
      select4: false,
      select5: false,
      center: { lat: 31.2902956, lng: 31.6900514 },
      name: "",
      prodata: [{}],
      docdata: [{}],
      totalspace: {},
      owners: [],
      owner: "",
      categories: [
        { category: "تجاري", value: "commercial" },
        { category: "سكني", value: "residential" },
        { category: "سكني و تجاري", value: "commercial & residential" },
        { category: "اداري", value: "administartive" },
        { category: "تجاري و اداري", value: "commercial & administartive" },
        { category: "صناعي", value: "industrial" },
        { category: "زراعي", value: "agricultural" },
      ],
      catitem: { category: "تجاري", value: "commercial" },
      type: "عمارة",
      servies: [
        { servie: "ايجار (عقارات مملوكة)", value: "rent (owned properties)" },
        { servie: "ايجار (إعادة استثمار)", value: "rent (reinvestment)" },
        { servie: "ادارة املاك الغير", value: "rent (managing properties)" },
      ],
      servie: {
        servie: "ايجار (عقارات مملوكة)",
        value: "rent (owned properties)",
      },
      commission: 5,
      conductor: "",
      commissionstype: [
        { category: "عند استحقاق القسط", value: "when the installment is due" },
        {
          category: "عند تحصيل القسط",
          value: "When collecting the installment",
        },
        { category: "لا يوجد عملة", value: "without commission" },
        { category: "سنويا", value: "annually" },
      ],
      comtype: {
        category: "عند استحقاق القسط",
        value: "when the installment is due",
      },
      doctype: "صك ورقي",
      docnum: "",
      docdate: "",
      payment: "",
      notes: "",
      file: "",
      taxaccount: {},
      account: {},
      commissionaccount: {},
      accountpayment: [],
      AllCommissions: [],
      commissions: [],
      allcommission: "",
    };
  },
  validations() {
    return {
      name: { required },
    };
  },
  async mounted() {
    const result = await axios.get("/data/customerowner/owner");
    if (result.status == 200) {
      this.owners = result.data.data;
    }
    const result2 = await axios.get(
      `/data/getproperty/${this.$route.params.id}`
    );
    const resul3 = await axios.get("/data/AllCommissions");
    if (resul3.status == 200) {
      this.AllCommissions = resul3.data.data;
    }
    const result3 = await axios.get("/data/getaccounttrue");
    if (result3.status == 200) {
      this.accountpayment = result3.data.data;
    }
    if (result2.status == 200) {
      const data = result2.data.data[0];
      this.url = store.state.imgurl + `/property/`;
      this.name = data.name;
      this.owner = { name: data.ownername, cid: data.ownerid };
      this.catitem = JSON.parse(data.catitem);
      this.type = data.type;
      this.servie = JSON.parse(data.servie);
      this.conductor = data.conductor;
      this.comtype = JSON.parse(data.comtype);
      this.commission = data.commission;
      this.prodata = JSON.parse(data.prodata);
      this.notes = data.notes;
      this.docdata = JSON.parse(data.docdata);
      this.doctype = data.doctype;
      this.docnum = data.docnum;
      this.docdate = data.docdate;
      this.totalspace = JSON.parse(data.totalspace);
      this.payment = data.payment;
      this.account = JSON.parse(data.account);
      this.taxaccount = JSON.parse(data.taxaccount);
      this.commissionaccount = JSON.parse(data.commissionaccount);
      this.commissions = JSON.parse(data.commissions);
      this.commissiontype = data.commissiontype;
    }
  },
  async created() {},
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async del(id) {
      this.prodata.splice(id, 1);
    },
    async del2(id) {
      this.docdata.splice(id, 1);
    },
    async submite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          // this.disabled = true;
          const result = await axios.post(`/data/editeproperty`, {
            id: this.$route.params.id,
            username: store.state.randp.username,
            name: this.name,
            ownername: this.owner.name,
            ownerid: this.owner.cid,
            catitem: this.catitem,
            type: this.type,
            servie: this.servie,
            comtype: this.comtype,
            commission: this.commission,
            conductor: this.conductor,
            location: this.location,
            prodata: JSON.stringify(this.prodata),
            doctype: this.doctype,
            docnum: this.docnum,
            docdate: this.docdate,
            docdata: JSON.stringify(this.docdata),
            totalspace: JSON.stringify(this.totalspace),
            payment: this.payment,
            taxaccount: JSON.stringify(this.taxaccount),
            account: JSON.stringify(this.account),
            commissionaccount: JSON.stringify(this.commissionaccount),
            commissions: JSON.stringify(this.commissions),
            commissiontype: this.commissiontype,
          });
          if (result.status == 200) {
            // this.redirectTo({ val: "allproperties" });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Data Entry successfully",
            });
          } else if (result.status == 401) {
            alert("Account Already Created Before");
          }
        } catch (error) {
          alert("Error");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    allcommission() {
      this.commissions.push({
        comid: this.allcommission.comid,
        name: this.allcommission.name,
        act: {
          actnum: this.allcommission.actnum,
          namear: this.allcommission.namear,
          nameen: this.allcommission.nameen,
        },
        taxact: {
          actnum: this.allcommission.taxnum,
          namear: this.allcommission.taxnamear,
          nameen: this.allcommission.taxnameen,
        },
        num: this.allcommission.rate,
        tax: 15,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 50%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.body4 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 8%;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}
</style>
