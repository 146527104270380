<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("customers") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"
              >{{ $t("import") }} {{ $t("data") }} {{ $t("customers") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <li>
          <router-link to="/AdminDashboard/Customers" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("import") }} {{ $t("data") }} {{ $t("customers") }}</h3>
      </div>
      <div class="body">
        <div class="part">
          <div class="part1">
            <h5>{{ $t("step") }} 1</h5>
            <div>
              برجاء تحميل الملف المرفق
              <br /><i class="bi bi-arrow-bar-down"></i><br />
              <a :href="this.url" target="_blank" download
                ><i class="bi bi-download"></i> &nbsp; Customers Templete</a
              >
            </div>
          </div>
          <div class="part2"><i class="bi bi-arrow-left-square-fill"></i></div>
          <div class="part1">
            <h5>{{ $t("step") }} 2</h5>
            يتم استكمال بيانات العملاء فى الملف الذى تم تحميلة
          </div>
          <div class="part2"><i class="bi bi-arrow-left-square-fill"></i></div>
          <div class="part1">
            <h5>{{ $t("step") }} 3</h5>
            يتم رفع الملف بعد استكمال البيانات
            <br /><br />
            <input type="file" @change="onChange" style="margin-right: 10%" />
          </div>
        </div>
        <br /><br />
        <div style="text-align: center" v-if="this.collection.length > 0">
          <button class="btn btn-primary" @click.prevent="confirm">
            {{ $t("import") }} &nbsp;&nbsp;<i class="bi bi-upload"></i>
          </button>
        </div>
        <xlsx-read :file="file">
          <xlsx-json>
            <template #default="{ collection }">
              <div v-if="collection.length > 0">
                <div style="display: none">
                  {{ (this.collection = collection) }}
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("name") }}</th>
                      <th>{{ $t("email") }}</th>
                      <th>{{ $t("mobile") }}</th>
                      <th>{{ $t("company") }}</th>
                      <th>{{ $t("website") }}</th>
                      <th>{{ $t("number") }} {{ $t("tax") }}</th>
                      <th colspan="3">{{ $t("address") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in collection" :key="item">
                      <td><input type="text" v-model="item.name" /></td>
                      <td><input type="text" v-model="item.email" /></td>
                      <td><input type="text" v-model="item.mobile" /></td>
                      <td><input type="text" v-model="item.companyname" /></td>
                      <td><input type="text" v-model="item.website" /></td>
                      <td><input type="text" v-model="item.taxnumber" /></td>
                      <td><input type="text" v-model="item.street" /></td>
                      <td><input type="text" v-model="item.city" /></td>
                      <td><input type="text" v-model="item.area" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </xlsx-json>
        </xlsx-read>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { XlsxRead, XlsxJson } from "vue3-xlsx";
export default {
  props: ["css"],
  components: {
    XlsxRead,
    XlsxJson,
  },
  data() {
    return {
      v$: useValidate(),
      namear: "",
      nameen: "",
      type: "",
      mobile: "",
      code: "",
      r2: store.state.randp.r2,
      file: null,
      url: "",
      collection: "",
    };
  },
  async mounted() {
    if (this.r2 == false) {
      this.redirectTo({ val: "Home" });
    }
    this.url = store.state.imgurl + `/templete/customerstemplete.xlsx`;
  },
  validations() {
    return {
      collection: { required },
    };
  },
  methods: {
    InvoiceView(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/InvoiceView/${row.id}`,
        params: { id: row.id },
      });
    },
    async confirm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post(`/data/importcustomerdata`, {
          username: store.state.randp.username,
          data: this.collection,
          cnum: store.state.cnum[0].cnum,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "Customers" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    mobile() {
      this.table.rows = this.table.data.filter((x) =>
        x.mobile.includes(this.mobile)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;
  padding: 0.25%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.15%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top:  v-bind("css.color") 5px solid;
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.main .body .part {
  display: flex;
  padding-right: 10%;
  padding-left: 10%;
}
.main .body .part1 {
  width: 25%;
  //   border: black 1px solid;
  padding: 1%;
  background-color: #ebe9e9;

  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  border-radius: 10px;
}
.main .body .part2 {
  width: 5%;
  //   border: black 1px dotted;
  padding-left: 1%;
  padding-right: 1%;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 3%;
  text-align: center;
  font-size: xx-large;
}
.table {
  text-align: center;
  font-size: 80%;
}
.table tr {
  background: rgba(191, 214, 219, 0.534);
  line-height: 5px;
}
button {
  margin: 1%;
}

.table button :hover {
  background: black;
  color: white;
  padding: 50%;
}
input {
  text-align: center;
  padding: 0.5%;
}
</style>
