<template>
  <LoadingPage v-if="loading"></LoadingPage>
  <div class="page" v-else>
    <ul class="box-info" v-if="dep != 'customer'">
      <li>
        <i class="bx bxs-calendar-check"><i class="bi bi-people"></i></i>
        <span class="text">
          <h5>{{ $t("customers") }}</h5>
          <p>{{ $t("numbers") }} : {{ costomer }}</p>
        </span>
      </li>
      <li>
        <i class="bx bxs-group"><i class="bi bi-reply-all-fill"></i></i>
        <span class="text">
          <h5>{{ $t("suppliers") }}</h5>
          <p>{{ $t("numbers") }} : {{ suppliers }}</p>
        </span>
      </li>
      <li>
        <i class="bx bxs-dollar-circle"><i class="bi bi-file-earmark-medical-fill"></i></i>
        <span class="text">
          <h5>{{ $t("invoices") }} {{ $t("sales") }}</h5>
          <p>{{ $t("numbers") }} : {{ invoices }}</p>
        </span>
      </li>
      <li>
        <i class="bx bxs-dollar-circle"><i class="bi bi-credit-card-2-front-fill"></i></i>
        <span class="text">
          <h5>{{ $t("bills") }}</h5>
          <p>{{ $t("numbers") }} : {{ bills }}</p>
        </span>
      </li>
    </ul>

  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import LoadingPage from "../components/LoadingPage.vue";
export default {
  components: { LoadingPage },

  async created() {
    this.loading = true;
    const result = await axios.get(`/reports/homesummary`);
    if (result.status == 200) {
      this.costomer = result.data.customer[0].count;
      this.suppliers = result.data.suppliers[0].count;
      this.invoices = result.data.invoice[0].count;
      this.bills = result.data.bills[0].count;
      this.salles = result.data.salles;
      // this.chartData.labels = this.salles.map((row) => row.date);
      // this.chartData.datasets[0].data = this.salles.map((row) => row.amount);
    }
    this.loading = false;
  },
  props: ["css"],
  data() {
    return {
      loading: false,
      data1: [],
      costomer: 0,
      suppliers: 0,
      invoices: 0,
      bills: 0,
      salles: "",
      dep: store.state.typeofuser,
    };
  },
  methods: {},
};
</script>

<style scoped>
.page {
  direction: v-bind("EN");
  font-family: "cairo";
  padding: 1%;
  margin: 1%;
}

#content main .box-info {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 24px;
  margin-top: 10px;
}

#content main .box-info li {
  width: 25%;
  padding: 2%;
  background: #f9f9f9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
}

#content main .box-info li .bx {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content main .box-info li .bx {
  background: v-bind("css.color");
  color: black;
}

#content main .box-info li .text h5 {
  font-size: 20px;
  font-weight: 600;
  color: #342e37;
}

#content main .box-info li .text p {
  color: #342e37;
  font-size: 14px;
}

.main {
  padding: 1%;
  margin: 1%;
  width: 50%;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  display: flex;
}

a {
  text-decoration: none;
  margin: 1%;
  color: v-bind("css.color");
  font-size: small;
}

.main .body .part {
  width: 50%;
}

.main .body .part ul li {
  margin: 1%;
}

.main2 {
  width: 46%;
  background: v-bind("css.light");
  padding: 1%;
  margin: 1%;
}

.main2 .head {
  border-top: rgba(98, 153, 174, 255) 5px solid;
  background: rgba(98, 153, 174, 255);
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main2 .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  display: flex;
}

@media screen and (max-width: 576px) {
  #content main .box-info {
    width: 100%;
    display: block;
  }

  #content main .box-info li {
    width: 100%;
    padding: 2%;
    margin: 2%;
    background: #f9f9f9;
    border-radius: 20px;
    align-items: center;
    grid-gap: 24px;
  }
}
</style>
