<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/CRM" class="active">{{ $t("CRM") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active"
              >{{ $t("create") }} {{ $t("request") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("request") }} {{ $t("new") }}</h3>
        </div>
        <div class="head2">
          <button
            class="buttonconfirm"
            type="submit"
            :disabled="disabled"
            @click="submite"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body"></div>
      <div class="body2">
        <div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("department") }}
              <span class="error-feedback" v-if="v$.assign.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3" style="display: flex">
              <select v-model="assign" class="form-control">
                <option value="financial">{{ $t("financial") }}</option>
                <option value="maintenance">{{ $t("maintenance") }}</option>
                <option value="operation">{{ $t("operation") }}</option>
                <option value="human resources">
                  {{ $t("human resources") }}
                </option>
                <option value="operation">{{ $t("license") }}</option>
                <option value="Executive management">
                  {{ $t("Executive management") }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("type") }} {{ $t("contact") }}
              <span class="error-feedback" v-if="v$.type.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3" style="display: flex">
              <select v-model="type" class="form-control">
                <option value="call">{{ $t("call") }}</option>
                <option value="Email">Email</option>
                <option value="WhatsApp">WhatsApp</option>
                <option value="WhatsApp">Twitter</option>
                <option value="Google">Google</option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("applicant name") }}
              <span class="error-feedback" v-if="v$.cname.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3" style="display: flex">
              <input class="form-control" type="text" v-model="cname" />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("mobile") }}
              <span class="error-feedback" v-if="v$.cmobile.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3" style="display: flex">
              <input class="form-control" type="text" v-model="cmobile" />
            </div>
          </div>

          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("priority") }}
              <span class="error-feedback" v-if="v$.priority.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3" style="display: flex">
              <select class="form-control" v-model="priority">
                <option value="low">{{ $t("low") }}</option>
                <option value="medium">{{ $t("medium") }}</option>
                <option value="high">{{ $t("high") }}</option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >{{ $t("details") }} {{ $t("request") }}
              <span class="error-feedback" v-if="v$.details.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3" style="display: flex">
              <textarea
                rows="3"
                class="form-control"
                v-model="details"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from "moment";

export default {
  components: {},
  props: ["css"],
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      date: moment().format("YYYY-MM-DD"),
      assign: "",
      priority: "",
      type: "",
      details: "",
      cname: "",
      cmobile: "",
    };
  },
  validations() {
    return {
      assign: { required },
      type: { required },
      cname: { required },
      cmobile: { required },
      priority: { required },
      details: { required },
    };
  },
  async mounted() {
    // const result2 = await axios.get(`/data/allproducts`);
    // if (result2.status == 200) {
    //   this.products = result2.data.data;
    // }
    // const result7 = await axios.get("/data/getcostcenter");
    // if (result7.status == 200) {
    //   this.branches = result7.data.data;
    //   this.level1 = this.branches.filter((x) => x.level == 1);
    // }
  },
  methods: {
    submite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disabled = true;
        Swal.fire({
          title: this.$t("create") + " " + this.$t("request"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            const action = await axios.post("/data/createrequest", {
              type: this.type,
              assign: this.assign,
              username: store.state.randp.username,
              name: this.cname,
              mobile: this.cmobile,
              priority: this.priority,
              details: this.details,
            });
            if (action.status == 200) {
              Swal.fire(this.$t("create") + " " + this.$t("request"));
              this.redirectTo({ val: "HomeCRM" });
            }
          }
        });
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 2%;
  padding: 5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
input {
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.body {
  display: flex;
  margin: 1%;
}

.part1 {
  width: 50%;
}

.body2 {
  width: 100%;
  padding: 1%;
  // border: 1px solid black;
  border-radius: 5px;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: x-small;
  }
  button {
    margin: 2%;
    padding: 5%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: x-small;
  }
}
</style>
