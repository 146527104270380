<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("users") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"> {{ data.fullname }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                <a href="#" class="btn-download">
                  <span class="text">Download PDF</span>
                </a>
              </li> -->

        <li>
          <router-link to="/AdminDashboard/RAndP" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i> Back</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("data") }} {{ $t("user") }} - {{ data.fullname }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("username") }}</span>
            <input
              type="text"
              class="form-control"
              v-model="data.username"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text"
              >{{ $t("type") }} {{ $t("user") }}</span
            >
            <input
              type="text"
              class="form-control"
              v-model="data.typeofuser"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">
              {{ $t("branch") }}
              <span class="error-feedback" v-if="v$.companyname.$error">{{
                "*"
              }}</span></span
            >
            <input
              type="text"
              class="form-control"
              v-model="data.branchname"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("empno") }}</span>
            <input
              type="text"
              class="form-control"
              v-model="data.empno"
              disabled
            />
          </div>
        </div>
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("created") }}</span>
            <input
              type="text"
              class="form-control"
              v-model="data.creationdate"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("status") }}</span>
            <input
              type="text"
              class="form-control"
              v-model="data.status"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("company") }}</span>
            <input
              type="text"
              class="form-control"
              v-model="cdata.cname"
              disabled
            />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("contract") }}</span>
            <input
              type="text"
              class="form-control"
              v-model="cdata.cnum"
              disabled
            />
          </div>
        </div>
      </div>
      <br />
      <div class="buttom">
        <button class="buttonconfirm" type="submit" @click="Edite">
          {{ $t("Edite") }} {{ $t("and") }} {{ $t("RAndP") }}
        </button>
        &nbsp;&nbsp;&nbsp;
        <button class="buttoncancel" type="submit" @click="back">
          {{ $t("cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

import { mapActions } from "vuex";
export default {
  async mounted() {
    const data2 = await axios.get(`/user/userdetails/${this.$route.params.id}`);
    if (data2.status == 200) {
      this.data = data2.data.data;
      this.cdata = JSON.parse(data2.data.data.cnum);
    }
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      data: "",
      cdata: "",
    };
  },
  validations() {
    return {
      name: { required },
      companyname: { required },
    };
  },
  methods: {
    Edite() {
      this.$root.$router.push({
        path: `/AdminDashboard/user/useredite/${this.data.id}/${this.data.username}`,
        params: { id: this.data.id },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}
.head-option ul li {
  display: inline;
  padding: 0.5%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top:  v-bind("css.color") 5px solid;
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  display: flex;
}
.main .body .part1 {
  width: 40%;
  margin-right: 5%;
  margin-left: 5%;
  background: v-bind("css.light");
}
.main .body .part1 .input-group {
  margin: 1%;
}
.main .body .part1 .input-group span {
  width: 25%;
  font-size: small;
}

.main .body .part2 {
  width: 25%;
  background: v-bind("css.light");
  padding-right: 2%;
  padding-left: 2%;
}
.main .body .part2 .part2head {
  background: rgba(191, 214, 219, 0.534);
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.main .body .part2 .part2body {
  box-shadow: rgba(75, 68, 68, 0.774) 0.25px 0.25px 1px;
  padding: 3%;
}
input:disabled {
  background-color: white;
}
.main .body .part3 {
  width: 18%;
  //   border: black 1px solid;
  padding: 1%;
  background-color: #ebe9e9;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  border-radius: 10px;
}
.option ul {
  margin: 0px;
}
.option ul li {
  display: inline;
  padding-right: 1%;
  padding-left: 1%;
  color: black;
  // background-color: #ebe9e9;
}
.option ul .active {
  display: inline;
  color: black;
  background-color: #ebe9e9;
}
.option a {
  color: black;
}
.body-option {
  margin: 0px;
  padding: 2%;
  width: 100%;
  background-color: #ebe9e9;
}
.buttom {
  text-align: center;
}
</style>
