<template>
  <div class="app" id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import store from "./store";
var date = moment().format("YYYY-MM-DD");
var time = moment().format("hh:mm:ssA");

export default {
  components: {},
  data() {
    return {
      date,
      time,
      user: store.state.typeofuser,
    };
  },
  mounted() {
    // const lang = localStorage.getItem("lang");
    // if (!lang) {
    //   localStorage.setItem("lang", "ltr");
    // }
    // if (!this.user) {
    //   this.redirectTo({ val: "signIn" });
    // }
    // this.$store.state.data = this.$cookies.get("data");
    this.$store.state.data = localStorage.getItem("data");

    // this.$store.state.token = this.$cookies.get("token");
    if (this.$store.state.data == null) {
      this.$root.$router.push({
        path: "/SignIn",
      });
    }
    this.$store.commit("start");
    this.$i18n.locale = this.$store.state.lang;
  },

  methods: {
    ...mapActions(["redirectTo"]),
  },
  watch: {
    changeprice() { },
  },
};
</script>

<style lang="scss">
.error-feedback {
  color: red;
}
</style>
