<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("products") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                <a href="#" class="btn-download">
                  <span class="text">Download PDF</span>
                </a>
              </li> -->
        <li>
          <router-link
            to="/AdminDashboard/AllProducts/NewProduct"
            class="btn-download"
          >
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("product") }}</h3>
      </div>
      <div class="body">
        <div class="stitle">
          <div to="/AdminDashboard/AllProducts" class="sstitle">
            {{ $t("step") }} {{ $t("first") }}<br />
            <small>{{ $t("type") }} {{ $t("product") }}</small>
          </div>
          <div to="/AdminDashboard/AllProducts" class="sstitle2">
            {{ $t("step") }} {{ $t("second") }}<br />
            <small> {{ $t("details") }}</small>
          </div>
          <div class="arrow2"></div>
        </div>

        <div class="body2" v-if="view == 1">
          <h5>{{ $t("product") }}</h5>
          <div class="comment">{{ $t("prodtype1") }}</div>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("ar") }}
                <span class="error-feedback" v-if="v$.namear.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="namear"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("en") }}
                <span class="error-feedback" v-if="v$.nameen.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="nameen"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("sn") }}
                <span class="error-feedback" v-if="v$.sn.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="sn"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("item") }}
                <span class="error-feedback" v-if="v$.item.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-3">
                <v-select :options="data1" label="category" v-model="item">
                  <template #option="option">
                    <span>{{ option.category }}</span>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-1">
                <button
                  class="add"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {{ $t("add") }} {{ $t("item") }}
                </button>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("barcode") }}
                <span class="error-feedback" v-if="v$.barcode.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <div class="col-sm-1" id="barcode " style="width: 50px">
                  <vue3-barcode :value="sn" :height="50" />
                </div>
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("unit type") }}
                <span class="error-feedback" v-if="v$.unittype.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <select
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="unittype"
                >
                  <option v-for="val in unit" :key="val" :value="val.unit">
                    {{ $t(val.unit) }}
                  </option>
                </select>
              </div>

              <div class="col-sm-1"></div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("tax") }} %
                <span class="error-feedback" v-if="v$.tax.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="tax"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("saleprice") }}
                <span class="error-feedback" v-if="v$.salep.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="salep"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("sales") }}
                <span class="error-feedback" v-if="v$.salec.$error">{{
                  "*"
                }}</span>
              </label>

              <div class="col-sm-2">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="salec"
                >
                  <option v-for="val in buylist" :key="val" :value="val">
                    {{ val.actnum }} - {{ val.namear }}
                  </option>
                </select>
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("buyprice") }}
                <span class="error-feedback" v-if="v$.buyp.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="buyp"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("cost") }}
                <span class="error-feedback" v-if="v$.buyc.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="buyc"
                >
                  <option v-for="val in selllist" :key="val" :value="val">
                    {{ val.actnum }} - {{ val.namear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("sale") }} {{ $t("discount") }}
                <span class="error-feedback" v-if="v$.discount.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="discount"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("follow") }} {{ $t("stock") }}
                <span class="error-feedback" v-if="v$.discount.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  v-model="stock"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("image") }} {{ $t("product") }}
              </label>

              <div class="col-sm-5">
                <input
                  type="file"
                  class="form-control form-control-sm"
                  id="image"
                  :name="this.sn"
                  @change="onFileChange"
                />
              </div>
              <div id="preview">
                <img v-if="url" :src="url" />
              </div>
            </div>
            <!--  -->

            <div class="buttom">
              <button
                class="buttonconfirm"
                :disabled="disabled"
                type="submit"
                @click="submit"
              >
                {{ $t("save") }}
              </button>
              <button class="buttoncancel" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div class="body2" v-if="view == 2">
          <h5>{{ $t("recipe") }}</h5>
          <div class="comment">
            {{ $t("prodtype2") }}
          </div>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("ar") }}
                <span class="error-feedback" v-if="v$.namear.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="namear"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("en") }}
                <span class="error-feedback" v-if="v$.nameen.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="nameen"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("sn") }}
                <span class="error-feedback" v-if="v$.sn.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="sn"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("item") }}
                <span class="error-feedback" v-if="v$.item.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-3">
                <v-select :options="data1" label="category" v-model="item">
                  <template #option="option">
                    <span>{{ option.category }}</span>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-1">
                <button
                  class="add"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {{ $t("add") }} {{ $t("item") }}
                </button>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("barcode") }}
                <span class="error-feedback" v-if="v$.barcode.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <div class="col-sm-1" id="barcode " style="width: 50px">
                  <vue3-barcode :value="sn" :height="50" />
                </div>
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("unit type") }}
                <span class="error-feedback" v-if="v$.unittype.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <select
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="unittype"
                >
                  <option v-for="val in unit" :key="val" :value="val.unit">
                    {{ $t(val.unit) }}
                  </option>
                </select>
              </div>

              <div class="col-sm-1"></div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("tax") }} %
                <span class="error-feedback" v-if="v$.tax.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="tax"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("saleprice") }}
                <span class="error-feedback" v-if="v$.salep.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="salep"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("sales") }}
                <span class="error-feedback" v-if="v$.salec.$error">{{
                  "*"
                }}</span>
              </label>

              <div class="col-sm-2">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="salec"
                >
                  <option v-for="val in buylist" :key="val" :value="val">
                    {{ val.actnum }} - {{ val.namear }}
                  </option>
                </select>
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("buyprice") }}
                <span class="error-feedback" v-if="v$.buyp.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="buyp"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("cost") }}
                <span class="error-feedback" v-if="v$.buyc.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="buyc"
                >
                  <option v-for="val in selllist" :key="val" :value="val">
                    {{ val.actnum }} - {{ val.namear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("sale") }} {{ $t("discount") }}
                <span class="error-feedback" v-if="v$.discount.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="discount"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("image") }} {{ $t("product") }}
                <span class="error-feedback" v-if="v$.namear.$error">{{
                  "*"
                }}</span>
              </label>

              <div class="col-sm-5">
                <input
                  type="file"
                  class="form-control form-control-sm"
                  id="image"
                  @change="onFileChange"
                />
              </div>
              <div id="preview">
                <img v-if="url" :src="url" />
              </div>
            </div>
            <!--  -->
            <div class="prodetails">
              {{ $t("details") }} {{ $t("aggregation") }} {{ $t("product") }}
              <br /><br />
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-1 col-form-label-sm">
                  {{ $t("product") }} :
                </label>

                <div class="col-sm-3">
                  <select
                    type="text"
                    class="form-control form-control-sm"
                    id="inputEmail3"
                    v-model="rawmaterialselect"
                  >
                    <option v-for="val in rawmaterial" :key="val" :value="val">
                      {{ val.namear }}
                    </option>
                  </select>

                  <button
                    class="buttonconfirm"
                    @click="add"
                    style="padding: 0.25%"
                  >
                    {{ $t("add") }} <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>

              <table class="table">
                <thead>
                  <tr>
                    <td>{{ $t("product") }}</td>
                    <td>{{ $t("unit type") }}</td>
                    <td>{{ $t("quantity") }}</td>
                    <td>{{ $t("price") }}</td>
                    <td>{{ $t("total") }}</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="val in pddata" :key="val">
                    <td>{{ val.namear }}</td>
                    <td>{{ val.unittype }}</td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        v-model="val.pquon"
                        @change="total"
                      />
                    </td>
                    <td>{{ val.pprice }}</td>
                    <td>{{ val.pprice * val.pquon }}</td>
                    <td>
                      <button
                        class="buttonconfirm"
                        @click="remove(val.id)"
                        style="padding: 0"
                      >
                        <i class="bi bi-dash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ $t("total") }}</td>
                    <td>{{ rawmaterialcost }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div class="buttom">
              <button
                class="btn btn-success"
                :disabled="disabled"
                type="submit"
                @click="submit"
              >
                {{ $t("save") }}
              </button>
              <button class="buttoncancel" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div class="body2" v-if="view == 3">
          <h5>{{ $t("material") }} {{ $t("row") }}</h5>
          <div class="comment">
            {{ $t("prodtype3") }}
          </div>
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("ar") }}
                <span class="error-feedback" v-if="v$.namear.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="namear"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("en") }}
                <span class="error-feedback" v-if="v$.nameen.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="nameen"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("sn") }}
                <span class="error-feedback" v-if="v$.sn.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="sn"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("item") }}
                <span class="error-feedback" v-if="v$.item.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-3">
                <v-select :options="data1" label="category" v-model="item">
                  <template #option="option">
                    <span>{{ option.category }}</span>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-1">
                <button
                  class="add"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {{ $t("add") }} {{ $t("item") }}
                </button>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("barcode") }}
                <span class="error-feedback" v-if="v$.barcode.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <div class="col-sm-1" id="barcode " style="width: 50px">
                  <vue3-barcode :value="sn" :height="50" />
                </div>
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("unit type") }}
                <span class="error-feedback" v-if="v$.unittype.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <select
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="unittype"
                >
                  <option v-for="val in unit" :key="val" :value="val.unit">
                    {{ $t(val.unit) }}
                  </option>
                </select>
              </div>

              <div class="col-sm-1"></div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("tax") }} %
                <span class="error-feedback" v-if="v$.tax.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="tax"
                />
              </div>
            </div>
            <br />
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("buyprice") }}
                <span class="error-feedback" v-if="v$.buyp.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="buyp"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("cost") }}
                <span class="error-feedback" v-if="v$.buyc.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="buyc"
                >
                  <option v-for="val in selllist" :key="val" :value="val">
                    {{ val.actnum }} - {{ val.namear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="buttom">
              <button
                class="btn btn-success"
                :disabled="disabled"
                type="submit"
                @click="submit"
              >
                {{ $t("save") }}
              </button>
              <button class="buttoncancel" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div class="body2" v-if="view == 4">
          <h5>{{ $t("service") }}</h5>
          <div class="comment">
            اضافة خدمة للعملاء مثل المنتجات ولاكن لا تخزن
          </div>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("ar") }}
                <span class="error-feedback" v-if="v$.namear.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="namear"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("en") }}
                <span class="error-feedback" v-if="v$.nameen.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="nameen"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("sn") }}
                <span class="error-feedback" v-if="v$.sn.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="sn"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("item") }}
                <span class="error-feedback" v-if="v$.item.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-3">
                <v-select :options="data1" label="category" v-model="item">
                  <template #option="option">
                    <span>{{ option.category }}</span>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-1">
                <button
                  class="add"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {{ $t("add") }} {{ $t("item") }}
                </button>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("barcode") }}
                <span class="error-feedback" v-if="v$.barcode.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <div class="col-sm-1" id="barcode " style="width: 50px">
                  <vue3-barcode :value="sn" :height="50" />
                </div>
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("unit type") }}
                <span class="error-feedback" v-if="v$.unittype.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <select
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="unittype"
                >
                  <option v-for="val in unit" :key="val" :value="val.unit">
                    {{ $t(val.unit) }}
                  </option>
                </select>
              </div>

              <div class="col-sm-1"></div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("tax") }} %
                <span class="error-feedback" v-if="v$.tax.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-2">
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="tax"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("saleprice") }}
                <span class="error-feedback" v-if="v$.salep.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="salep"
                />
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("sales") }}
                <span class="error-feedback" v-if="v$.salec.$error">{{
                  "*"
                }}</span>
              </label>

              <div class="col-sm-2">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="salec"
                >
                  <option v-for="val in selllist" :key="val" :value="val">
                    {{ val.actnum }} - {{ val.namear }}
                  </option>
                </select>
              </div>

              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("sale") }} {{ $t("discount") }}
                <span class="error-feedback" v-if="v$.discount.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="discount"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("image") }} {{ $t("product") }}
              </label>

              <div class="col-sm-5">
                <input
                  type="file"
                  class="form-control form-control-sm"
                  id="image"
                  @change="onFileChange"
                />
              </div>
              <div id="preview">
                <img v-if="url" :src="url" />
              </div>
            </div>
            <!--  -->

            <div class="buttom">
              <button
                class="btn btn-success"
                :disabled="disabled"
                type="submit"
                @click="submit"
              >
                {{ $t("save") }}
              </button>
              <button class="buttoncancel" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div class="body2" v-if="view == 5">
          <h5>{{ $t("expences") }}</h5>
          <div class="comment">مصروفات متنوعة</div>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("name") }} {{ $t("ar") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-5">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                />
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("name") }} {{ $t("en") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-5">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("sn") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-5">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                />
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("item") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-5">
                <div>
                  <div>
                    <div
                      class="form-control form-control-sm"
                      style="display: flex"
                      @click="this.selection = !this.selection"
                    >
                      <div style="width: 95%">{{ $t(item) }}</div>

                      <div><i class="bi bi-arrow-down-circle-fill"></i></div>
                    </div>
                  </div>
                  <div class="options" v-if="this.selection == true">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filter"
                    />
                    <ul>
                      <!-- <li
                        @click="
                          this.item = 'parent';
                          this.selection = !this.selection;
                        "
                      >
                        {{ $t("parent") }}
                      </li> -->
                      <li
                        v-for="val in data1"
                        :key="val"
                        @click="
                          this.item = val.category;
                          this.selection = !this.selection;
                        "
                      >
                        {{ val.category }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm">
              </label>

              <div class="col-sm-5">
                <!-- <input
                  type="email"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                /> -->
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-5">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("unit type") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-5">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("tax") }} %
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-5">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                >
                  <option value=""></option>
                  <option value=""></option>
                  <option value=""></option>
                </select>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("buyprice") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span></label
              >

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                />
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("expences") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span>
              </label>

              <div class="col-sm-2">
                <select
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="name"
                >
                  <option value=""></option>
                  <option value=""></option>
                  <option value=""></option>
                </select>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("image") }} {{ $t("product") }}
                <span class="error-feedback" v-if="v$.name.$error">{{
                  "*"
                }}</span>
              </label>

              <div class="col-sm-5">
                <input
                  type="file"
                  class="form-control form-control-sm"
                  id="image"
                  @change="onFileChange"
                />
              </div>
              <div id="preview">
                <img v-if="url" :src="url" />
              </div>
            </div>
            <!--  -->

            <div class="buttom">
              <button
                class="buttonconfirm"
                :disabled="disabled"
                type="submit"
                @click="submit"
              >
                {{ $t("save") }}
              </button>
              <button class="buttoncancel" type="submit" @click="back">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <h4>{{ $t("add") }} {{ $t("item") }}</h4>
                <div class="row mb-4">
                  <label for="inputEmail3" class="col-sm-3 col-form-label-sm"
                    >{{ $t("name") }} {{ $t("categorie") }}
                  </label>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="inputEmail3"
                      v-model="catname"
                      @change="checkname()"
                    />
                    <span class="error-feedback" v-if="alerted == true">{{
                      "تم تسجيل المنتج من قبل"
                    }}</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <label for="inputEmail3" class="col-sm-3 col-form-label-sm"
                    >{{ $t("description") }}
                  </label>
                  <div class="col-sm-5">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      v-model="catdescription"
                    ></textarea>
                  </div>
                </div>
                <div class="row mb-2">
                  <label for="inputEmail3" class="col-sm-3 col-form-label-sm"
                    >{{ $t("categorie") }} {{ $t("parent") }}
                  </label>
                  <div class="col-sm-5">
                    <v-select
                      :options="data1"
                      label="category"
                      v-model="catitem"
                    >
                      <template #option="option">
                        <span>{{ option.category }}</span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button @click="submitcat" class="buttonconfirm">
                  {{ $t("save") }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t("cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from "vuex";
import Vue3Barcode from "vue3-barcode";
import html2pdf from "html2pdf.js";
import vSelect from "vue-select";
export default {
  props: ["css"],
  components: {
    Vue3Barcode,
    vSelect,
  },
  async mounted() {
    this.view = this.$route.params.id;
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/allproductcategoriesparent");
    const result2 = await axios.get("/data/allunittypes");
    const result3 = await axios.get("/data/accountingmanual");
    const result4 = await axios.get("/data/allproducts3");
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data;
      this.unit = result2.data.data;
      this.selllist = result3.data.data;
      this.buylist = result3.data.data;
      this.rawmaterial = result4.data.data;
    }
  },
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      view: 0,
      namear: "",
      nameen: "",
      sn: "",
      item: "",
      description: "",
      unittype: "",
      tax: "",
      salep: 0,
      salec: 0,
      buyp: 0,
      buyc: 0,
      discount: 0,
      stock: false,
      unitsel: "",
      mobile: "",
      code: "",
      r2: store.state.randp.r2,
      barcode: 0,
      url: null,
      pddata: [],
      pname: "",
      pquon: "",
      pprice: "",
      data1: [],
      data2: [],
      unit: [],
      filter: "",
      selection: false,
      alert: false,
      selllist: [],
      buylist: [],
      rawmaterial: [],
      rawmaterialselect: "",
      rawmaterialcost: 0,
      file: "",
      alerted: false,
      catname: "",
      catdescription: "",
      catitem: "",
    };
  },
  validations() {
    return {
      namear: { required },
      nameen: { required },
      sn: { required },
      item: { required },
      description: { required },
      unittype: { required },
      barcode: { required },
      tax: { required },
      salep: { required },
      salec: { required },
      buyp: { required },
      buyc: { required },
      discount: { required },
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllProducts/NewProduct",
      });
    },
    async checkname() {
      const result = await axios.get(`/data/productcheckname/${this.catname}`);
      if (result.status == 200) {
        const data = result.data.data;
        if (data.length > 0) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error && this.alert == false) {
        this.disabled = true;
        const result = await axios.post("/data/createnewproduct", {
          username: store.state.randp.username,
          kind: this.view,
          namear: this.namear,
          nameen: this.nameen,
          sn: this.sn,
          item: this.item.category,
          description: this.description,
          unittype: this.unittype,
          barcode: this.sn,
          tax: this.tax,
          salep: this.salep,
          salec: this.salec.actnum,
          snamear: this.salec.namear,
          snameen: this.salec.nameen,
          buyp: this.buyp,
          buyc: this.buyc.actnum,
          bnamear: this.buyc.namear,
          bnameen: this.buyc.nameen,
          discount: this.discount,
          stock: this.stock,
          rawmaterial: JSON.stringify(this.pddata),
          rawmaterialcost: this.rawmaterialcost,
        });
        if (result.status == 200) {
          let formData = new FormData();
          formData.append("file", this.file);
          axios.patch(`/upload/${this.sn}`, formData);
          this.redirectTo({ val: "AllProducts" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    add() {
      const id = this.rawmaterialselect.id;
      const filtter = this.pddata.filter((x) => x.id == id);
      if (filtter.length == 0) {
        this.pddata.push({
          id: this.rawmaterialselect.id,
          namear: this.rawmaterialselect.namear,
          nameen: this.rawmaterialselect.nameen,
          unittype: this.rawmaterialselect.unittype,
          pquon: 1,
          pprice: this.rawmaterialselect.buyp,
        });
        this.rawmaterialcost = 0;
        for (let i = 0; i < this.pddata.length; i++) {
          this.rawmaterialcost =
            this.pddata[i].pquon * this.pddata[i].pprice + this.rawmaterialcost;
        }
      } else {
        alert("تم اضافة المنتج ");
      }
    },
    remove(id) {
      const indexOfObject = this.pddata.findIndex((object) => {
        return object.id === id;
      });
      this.pddata.splice(indexOfObject, 1);
      this.rawmaterialcost = 0;
      for (let i = 0; i < this.pddata.length; i++) {
        this.rawmaterialcost =
          this.pddata[i].pquon * this.pddata[i].pprice + this.rawmaterialcost;
      }
    },
    total() {
      this.rawmaterialcost = 0;
      for (let i = 0; i < this.pddata.length; i++) {
        this.rawmaterialcost =
          this.pddata[i].pquon * this.pddata[i].pprice + this.rawmaterialcost;
      }
    },
    print() {
      html2pdf(document.getElementById("barcode"), {
        margin: 0,
        filename: "barcode.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
    async submitcat() {
      const result = await axios.post("/data/createnewproductcategory", {
        username: store.state.randp.username,
        category: this.catname,
        description: this.catdescription,
        parent: this.catitem.category,
      });
      if (result.status == 200) {
        const result5 = await axios.get("/data/allproductcategoriesparent");
        if (result.status == 200) {
          this.data1 = result5.data.data;
        }
        this.item = this.catname;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
        // this.redirectTo({ val: "Home" });
      } else if (result.status == 401) {
        alert("Account Already Created Before");
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data1 = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
    rawmaterialcost() {
      this.buyp = this.rawmaterialcost;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;
  padding: 0.25%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.15%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.table {
  text-align: center;
}
// .table tr {
//   background: rgba(191, 214, 219, 0.534);
//   line-height: 5px;
// }
button {
  margin: 1%;
  text-align: center;
}
.buttom {
  text-align: center;
}
.stitle {
  background-color: #aaaaaa;
  display: flex;
  text-align: center;
  //   padding: 1%;
}
.sstitle {
  text-align: center;
  background-color: v-bind("css.color");
  //   border: solid 2px rgba(128, 128, 128, 0.589);
  //   margin: 0.25%;
  color: white;
  padding: 1%;
}
.arrow {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 20px solid v-bind("css.color");
}
.sstitle2 {
  text-align: center;
  background-color: v-bind("css.color");
  //   border: solid 2px rgba(128, 128, 128, 0.589);
  color: white;
  padding: 1%;
  color: black;
}
.arrow2 {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 20px solid v-bind("css.color");
  color: black;
}
.main .body2 {
  padding: 2%;
  text-align: center;
  background-color: white;
}
.main .body3 {
  display: flex;
}
.comment {
  font-size: small;
}
.form1 {
  padding: 1%;
  // border: black solid 1px;
  text-align: right;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  // border: black solid 1px;
}

#preview img {
  max-width: 100%;
  max-height: 400px;
}
.prodetails {
  text-align: v-bind("css.dirn");
}
.prodetails .table {
  text-align: v-bind("css.dirn");
  margin: 1%;
  width: 50%;
}
img {
  border: black 1px solid;
  padding: 0.5%;
  width: 400px;
  height: 200px;
  border-radius: 20px;
}
.options {
  height: 150px;
  margin: 1%;
  background-color: v-bind("css.light");
  color: v-bind("css.dark");
  padding: 2%;
  overflow-y: auto;
}
.options ul li {
  margin: 0.5%;
}
.options ul li:hover {
  color: white;
  background-color: black;
}
.add {
  background-color: blue;
  padding: 8%;
  color: white;
  border-radius: 5px;
  font-size: smaller;
}
@media screen and (max-width: 576px) {
  .add {
    background-color: blue;
    padding: 4%;
    color: white;
    border-radius: 5px;
    font-size: smaller;
  }
}
</style>
