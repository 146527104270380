<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/purchases/home" class="active">{{
              $t("purchases")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("bills") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("bills") }}</h3>
      </div>
      <div class="head">
        <div class="head1">
          <input
            type="text"
            :placeholder="$t('code')"
            v-model="code"
            @keypress.enter="getbycode"
          />
          <input type="text" :placeholder="$t('name')" />
          <input type="text" :placeholder="$t('status')" />
        </div>
        <div class="head2">
          <button>
            <router-link
              to="/purchases/AllBills/CreateBill"
              class="btn-download"
            >
              <span class="text">
                <i class="bi bi-clipboard-plus-fill"></i>
                {{ $t("add") }} {{ $t("bills") }}</span
              >
            </router-link>
          </button>
        </div>
      </div>
      <div class="body">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("dueon") }}</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("description") }}</th>
              <th>{{ $t("total") }}</th>
              <th>{{ $t("payment") }}</th>
              <th>{{ $t("status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in data" :key="val">
              <td>
                <router-link :to="`/purchases/AllBills/BillView/${val.bid}`">
                  {{ val.bid }}</router-link
                >
              </td>
              <td>
                {{ val.due }}
              </td>
              <td>
                <router-link :to="`/purchases/AllBills/BillView/${val.bid}`">
                  {{ val.cname }}</router-link
                >
              </td>
              <td>
                {{ val.description }}
              </td>
              <td>
                {{ val.tat }}
              </td>
              <td>
                {{ val.payment }}
              </td>
              <td>
                {{ val.review }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import TableLite from "../../components/TableLite.vue";
import store from "@/store";
import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  title: "Bills",
  props: ["css"],
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      r7: JSON.parse(store.state.randp.r7),
      data: [],
      data2: [],
    };
  },
  async mounted() {
    const result = await axios.get(`/data/allbills`);
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    InvoiceView(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/AllBills/BillView/${row.bid}`,
        params: { id: row.id },
      });
    },
    async getbycode() {
      if (this.code != "") {
        const result = await axios.get(`/data/billview/${this.code}`);
        if (result.status == 200) {
          this.data = result.data.data;
        }
      } else {
        this.data = this.data2;
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    mobile() {
      this.table.rows = this.table.data.filter((x) =>
        x.mobile.includes(this.mobile)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
