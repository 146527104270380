<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="/HomeProperty" class="active">{{ $t("Property") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active">{{ $t("Account Statement") }} {{ $t("renter") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("contract") }}</span> &nbsp;&nbsp;
        <v-select
          :options="accounts"
          label="rentername"
          v-model="item"
          style="background-color: white; color: black; width: 70%"
        >
          <template #option="option">
            <div style="color: black; text-align: center">
              رقم العقد {{ option.conid }} - {{ option.propertyname }} -
              {{ option.rentername }} - رقم الوحدة {{ option.unite }}
            </div>
          </template>
        </v-select>
        &nbsp;

        <span style="width: 7%">{{ $t("to") }} {{ $t("date") }}</span>

        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
    </div>
    <div class="body" id="main">
      <!-- <img :src="this.url" crossorigin="anonymous" /> -->
      <br />
      <div style="padding: 2%; direction: rtl; font-size: small">
        <div class="body1">
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label">{{ $t("realty") }} </label>
              <div class="col-sm-7">
                {{ propertyname }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label">{{ $t("unit") }} </label>
              <div class="col-sm-7">
                {{ unit }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label">{{ $t("renter") }} </label>
              <div class="col-sm-7">
                {{ rentername }}
              </div>
            </div>
          </div>
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label">رقم عقد ايجار </label>
              <div class="col-sm-7">
                {{ igarnumber }}
              </div>
            </div>
          </div>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <th>{{ $t("total") }} {{ $t("amount") }} {{ $t("contract") }}</th>
              <th>{{ $t("total") }} {{ $t("Commissions") }}</th>
              <th>{{ $t("total") }} {{ $t("amount") }}</th>
              <th>{{ $t("remaining") }}</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{{ totalannualrent }}</td>
              <td>{{ totalcom }}</td>
              <td>{{ totalamount }}</td>
              <td>
                {{ Number(this.totalamount - this.totalbalance).toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("type") }}</th>
              <th>{{ $t("amount") }}</th>
              <th>{{ $t("description") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in data1" :key="i">
              <td>{{ i.date }}</td>
              <td>{{ i.typename }}</td>
              <td>{{ i.amount }}</td>
              <td>
                {{ i.details }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="buttom">
      <button
        class="buttonconfirm"
        type="submit"
        v-if="this.view != 0"
        @click="pdf"
      >
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import store from "@/store";
export default {
  components: {
    vSelect,
  },
  async mounted() {
    const result2 = await axios.get("/reports/getallcontracts");
    if (result2.status == 200) {
      this.accounts = result2.data.data;
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      item: "",
      accounts: "",
      data1: [],
      propertyname: "",
      unit: "",
      totalannualrent: "",
      totalcom: "",
      totalamount: "",
      totalbalance: "",
      to: "",
    };
  },
  methods: {
    async submit() {
      const result = await axios.get(
        `/reports/getcontractstatment/${this.item.conid}/${this.to}`
      );
      if (result.status == 200) {
        const data1 = result.data.data;
        this.data1 = data1.sort((a, b) => new Date(a.date) - new Date(b.date));
        this.propertyname = this.item.propertyname;
        this.unit = this.item.unite;
        this.rentername = this.item.rentername;
        this.igarnumber = this.item.igarnumber;
        const one = data1.filter(
          (x) => x.details == "استحقاق" && x.type == "installment"
        );
        this.totalannualrent = one.reduce(
          (acc, array) => acc + Number(array.amount),
          0
        );
        const two = data1.filter(
          (x) => x.details == "استحقاق" && x.type == "commission"
        );
        this.totalcom = two.reduce(
          (acc, array) => acc + Number(array.amount),
          0
        );
        this.totalamount = Number(
          Number(this.totalannualrent) + Number(this.totalcom)
        ).toFixed(2);
        const three = data1.filter((x) => x.details == "سداد");
        this.totalbalance = three.reduce(
          (acc, array) => acc + Number(array.amount),
          0
        );
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.1,
        filename: `Statement-${this.item.igarnumber}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print2() {
      this.$htmlToPaper("main");
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
  display: flex;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin-right: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.body1 {
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}
.group {
  width: 100%;
  padding: 1%;
  margin: 1%;
}
.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}
.row {
  background-color: rgb(236, 232, 232);
}
@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
