<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#" class="active">{{ $t("Property") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active">{{ $t("contracts") }} {{ $t("archive") }} </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        {{ $t("from") }}
        <input type="date" :placeholder="$t('from')" v-model="from" />
        {{ $t("to") }}
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
      <div class="head2">
        <!-- <button type="submit" @click="pdf">
            <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
          </button>
          <button type="submit" @click="exportToExcel">
            <i class="bi bi-file-earmark-excel"></i> Excel
          </button> -->
      </div>
    </div>
    <div class="body" v-if="this.alldata.length > 0">
      <!-- <img :src="this.url" crossorigin="anonymous" /> -->
      <br />
      <div style="padding: 2%; direction: rtl; font-size: small">
        <h4>{{ $t("report") }} {{ $t("contracts") }} {{ $t("Expired") }}</h4>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>

        <table class="table">
          <thead>
            <tr>
              <th>رقم العقار</th>
              <th>رقم الوحدة</th>
              <th>اسم العقار</th>
              <th>اسم المستاجر</th>
              <th>رقم العقد</th>
              <th>نوع العقد</th>
              <th>تاريخ نهاية العقد</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in alldata" :key="i">
              <td>{{ i.propertyid }}</td>
              <td>{{ i.unite }}</td>
              <td>{{ i.propertyname }}</td>
              <td>{{ i.rentername }}</td>
              <td>{{ i.igarnumber }}</td>
              <td>
                <span v-if="i.conttype != null"> {{ $t(i.conttype) }}</span>
              </td>
              <td>{{ i.contend }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import html2pdf from "html2pdf.js";
import store from "@/store";
import * as XLSX from "xlsx";
import { Buffer } from "buffer";
import Swal from "sweetalert2";
export default {
  components: {},
  async mounted() {
    // const result2 = await axios.get("/data/allowners");
    // if (result2.status == 200) {
    //   const cust = result2.data.data;
    //   this.accounts = cust;
    // }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: "",
      todate: "",
      alldata: [],
      alldata2: [],
      accounts: "",
      from: "",
      to: "",
      url: "",
    };
  },
  methods: {
    async submit() {
      this.alldata2 = [];
      this.alldata = [];
      const result = await axios.get(
        `/reports/ArchiveContracts/${this.from}/${this.to}`
      );
      if (result.status == 200) {
        this.fromdate = this.from;
        this.todate = this.to;
        this.alldata2 = result.data.data;
        this.alldata = result.data.data;
        if (this.alldata.length == 0) {
          alert("لا يوجد معاملات");
        }
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.1,
        filename: `Statement-${this.item.name}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },

    pdf2() {
      html2pdf(document.getElementById("main2").innerHTML, {
        margin: 0.5,
        filename: `${this.owner}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    tlvEncode(tag, value) {
      const valueBytes = new TextEncoder().encode(value);
      const length = valueBytes.length;
      return Buffer.from([tag, length, ...valueBytes]);
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.alldata.length; i++) {
        const name = this.alldata[i];
        data.push({
          رقم_العقار: name.properyid,
          اسم_العقار: name.propertyname,
          اسم_المستاجر: name.rentername,
          رقم_العقد: name.igarnumber,
          نوع_العقد: name.conttype,
          مبلغ_الاستحقاق: name.dueamount,
          الضريبة: name.duetax,
          تاريخ_الاستحقاق: name.duedate,
        });
      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, `statement${this.owner}.xlsx`);
    },
    print2() {
      this.$htmlToPaper("main");
    },
    async edite(i) {
      const result = await axios.patch(
        `/reports/editedateinvoice/${i.id}/${this.invoicedate}`
      );
      if (result.status == 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
      }
    },
  },
  watch: {
    type() {
      this.alldata = this.alldata2.filter((x) => x.conttype == this.type);
      this.total = Number(
        this.alldata.reduce((acc, array) => acc + Number(array.dueamount), 0)
      ).toFixed(2);
      this.totaltax = Number(
        this.alldata.reduce((acc, array) => acc + Number(array.duetax), 0)
      ).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
  display: flex;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
  font-size: x-small;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
