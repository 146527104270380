import RoleList from "../views/Role/RoleList";
import RoleAdd from "../views/Role/RoleAdd";
import RoleEdit from "../views/Role/RoleEdit";

export default [
   // roles
   {
    path: "/AdminDashboard/roles",
    name: "roles",
    component: RoleList,
    meta: {
        requiresAuth: true,
        permissions: ["role_list"],
      },
  },
  {
    path: "/AdminDashboard/roles/create",
    name: "rolesCreate",
    component: RoleAdd,
    meta: {
        requiresAuth: true,
        permissions: ["role_create"],
      },
  },
  {
    path: "/AdminDashboard/roles/:id",
    name: "rolesEdit",
    component: RoleEdit,
    meta: {
        requiresAuth: true,
        permissions: ["role_edit"],
      },
  },

];


