<template>
  <div class="page">
    <section id="sidebar">
      <div class="brand">
        <!-- <img src="../assets/logo.png" /> -->
        <img :src="this.url" crossorigin="anonymous" />
      </div>

      <ul class="side-menu top">
        <li>
          <router-link to="/AdminDashboard/Home" @click.prevent="home"><i class="bi bi-house"></i>&nbsp; {{ $t("stitle")
            }}</router-link>
        </li>
        <li v-if="typeofuser == 'Admin'&& hasPermission('role_list')">
                    <router-link to="/AdminDashboard/roles" style="font-size: x-small"><i
                            class="bi bi-eye-slash-fill"></i>
                        {{ $t("roles") }}
                    </router-link>
                </li>
        <li v-if="typeofuser == 'Admin'&& hasPermission('user_list')">
          <router-link to="/AdminDashboard/RAndP" style="font-size: x-small"><i class="bi bi-eye-slash-fill"></i>{{
            $t("users") }}
            {{ $t("and") }} {{ $t("RAndP") }}</router-link>
        </li>
        <li v-if="(typeofuser == 'Admin' || typeofuser == 'accountant')&& hasPermission('companies_list')">
          <router-link to="/AdminDashboard/AllContracts" style="font-size: x-small"><i
              class="bi bi-eye-slash-fill"></i>{{ $t("change") }}
            {{ $t("company") }}</router-link>
        </li>
        <li v-if=" hasPermission('accounting_module')">
          <router-link to="/accounting/home"><i class="bi bi-cash-stack"></i> &nbsp;
            {{ $t("accounting") }}</router-link>
        </li>
        <li v-if="hasPermission('purchases_module')">
          <router-link to="/purchases/home"><i class="bi bi-postcard"></i> &nbsp;
            {{ $t("purchases") }}</router-link>
        </li>

        <li v-if="hasPermission('sales_module')">
          <router-link to="/sales/home"><i class="bi bi-card-heading"></i> &nbsp;
            {{ $t("sales") }}</router-link>
        </li>
        <li v-if="hasPermission('inventory_module')">
          <router-link to="/stock/HomeStock">
            <i class="bi bi-box-seam"></i> &nbsp; {{ $t("stock") }}</router-link>
        </li>
        <li v-if="hasPermission('property_module')">
          <router-link to="/HomeProperty">
            <i class="bi bi-building"></i> &nbsp; {{ $t("management") }}
            {{ $t("Property") }}</router-link>
        </li>
        <li li v-if="hasPermission('tasks_module')">
          <router-link to="/taskshome">
            <i class="bi bi-card-checklist"></i> &nbsp; {{ $t("management") }}
            {{ $t("tasks") }}</router-link>
        </li>

        <li li v-if="hasPermission('crm_module')">
          <router-link to="/HomeCRM">
            <i class="bi bi-people-fill"></i> &nbsp;
            {{ $t("CRM") }}</router-link>
        </li>
        <!--  -->
      </ul>

      <ul class="side-menu">
        <hr />
        <li v-if="typeofuser != 'customer'">
          <a href="#" @click.prevent="setting">
            <i class="bi bi-gear"></i>
            <span class="text">{{ $t("Settings") }}</span>
          </a>
        </li>
        <li v-if="this.settinglist">
          <router-link to="/AdminDashboard/ChangePassword" href="#">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i
              class="bi bi-eye-slash-fill"></i>&nbsp; {{ $t("changepassword") }}</router-link>
        </li>
        <li>
          <a href="#" class="logout" @click.prevent="logout">
            <i class="bi bi-door-open-fill"></i>
            <span class="text">{{ $t("Logout") }}</span>
          </a>
        </li>
      </ul>
    </section>
    <!-- CONTENT -->
    <section id="content">
      <!-- NAVBAR -->
      <nav>
        <button @click.prevent="menu" style="border: none" class="menuaction">
          <i class="bi-menu-button-wide"></i>
        </button>

        <a href="#" class="nav-link">HESABTECH ERP System </a>
        <form action="#">
          <div class="form-input"></div>
        </form>
        <button type="button" @click="langen" class="btn btn-light" v-if="this.lang == 'ar'">
          English
          <!-- <img style="width: 40%; margin: 3%" src="../assets/gbp.jpg" alt="" /> -->
        </button>
        <button type="button" @click="langar" class="btn btn-light" v-if="this.lang == 'en'">
          العربية
          <!-- <img style="width: 40%; margin: 3%" src="../assets/sar.jpg" alt="" /> -->
        </button>
        <a href="#" class="notification">
          <i class="bi bi-bell-fill"></i>
          <span class="num">0</span>
        </a>
        <div href="#" class="profile">
          <i class="bi bi-person-bounding-box"></i>&nbsp;
          {{ this.$store.state.fullname }}
          &nbsp; ({{ this.$store.state.cnum[0].cname }})
        </div>
      </nav>
      <!-- NAVBAR -->
      <LoadingPage v-if="loading"></LoadingPage>
      <!-- MAIN -->
      <main style="padding: 1%" v-else class="router">
        <router-view :css="{ light, dark, EN, AR, dirn, xdirn, lang, color }"></router-view>
      </main>
      <!-- MAIN -->
    </section>

    <!-- CONTENT -->
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import LoadingPage from "../components/LoadingPage.vue";
// import hasPermission from "../mixins/permissionMixin";

const searchButtonIcon = document.querySelector(
  "#content nav form .form-input button .bx"
);
const searchForm = document.querySelector("#content nav form");

// const allSideMenu = document.querySelectorAll("#sidebar .side-menu.top li a");

export default {
  components: {
    LoadingPage,
  },
  created() {
    if (!this.user) {
      window.location.href = "http://localhost:8080/SignIn";
    }
  },
  data() {
    return {
      loading: store.state.loading,
      showside: true,
      accountingmanual: [],
      branch: 1,
      saleslist: false,
      purchaseslist: false,
      productlist: false,
      companylist: false,
      translist: false,
      reportlist: false,
      settinglist: false,
      theme: "",
      css: "",
      lang: "ar",
      EN: "rtl",
      AR: "ltr",
      dirn: "right",
      xdirn: "left",
      typeofuser: store.state.typeofuser,
      r1: JSON.parse(store.state.randp.r1).create,
      r2: JSON.parse(store.state.randp.r2).create,
      r3: JSON.parse(store.state.randp.r3).create,
      r4: JSON.parse(store.state.randp.r4).create,
      r5: JSON.parse(store.state.randp.r5).create,
      r6: JSON.parse(store.state.randp.r6).create,
      r7: JSON.parse(store.state.randp.r7).create,
      r8: JSON.parse(store.state.randp.r8).create,
      r9: JSON.parse(store.state.randp.r9).create,
      r10: JSON.parse(store.state.randp.r10).create,
      r11: JSON.parse(store.state.randp.r11).create,
      r12: JSON.parse(store.state.randp.r12).create,
      r13: JSON.parse(store.state.randp.r13).create,
      r14: JSON.parse(store.state.randp.r14).create,
      r15: JSON.parse(store.state.randp.r15).create,
      r16: JSON.parse(store.state.randp.r16).create,
      r17: JSON.parse(store.state.randp.r17).create,
      r18: JSON.parse(store.state.randp.r18).create,
      r19: JSON.parse(store.state.randp.r19).create,
      r20: JSON.parse(store.state.randp.r20).create,
      r21: JSON.parse(store.state.randp.r21).create,
      r22: JSON.parse(store.state.randp.r22).create,
      r23: JSON.parse(store.state.randp.r23).create,
      r24: JSON.parse(store.state.randp.r24).create,
      r25: JSON.parse(store.state.randp.r25).create,
      r26: JSON.parse(store.state.randp.r26).create,
      mode: "",
      dir: "-230px",
      contantwi: "-60px",
      allSideMenu: "",
      light: "#f9f9f9",
      grey: "#eee",
      dark: "#342e37",
      user: store.state.typeofuser,
      url: "",
      color: "rgba(98,153,174,255)",
    };
  },
  async mounted() {
    // hasPermission('role_list');
    console.log(this.$store.state);

    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
    if (this.$store.state.lang == 'en') {
      this.EN = "ltr";
      this.AR = "rtl";
      this.dir = "230px";
      this.contantwi = "60px";
      this.dirn = "left";
      this.xdirn = "right";
      this.lang = "en";
      this.$i18n.locale = this.lang;
    }
    if (this.$store.state.lang == 'ar') {
      this.EN = "rtl";
      this.AR = "ltr";
      this.dir = "-230px";
      this.contantwi = "-60px";
      this.dirn = "right";
      this.xdirn = "left";
      this.lang = "ar";
      this.$i18n.locale = this.lang;
    }
    if (window.innerWidth < 768) {
      this.menu();
    } else if (window.innerWidth > 576) {
      this.menu2();
    }
  },
  methods: {
    home() {
      this.saleslist = false;
      this.purchaseslist = false;
      this.productlist = false;
      this.companylist = false;
      this.translist = false;
      this.reportlist = false;
    },
    slist() {
      this.saleslist = !this.saleslist;
      this.purchaseslist = false;
      this.productlist = false;
      this.companylist = false;
      this.translist = false;
      this.reportlist = false;
    },
    plist() {
      this.purchaseslist = !this.purchaseslist;
      this.productlist = false;
      this.saleslist = false;
      this.companylist = false;
      this.translist = false;
      this.reportlist = false;
    },
    prolist() {
      this.productlist = !this.productlist;
      this.purchaseslist = false;
      this.saleslist = false;
      this.companylist = false;
      this.translist = false;
      this.reportlist = false;
    },
    clist() {
      this.companylist = !this.companylist;
      this.purchaseslist = false;
      this.productlist = false;
      this.saleslist = false;
      this.translist = false;
      this.reportlist = false;
    },
    Tlist() {
      this.translist = !this.translist;
      this.purchaseslist = false;
      this.saleslist = false;
      this.productlist = false;
      this.companylist = false;
      this.reportlist = false;
    },
    Blist() {
      this.reportlist = !this.reportlist;
      this.purchaseslist = false;
      this.saleslist = false;
      this.productlist = false;
      this.companylist = false;
      this.translist = false;
    },
    setting() {
      this.settinglist = !this.settinglist;
    },
    menu() {
      const sidebar = document.getElementById("sidebar");
      sidebar.classList.toggle("hide");
    },
    menu2() {
      searchButtonIcon.classList.replace("bi bi-search", "bi bi-search");
      searchForm.classList.remove("show");
    },
    logout() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You Need to logout !",
          showCancelButton: true,
          confirmButtonText: "Yes, logout !",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$cookies.remove("data");
            localStorage.clear();
            window.location.href = `${this.$store.state.surl}/SignIn`;
          }
        });
    },
    changepassword() {
      this.redirectTo({ val: "ChangePassword" });
    },
    async langar() {
      const change = await axios.post("/user/changelang", {
        username: this.$store.state.username,
        lang: "ar",
      });
      if (change.status == 200) {
        this.$store.state.lang = "ar"
        this.EN = "rtl";
        this.AR = "ltr";
        this.dir = "-230px";
        this.contantwi = "-60px";
        this.dirn = "right";
        this.xdirn = "left";
        this.lang = "ar";
        this.$i18n.locale = this.lang;
      }

    },
    async langen() {
      const change = await axios.post("/user/changelang", {
        username: this.$store.state.username,
        lang: "en",
      });
      if (change.status == 200) {
        this.$store.state.lang = "en"
        this.EN = "ltr";
        this.AR = "rtl";
        this.dir = "230px";
        this.contantwi = "60px";
        this.dirn = "left";
        this.xdirn = "right";
        this.lang = "en";
        this.$i18n.locale = this.lang;
      }

    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page {
  direction: v-bind("EN");
  //font-family: "cairo";
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

html {
  overflow-x: hidden;
}

body {
  background: v-bind("gray");
  overflow-x: hidden;
}

/* SIDEBAR */
#sidebar {
  position: fixed;
  width: 230px;
  height: 100%;
  background: v-bind("light");
  z-index: 2000;
  // font-family: "Lato", sans-serif;
  transition: 0.3s ease;
  overflow-x: hidden;
  scrollbar-width: none;
}

#sidebar::--webkit-scrollbar {
  display: none;
}

#sidebar.hide {
  width: 60px;
}

#sidebar .brand {
  font-size: 20px;
  font-weight: 700;
  // height: 25px;
  display: flex;
  padding: 2%;
  align-items: center;
  color: v-bind("dark");
  position: sticky;
  box-sizing: content-box;
}

#sidebar .brand img {
  width: 92%;
  max-height: 100px;
  padding: 2%;
  // margin-left: 15%;
  // margin-right: 15%;
}

#sidebar .brand .bi {
  min-width: 60px;
  display: flex;
  justify-content: center;
}

#sidebar .side-menu {
  width: 100%;
  margin-top: 15px;
  // max-height: 100%;
}

#sidebar .side-menu li {
  height: 40px;
  background: transparent;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 10px;
  padding: 2px;
}

#sidebar .side-menu li.active {
  background: rgb(175, 174, 174);
  position: relative;
}

#sidebar .side-menu li.active::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  // border-radius: 15%;
  top: -40px;
  right: 0;
  box-shadow: 20px 20px 0 v-bind("gray");
  z-index: -1;
}

#sidebar .side-menu li.active::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  // border-radius: 15%;
  bottom: -40px;
  right: 0;
  box-shadow: 20px -20px 0 v-bind("gray");
  z-index: -1;
}

#sidebar .side-menu li a {
  width: 100%;
  height: 100%;
  background: v-bind("light");
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: small;
  // font-weight: bold;
  color: v-bind("dark");
  white-space: nowrap;
  overflow-x: hidden;
}

#sidebar .side-menu li .nestli {
  width: 100%;
  height: 100%;
  background: v-bind("dark");
  display: flex;
  align-items: center;
  // border-radius: 5px;
  font-size: small;
  color: v-bind("light");
  white-space: nowrap;
  overflow-x: hidden;
  padding: 1% 10% 1% 10%;
}

#sidebar .side-menu.top li.active a {
  color: rgb(226, 77, 77);
  font-weight: bold;
}

#sidebar.hide .side-menu li a {
  width: calc(48px - (4px * 2));
  transition: width 0.3s ease;
}

#sidebar .side-menu li a.logout {
  color: #db504a;
}

#sidebar .side-menu.top li a:hover {
  color: rgb(226, 77, 77);

  // font-size: x-large;
}

#sidebar .side-menu li a .bi {
  min-width: calc(60px - ((4px + 6px) * 2));
  display: flex;
  justify-content: center;
}

/* CONTENT */
#content {
  position: relative;
  width: calc(100% - 230px);
  left: v-bind("dir");
  transition: 0.3s ease;
}

#sidebar.hide~#content {
  width: calc(100% - 60px);
  left: v-bind("contantwi");
}

/* NAVBAR */
#content nav {
  height: 56px;
  background: v-bind("light");
  padding: 0 24px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  // font-family: "Lato", sans-serif;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.705) 1px 0.5px 7px;
}

#content nav::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 0;
  // border-radius: 50%;
  box-shadow: -20px -20px 0 v-bind("light");
}

#content nav a {
  color: v-bind("dark");
}

#content nav .bx.bx-menu {
  cursor: pointer;
  color: v-bind("dark");
}

#content nav .nav-link {
  font-size: small;
  transition: 0.3s ease;
}

#content nav .nav-link:hover {
  color: #3c91e6;
}

#content nav form {
  max-width: 300px;
  width: 100%;
  margin-right: auto;
}

#content nav form .form-input {
  display: flex;
  align-items: center;
  height: 36px;
}

#content nav form .form-input input {
  flex-grow: 1;
  padding: 0 16px;
  height: 100%;
  border: none;
  background: v-bind("gray");
  outline: none;
  color: v-bind("dark");
}

#content nav form .form-input button {
  width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3c91e6;
  color: v-bind("light");
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 36px 36px 36px 36px;
  cursor: pointer;
}

#content nav .notification {
  font-size: 20px;
  position: relative;
}

#content nav .notification .num {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid v-bind("light");
  background: #db504a;
  color: v-bind("light");
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content nav .profile {
  display: flex;
  font-size: small;
  padding: 2%;
  justify-content: center;
  color: v-bind("dark");
}

#content nav .profile img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

#content nav .switch-mode {
  display: block;
  min-width: 50px;
  height: 25px;
  border-radius: 25px;
  background: v-bind("gray");
  cursor: pointer;
  position: relative;
}

#content nav .switch-mode::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: calc(25px - 4px);
  background: #3c91e6;
  border-radius: 50%;
  transition: all 0.3s ease;
}

#content nav #switch-mode:checked+.switch-mode::before {
  left: calc(100% - (25px - 4px) - 2px);
}

/* NAVBAR */

@media screen and (max-width: 576px) {

  /* SIDEBAR */
  #sidebar {
    position: fixed;
    width: 250px;
    height: 100%;
    background: v-bind("light");
    z-index: 2000;
    // font-family: "Lato", sans-serif;
    transition: 0.3s ease;
    overflow-x: hidden;
    scrollbar-width: none;
  }

  #sidebar::--webkit-scrollbar {
    display: none;
  }

  #sidebar.hide {
    width: 60px;
  }

  #sidebar .brand {
    font-size: 20px;
    font-weight: 700;
    // height: 25px;
    display: flex;
    padding: 2%;
    align-items: center;
    color: v-bind("dark");
    position: sticky;
    box-sizing: content-box;
  }

  #sidebar .brand img {
    width: 92%;
    max-height: 100px;
    padding: 2%;
    // margin-left: 15%;
    // margin-right: 15%;
  }

  #sidebar .brand .bi {
    min-width: 60px;
    display: flex;
    justify-content: center;
  }

  #sidebar .side-menu {
    width: 100%;
    margin-top: 15px;
    // max-height: 100%;
  }

  #sidebar .side-menu li {
    height: 40px;
    background: transparent;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 10px;
    padding: 2px;
  }

  #sidebar .side-menu li.active {
    background: rgb(175, 174, 174);
    position: relative;
  }

  #sidebar .side-menu li.active::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    // border-radius: 15%;
    top: -40px;
    right: 0;
    box-shadow: 20px 20px 0 v-bind("gray");
    z-index: -1;
  }

  #sidebar .side-menu li.active::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    // border-radius: 15%;
    bottom: -40px;
    right: 0;
    box-shadow: 20px -20px 0 v-bind("gray");
    z-index: -1;
  }

  #sidebar .side-menu li a {
    width: 100%;
    height: 100%;
    background: v-bind("light");
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: small;
    // font-weight: bold;
    color: v-bind("dark");
    white-space: nowrap;
    overflow-x: hidden;
  }

  #sidebar .side-menu li .nestli {
    width: 100%;
    height: 100%;
    background: v-bind("dark");
    display: flex;
    align-items: center;
    // border-radius: 5px;
    font-size: small;
    color: v-bind("light");
    white-space: nowrap;
    overflow-x: hidden;
    padding: 1% 10% 1% 10%;
  }

  #sidebar .side-menu.top li.active a {
    color: rgb(226, 77, 77);
    font-weight: bold;
  }

  #sidebar.hide .side-menu li a {
    width: calc(48px - (4px * 2));
    transition: width 0.3s ease;
  }

  #sidebar .side-menu li a.logout {
    color: #db504a;
  }

  #sidebar .side-menu.top li a:hover {
    color: rgb(226, 77, 77);

    // font-size: x-large;
  }

  #sidebar .side-menu li a .bi {
    min-width: calc(60px - ((4px + 6px) * 2));
    display: flex;
    justify-content: center;
  }

  /* CONTENT */

  #sidebar.hide~#content {
    width: calc(100% - 60px);
    left: v-bind("contantwi");
  }

  /* NAVBAR */
  #content nav {
    height: 56px;
    background: v-bind("light");
    padding: 0 24px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    // font-family: "Lato", sans-serif;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.705) 1px 0.5px 7px;
  }

  #content nav::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: -40px;
    left: 0;
    // border-radius: 50%;
    box-shadow: -20px -20px 0 v-bind("light");
  }

  #content nav a {
    color: v-bind("dark");
    font-size: xx-small;
  }

  #content nav .bx.bx-menu {
    cursor: pointer;
    color: v-bind("dark");
  }

  #content nav .nav-link {
    font-size: xx-small;
    transition: 0.3s ease;
  }

  #content nav .nav-link:hover {
    color: #3c91e6;
  }

  #content nav form {
    max-width: 300px;
    width: 100%;
    margin-right: auto;
    display: none;
  }

  #content nav form .form-input {
    display: flex;
    align-items: center;
    height: 36px;
    display: none;
  }

  #content nav form .form-input input {
    flex-grow: 1;
    padding: 0 16px;
    height: 100%;
    border: none;
    background: v-bind("gray");
    outline: none;
    color: v-bind("dark");
  }

  #content nav form .form-input button {
    width: 36px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3c91e6;
    color: v-bind("light");
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 36px 36px 36px 36px;
    cursor: pointer;
  }

  #content nav .notification {
    font-size: 20px;
    position: relative;
    display: none;
  }

  #content nav .notification .num {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid v-bind("light");
    background: #db504a;
    color: v-bind("light");
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #content nav .profile {
    display: flex;
    font-size: xx-small;
    padding: 2%;
    justify-content: center;
    color: v-bind("dark");
  }

  #content nav .profile img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }

  #content nav .switch-mode {
    display: block;
    min-width: 50px;
    height: 25px;
    border-radius: 25px;
    background: v-bind("gray");
    cursor: pointer;
    position: relative;
  }

  #content nav .switch-mode::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: calc(25px - 4px);
    background: #3c91e6;
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  #content nav #switch-mode:checked+.switch-mode::before {
    left: calc(100% - (25px - 4px) - 2px);
  }

  .btn {
    font-size: xx-small;
  }
}

#lang {
  padding: 0.5%;
  background-color: v-bind("light");
  color: v-bind("dark");
}

.router {
  // background-image: url("../assets/background.png");
  // background-repeat: no-repeat;
  // background-size: 100%;
  // background-attachment: fixed;
  min-height: 90vh;
}

button {
  font-size: large;
  display: flex;
}
</style>
