<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("users") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("Edite") }} {{ $t("data") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"> {{ data.fullname }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                  <a href="#" class="btn-download">
                    <span class="text">Download PDF</span>
                  </a>
                </li> -->
        <li>
          <router-link to="/AdminDashboard/RAndP" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i> Back</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("Edite") }} {{ $t("data") }} {{ $t("user") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("name") }}</span>
            <input type="text" class="form-control" v-model="data.fullname" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("username") }}</span>
            <input type="text" class="form-control" v-model="data.username" disabled />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("department") }}</span>
            <input type="text" class="form-control" v-model="data.typeofuser" />
            <span class="input-group-text">
              {{ $t("Edite") }} {{ $t("department") }} {{ $t("to") }}
            </span>
            <select type="text" class="form-control" v-model="data.tobranch">
              <option v-for="x in branches" :key="x" :value="x">
                {{ x.namear }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <span class="input-group-text">
              {{ $t("branch") }}
            </span>
            <input type="text" class="form-control" v-model="data.branchname" />
            <span class="input-group-text">
              {{ $t("Edite") }} {{ $t("branch") }} {{ $t("to") }}
            </span>
            <select type="text" class="form-control" v-model="data.tobranch">
              <option v-for="x in branches" :key="x" :value="x">
                {{ x.namear }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("empno") }}</span>
            <input type="text" class="form-control" v-model="data.empno" disabled />
          </div>
        </div>
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("created") }}</span>
            <input type="text" class="form-control" v-model="data.creationdate" disabled />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("status") }}</span>
            <select type="text" class="form-control" v-model="data.status">
              <option value="active">{{ $t("active") }}</option>
              <option value="inactive">{{ $t("inactive") }}</option>
            </select>
          </div>
          <div class="input-group">
                        <v-select multiple :options="roles" label="name" v-model="selectedRoles">
                            <template #option="option">
                                <span>{{ option.name }}</span>
                            </template>
                        </v-select>
                    </div>
        </div>
        <div class="buttom">
          <button class="buttonconfirm" type="submit" @click="Edite">
            {{ $t("Edite") }}
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="buttoncancel" type="submit" @click="resetpass">
            Reset PassWord
          </button>
          <hr />
          <h1>{{ $t("RAndP") }}</h1>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("code") }}</th>
                <th>{{ $t("name") }}</th>
                <th>{{ $t("RAndP") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="x in head" :key="x">
                <td>{{ x }}</td>
                <td>{{ rnamear[x] }}</td>
                <td>
                  {{ $t("view") }} :
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                    @change.prevent="use(randp[x], x)" v-model="randp[x].create" />
                  {{ $t("Edite") }} :
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                    @change.prevent="use(randp[x], x)" v-model="randp[x].edite" />
                  {{ $t("delete") }} :
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                    @change.prevent="use(randp[x], x)" v-model="randp[x].del" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required ,minLength } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";

export default {
  async mounted() {
    const data2 = await axios.get(`/user/userdetails/${this.$route.params.id}`);
    if (data2.status == 200) {
      this.data = data2.data.data;
    }

    const result2 = await axios.get(
      `/user/userrandp/${this.$route.params.username}`
    );
    if (result2.status == 200) {
      this.randp = result2.data.data;
      this.head = Object.keys(this.randp).filter(function (item) {
        return item !== "id" && item !== "username";
      });
      for (let i = 0; i < this.head.length; i++) {
        const report = this.head[i];
        this.randp[report] = JSON.parse(this.randp[report]);
        if (this.randp[report] == null) {
          this.randp[report] = {};
        }
      }
      this.rnamear = {
        r1: " المشتريات - العملاء",
        r2: " المبيعات ",
        r3: "الحسابات",
        r4: " المخازن",
        r5: " ادارة الاملاك",
        r6: " ادارة المهام",
        r7: " ادارة خدمة العملاء",
        r8: "   ",
        r9: " ",
        r10: " ",
        r11: " ",
        r12: "",
        r13: "",
        r14: " ",
        r15: "",
        r16: "",
        r17: "",
        r18: " ",
        r19: " ",
        r20: "  ",
        r21: "",
        r22: "  ",
        r23: "  ",
        r24: "  ",
        r25: " ",
        r26: "",
      };
    }
    const roles = await axios.get("/roles");
        if (roles.status == 200) {
            this.roles = roles.data.data;
            this.selectedRoles = this.roles.filter(role =>
      this.data.roles.some(userRole => userRole.id === role.id)
        );

        }
  },
  components: {
        vSelect,
    },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      data: "",
      branches: [],
      name: "",
      randp: "",
      head: [],
      rname: {},
      roles: [],
            selectedRoles: [],

    };
  },
  validations() {
    return {
      data: { required },
      selectedRoles: {
                required,
                minLength: minLength(1),
            },

    };
  },
  methods: {
    async Edite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const roleIds = this.selectedRoles.map(role => role.id);
        this.data.roleIds=roleIds;

        const result = await axios.patch(
          `/user/useredite/${this.$route.params.id}`,
          {
            username: store.state.randp.username,
            data: this.data,
          }
        );
        if (result.status == 200) {
          this.redirectTo({ val: "RAndP" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    async resetpass() {
      const result = await axios.post(`/user/resetpassword`, {
        username: this.data.username,
        password: "Aa123456",
      });
      if (result.status == 200) {
        this.redirectTo({ val: "RAndP" });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
        // this.redirectTo({ val: "Home" });
      } else if (result.status == 401) {
        alert("Account Already Created Before");
      }
    },
    async use(value, x) {
      let level1 = await axios.post(`/user/allrandp`, {
        username: this.data.username,
        report: x,
        action: value,
      });

      if (level1.status == 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
        // this.redirectTo({ val: "Home" });
      } else if (level1.status == 401) {
        alert("Account Already Created Before");
      }
    },
    view(value) {
      alert(value);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}

a {
  text-decoration: none;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}

.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.head-title .left .breadcrumb li {
  color: #342e37;
}

.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}

.head-option ul li {
  display: inline;
  padding: 0.5%;
}

.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  // display: flex;
}

.main .body .part1 {
  width: 60%;
  margin-right: 5%;
  margin-left: 5%;
  background: v-bind("css.light");
}

.main .body .part1 .input-group {
  margin: 1%;
}

.main .body .part1 .input-group span {
  width: 25%;
  font-size: small;
}

.main .body .part2 {
  width: 25%;
  background: v-bind("css.light");
  padding-right: 2%;
  padding-left: 2%;
}

.main .body .part2 .part2head {
  background: rgba(191, 214, 219, 0.534);
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.main .body .part2 .part2body {
  box-shadow: rgba(75, 68, 68, 0.774) 0.25px 0.25px 1px;
  padding: 3%;
}

input:disabled {
  background-color: white;
}

.main .body .part3 {
  width: 18%;
  //   border: black 1px solid;
  padding: 1%;
  background-color: #ebe9e9;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  border-radius: 10px;
}

.buttom {
  text-align: center;
}

.table {
  background-color: white;
}
</style>
