<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{
              $t("Commissions")
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <input type="text" :placeholder="$t('Commission')" />
        </div>
        <div class="head2">
          <button>
            <router-link to="/accounting/AllCommissions/AddCommission"
              >+ {{ $t("add") }} {{ $t("Commissions") }}</router-link
            >
          </button>
        </div>
      </div>
      <div class="body">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("rate") }}</th>
              <th>{{ $t("comment") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in data" :key="val">
              <td>{{ val.comid }}</td>
              <td>{{ val.date }}</td>
              <td>{{ val.name }}</td>
              <td>{{ val.rate }}</td>
              <td>{{ val.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import store from "@/store";
import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  components: {},
  data() {
    return {
      name: "",
      status: "",
      mobile: "",
      code: "",
      data: [],
    };
  },
  async mounted() {
    const result = await axios.get("/data/AllCommissions");
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    view(row) {
      this.$root.$router.push({
        path: `/AdminDashboard/JournalEntry/JournalEntryReview/${row.jid}`,
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.namear.includes(this.name.toLowerCase())
      );
    },

    status() {
      this.table.rows = this.table.data.filter((x) => x.review == this.status);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}
</style>
