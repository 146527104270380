<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("accounting") }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="body">
        <div class="part">
          <h6>{{ $t("Accounts") }}</h6>
          <ul>
            <li v-if="hasPermission('account_tree_view')">
              <i  class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/ChartOfAccounts">{{
                $t("chart of accounts")
              }}</router-link>
            </li>
            <li v-if="hasPermission('opening_balance_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/OpeningBalance">{{ $t("balance") }} {{ $t("opening") }}
              </router-link>
            </li>
            <li v-if="hasPermission('commissions_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/AllCommissions">{{ $t("Commissions") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("Payments") }}</h6>
          <ul>
            <li v-if="hasPermission('journal_entry_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/JournalEntry">{{
                $t("Journal Report")
              }}</router-link>
            </li>
            <li v-if="hasPermission('quick_entry_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/AllFastJL">{{
                $t("fastjl")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("ccenter") }}</h6>
          <ul>
            <li v-if="hasPermission('cost_center_guide_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/costcenter">{{
                $t("Cost Centers Guide")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("reports") }} {{ $t("General Ledger") }}</h6>
          <ul>
            <li v-if="hasPermission('general_ledger_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/JournalReport">{{ $t("General Ledger") }} {{ $t("general") }}</router-link>
            </li>
            <li v-if="hasPermission('general_ledger_summary_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/GeneralLedgerSummary">{{ $t("summary") }} {{ $t("General Ledger") }}
                {{ $t("general") }}</router-link>
            </li>
            <li v-if="hasPermission('account_statement_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/GeneralLedgerSummaryDaily/0/0/0/0/0/0">{{ $t("Account Statement")
                }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("data") }} {{ $t("financial") }}</h6>
          <ul>
            <li v-if="hasPermission('trial_balance_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/TrialBalance">{{
                $t("Trial Balance")
              }}</router-link>
            </li>
            <li v-if="hasPermission('income_statement_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/IncomeStatement">{{
                $t("Income Statement")
              }}</router-link>
            </li>
            <li v-if="hasPermission('balance_sheet_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/FinancialCenter">{{
                $t("Financial Center")
              }}</router-link>
            </li>
            <li v-if="hasPermission('revenues_view')">
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/accounting/RevenuesReport">{{
                $t("revenues")
              }}</router-link>
            </li>
          </ul>
        </div>

        <div class="part"></div>
        <div class="part"></div>
        <div class="part"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import store from "@/store";
// import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  title: "Accounting",
  props: ["css"],
  components: {},
  data() {
    return {};
  },
  async mounted() { },

  methods: {
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  display: grid;
  grid-template-columns: repeat(3, 5fr);
  grid-gap: 20px;
  padding: 1%;
  // min-height: 500px;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

@media screen and (max-width: 576px) {
  .body {
    display: grid;
    grid-template-columns: repeat(1, 5fr);
    grid-gap: 20px;
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .part {
    font-size: small;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
  }

  .bi {
    margin: 1%;
  }
}
</style>
