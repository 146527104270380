<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#">{{ $t("reports") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#"
            >{{ $t("balance") }} {{ $t("stock") }} {{ $t("from") }}
            {{ $t("products") }}</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <!-- <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from"  /> -->
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <span>{{ $t("branch") }}</span>
        <select v-model="branch">
          <option value="all">{{ $t("all") }} {{ $t("branches") }}</option>
          <option v-for="val in branches" :key="val.id" :value="val.bid">
            {{ val.bid }} - <span v-if="css.lang == 'ar'">{{ val.namear }}</span
            ><span v-if="css.lang == 'en'">{{ val.nameen }}</span>
          </option>
        </select>
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
        <!-- <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select> -->
      </div>
      <div class="head2"></div>
    </div>

    <div class="body">
      <div id="main">
        <table class="table" :style="{ direction: css.EN }">
          <thead>
            <tr style="text-align: center">
              <th colspan="5">
                {{ $t("balance") }} {{ $t("stock") }} {{ $t("from") }}
                {{ $t("products") }}
                {{ $t("to") }} : {{ todate }}
              </th>
            </tr>
            <tr>
              <th>{{ $t("sn") }}</th>
              <th>{{ $t("product") }}</th>
              <th>{{ $t("quantity") }}</th>
              <th>{{ $t("cost") }} {{ $t("unit") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in data1" :key="x">
              <td>{{ x.sn }}</td>
              <td>
                <span v-if="css.lang == 'ar'">{{ x.namear }}</span
                ><span v-if="css.lang == 'en'">{{ x.nameen }}</span>
              </td>
              <td>{{ Number(x.balance).toFixed(2) }} - {{ x.unittype }}</td>
              <td>{{ Number(x.price).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-primary" type="submit" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import html2pdf from "html2pdf.js";

export default {
  async mounted() {
    // const result = await axios.get(
    //   `/reports/stockbalanceall/${this.fromdate}/${this.todate}`
    // );
    // if (result.status == 200) {
    //   this.data1 = result.data.data;
    // }
    const result2 = await axios.get("/data/allbranches");
    if (result2.status == 200) {
      this.branches = result2.data.data;
    }
  },
  //   async mounted() {
  //     const result = await axios.get(
  //       `/reports/allbuyandsales/${this.fromdate}/${this.todate}`
  //     );
  //     if (result.status == 200) {
  //       const data1 = result.data.data;
  //       const data2 = result.data.data2;

  //       const uniqueIds = new Set();
  //       const data11 = data1.filter((element) => {
  //         const isDuplicate = uniqueIds.has(element.transnum);
  //         uniqueIds.add(element.transnum);
  //         if (!isDuplicate) {
  //           return true;
  //         }
  //         return false;
  //       });
  //       for (let i = 0; i < data11.length; i++) {
  //         const alldata = data1.filter((x) => x.transnum == data11[i].transnum);
  //         const total = alldata.reduce(
  //           (acc, array) => acc + Number(array.pdpt),
  //           0
  //         );
  //         this.data1.push({
  //           transnum: data11[i].transnum,
  //           data: alldata,
  //           total: total,
  //         });
  //       }
  //       const data22 = data2.filter((element) => {
  //         const isDuplicate = uniqueIds.has(element.transnum);
  //         uniqueIds.add(element.transnum);
  //         if (!isDuplicate) {
  //           return true;
  //         }
  //         return false;
  //       });
  //       for (let i = 0; i < data22.length; i++) {
  //         const alldata = data2.filter((x) => x.transnum === data22[i].transnum);
  //         const total = alldata.reduce(
  //           (acc, array) => acc + Number(array.amount),
  //           0
  //         );
  //         this.data2.push({
  //           transnum: data22[i].transnum,
  //           data: alldata,
  //           total: total,
  //         });
  //       }

  //       this.purchasenum = this.data1.length;
  //       this.salenum = this.data2.length;
  //     }
  //   },
  props: ["css"],
  data() {
    return {
      fromdate: moment().startOf("year").format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      data2: [],
      branches: [],
      from: moment().startOf("year").format("YYYY-MM-DD"),
      to: "",
      purchasenum: 0,
      type: "",
      branch: "all",
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllReports/GeneralLedgerSummaryMonthly/${val.actnum}/${val.namear}/${val.nameen}/${val.typeact}`,
      });
    },
    async submit() {
      this.data1 = [];
      this.data2 = [];
      if (this.branch == "all") {
        const result = await axios.get(
          `/reports/stockbalanceall/${this.from}/${this.to}`
        );
        if (result.status == 200) {
          this.data1 = result.data.data;
          this.todate = this.to;
        }
      } else {
        const result = await axios.get(
          `/reports/stockbalance/${this.from}/${this.to}/${this.branch}`
        );
        if (result.status == 200) {
          this.data1 = result.data.data;
          this.todate = this.to;
        }
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "barcode.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
