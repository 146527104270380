<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("Property") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allcustomers" class="active"
              >{{ $t("all") }} {{ $t("customers") }}
            </router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ this.name }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("Edite") }} {{ $t("customer") }}</h3>
        </div>
        <div class="head2">
          <button
            class="buttonconfirm"
            type="submit"
            :disabled="disabled"
            @click="submite"
          >
            {{ $t("update") }}
          </button>
        </div>
      </div>
      <br />
      <!-- <div class="selection">
        <button
          class=""
          :class="select11 ? 'active' : ''"
          @click="
            (this.select11 = true),
              (this.select22 = false),
              (this.type = 'renter')
          "
        >
          {{ $t("renter") }}
        </button>
        <button
          class=""
          :class="select22 ? 'active' : ''"
          @click="
            (this.select11 = false),
              (this.select22 = true),
              (this.type = 'owner')
          "
        >
          {{ $t("owners") }}
        </button>
      </div> -->
      <div class="body">
        <div class="body1">
          <div class="group">
            <label for="basic-url" class="form-label"
              >{{ $t("name") }}
              <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span></label
            >
            <div>
              <input type="text" v-model="name" />
            </div>
          </div>
          <div class="group"></div>
        </div>
        <hr />
        <hr />
        <div class="body3">
          <div class="selection">
            <button
              class=""
              :class="select1 ? 'active' : ''"
              @click="
                (this.select1 = true),
                  (this.select2 = false),
                  (this.select3 = false),
                  (this.select4 = false)
              "
            >
              {{ $t("description") }}
            </button>
            <button
              class=""
              :class="select2 ? 'active' : ''"
              @click="
                (this.select1 = false),
                  (this.select2 = true),
                  (this.select3 = false),
                  (this.select4 = false)
              "
            >
              {{ $t("deputy") }}
            </button>
            <button
              class=""
              :class="select3 ? 'active' : ''"
              @click="
                (this.select1 = false),
                  (this.select2 = false),
                  (this.select3 = true),
                  (this.select4 = false)
              "
            >
              {{ $t("job") }} {{ $t("and") }} {{ $t("family") }}
            </button>
            <button
              class=""
              :class="select4 ? 'active' : ''"
              @click="
                (this.select1 = false),
                  (this.select2 = false),
                  (this.select3 = false),
                  (this.select4 = true)
              "
            >
              {{ $t("Accounts") }} {{ $t("and") }} {{ $t("invoices") }}
            </button>
            <!-- <button @click="this.select = 4">{{ $t("other") }}</button> -->
          </div>
          <div class="group2" v-if="select1 == true">
            <div class="part">
              <div class="row mb-1">
                <label for="inputEmail3" class="col-sm-2 col-form-label">{{
                  $t("Categorie")
                }}</label>
                <div class="col-sm-10">
                  <v-select
                    :options="categories"
                    label="category"
                    v-model="catitem"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.category }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputEmail3" class="col-sm-2 col-form-label"
                  >{{ $t("type") }} {{ $t("entity") }}</label
                >
                <div class="col-sm-10">
                  <v-select
                    :options="entities"
                    label="entity"
                    v-model="enttype"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.entity }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputEmail3" class="col-sm-2 col-form-label"
                  >{{ $t("type") }} {{ $t("identification") }}</label
                >
                <div class="col-sm-5">
                  <select class="form-control" v-model="idtype">
                    <option value="pid">{{ $t("pid") }}</option>
                    <option value="commercial record">
                      {{ $t("commercial record") }}
                    </option>
                    <option value="national identity">
                      {{ $t("national identity") }}
                    </option>
                    <option value="Unified National Number">
                      {{ $t("Unified National Number") }}
                    </option>
                    <option value="Residence ID">
                      {{ $t("Residence ID") }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("number") }} {{ $t("identification") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="idnum"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("number") }} {{ $t("mobile") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="mobile"
                  />
                </div>
              </div>
            </div>
            <div class="part">
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("email") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="email"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("address") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="address"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("city") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="city"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("zipcode") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="zipcode"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("area") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="area"
                  />
                </div>
              </div>
            </div>
            <div class="part">
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("nationality") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="nationality"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("number") }} {{ $t("tax") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="tax"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("number") }} {{ $t("Account") }} {{ $t("bank") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="bankaccount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="body4" v-if="select1 == true">
            <hr />
            <h5>{{ $t("notes") }}</h5>
            <textarea
              v-model="notes"
              id=""
              style="width: 100%"
              rows="4"
            ></textarea>
          </div>
          <div v-if="select2 == true">
            <div class="form-check form-switch" style="width: 25%; padding: 1%">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                v-model="deputy"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked"
                >{{ $t("deputy") }} بنفسة</label
              >
            </div>
          </div>
          <div class="group2" v-if="deputy == false && select2 == true">
            <div class="part">
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("name") }} {{ $t("deputy") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.name"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("email") }} {{ $t("deputy") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.email"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputEmail3" class="col-sm-3 col-form-label"
                  >{{ $t("type") }} {{ $t("identification") }}
                  {{ $t("deputy") }}</label
                >
                <div class="col-sm-7">
                  <v-select
                    :options="id"
                    label="idtype"
                    v-model="deputydata.idtype"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.idtype }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("number") }} {{ $t("identification") }}
                  {{ $t("deputy") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.idnum"
                  />
                </div>
              </div>
            </div>
            <div class="part">
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("nationality") }} {{ $t("deputy") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.nationality"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("type") }} {{ $t("receipt") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.receipt"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("number") }} {{ $t("receipt") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.receiptn"
                  />
                </div>
              </div>

              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("date") }} {{ $t("receipt") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="date"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.receiptd"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("expiry") }} {{ $t("receipt") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="date"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.receiptثء"
                  />
                </div>
              </div>

              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("mobile") }} {{ $t("deputy") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="deputydata.nationality"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="group2" v-if="select3 == true">
            <div class="part">
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("name") }} {{ $t("job") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="jobdata.name"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("income") }} {{ $t("monthly") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="number"
                    class="form-control"
                    id="inputPassword"
                    v-model="jobdata.salary"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("sector") }}
                </label>
                <div class="col-sm-7">
                  <select
                    class="form-control"
                    id="inputPassword"
                    v-model="jobdata.sector"
                  >
                    <option value="government">حكومي</option>
                    <option value="private sector">قطاع خاص</option>
                    <option value="student">طلاب</option>
                    <option value="retired">متقاعد</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("address") }} {{ $t("job") }}
                </label>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    v-model="jobdata.address"
                  />
                </div>
              </div>
            </div>
            <div class="part">
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  عدد المعالين
                </label>
                <div class="col-sm-4">
                  <input
                    type="number"
                    class="form-control"
                    id="inputPassword"
                    v-model="jobdata.familyn"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="body4" v-if="select3 == true"></div>
          <div class="group2" v-if="select4 == true">
            <div class="part">
              <div class="row mb-1">
                <label for="inputEmail3" class="col-sm-3 col-form-label"
                  >{{ $t("Account") }}
                  <span class="error-feedback" v-if="v$.account.$error">{{
                    "*"
                  }}</span>
                </label>
                <div class="col-sm-7">
                  <v-select
                    :options="accounts"
                    label="namear"
                    v-model="account"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.namear }} - {{ option.actnum }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label for="inputEmail3" class="col-sm-3 col-form-label"
                  >{{ $t("Account") }} {{ $t("tax") }} 
                  <span class="error-feedback" v-if="v$.account.$error">{{
                    "*"
                  }}</span>
                </label>
                <div class="col-sm-7">
                  <v-select
                    :options="accounts"
                    label="namear"
                    v-model="taxaccount"
                  >
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.namear }} - {{ option.actnum }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="part">
              <div class="row mb-1">
                <label for="inputPassword" class="col-sm-3 col-form-label">
                  {{ $t("balance") }}
                </label>
                <div class="col-sm-5">
                  <input
                    type="number"
                    class="form-control"
                    id="inputPassword"
                    v-model="balance"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import vSelect from "vue-select";

export default {
  props: ["css"],
  components: {
    vSelect,
  },
  async mounted() {
    const result2 = await axios.get("/data/getaccounttrue");
    if (result2.status == 200) {
      this.accounts = result2.data.data;
    }
    const result = await axios.get(
      `/data/delcustomer/${this.$route.params.id}`
    );
    if (result.status == 200) {
      const data = result.data.data[0];
      this.name = data.name;
      this.email = data.email;
      this.type = data.type;
      this.idnum = data.idnum;
      this.mobile = data.mobile;
      this.address = data.address;
      this.city = data.city;
      this.zipcode = data.zipcode;
      this.area = data.area;
      this.nationality = data.nationality;
      this.tax = data.tax;
      this.bankaccount = data.bankaccount;
      this.account = JSON.parse(data.account);
      this.taxaccount = JSON.parse(data.taxaccount);
      this.catitem = JSON.parse(data.catitem);
      this.enttype = JSON.parse(data.enttype);
      this.idtype = JSON.parse(data.idtype);
      this.jobdata = JSON.parse(data.jobdata);
      this.deputydata = JSON.parse(data.deputydata);
    }
  },
  data() {
    return {
      v$: useValidate(),
      url: "",
      deputy: true,
      select11: true,
      select22: false,
      select1: true,
      select2: false,
      select3: false,
      select4: false,
      prodata: [{}],
      docdata: [{}],
      totalspace: {},
      categories: [
        { category: "مستاجر", value: "renter" },
        { category: "مالك", value: "owner" },
        { category: "عميل", value: "customer" },
      ],
      entities: [
        { entity: "فرد", value: "individual" },
        { entity: "منشأة تجارية", value: "corporate" },
        { entity: "جهه حكومية", value: "governmental" },
      ],
      id: [
        { idtype: "هوية شخصية", value: "pid" },
        { idtype: "سجل تجاري", value: "commercial record" },
        { idtype: "هوية وطنية", value: "national identity" },
        { idtype: "الرقم الوطني الموحد", value: "Unified National Number" },
        { idtype: "هوية اقامة", value: "Residence ID" },
      ],
      catitem: { category: "مستاجر", value: "renter" },
      enttype: { entity: "فرد", value: "individual" },
      idtype: "",
      deputydata: {},
      jobdata: {},
      accounts: [],
      account: "",
      taxaccount:"",
      balance: "",
      type: "",
      idnum: "",
      mobile: "",
      email: "",
      address: "",
      city: "",
      zipcode: "",
      area: "",
      nationality: "",
      tax: "",
      name: "",
      bankaccount: "",
    };
  },
  validations() {
    return {
      name: { required },
      account: { required },
    };
  },

  async created() {},
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async del(id) {
      this.prodata.splice(id, 1);
    },
    async submite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disabled = true;
        const result = await axios.post(`/data/updatecustomerowner`, {
          username: store.state.randp.username,
          code: this.$route.params.id,
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          companyname: this.companyname,
          website: this.website,
          taxnumber: this.tax,
          street: this.street,
          city: this.city,
          area: this.area,
          zipcode: this.zipcode,
          building: this.building,
          country: this.country,
          type: this.catitem.value,
          enttype: this.enttype.value,
          idtype: this.idtype,
          idnum: this.idnum,
          caddress: this.address,
          nationality: this.nationality,
          deputydata: JSON.stringify(this.deputydata),
          jobdata: JSON.stringify(this.jobdata),
          account: JSON.stringify(this.account),
          taxaccount: JSON.stringify(this.taxaccount),
          balance: this.balance,
          bankaccount: this.bankaccount,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "allcustomers" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    type() {
      if (this.type == "renter") {
        this.catitem = { category: "مستاجر", value: "renter" };
      }
      if (this.type == "owner") {
        this.catitem = { category: "مالك", value: "owner" };
      }
    },
    deputy() {
      if (this.deputy == true) {
        this.deputydata = {};
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 50%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.body4 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 8%;
}

.option {
  text-align: center;
}
</style>
