<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link to="/tasks" class="active">{{ $t("management") }} {{ $t("tasks") }}</router-link>
        </li>
        <li>-</li>
        <li>
          <router-link to="" class="active">{{ $t("collecting") }} {{ $t("supply order") }}</router-link>
        </li>
      </ul>
    </div>
  </div>

  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" @change="submit" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" @change="submit" />
        <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option value="all">الكل</option>
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
      </div>
      <div class="head2"></div>
    </div>
    <br />
    <div class="body" v-if="this.trans.length == 0">برجاء اختيار المدة</div>
    <div class="body" v-if="this.trans.length > 0" style="overflow: scroll;">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("code") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("time") }}</th>
            <th>{{ $t("details") }}</th>
            <th>{{ $t("description") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in trans" :key="i">
            <td>
              <router-link to="">{{ i.tid }}</router-link>
            </td>
            <td>{{ i.date }}</td>
            <td>{{ i.time }}</td>
            <td>{{ i.description }}</td>
            <td>
              <table class="table">
                <thead>
                  <tr>
                    <td></td>
                    <td>{{ $t("materials") }}</td>
                    <td>{{ $t("code") }}</td>
                    <td>{{ $t("unit") }}</td>
                    <td>
                      {{ $t("quantity") }}
                    </td>
                    <td>{{ $t("comment") }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, id) in i.details" :key="x">
                    <td>{{ id + 1 }}</td>
                    <td>
                      {{ x.namear }}
                    </td>
                    <td>
                      {{ x.sn }}
                    </td>
                    <td>
                      {{ x.unittype }}
                    </td>
                    <td>
                      {{ x.quantity }}
                    </td>
                    <td>
                      {{ x.comment }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <div>
          <h4>{{ $t("total") }} {{ $t("purchases") }}</h4>
          <br />
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("name") }}</th>
                <th>{{ $t("sn") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>{{ $t("stock") }}</th>
                <th>{{ $t("purchases") }}</th>
                <th>
                  {{ $t("minimum") }} {{ $t("balance") }} {{ $t("stock") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in alltrans" :key="i">
                <td>{{ i.name }}</td>
                <td>{{ i.sn }}</td>

                <td>{{ i.quantity }}</td>
                <td>{{ Number(i.stockbalance).toFixed(2) }}</td>
                <td><input type="number" v-model="i.purchase" /></td>
                <td>{{ i.minstock }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="main" style="display: none">
        <div style="direction: rtl">
          <h3>{{ $t("total") }} {{ $t("purchases") }}</h3>
          {{ $t("date") }} : {{ date }}
          <br />
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("name") }}</th>
                <th>{{ $t("sn") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>{{ $t("stock") }}</th>
                <th>{{ $t("purchases") }}</th>
                <th>
                  {{ $t("minimum") }} {{ $t("balance") }} {{ $t("stock") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in alltrans" :key="i">
                <td>{{ i.name }}</td>
                <td>{{ i.sn }}</td>

                <td>{{ i.quantity }}</td>
                <td>{{ i.stockbalance }}</td>
                <td>{{ i.purchase }}</td>
                <td>{{ i.minstock }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mb-3">
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">{{
            $t("comment")
          }}</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="comment"></textarea>
        </div>
      </div>
      <button style="padding: 1%" type="submit" @click="print2">
        <i class="bi bi-printer-fill"></i> {{ $t("printer") }}
      </button>
      <button style="padding: 1%" type="submit" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
      <button @click="purchaseorder" style="padding: 1%">
        {{ $t("create") }} {{ $t("supplyorder") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import vSelect from "vue-select";
import Swal from "sweetalert2";
import store from "@/store";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
export default {
  components: {},
  async mounted() {
    const result3 = await axios.get("/data/getcostcenter");
    if (result3.status == 200) {
      this.branches = result3.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
    }
  },
  props: ["css"],
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      item: "",
      fromdate: "",
      todate: "",
      data1: [],
      view: "",
      namear: "",
      nameen: "",
      typeact: "",
      accounts: [],
      selection: false,
      filter: "",
      totalbalance: 0,
      totaldpt: 0,
      totalcrd: 0,
      from: "",
      to: "",
      parent: "all",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
      trans: [],
      Products: [],
      Product2: [],
      alltrans: [],
      comment: "",
    };
  },
  methods: {
    async submit() {
      this.trans = [];
      this.Products = [];
      this.Product2 = [];
      this.alltrans = [];
      if (this.from && this.to) {
        const result = await axios.get(
          `/reports/collectingorders/${this.from}/${this.to}/${this.parent}`
        );
        if (result.status == 200) {
          const data = result.data.data;
          if (data.length == 0) {
            Swal.fire("لا يوجد طلبات");
          }
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            this.trans.push({
              tid: element.tid,
              date: element.date,
              time: element.time,
              description: element.description,
              details: JSON.parse(element.details),
            });
            const details = JSON.parse(element.details);
            for (let i = 0; i < details.length; i++) {
              const element = details[i];
              this.Products.push({
                name: element.namear,
                sn: element.sn,
                unittype: element.unittype,
                quantity: element.quantity,
                minstock: element.minstock,
              });
            }
          }
          const uniqueIds = new Set();
          this.Product2 = this.Products.filter((element) => {
            const isDuplicate = uniqueIds.has(element.sn);
            uniqueIds.add(element.sn);
            if (!isDuplicate) {
              return true;
            }
            return false;
          });
          for (let i = 0; i < this.Product2.length; i++) {
            const element = this.Product2[i].sn;
            const allproduct = this.Products.filter((x) => x.sn == element);
            const total = allproduct.reduce(
              (acc, array) => acc + Number(array.quantity),
              0
            );
            const stockvalidationbalance = await axios.get(
              `/reports/stockvalidationbalance/${this.Product2[i].sn}`
            );
            if (stockvalidationbalance.status == 200) {
              const data = stockvalidationbalance.data.data[0][0];
              const stockbalance = data.dpt - data.crd;
              //   if (stockbalance > 0) {
              //     this.details[i].avcost = stockvalidationbalance.data.data[1]
              //   }
              this.alltrans.push({
                name: this.Product2[i].name,
                sn: this.Product2[i].sn,
                unittype: this.Product2[i].unittype,
                quantity: total,
                stockbalance: stockbalance,
                purchase: null,
                minstock: stockvalidationbalance.data.data[2],
              });
            }
          }
        }
      }
    },
    async purchaseorder() {
      Swal.fire({
        title: this.$t("create") + " " + this.$t("supplyorder"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/tasksupplyorder", {
            data: this.trans,
            data2: this.alltrans,
            username: store.state.randp.username,
            comment: this.comment,
          });
          if (action.status == 200) {
            Swal.fire(
              this.$t("create") +
              " " +
              this.$t("supplyorder") +
              " " +
              action.data.data[0].soid
            );
            this.redirectTo({ val: "AllTasksView" });
          }
        }
      });
    },
    print2() {
      this.$htmlToPaper("main");
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: `${this.$t("supplyorder")}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    async parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
  display: flex;
}

.head1 input {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: #eee;

}

.head1 span {
  padding: 1%;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

button {
  margin: 0.5%;
  padding: 3%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.table input {
  text-align: center;
  border: none;
}

@media screen and (max-width: 576px) {
  .page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
    font-size: x-small;
  }

  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
