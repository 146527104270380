<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("Property") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allpayment" class="active"
              >{{ $t("receipts") }} {{ $t("paid") }}</router-link
            >
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/createpayment" class="active"
              >{{ $t("receipt") }} {{ $t("paid") }} {{ this.$route.params.id }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            <i class="bi bi-credit-card"></i> {{ $t("receipt") }}
            {{ $t("paid") }}
          </h3>
        </div>
        <div class="head2">
          <button
            class="buttonconfirm"
            :disabled="disabled"
            @click="submit"
            v-if="this.status == 'waiting'"
          >
            <i class="bi bi-file-check-fill"></i> {{ $t("confirm") }}
          </button>
          <!-- <button class="buttonconfirm" type="submit" @click="print2">
            <i class="bi bi-printer-fill"></i> {{ $t("printer") }}
          </button> -->
          <button class="buttonconfirm" type="submit" @click="pdf">
            <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
          </button>
        </div>
      </div>

      <div class="body">
        <div class="body1">
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label"
                >{{ $t("receipt") }}
              </label>
              <div class="col-sm-7">
                {{ this.$route.params.id }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label"
                >{{ $t("date") }} {{ $t("payment") }}
              </label>
              <div class="col-sm-7">
                {{ paydate }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label">{{ $t("realty") }} </label>
              <div class="col-sm-7">
                {{ property }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label">{{ $t("owner") }} </label>
              <div class="col-sm-7">
                {{ rentername }}
              </div>
            </div>
          </div>
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label"
                >{{ $t("date") }} {{ $t("creation") }}
              </label>
              <div class="col-sm-7">
                {{ creationdate }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label"
                >{{ $t("Method of Payment") }}
              </label>
              <div class="col-sm-7">
                {{ method }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label"
                >{{ $t("Account") }}
              </label>
              <div class="col-sm-7">
                {{ accountpayment }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label">{{ $t("total") }} </label>
              <div class="col-sm-7">
                {{ total }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="activity">
        <ul v-for="x in activity" :key="x">
          <li style="width: 150px">
            {{ x.action }} <br />
            <span style="font-size: x-small">{{ x.date }} - {{ x.time }}</span>
            <br v-if="x.comment" />{{ x.comment }} <br />{{ x.username }}
          </li>
        </ul>
      </div>
      <div id="main" style="display: none">
        <div style="padding: 2%; direction: rtl; font-size: small">
          <div class="body1">
            <div class="group">
              <h3 style="padding: 2%">
                {{ $t("receipt") }}
                {{ $t("received") }}
              </h3>
            </div>
            <div class="group">
              <img
                :src="this.url"
                crossorigin="anonymous"
                style="height: 100px; width: 50%; float: left"
              />
            </div>
          </div>
          <div class="body1">
            <div class="group">
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("receipt") }}
                </label>
                <div class="col-sm-7">
                  {{ this.$route.params.id }}
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("date") }} {{ $t("payment") }}
                </label>
                <div class="col-sm-7">
                  {{ paydate }}
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("realty") }}
                </label>
                <div class="col-sm-7">
                  {{ property }}
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("owner") }}
                </label>
                <div class="col-sm-7">
                  {{ rentername }}
                </div>
              </div>
            </div>
            <div class="group">
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("date") }} {{ $t("creation") }}
                </label>
                <div class="col-sm-7">
                  {{ creationdate }}
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("Method of Payment") }}
                </label>
                <div class="col-sm-7">
                  {{ method }}
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("Account") }}
                </label>
                <div class="col-sm-7">
                  {{ accountpayment }}
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label"
                  >{{ $t("total") }}
                </label>
                <div class="col-sm-7">
                  {{ total }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import html2pdf from "html2pdf.js";
export default {
  props: ["css"],
  components: {},
  async mounted() {
    const result = await axios.get(
      `/data/getownerreceipt/${this.$route.params.id}`
    );
    if (result.status == 200) {
      const data = result.data.data[0];
      this.property = data.proname;
      this.rentername = data.ownername;
      this.paydate = data.paydate;
      this.creationdate = data.date;
      this.method = data.paytype;
      this.accountpayment = JSON.parse(data.payaccount).namear;
      this.contractnum = data.conid;
      this.status = data.status;
      this.total = data.amount;
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
    this.activity = result.data.activity;
  },
  data() {
    return {
      v$: useValidate(),
      rentername: "",
      property: "",
      comm: "",
      paydate: "",
      creationdate: "",
      trans: [],
      contract: "",
      contstatus: "",
      payamount: "",
      paytax: "",
      contduration: "",
      contamount: "",
      Method: "",
      contractnum: "",
      accountpayment: "",
      account: "",
      paymentdate: "",
      conductor: "",
      commission: [],
      paymentamount: "",
      propertyaccount: "",
      propertytaxaccount: "",
      renteraccount: "",
      totalcommission: 0,
      totalcommissiontax: 0,
      unit: "",
      description: "",
      commissionaccount: "",
      ownerid: "",
      totalrentpayment: 0,
      totalrentpaymenttax: 0,
      installment: [],
      igarnumber: "",
      url: "",
      status: "",
      activity: [],
    };
  },
  validations() {
    return {
      property: { required },
      contract: { required },
      paymentamount: { required },
      Method: { required },
      account: { required },
      paymentdate: { required },
    };
  },
  methods: {
    async submit() {
      Swal.fire({
        title: this.$t("confirm") + "-" + this.$t("payment"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/confirownerrecepit", {
            username: store.state.randp.username,
            id: this.$route.params.id,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("done") + " " + this.$t("payment"));
            this.redirectTo({ val: "AllOwnerReceipts" });
          }
        }
      });
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: `coll-${this.$route.params.id}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print() {
      var divElements = document.getElementById("main").innerHTML;
      var WinPrint = window.open();
      WinPrint.document.write(
        "<html><head><title>ESLAM</title></head><body>" +
          divElements +
          "</body>"
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    print2() {
      this.$htmlToPaper("main");
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 15%;
}

.head2 button a {
  color: white;
}

.group {
  width: 100%;
  padding: 1%;
  margin: 1%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.body4 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}

.row {
  background-color: rgb(236, 232, 232);
}
.activity {
  // display: flex;
  margin-top: 2%;
  width: 75%;
  font-size: small;
}
</style>
