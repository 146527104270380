<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/accounting/ChartOfAccounts" class="active">{{
              $t("Commissions")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="#" class="active"
              >{{ $t("add") }} {{ $t("Commission") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("Commission") }}</h3>
        </div>
        <div class="head2">
          <button @click="submit">{{ $t("save") }}</button>
        </div>
      </div>
      <div class="body">
        <div class="group">
          <div class="row mb-4">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("name") }} {{ $t("Commission") }}
              <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-4">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="name"
              />
            </div>
          </div>
          <div class="row mb-4">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("rate") }} {{ $t("Commission") }}
              <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="rate"
              />
            </div>
          </div>
          <div class="row mb-4">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("Account") }} {{ $t("Commission") }}
            </label>
            <div class="col-sm-4">
              <v-select :options="data1" label="namear" v-model="account">
                <template #option="option">
                  <span>{{ option.namear }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-4">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("Account") }} {{ $t("tax") }}
            </label>
            <div class="col-sm-4">
              <v-select :options="data1" label="namear" v-model="tax">
                <template #option="option">
                  <span>{{ option.namear }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-4">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("description") }}
            </label>
            <div class="col-sm-4">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      name: "",
      tax: "",
      description: "",
      r2: store.state.randp.r2,
      datalevel: [],
      data1: [],
      data2: [],
      unit: [],
      rate: "",
      account: "",
    };
  },
  validations() {
    return {
      name: { required },
      rate: { required },
      account: { required },
      tax: { required },
    };
  },
  async mounted() {
    const result = await axios.get("/data/accountingmanual");
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/accounting/ChartOfAccounts",
      });
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post("/data/createcommission", {
          username: store.state.randp.username,
          name: this.name,
          account: this.account,
          tax: this.tax,
          rate: this.rate,
          parent: this.parent,
          description: this.description,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "AllCommissions" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}
.main {
  width: 98%;
  // margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}
.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}
.head1 {
  width: 70%;
}
.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.head2 {
  width: 30%;
  text-align: center;
}
.head2 button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}
.group {
  padding: 1%;
  width: 90%;
}

.group .select {
  width: 50%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
</style>
