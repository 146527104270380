// eslint-disable-next-line vue/multi-word-component-names
<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/purchases/home" class="active">{{
              $t("purchases")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/purchases/AllPurchaseOrder" class="active"
              >{{ $t("order") }} {{ $t("purchase") }}</router-link
            >
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"
              >{{ $t("add") }} {{ $t("order") }} {{ $t("purchase") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("order") }} {{ $t("purchase") }}</h3>
        </div>
        <div class="head2">
          <button @click="submit">{{ $t("save") }}</button>
        </div>
      </div>
      <div class="body">
        <div class="part1">
          <div class="part11">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("number") }}
              </label>
              <div class="col-sm-7">ORD-{{ id }}</div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="date"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="description"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("supplier") }}
              </label>
              <div class="col-sm-7">
                <v-select :options="customers" label="name" v-model="sdata">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <!-- <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("date") }} {{ $t("due") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="duedate"
                />
              </div>
            </div> -->
            <!-- <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("date") }} {{ $t("supply") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-7">
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="supplydate"
                />
              </div>
            </div> -->
          </div>
          <div class="part11">
            <h4>{{ $t("data") }} {{ $t("supplier") }}</h4>

            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.name }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("mobile") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.mobile }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("email") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.email }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("tax") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.taxnumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="ccenter">
          <button @click="ccenter = !ccenter">
            {{ $t("add") }} {{ $t("ccenter") }}
          </button>
          <div v-if="ccenter == true">
            <span>{{ $t("ccenter") }}</span>
            <select v-model="parent">
              <option v-for="x in level1" :key="x" :value="x.actnum">
                {{ x.namear }}
              </option>
            </select>
            <select v-model="parent2" v-if="level2.length > 0">
              <option v-for="x in level2" :key="x" :value="x.actnum">
                {{ x.namear }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
            >{{ $t("product") }}
          </label>
          <div class="col-sm-3">
            <v-select :options="products" label="namear" v-model="product">
              <template #option="option">
                <span>{{ option.barcode }} - {{ option.namear }}</span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="part2">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th style="width: 12%">{{ $t("product") }}</th>
                <th>{{ $t("description") }}</th>
                <th style="width: 10%">{{ $t("quantity") }}</th>
                <th style="width: 10%">{{ $t("unit type") }}</th>
                <th>{{ $t("price") }}</th>
                <th>{{ $t("discount") }}</th>
                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                <th>{{ $t("tax") }}</th>
                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                <th>{{ $t("total") }}</th>
                <th>{{ $t("cancel") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) in data" :key="value">
                <td>{{ id + 1 }}</td>
                <td style="width: 20%">
                  <span
                    >{{ value.product.barcode }} -
                    {{ value.product.namear }}</span
                  >
                </td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="value.description"
                  />
                </td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="value.quantity"
                    @change="
                      value.proid = value.product.pid;
                      value.pronamear = value.product.namear;
                      value.pronameen = value.product.nameen;
                      value.salep = value.product.salep;
                      value.salec = value.product.salec;
                      value.snamear = value.product.snamear;
                      value.snameen = value.product.snameen;
                      value.buyp = value.product.buyp;
                      value.buyc = value.product.buyc;
                      value.bnamear = value.product.bnamear;
                      value.bnameen = value.product.bnameen;
                      value.discount = value.product.discount;
                      value.unittype = value.product.unittype;
                      value.tax = value.product.tax;
                      value.sn = value.product.sn;
                      value.barcode = value.product.barcode;
                      value.tbt =
                        value.product.buyp * value.quantity -
                        (value.product.buyp *
                          value.quantity *
                          value.product.discount) /
                          100;
                      value.taxamount = (value.tbt * value.product.tax) / 100;
                      value.total =
                        value.tbt + (value.tbt * value.product.tax) / 100;
                      this.calc();
                    "
                  />
                </td>
                <td>{{ value.unittype }}</td>
                <td>{{ value.buyp }}</td>
                <td>{{ value.discount }} %</td>
                <td>{{ value.tbt }}</td>
                <td style="display: flex">
                  <input
                    type="number"
                    v-model="value.tax"
                    style="width: 100px"
                    @change="
                      value.tbt =
                        value.product.buyp * value.quantity -
                        (value.product.buyp *
                          value.quantity *
                          value.product.discount) /
                          100;
                      value.taxamount = (value.tbt * value.tax) / 100;
                      value.total = value.tbt + (value.tbt * value.tax) / 100;
                      value.costbt =
                        value.product.buyp * value.quantity -
                        (value.product.buyp *
                          value.quantity *
                          value.product.discount) /
                          100;
                      value.cost =
                        value.costbt + (value.costbt * value.tax) / 100;
                      this.calc();
                    "
                  />
                  %
                </td>
                <td>{{ value.taxamount }}</td>
                <td>{{ value.total }}</td>
                <td>
                  <a href="#" @click.prevent="del(id)"
                    ><i class="bi bi-x-circle-fill"></i
                  ></a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6"></td>
                <td>{{ $t("total") }}</td>
                <td>{{ tbt }}</td>
                <td></td>
                <td>{{ taxamount }}</td>
                <td>{{ total }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- <div class="row mb-2">
          <label class="col-sm-1 col-form-label-sm">{{ $t("branch") }} </label>
          <div class="col-sm-2">
            <select
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="branchno"
            >
              <option v-for="val in branches" :key="val" :value="val.id">
                {{ val.namear }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("add") }} {{ $t("to") }}
          </label>

          <div class="col-sm-1">
            <input
              class="form-check-input"
              type="radio"
              value="project"
              v-model="addto"
            />
            &nbsp;
            <label class="form-check-label" for="gridRadios2">
              {{ $t("project") }}
            </label>
          </div>
          <div class="col-sm-1">
            <input
              class="form-check-input"
              type="radio"
              v-model="addto"
              value="task"
            />
            &nbsp;
            <label class="form-check-label" for="gridRadios2">
              {{ $t("task") }}
            </label>
          </div>
          <div class="row mb-2" v-if="addto == 'project'">
            <label class="col-sm-1 col-form-label-sm"
              >{{ $t("project") }}
            </label>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="projectno"
              >
                <option v-for="val in allprojects" :key="val" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="addto == 'task'">
            <label class="col-sm-1 col-form-label-sm">{{ $t("task") }} </label>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="taskno"
                @change="task"
              >
                <option v-for="val in alltasks" :key="val.id" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
        </div> -->
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("notes") }}
          </label>
          <div class="col-sm-5">
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="notes"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
  title: "Create Purchase Order",
  components: {
    vSelect,
  },
  props: ["css"],
  async mounted() {
    const result3 = await axios.get("/data/allsupplier");
    if (result3.status == 200) {
      this.customers = result3.data.data;
    }
    const result2 = await axios.get("/data/allproducts");
    if (result2.status == 200) {
      this.products = result2.data.data;
    }
    const result4 = await axios.get("/data/allprojects");
    const result5 = await axios.get("/data/alltasks");
    // const result1 = await axios.get("/data/allbranches");
    const result7 = await axios.get("/data/getcostcenter");
    const result6 = await axios.get("/data/purchaseorderID");
    if (result4.status == 200) {
      this.branches = result7.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
      this.allprojects = result4.data.data;
      this.alltasks = result5.data.data;
      // this.branches = result1.data.data;
      this.id = Number(result6.data.data[0].count) + Number(1);
    }
  },
  data() {
    return {
      v$: useValidate(),
      id: "",
      data: [],
      number: "",
      date: "",
      description: "",
      customers: [],
      products: [],
      product: "",
      selection: false,
      customerid: "",
      customername: "",
      customerdata: "",
      taxamount: 0,
      tbt: 0,
      total: 0,
      addto: "",
      allprojects: [],
      alltasks: [],
      taskno: "",
      projectno: "",
      branchno: "",
      duedate: "",
      supplydate: "",
      sdata: "",
      ccenter: false,
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
    };
  },
  validations() {
    return {
      date: { required },
      description: { required },
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllPurchaseOrder",
      });
    },
    calc() {
      this.tbt = this.data.reduce((acc, array) => acc + Number(array.tbt), 0);
      this.taxamount = this.data.reduce(
        (acc, array) => acc + Number(array.taxamount),
        0
      );
      this.total = this.data.reduce(
        (acc, array) => acc + Number(array.total),
        0
      );
    },
    async del(id) {
      this.data.splice(id, 1);
      this.calc();
    },
    task() {
      const project = this.alltasks.filter((x) => x.id == this.taskno)[0]
        .projectid;
      this.projectno = project;
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post("/data/CreatePurchaseOrder", {
          username: store.state.randp.username,
          due: this.date,
          description: this.description,
          duedate: this.duedate,
          supplydate: this.supplydate,
          cid: this.customerdata.id,
          cname: this.customerdata.name,
          cmobile: this.customerdata.mobile,
          cmail: this.customerdata.email,
          ctax: this.customerdata.taxnumber,
          details: JSON.stringify(this.data),
          tbt: this.tbt,
          tax: this.taxamount,
          tat: this.total,
          branch: this.branchno,
          project: this.projectno,
          task: this.taskno,
          notes: this.notes,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "AllPurchaseOrder" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    async sdata() {
      const result3 = await axios.get(`/data/delsupplier/${this.sdata.sid}`);
      if (result3.status == 200) {
        this.customerdata = result3.data.data[0];
        this.customername = this.sdata.name;
        this.customerid = this.sdata.sid;
      }
    },
    addto() {
      this.projectno = null;
      this.taskno = null;
    },
    product() {
      const data2 = this.data.filter((x) => x.pronamear == this.product.namear);
      if (data2.length == 0) {
        this.data.push({
          proid: this.product.pid,
          pronamear: this.product.namear,
          pronameen: this.product.nameen,
          salep: this.product.salep,
          salec: this.product.salec,
          snamear: this.product.snamear,
          snameen: this.product.snameen,
          buyp: this.product.buyp,
          buyc: this.product.buyc,
          bnamear: this.product.bnamear,
          bnameen: this.product.bnameen,
          discount: this.product.discount,
          unittype: this.product.unittype,
          tax: this.product.tax,
          sn: this.product.sn,
          barcode: this.product.barcode,
          product: this.product,
          quantity: 1,
          tbt:
            this.product.buyp * 1 -
            (this.product.buyp * 1 * this.product.discount) / 100,
          taxamount:
            ((this.product.buyp * 1 -
              (this.product.buyp * 1 * this.product.discount) / 100) *
              this.product.tax) /
            100,
          total:
            this.product.buyp * 1 -
            (this.product.buyp * 1 * this.product.discount) / 100 +
            ((this.product.buyp * 1 -
              (this.product.buyp * 1 * this.product.discount) / 100) *
              this.product.tax) /
              100,
        });
        this.calc();
      }
    },
    parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.part1 {
  display: flex;
}
.part11 {
  width: 50%;
  padding: 1%;
}
.ccenter {
  width: 100%;
}
.ccenter button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
}
@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
