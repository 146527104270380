<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#"> {{ $t("Edite") }} {{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ data.namear }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                <a href="#" class="btn-download">
                  <span class="text">Download PDF</span>
                </a>
              </li> -->
        <li>
          <router-link to="/purchases/AllProducts" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i> Back</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main" id="main">
      <div class="head">
        <h3>{{ $t("Edite") }} {{ $t("product") }} - {{ data.namear }}</h3>
      </div>
      <div class="body">
        <div class="body2" id="main2">
          <div class="comment" v-if="data.kind == 1">{{ $t("prodtype1") }}</div>
          <div class="comment" v-if="data.kind == 2">{{ $t("prodtype2") }}</div>
          <div class="comment" v-if="data.kind == 3">{{ $t("prodtype3") }}</div>
          <br />
          <div class="form1">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("ar") }}
              </label>
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.namear"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("name") }} {{ $t("en") }}
              </label>

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.nameen"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("sn") }}
              </label>
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  disabled
                  v-model="data.sn"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("item") }}
              </label>

              <div class="col-sm-4">
                <input
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  disabled
                  v-model="data.item"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">
              </label>

              <div class="col-sm-4"></div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("description") }}
              </label>

              <div class="col-sm-4">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="data.description"
                ></textarea>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("unit type") }}
              </label>

              <div class="col-sm-4">
                <select
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.unittype"
                >
                  <option v-for="val in unit" :key="val" :value="val.unit">
                    {{ $t(val.unit) }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("barcode") }}
              </label>

              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  disabled
                  v-model="data.barcode"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-2">
                <button class="btn btn-primary" type="submit" @click="print">
                  <i class="bi bi-printer-fill"></i> {{ $t("printe") }}
                </button>
              </div>
              <div class="col-sm-3" id="barcode">
                <div style="direction: rtl">
                  <vue3-barcode :value="data.barcode" :height="25" />
                  <div
                    style="
                      font-size: smaller;
                      direction: right;
                      font-weight: bold;
                    "
                  >
                    {{ this.$store.state.cnum[0].cname }} - {{ data.namear }} -
                    {{ data.salep }}
                  </div>
                </div>
                <br />
              </div>
            </div>

            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("tax") }} %
              </label>

              <div class="col-sm-5">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.tax"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("saleprice") }}
              </label>

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.salep"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("sales") }}
              </label>

              <div class="col-sm-2">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  disabled
                  v-model="data.salec"
                />
              </div>
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("buyprice") }}
              </label>

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.buyp"
                />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                >{{ $t("Account") }} {{ $t("cost") }}
              </label>

              <div class="col-sm-2">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  disabled
                  v-model="data.buyc"
                />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("sale") }} {{ $t("discount") }}
              </label>

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.discount"
                />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("minimum") }} {{ $t("balance") }} {{ $t("stock") }}
              </label>

              <div class="col-sm-1">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="inputEmail3"
                  v-model="data.minstock"
                />
              </div>
            </div>
            <br /><br />
            <!-- 
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("image") }} {{ $t("product") }}
              </label>

              <div class="col-sm-5">
                <div id="preview">
                  <img :src="this.url" crossorigin="anonymous" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <button class="btn btn-primary" @click="submit">
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import Vue3Barcode from "vue3-barcode";
import html2pdf from "html2pdf.js";
export default {
  async mounted() {
    const data2 = await axios.get(`/data/productview/${this.$route.params.id}`);
    const result2 = await axios.get("/data/allunittypes");
    if (data2.status == 200) {
      const data = data2.data.data[0];
      this.unit = result2.data.data;
      this.data = data;
      this.rawmaterial = JSON.parse(data.rawmaterial);
      this.url = store.state.imgurl + `/products/${this.data.sn}.png`;
    }
  },
  props: ["css"],
  components: {
    Vue3Barcode,
  },
  data() {
    return {
      v$: useValidate(),
      data: "",
      rawmaterial: "",
      url: "",
      namear: "",
      nameen: "",
      sn: "",
      item: "",
      description: "",
      unittype: "",
      barcode: "",
      tax: "",
      salep: "",
      salec: "",
      snamear: "",
      snameen: "",
      buyp: "",
      buyc: "",
      bnamear: "",
      bnameen: "",
      discount: "",
      stock: "",
      rawmaterialcost: "",
      unit: "",
    };
  },
  validations() {
    return {
      data: { required },
    };
  },
  methods: {
    async submit() {
      const result = await axios.post("/data/productedite", {
        username: store.state.randp.username,
        namear: this.data.namear,
        nameen: this.data.nameen,
        description: this.data.description,
        tax: this.data.tax,
        salep: this.data.salep,
        buyp: this.data.buyp,
        discount: this.data.discount,
        stock: this.data.stock,
        minstock: this.data.minstock,
        unittype: this.data.unittype,
        id: this.$route.params.id,
      });
      if (result.status == 200) {
        // let formData = new FormData();
        // formData.append("file", this.file);
        // axios.patch(`/upload/${this.sn}`, formData);
        this.redirectTo({ val: "AllProducts" });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
        // this.redirectTo({ val: "Home" });
      } else if (result.status == 401) {
        alert("Account Already Created Before");
      }
    },
    pdf() {
      html2pdf(document.getElementById("barcode").innerHTML, {
        margin: 0.2,
        filename: `barcode.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print() {
      var divElements = document.getElementById("barcode").innerHTML;
      var WinPrint = window.open();
      WinPrint.document.write(
        "<html><head><title>Barcode</title></head><body>" +
          divElements +
          "</body>"
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    copy() {
      if (this.copy == true) {
        this.streetsh = this.street;
        this.citysh = this.city;
        this.areash = this.area;
        this.zipcodesh = this.zipcode;
        this.buildingsh = this.building;
        this.countrysh = this.country;
      } else {
        this.streetsh = "";
        this.citysh = "";
        this.areash = "";
        this.zipcodesh = "";
        this.buildingsh = "";
        this.countrysh = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}

a {
  text-decoration: none;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}

.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.head-title .left .breadcrumb li {
  color: #342e37;
}

.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.main {
  direction: v-bind("css.EN");
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}

.head-option ul li {
  display: inline;
  padding: 0.5%;
}

.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  //   display: inline-block;
}

.main .body .part1 {
  width: 60%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  float: v-bind("css.dirn");
}

.main .body .part1 .input-group {
  margin: 1%;
}

.main .body .part1 .input-group span {
  width: 30%;
  font-size: small;
}

.main .body .part2 {
  width: 50%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  float: v-bind("css.dirn");
}

.main .body .part2 .part2head {
  background: rgba(191, 214, 219, 0.534);
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.main .body .part2 .part2body {
  box-shadow: rgba(75, 68, 68, 0.774) 0.25px 0.25px 1px;
  padding: 5%;
}

img {
  border: black 1px solid;
  padding: 0.5%;
  width: 400px;
  height: 200px;
  border-radius: 20px;
}
</style>
