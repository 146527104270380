<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a>{{ $t("branches") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("branch") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                    <a href="#" class="btn-download">
                      <span class="text">Download PDF</span>
                    </a>
                  </li> -->

        <li>
          <router-link to="/AdminDashboard/AllBranches" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("branch") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("name") }} {{ $t("ar") }}
              <span class="error-feedback" v-if="v$.namear.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="namear"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("name") }} {{ $t("en") }}
              <span class="error-feedback" v-if="v$.nameen.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="nameen"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("Account") }} {{ $t("stock") }}
              <span class="error-feedback" v-if="v$.staccount.$error">{{
                "*"
              }}</span></label
            >

            <div class="col-sm-5">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="staccount"
              />
            </div>
          </div>
          <div class="part1" style="width: 50%">
            <div class="part2head">{{ $t("details") }} {{ $t("address") }}</div>
            <br />
            <div class="part2body">
              <div class="row g-3">
                <div class="col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('street')"
                    v-model="street"
                  />
                </div>
                <div class="row g-2">
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('city')"
                      v-model="city"
                    />
                  </div>
                  <div class="col-sm">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('area')"
                      v-model="area"
                    />
                  </div>
                </div>
                <div class="row g-2">
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('zipcode')"
                      v-model="zipcode"
                    />
                  </div>
                  <div class="col-sm">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('building')"
                      v-model="building"
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('country')"
                    v-model="country"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="buttom">
            <button class="buttonconfirm" type="submit" @click="submit">
              {{ $t("save") }}
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="buttoncancel" type="submit" @click="back">
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      namear: "",
      nameen: "",
      staccount: "",
      r2: store.state.randp.r2,
      street: "",
      city: "",
      area: "",
      zipcode: "",
      building: "",
      country: "",
    };
  },
  validations() {
    return {
      namear: { required },
      nameen: { required },
      staccount: { required },
    };
  },
  async mounted() {
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    //   const result = await axios.get("/data/allproductcategoriesparent");
    //   if (result.status == 200) {
    //     this.data = result.data.data;
    //     this.data2 = result.data.data;
    //   }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllBranches",
      });
    },
    async checkname() {
      const result = await axios.get(`/data/productcheckname/${this.name}`);
      if (result.status == 200) {
        const data = result.data.data;
        if (data.length > 0) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      }
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const result = await axios.post("/data/createnewbranch", {
            username: store.state.randp.username,
            namear: this.namear,
            nameen: this.nameen,
            staccount: this.staccount,
            taxnumber: this.taxnumber,
            street: this.street,
            city: this.city,
            area: this.area,
            zipcode: this.zipcode,
            building: this.building,
            country: this.country,
          });
          if (result.status == 200) {
            this.redirectTo({ val: "AllBranches" });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Data Entry successfully",
            });
            // this.redirectTo({ val: "Home" });
          }
        } catch (Error) {
          alert("حدث خطء ");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;

  margin: 0.15%;
}
.head-option .btn-download {
  // width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.part1 {
  //   width: 80%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.buttom {
  text-align: center;
}
</style>
