<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/accounting/costcenter" class="active">{{
              $t("chart of accounts")
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <input type="text" :placeholder="$t('name') + ' ' + $t('Account')" />
          <input
            type="text"
            :placeholder="$t('number') + ' ' + $t('Account')"
          />
        </div>
        <div class="head2">
          <button>
            <router-link to="/accounting/ChartOfAccounts/AddAccount"
              >+ {{ $t("add") }} {{ $t("Account") }}</router-link
            >
          </button>
        </div>
      </div>
      <div class="body">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("name") }} {{ $t("Account") }}</th>
              <th>{{ $t("number") }} {{ $t("Account") }}</th>
              <th>{{ $t("Account") }} {{ $t("parent") }}</th>
              <th>{{ $t("description") }}</th>
              <th style="font-size: xx-small">{{ $t("acceptedpayment") }}</th>
              <th>{{ $t("created") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in data" :key="val">
              <td v-if="val.level == '1'">
                {{ val.namear }}
              </td>
              <td v-if="val.level == '2'" class="level2">
                {{ val.namear }}
              </td>
              <td v-if="val.level == '3'" class="level3">
                {{ val.namear }}
              </td>
              <td v-if="val.level == '4'" class="level4">
                {{ val.namear }}
              </td>
              <td v-if="val.level == '5'" class="level5">
                {{ val.namear }}
              </td>
              <td>
                {{ val.actnum }}
              </td>
              <td>
                {{ val.parent }}
              </td>
              <td>{{ val.description }}</td>
              <td v-if="val.sup == 'false'">
                <i class="bi bi-x"></i>
              </td>
              <td v-if="val.sup == 'true'"><i class="bi bi-check2"></i></td>

              <td>{{ val.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import store from "@/store";
import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      data: [],
      data2: [],
    };
  },
  async mounted() {
    const result = await axios.get("/data/accountingmanual");
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    eslam(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/Suppliers/editesupplier/${row.id}`,
        params: { id: row.id },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.data = this.data2.filter((x) =>
        x.namear.includes(this.name.toLowerCase())
      );
    },
    code() {
      this.data = this.data2.filter((x) =>
        x.actnum.includes(this.code.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}
.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}
.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}
.head1 {
  width: 70%;
}
.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}
.head2 {
  width: 30%;
  text-align: center;
}
.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}
.head2 button a {
  color: white;
}
.table {
  margin-top: 1%;
}
table thead tr {
  border-radius: 5px;
  background-color: #eee;
}
.level2 {
  color: rgba(98, 153, 174, 255);
  font-weight: bold;
  padding-left: 2%;
  padding-right: 2%;
}
.level3 {
  color: rgb(109, 13, 13);
  font-weight: bold;
  padding-left: 4%;
  padding-right: 4%;
}
.level4 {
  color: v-bind("css.color");
  font-weight: bold;
  padding-left: 6%;
  padding-right: 6%;
}
.level5 {
  color: v-bind("css.color");
  font-weight: bold;
  padding-left: 8%;
  padding-right: 8%;
}
</style>
