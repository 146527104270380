<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/tasks" class="active">{{ $t("management") }} {{ $t("tasks") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ $t("create") }} {{ $t("task") }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("task") }}</h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submite">
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="part1">
          <form>
            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-2 col-form-label">{{
                $t("type")
              }}</label>
              <div class="col-sm-5">
                <select class="form-control" id="inputEmail3" v-model="type">
                  <option value="supplyorder">
                    {{ $t("supply order") }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-2 col-form-label"
                >{{ $t("assign") }}
                <span class="error-feedback" v-if="v$.assign.$error">{{
                  "*"
                }}</span></label
              >
              <div class="col-sm-10">
                <select v-model="assign">
                  <option value="accounting">الحسابات</option>
                  <option value="purchases">المشتريات</option>
                </select>
              </div>
            </div> -->
          </form>
        </div>
      </div>
      <div class="body2" v-if="type == 'supplyorder'">
        <h3>{{ $t("supply order") }}</h3>
        <div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("date") }}
              <span class="error-feedback" v-if="v$.date.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <input type="date" class="form-control" id="inputPassword3" v-model="date" />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("ccenter") }}
            </label>
            <div class="col-sm-3" style="display: flex">
              <select v-model="parent" class="form-control">
                <option v-for="x in level1" :key="x" :value="x">
                  {{ x.namear }}
                </option>
              </select>
              <select v-model="parent2" v-if="level2.length > 0" class="form-control">
                <option v-for="x in level2" :key="x" :value="x">
                  {{ x.namear }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{
              $t("product")
            }}</label>
            <div class="col-sm-3">
              <v-select :options="products" label="description" v-model="product" v-if="css.EN == 'rtl'">
                <template #option="option">
                  <div style="text-align: center">
                    <span>{{ option.namear }} - {{ option.sn }} -
                      {{ option.unittype }}
                    </span>
                  </div>
                </template>
              </v-select>
              <v-select :options="products" label="nameen" v-model="product" v-if="css.EN == 'ltr'">
                <template #option="option">
                  <div style="text-align: center">
                    <span>{{ option.nameen }} - {{ option.sn }} -
                      {{ $t(option.unittype) }}
                    </span>
                  </div>
                </template>
              </v-select>
            </div>
          </div>

          <table class="table">
            <thead>
              <tr>
                <th colspan="7">{{ $t("details") }} {{ $t("materials") }}</th>
              </tr>
              <tr>
                <th></th>
                <th>{{ $t("materials") }}</th>
                <th>{{ $t("code") }}</th>
                <th>{{ $t("unit") }}</th>
                <th>
                  {{ $t("quantity") }}
                </th>
                <th>{{ $t("comment") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(x, id) in data" :key="x">
                <td>{{ id + 1 }}</td>
                <td v-if="css.EN == 'rtl'">
                  {{ x.namear }}
                </td>
                <td v-if="css.EN == 'ltr'">
                  {{ x.nameen }}
                </td>
                <td>
                  {{ x.sn }}
                </td>
                <td>
                  {{ $t(x.unittype) }}
                </td>
                <td style="width: 15%;">
                  <input type="number" v-model="x.quantity" @input="convertArabicToEnglish(id)"
                    style="width: 100%; border: 1px solid black" />
                </td>
                <td>
                  <input style="width: 100%" type="text" v-model="x.comment" />
                </td>
                <td>
                  <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from "moment";

export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      date: moment().format("YYYY-MM-DD"),
      assign: "purchases",
      mobile: "",
      code: "",
      data: [],
      type: "supplyorder",
      products: [],
      product: {},
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
      costn: "",
      costname: "",
      address: "",
      dep: store.state.dep,
    };
  },
  validations() {
    return {
      date: { required },
      assign: { required },
    };
  },
  async mounted() {
    if (store.state.typeofuser != "customer") {
      const result2 = await axios.get(`/data/allproducts`);
      if (result2.status == 200) {
        this.products = result2.data.data;
      }
    }
    if (store.state.typeofuser == "customer") {
      const result2 = await axios.get(
        `/reports/allproductsforcustomer/${this.dep}`
      );
      if (result2.status == 200) {
        this.products = result2.data.data;
      }
    }

    const result7 = await axios.get("/data/getcostcenter");
    if (result7.status == 200) {
      this.branches = result7.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
    }
  },
  methods: {
    async del(id) {
      this.data.splice(id, 1);
      this.calc();
    },
    submite() {
      for (let i = 0; i < this.data.length; i++) {
        const element = this.data[i];
        if (
          element.quantity == 0 ||
          element.quantity == null ||
          isNaN(element.quantity)
        ) {
          alert("برجاء مراجعة كميات المنتجات");
          break;
        } else {
          this.v$.$validate();
          if (!this.v$.$error) {
            Swal.fire({
              title: this.$t("create") + " " + this.$t("task"),
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.$t("confirm"),
            }).then(async (result) => {
              if (result.isConfirmed) {
                const action = await axios.post("/data/createtask", {
                  date: this.date,
                  type: this.type,
                  assign: this.assign,
                  data: JSON.stringify(this.data),
                  username: store.state.randp.username,
                  costn: this.costn,
                  costname: this.costname,
                  address: this.address,
                  description: `طلب مقدم من ${this.$store.state.fullname}`,
                });
                if (action.status == 200) {
                  const tid = action.data.data[0].tid;
                  Swal.fire(
                    this.$t("create") + " " + this.$t("task") + " " + tid
                  );
                  this.redirectTo({ val: "taskshome" });
                }
              }
            });
          }
        }
      }
    },
    convertArabicToEnglish(id) {
      const arabicToEnglishMap = {
        '٠': '0',
        '١': '1',
        '٢': '2',
        '٣': '3',
        '٤': '4',
        '٥': '5',
        '٦': '6',
        '٧': '7',
        '٨': '8',
        '٩': '9'
      };

      let newValue = this.data[id].quantity;

      for (const [arabic, english] of Object.entries(arabicToEnglishMap)) {
        newValue = newValue.replace(new RegExp(arabic, 'g'), english);
      }

      this.data[id].quantity = newValue;
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    parent() {
      this.level2 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent.actnum) &&
          x.actnum != this.parent.actnum
      );
      this.costname = this.parent.namear;
      this.costn = this.parent.actnum;
    },
    parent2() {
      this.costname = this.parent2.namear;
      this.costn = this.parent2.actnum;
    },
    ccenter() {
      if (this.ccenter == false) {
        this.costname = "";
        this.costn = "";
      }
    },
    type() {
      if (this.type == "supplyorder") {
        this.assign = "purchases";
      }
    },
    product() {
      const filter = this.data.filter((x) => x.sn == this.product.sn);
      if (filter.length == 0) {
        this.data.push({
          namear: this.product.namear,
          nameen: this.product.nameen,
          sn: this.product.sn,
          unittype: this.product.unittype,
          quantity: "",
          minstock: this.product.minstock,
        });
      } else {
        alert("تم اضافة المنتج من قبل");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 2%;
  padding: 5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.body {
  display: flex;
  margin: 1%;
}

.part1 {
  width: 50%;
}

.body2 {
  width: 100%;
  padding: 3%;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
}

table input {
  text-align: center;
  width: 50px;
  border: none;
}

@media screen and (max-width: 576px) {
  .page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
  font-size: x-small;
}
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: x-small;
  }
}
</style>
