<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#">{{ $t("reports") }}</a>
        </li>
        <li>-</li>
        <li class="active">
          <a> {{ $t("dues") }} {{ $t("delegates") }} </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("from") }} :</label
          >
        </div>
        <div class="col-auto">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="from"
          />
        </div>
      </div>
      &nbsp;&nbsp;
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("to") }} :</label
          >
        </div>
        <div class="col-auto">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="to"
          />
        </div>
      </div>

      <div class="col">
        <button class="btn btn-primary" @click="submit">
          {{ $t("search") }} <i class="bi bi-search"></i>
        </button>
        &nbsp;
        <button class="btn btn-light">
          {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
        </button>
      </div>
    </div>
    <div class="body" v-if="this.alldata.length == 0">برجاء اختيار التاريخ</div>
    <div class="body" v-if="this.alldata.length > 0" id="main">
      <div :style="{ direction: css.EN }">
        <img
          src="../../assets/logo.png"
          :style="{ float: css.xdirn, width: '10%' }"
        />
        <h3>{{ $t("dues") }} {{ $t("delegates") }}</h3>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("name") }}</th>
              <th>
                {{ $t("numbers") }} ({{ $t("orders") }} {{ $t("to") }}
                {{ $t("customer") }})
              </th>
              <th>
                {{ $t("numbers") }} ({{ $t("orders") }} {{ $t("to") }}
                {{ $t("store") }})
              </th>
              <th>{{ $t("amount") }}</th>
              <th>{{ $t("advance payment") }}</th>
              <th>{{ $t("discount") }}</th>
              <th>{{ $t("installment") }}</th>
              <th>{{ $t("total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in alldata" :key="i">
              <td>{{ i.name }}</td>
              <td>{{ i.tocustomer }}</td>
              <td>{{ i.toclient }}</td>
              <td>
                {{ Number(i.tocustomeramount) + Number(i.toclientamount) }}
              </td>
              <td>{{ i.delegatedues }}</td>
              <td>{{ i.discount }}</td>

              <td>{{ i.car }}</td>
              <td>
                {{
                  Number(i.tocustomeramount) +
                  Number(i.toclientamount) -
                  i.delegatedues -
                  i.car -
                  i.discount
                }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="7">{{ $t("total") }}</td>
              <td>{{ total }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <button
      class="btn btn-primary"
      v-if="this.alldata.length > 0"
      type="submit"
      @click="pdf"
    >
      <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
    </button>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import store from "@/store";
import html2pdf from "html2pdf.js";
export default {
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: "",
      todate: "",
      alldata: [],
      from: "",
      to: "",
      r12: JSON.parse(store.state.randp.r12),
      total: 0,
      date: "",
      discount: 0,
      discount2: 0,
    };
  },
  async mounted() {
    if (this.r12.create != true) {
      this.redirectTo({ val: "Home" });
    }
  },
  methods: {
    async submit() {
      const result = await axios.get(
        `/reports/delegtesdues/${this.from}/${this.to}`
      );
      if (result.status == 200) {
        this.alldata = result.data.data;
        this.fromdate = this.from;
        this.todate = this.to;
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(this.from);
        const secondDate = new Date(this.to);
        this.date = Math.round(Math.abs((firstDate - secondDate) / oneDay) + 1);
        if (this.from == this.to) {
          this.date = 1;
        }
        for (let i = 0; i < this.date; i++) {
          // console.log(moment(this.datefrom).add(i,'days').format("dddd"));
          const day = moment(this.from).add(i, "days").format("dddd");
          if (day == "Friday") {
            // this.date = this.date - 1;
          }
        }
        this.discount = this.date * 68;
        this.discount2 = Number(this.date * 23.33).toFixed(0);
        const one = this.alldata.reduce(
          (acc, array) => acc + Number(array.tocustomeramount),
          0
        );
        const two = this.alldata.reduce(
          (acc, array) => acc + Number(array.toclientamount),
          0
        );
        const three = this.alldata.reduce(
          (acc, array) => acc + Number(array.delegatedues),
          0
        );
        const four = this.alldata.reduce(
          (acc, array) => acc + Number(array.car),
          0
        );
        const five = this.alldata.reduce(
          (acc, array) => acc + Number(array.discount),
          0
        );
        this.total = Number(one) + Number(two) - three - four - five;
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: `delegatedues.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
  },
  watch: {
    filter() {
      this.accounts = this.accounts.filter((x) =>
        x.namear.includes(this.filter.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
  color: black;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: #3c91e6;
  pointer-events: unset;
}

.main {
  margin: 1%;
}
.main .head {
  border-top: #3c91e6 5px solid;
  background: #040531;
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 1%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  text-align: center;
}

.col {
  padding-left: 2%;
  padding-right: 2%;
}
tr {
  text-align: v-bind("css.dirn");
}
.table {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  background-color: white;
}
.child {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  background-color: #d6d6d6;
}
.childname {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #d6d6d6;
}

.table {
  padding: 3%;
}
.table thead th {
  background-color: #c6d7e9;
}
tfoot {
  background-color: #dfdddd;
}
</style>
