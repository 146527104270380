<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("Property") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allproperties" class="active"
              >{{ $t("all") }} {{ $t("offer") }} {{ $t("price") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("offer") }} {{ $t("price") }}</h3>
          <input
            type="text"
            :placeholder="$t('code')"
            v-model="realtyname"
            @keypress.enter="filter"
          />
          <input
            type="text"
            :placeholder="$t('name') + ' ' + $t('realty')"
            v-model="ownername"
            @keypress.enter="filter2"
          />
          <!-- <input type="text" :placeholder="$t('mobile')" /> -->
        </div>
        <div class="head2">
          <button>
            <router-link to="/property/createpriceoffer" class="btn-download">
              <span class="text">
                <i class="bi bi-clipboard-plus-fill"></i>
                {{ $t("add") }} {{ $t("offer") }} {{ $t("price") }}</span
              >
            </router-link>
          </button>
        </div>
      </div>
      <div class="body">
        <!-- <router-link class="btn-download" v-for="x in property" :key="x" :to="link + x.pid">
          <div class="part">
            <div class="group">
              <div style="text-align: center">{{ x.name }}</div>
              <div style="font-size: small">
                {{ $t("owner") }} : {{ x.ownername }}
              </div>
            </div>
            <div class="group">
              <img :src="this.url + x.pid + '-' + this.cnum + '.png'"
                @error="$event.target.src = `${this.url}Default.png`" crossorigin="anonymous" />
            </div>
          </div>
        </router-link> -->

        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("name") }} {{ $t("realty") }}</th>
              <th>{{ $t("name") }} {{ $t("renter") }}</th>
              <th>{{ $t("unit") }}</th>
              <th>{{ $t("type") }} {{ $t("contract") }}</th>
              <th>{{ $t("amount") }}</th>
              <th>{{ $t("payment") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in property" :key="x">
              <th>
                <router-link :to="link + x.qid">
                  {{ x.qid }}
                </router-link>
              </th>
              <th>
                <router-link :to="link + x.qid">{{
                  x.propertyname
                }}</router-link>
              </th>
              <th>
                <router-link :to="link + x.qid">{{ x.rentername }}</router-link>
              </th>
              <td>{{ x.unite }}</td>
              <td>{{ $t(x.conttype) }}</td>
              <td>
                {{
                  Number((x.contamount / 12) * x.contduration).toLocaleString()
                }}
              </td>
              <td>
                <span v-if="x.contduration == 1">شهرى</span>
                <span v-if="x.contduration == 3">ربع سنوي</span>
                <span v-if="x.contduration == 6">نصف سنوى</span>
                <span v-if="x.contduration == 12">سنويا</span>
              </td>
              <td>
                <!-- Button trigger modal -->
                <button
                  type="button"
                  data-bs-toggle="modal"
                  style="width: 100%"
                  :data-bs-target="'#exampleModal' + x.qid"
                  @click="getdata(x)"
                >
                  <i class="bi bi-printer-fill"></i>
                </button>

                <!-- Modal -->
                <div
                  class="modal fade"
                  :id="'exampleModal' + x.qid"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          عرض سعر
                        </h5>
                      </div>
                      <div class="modal-body" style="padding: 6%" id="main">
                        <div style="direction: rtl">
                          <!-- <div class="modhead">
                            <div class="part">
                              عرض أسعار <br />
                              Quote
                            </div>
                            <div class="logo">
                              <img :src="this.url" crossorigin="anonymous" />
                            </div>
                          </div> -->
                          <br />
                          <br />
                          <br />

                          <div class="modhead">
                            <div class="part">
                              {{ $t("number") }} : {{ x.qid }} <br />
                              {{ $t("date") }} : {{ x.date }}
                            </div>
                          </div>
                          <div style="padding: 1%">
                            السادة : {{ x.rentername }} <br />
                            السلام عليكم ورحمة الله وبركاتة <br />
                            تحية طيبة وبعد ,,,, <br />
                            إشارة الى رغبتكم فى إيجار {{ unitetype }} -
                            {{ x.propertyname }} - رقم {{ x.unite }}
                          </div>
                          <div style="padding: 1%; display: flex">
                            <div class="part" style="width: 10%">
                              {{ $t("notes") }}
                            </div>
                            <div class="part" style="width: 90%">
                              {{ x.notes }}
                            </div>
                          </div>

                          <div style="padding: 2%">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>نوع العقار</th>
                                  <th>الاستخدام</th>
                                  <th>اجمالى المساحة</th>
                                  <th>سعر المتر المربع</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ unitetype }}</td>
                                  <td>{{ $t(x.conttype) }}</td>
                                  <td>{{ unitspace }}</td>
                                  <td>{{ unitspacep }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div style="padding: 2%">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>{{ $t("details") }}</th>
                                  <th>{{ $t("amount") }}</th>
                                  <th>{{ $t("tax") }}</th>
                                  <th>{{ $t("total") }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    القسط الاول لمده
                                    <span v-if="due == 1">شهر</span>
                                    <span v-if="due == 4">3 شهور</span>
                                    <span v-if="due == 6"> 6 شهور</span>
                                    <span v-if="due == 12"> سنة</span>
                                  </td>
                                  <td>
                                    {{ Number(dueamount).toLocaleString() }}
                                  </td>
                                  <td>{{ Number(tax).toFixed(2) }}</td>
                                  <td>
                                    {{
                                      Number(
                                        (x.contamount / 12) * x.contduration
                                      ).toLocaleString()
                                    }}
                                  </td>
                                </tr>
                                <tr v-for="y in commission" :key="y">
                                  <td>{{ y.name }}</td>
                                  <td>
                                    {{ Number(y.comamount).toLocaleString() }}
                                  </td>
                                  <td>
                                    {{
                                      Number(
                                        (y.comamount * y.tax) / 100
                                      ).toFixed(2)
                                    }}
                                  </td>
                                  <td>
                                    {{ Number(y.amount).toLocaleString() }}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td>الاجمالى</td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {{ Number(totalamount).toLocaleString() }}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div>
                            نأمل منكم التكرم بسداد المبلغ الموضح أعلاه بواسطة
                            شيك او حوالة بنكية او اية طريقة ترونها مناسبة علما
                            بانة معلومات الحساب البنكى كالاتى :
                            <br />
                            {{ this.$store.state.cnum[0].cname }} <br />
                            SA0980000101608013332224 مصرف الراجحى <br />
                            SA1305000068201333222000 مصرف الانماء <br />
                            SA7215000427122878050001 بنك البلاد <br /><br />
                            ولكم جزيل الشكر ,,,,,
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          {{ $t("cancel") }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="pdf"
                        >
                          <i class="bi bi-printer-fill"></i> {{ $t("print") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
export default {
  props: ["css"],
  components: {},
  data() {
    return {
      cnum: store.state.cnum[0].cnum,
      property: [],
      property2: [],
      suppliers: 0,
      invoices: 0,
      bills: 0,
      salles: "",
      url: "",
      link: "/property/propertypriceofferview/",
      realtyname: "",
      ownername: "",
      commission: [],
      due: "",
      dueamount: "",
      tax: "",
      unitetype: "",
      unitspace: "",
      protype: "",
      totalamount: "",
      unitspacep: "",
    };
  },
  async mounted() {
    const result = await axios.get("/data/getallpropertypriceoffer");
    if (result.status == 200) {
      this.property = result.data.data;
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  async created() {},
  methods: {
    filter() {
      this.property = this.property2.filter((x) =>
        x.name.includes(this.realtyname)
      );
    },
    filter2() {
      this.property = this.property2.filter((x) =>
        x.ownername.includes(this.ownername)
      );
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: `عرض سعر.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    getdata(x) {
      this.commission = JSON.parse(x.commission);
      this.unite = JSON.parse(x.unitedetails);
      this.due = this.unite.due;
      this.unitetype = this.unite.type;
      const amount = (x.contamount / 12) * x.contduration;
      if (x.conttype == "residential") {
        this.dueamount = amount;
        this.tax = 0;
      }
      if (x.conttype == "commercial") {
        const tax = ((amount / 1.15) * 15) / 100;
        this.dueamount = amount - tax;
        this.tax = tax;
      }

      this.unitspace = this.unite.unitspace;
      this.unitspacep = this.unite.unitspacep;
      this.protype = this.unite.protype;
      const totalcommission = this.commission.reduce(
        (acc, array) => acc + Number(array.amount),
        0
      );
      this.totalamount = Number(
        Number(amount) + Number(totalcommission)
      ).toFixed(2);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
  width: 50%;
  //   border: black 1px solid;
}

.part img {
  width: 100%;
  height: 100px;
  //   border: black 1px solid;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 30%;
}

.head2 button a {
  color: white;
}

.modhead {
  display: flex;
}

.modhead .part {
  width: 75%;
  padding-top: 5%;
  font-size: large;
}

.modhead .logo {
  width: 15%;
}

.modhead img {
  width: 100%;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 25%;
}
</style>
