<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#" class="active">{{ $t("Property") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active">{{ $t("Account Statement") }} {{ $t("owner") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("owner") }}</span> &nbsp;&nbsp;
        <v-select
          :options="accounts"
          label="name"
          v-model="item"
          style="background-color: white; color: black; width: 30%"
        >
          <template #option="option">
            <span style="color: black"> {{ option.name }}</span>
          </template> </v-select
        >&nbsp;&nbsp;
        {{ $t("from") }}
        <input type="date" :placeholder="$t('from')" v-model="from" />
        {{ $t("to") }}
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
    </div>

    <div class="body" v-if="this.view == 0">برجاء اختيار اسم العميل</div>
    <div class="body" v-if="this.view != 0" id="main">
      <!-- <img :src="this.url" crossorigin="anonymous" /> -->
      <br />
      <div style="padding: 2%; direction: rtl; font-size: small">
        <h4>
          {{ $t("Account Statement") }} {{ $t("owner") }} -
          <span v-if="css.lang == 'ar'">{{ namear }}</span
          ><span v-if="css.lang == 'en'">{{ nameen }}</span> {{ view }}
        </h4>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <tbody>
            <tr>
              <th>
                {{ $t("total") }} {{ $t("amount") }} {{ $t("contracts") }}
              </th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{{ allcontract }}</td>
            </tr>
          </tbody>
        </table>

        <h6>{{ $t("balance") }} {{ $t("opening") }} : {{ openbalance }}</h6>
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("namear") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("time") }}</th>
              <th>{{ $t("description") }}</th>
              <th>{{ $t("debit") }}</th>
              <th>{{ $t("credit") }}</th>
              <th>{{ $t("balance") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in data1" :key="i">
              <td>
                <router-link to="">{{ i.journalid }}</router-link>
              </td>
              <td>{{ i.namear }}</td>
              <td>{{ i.date }}</td>
              <td>{{ i.time }}</td>
              <td>{{ i.description }}</td>
              <td>
                {{ i.dpt }}
              </td>
              <td>
                {{ i.crd }}
              </td>
              <td>{{ i.balance }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">{{ $t("total") }}</td>
              <td>{{ totaldpt }}</td>
              <td>{{ totalcrd }}</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td>{{ $t("balance") }} {{ $t("final") }}</td>
              <td>{{ totalbalance }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="buttom">
      <button
        class="buttonconfirm"
        type="submit"
        v-if="this.view != 0"
        @click="pdf"
      >
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import store from "@/store";
export default {
  components: {
    vSelect,
  },
  async mounted() {
    const result2 = await axios.get("/data/allcustomer");
    if (result2.status == 200) {
      const cust = result2.data.data;
      this.accounts = cust.filter((x) => x.type == "owner");
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: "",
      todate: "",
      data1: [],
      view: "",
      namear: "",
      nameen: "",
      typeact: "",
      accounts: [],
      openbalance: "",
      totalbalance: 0,
      totaldpt: 0,
      totalcrd: 0,
      from: "",
      to: "",
      url: "",
      renteraccount: "",
      totalannualrent: 0,
      totalcom: 0,
      totalamount: 0,
      remaining: 0,
      allcontract: 0,
      taxaccount:""
    };
  },
  methods: {
    async submit() {
      const result = await axios.get(
        `/reports/ownerstatement/${this.from}/${this.to}/${this.item.cid}/${this.renteraccount}/${this.taxaccount}`
      );
      if (result.status == 200) {
        this.allcontract = result.data.data3[0].amount;
        this.view = this.item.actnum;
        this.fromdate = this.from;
        this.todate = this.to;
        this.namear = this.item.name;
        this.nameen = this.item.name;
        const data2 = result.data.data[0];
        const data = result.data.data2;
        this.openbalance = data2.dpt - data2.crd;
        this.data1 = [];
        this.totalbalance = 0;
        this.totalbalance = Number(data2.crd - data2.dpt).toFixed(2);
        this.totaldpt = Number(
          data.reduce((acc, array) => acc + Number(array.dpt), 0)
        ).toFixed(2);
        this.totalcrd = Number(
          data.reduce((acc, array) => acc + Number(array.crd), 0)
        ).toFixed(2);
        this.data1.push({
          description: "رصيد افتتاحى",
          date: this.fromdate,
          balance: this.totalbalance,
        });
        for (let i = 0; i < this.data1.length; i++) {
          this.totalbalance = Number(
            Number(this.totalbalance) +
              Number(data[i].crd) -
              Number(data[i].dpt)
          ).toFixed(2);
          this.data1.push({
            journalid: data[i].journalid,
            namear: data[i].namear,
            description: data[i].description,
            date: data[i].trandate,
            time: data[i].time,
            dpt: Number(data[i].dpt),
            crd: Number(data[i].crd),
            balance: Number(this.totalbalance),
          });
        }
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.1,
        filename: `Statement-${this.item.name}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print2() {
      this.$htmlToPaper("main");
    },
  },
  watch: {
    item() {
      this.renteraccount = JSON.parse(this.item.account).actnum;
      this.taxaccount = JSON.parse(this.item.taxaccount).actnum;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
