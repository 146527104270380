<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link to="/accounting/home" class="active">{{
            $t("accounting")
          }}</router-link>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#">
            {{ $t("summary") }} {{ $t("General Ledger") }}
            {{ $t("general") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" @change="getdata" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" @change="getdata" />
        <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
      </div>
      <div class="head2"></div>
    </div>
    <div class="body">
      <br />
      <h3>{{ $t("summary") }} {{ $t("General Ledger") }}</h3>
      <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("Account") }}</th>
            <th>{{ $t("dpt") }}</th>
            <th>{{ $t("crd") }}</th>
            <th>{{ $t("balance") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data1" :key="i">
            <td v-if="i.sup == 'true'" class="childname">
              <router-link to="" @click="summary(i)">
                {{ i.actnum }} -
                <span v-if="css.lang == 'ar'">{{ i.namear }}</span><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
              </router-link>
            </td>
            <td v-if="i.sup == 'false'">
              {{ i.actnum }} -
              <span v-if="css.lang == 'ar'">{{ i.namear }}</span><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
            </td>
            <td v-if="i.sup == 'true'" class="child">
              {{ Number(i.dpt).toFixed(2) }}
            </td>
            <td v-if="i.sup == 'false'"></td>
            <td v-if="i.sup == 'true'" class="child">
              {{ Number(i.crd).toFixed(2) }}
            </td>
            <td v-if="i.sup == 'false'"></td>
            <td v-if="i.sup == 'true'" class="child">
              {{ Number(i.balance).toFixed(2) }}
            </td>
            <td v-if="i.sup == 'false'"></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{{ $t("total") }}</td>
            <td>{{ Number(totaldpt).toFixed(2) }}</td>
            <td>{{ Number(totalcrd).toFixed(2) }}</td>
            <td>{{ Number(total).toFixed(2) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  async mounted() {
    const result2 = await axios.get("/data/getcostcenter");
    const result = await axios.get(
      `/reports/generalledgersummary/${this.fromdate}/${this.todate}`
    );
    if (result.status == 200) {
      this.branches = result2.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
      this.data1 = result.data.data;
      const data2 = this.data1.filter((x) => x.sup == "true");
      this.totaldpt = data2.reduce((acc, array) => acc + Number(array.dpt), 0);
      this.totalcrd = data2.reduce((acc, array) => acc + Number(array.crd), 0);
      this.total = Number(this.totaldpt - this.totalcrd).toFixed(2);
    }
  },
  props: ["css"],
  data() {
    return {
      fromdate: moment().startOf("year").format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      from: moment().startOf("year").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
    };
  },
  methods: {
    async getdata() {
      (this.parent = ""), (this.parent2 = "");
      const result = await axios.get(
        `/reports/generalledgersummary/${this.from}/${this.to}`
      );
      if (result.status == 200) {
        this.fromdate = this.from;
        this.todate = this.to;
        this.data1 = result.data.data;
        const data2 = this.data1.filter((x) => x.sup == "true");
        this.totaldpt = data2.reduce(
          (acc, array) => acc + Number(array.dpt),
          0
        );
        this.totalcrd = data2.reduce(
          (acc, array) => acc + Number(array.crd),
          0
        );
        this.total = data2.reduce(
          (acc, array) => acc + Number(array.balance),
          0
        );
      }
    },
  },
  watch: {
    async parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
      const result = await axios.get(
        `/reports/generalledgersummarycenter/${this.from}/${this.to}/${this.parent}`
      );
      if (result.status == 200) {
        this.fromdate = this.from;
        this.todate = this.to;
        this.data1 = result.data.data;
        const data2 = this.data1.filter((x) => x.sup == "true");
        this.totaldpt = data2.reduce(
          (acc, array) => acc + Number(array.dpt),
          0
        );
        this.totalcrd = data2.reduce(
          (acc, array) => acc + Number(array.crd),
          0
        );
        this.total = data2.reduce(
          (acc, array) => acc + Number(array.balance),
          0
        );
      }
    },
    async parent2() {
      const result = await axios.get(
        `/reports/generalledgersummarycenter/${this.from}/${this.to}/${this.parent2}`
      );
      if (result.status == 200) {
        this.fromdate = this.from;
        this.todate = this.to;
        this.data1 = result.data.data;
        const data2 = this.data1.filter((x) => x.sup == "true");
        this.totaldpt = data2.reduce(
          (acc, array) => acc + Number(array.dpt),
          0
        );
        this.totalcrd = data2.reduce(
          (acc, array) => acc + Number(array.crd),
          0
        );
        this.total = data2.reduce(
          (acc, array) => acc + Number(array.balance),
          0
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
