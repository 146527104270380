<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a class="active" href="#"> {{ $t("roles") }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="head-option">
            <ul>
                <li>
                    <router-link to="/AdminDashboard/roles/create" class="btn-download">
                        <span class="text">
                            <i class="bi bi-clipboard-plus-fill"></i> {{ $t("add") }}
                            {{ $t("roles") }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="main">
            <div class="head">
                <h3>{{ $t("users") }}</h3>
            </div>
            <div class="body">
                <div class="row">
                    <div class="col-sm-2">
                        <input type="text" class="form-control form-control-sm" :placeholder="$t('name')"
                            v-model="name" />
                    </div>


                    <div class="col-sm-2">
                        <button class="btn btn-primary" @click="reset">
                            {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
                        </button>
                    </div>
                </div>
                <div>
                    <table-lite :is-static-mode="true" :columns="table.columns" :rows="table.rows"
                        :total="table.totalRecordCount" :sortable="table.sortable" @row-delete="rowDelete"
                        @row-edite="edit" @row-view="details"></table-lite>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, computed } from "vue";
import TableLite from "../../components/TableLite.vue";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
    async mounted() {
        if (this.typeofuser != "Admin") {
            this.redirectTo({ val: "Home" });
        }
    },
    props: ["css"],
    components: { TableLite },
    data() {
        return {
            typeofuser: store.state.typeofuser,
            name: ""
        };
    },
    setup() {
        // Table config
        const table = reactive({
            columns: [
                {
                    label: "#",
                    field: "id",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "name",
                    field: "name",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "option",
                    field: "quick",
                    width: ".5%",
                    display: function () {
                        return `<button class="btn btn-light">
                            <i class="bi bi-pencil-square"></i></button>`;
                    },
                },
            ],
            rows: [],
            data: [],
            totalRecordCount: computed(() => {
                return table.rows.length;
            }),
            sortable: {
                order: "id",
                sort: "asc",
            },
        });
        const rowDelete = async (row) => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const del = await axios.delete(`/data/deluser/${row.id}`);
                    if (del.status == 200) {
                        const indexOfObject = table.rows.findIndex((object) => {
                            return object.id === row.id;
                        });
                        table.rows.splice(indexOfObject, 1);
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    }
                }
            });
        };
        const rowEdit = () => {
            // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
        };
        const rowView = (row) => {
            console.log("Row rowView!", row);
        };
        /**
         * Table search event
         */
        const doSearch = (offset, limit, order, sort) => {
            table.isLoading = true;
            // Start use axios to get data from Server
            let url = "/roles";
            axios.get(url).then((response) => {
                console.log(response.data.meta.total);
                table.rows = response.data.data;
                table.data = response.data.data;
                table.totalRecordCount = response.data.meta.total;
                table.sortable.order = order;
                table.sortable.sort = sort;
            });
        };

        // Get data first
        doSearch(0, 10, "id", "asc");
        return {
            table,
            rowDelete,
            rowEdit,
            rowView,
        };
    },
    methods: {
        edit(row) {
            this.$root.$router.push({
                path: `/AdminDashboard/roles/${row.id}`,
                params: { id: row.id },
            });
        },
        details(row) {
            this.$root.$router.push({
                path: `/AdminDashboard/user/userdetails/${row.id}`,
                params: { id: row.id },
            });
        },
        async search() {
            const result = await axios.post(`/data/customersearch`, {
                name: this.name,
                email: this.email,
                code: this.code,
                mobile: this.mobile,
                categorie: this.categorie,
            });
            if (result.status == 200) {
                this.table.rows = result.data.data;
            }
        },
        async bycode() {
            const result = await axios.get(`/data/delcustomer/${this.code}`);
            if (result.status == 200) {
                this.table.rows = result.data.data;
            }
        },
        reset() {
            this.name = "";
        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
        name() {
            this.table.rows = this.table.data.filter(
                (x) =>
                    x.fullname.includes(this.name) && x.typeofuser.includes(this.title)
            );
        }

    },
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

a {
    text-decoration: none;
}

.head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    flex-wrap: wrap;
}

.head-title .left h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #342e37;
}

.head-title .left .breadcrumb {
    display: flex;
    align-items: center;
    grid-gap: 16px;
}

.head-title .left .breadcrumb li {
    color: #342e37;
}

.head-title .left .breadcrumb li a {
    color: #aaaaaa;
    pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
    color: v-bind("css.color");
    pointer-events: unset;
}

.head-option {
    direction: v-bind("css.AR");
    width: 100%;
    // border: #342e37 1px solid;
    padding: 0.25%;
}

.head-option ul li {
    display: inline;
    padding: 0.25%;
}

.head-option .btn-download {
    padding-right: 0.5%;
    padding-left: 0.5%;
    border-radius: 5px;
    background: v-bind("css.color");
    color: #f9f9f9;
}

.main .head {
    border-top: v-bind("css.color") 5px solid;
    background: v-bind("css.color");
    color: #f9f9f9;
    border-radius: 3px 3px 0px 0px;
    padding: 0.25%;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
    background: v-bind("css.light");
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.table {
    text-align: center;
}

.table tr {
    background: rgba(191, 214, 219, 0.534);
    line-height: 5px;
}

button {
    margin: 1%;
}

.table button :hover {
    background: black;
    color: white;
    padding: 50%;
}

.bg-gold {
    background: gold !important;
}

.bg-gray {
    background: gray !important;
}

.color-red {
    color: red !important;
}
</style>
