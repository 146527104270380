<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/sales/home" class="active">{{
              $t("sales")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/sales/AllInvoices" class="active">{{ $t("invoices") }} {{ $t("sales") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">
              {{ $t("invoice") }} {{ $t("sales") }} - INV-{{
                alldata2.invid
              }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            {{ $t("invoice") }} {{ $t("sales") }} - INV-{{ alldata2.invid }}
          </h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" @click="Edite" v-if="alldata2.review == 'waiting'"
            style="background-color: red;">
            <i class="bi bi-file-check-fill"></i> {{ $t("Edite") }}
          </button>
          <button class="buttonconfirm" type="submit" @click="confirm" v-if="alldata2.review == 'waiting'">
            <i class="bi bi-file-check-fill"></i> {{ $t("confirm") }}
          </button>
          <button class="buttonconfirm" type="submit" @click="print2">
            <i class="bi bi-printer-fill"></i> {{ $t("printer") }}
          </button>
          <button class="buttonconfirm" type="submit" @click="pdf">
            <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
          </button>
          <button class="buttoncancel" type="submit" @click="back">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="part1">
          <div class="part11">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }}
              </label>
              <div class="col-sm-7">
                <div class="form-control form-control-sm">
                  INV-{{ alldata2.invid }}
                </div>
              </div>
            </div>
            <div class="row mb-2" v-if="alldata2.qte != null">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("offer") }} {{ $t("price") }}
              </label>
              <div class="col-sm-7">
                <div class="form-control form-control-sm">
                  QTE-{{ alldata2.qte }}
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
              </label>
              <div class="col-sm-7">
                <div class="form-control form-control-sm">
                  {{ alldata2.due }}
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("description") }}
              </label>
              <div class="col-sm-7">
                <div class="form-control form-control-sm">
                  {{ alldata2.description }}
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("due") }}
              </label>
              <div class="col-sm-7">
                <div class="form-control form-control-sm">
                  {{ alldata2.duedate }}
                </div>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("supply") }}
              </label>
              <div class="col-sm-7">
                <div class="form-control form-control-sm">
                  {{ alldata2.supplydate }}
                </div>
              </div>
            </div>
          </div>

          <div class="part11">
            <h4>{{ $t("data") }} {{ $t("customer") }}</h4>

            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }}
              </label>
              <div class="col-sm-7">
                {{ alldata2.cname }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("mobile") }}
              </label>
              <div class="col-sm-7">
                {{ alldata2.cmobile }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("email") }}
              </label>
              <div class="col-sm-7">
                {{ alldata2.cmail }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("tax") }}
              </label>
              <div class="col-sm-7">
                {{ alldata2.ctax }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("address") }}
              </label>
              <div class="col-sm-7">
                {{ alldata2.caddress }}
              </div>
            </div>
          </div>
        </div>
        <div class="ccenter">
          <div>
            <span>{{ $t("ccenter") }}</span>
            <input type="text" disabled v-model="alldata2.costname" />
          </div>
        </div>
        <br />
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("product") }}
          </label>
          <div class="col-sm-3">
            <v-select :options="products" label="namear" v-model="product">
              <template #option="option">
                <span>{{ option.barcode }} - {{ option.namear }}</span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="part2">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th style="width: 12%">{{ $t("product") }}</th>

                <th>{{ $t("stock") }}</th>
                <th>{{ $t("average") }} {{ $t("cost") }}</th>

                <th style="width: 10%">{{ $t("quantity") }}</th>
                <th style="width: 10%">{{ $t("unit type") }}</th>
                <th>{{ $t("price") }}</th>
                <th>{{ $t("discount") }}</th>
                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                <th>{{ $t("tax") }}</th>
                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                <th>{{ $t("total") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) in details" :key="value">
                <td>{{ id + 1 }}</td>
                <td style="width: 12%">
                  <span>{{ value.barcode }} - {{ value.pronamear }}</span>
                </td>
                <td style="background-color: red; color: white" v-if="value.stockbalance <= 0">
                  {{ value.stockbalance }}
                </td>
                <td style="background-color: green; color: white;font-size: xx-small;" v-if="value.stockbalance > 0">
                  {{ value.stockbalance }} {{ value.unittype }}
                </td>
                <!-- <td style="
                    background-color: green;
                    color: white;
                    font-size: smaller;
                  " v-if="typeof value.stockbalance == 'string'">
                  {{ value.stockbalance }}
                </td> -->
                <td>
                  <span v-if="value.stock == true">{{ value.avcost }}</span>
                </td>
                <!-- <td>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="value.description"
                  />
                </td> -->
                <td>
                  <input class="form-control form-control-sm" type="text" v-model="value.quantity" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                </td>

                <td>{{ value.unittype }}</td>
                <td>
                  <input type="number" v-model="value.salep" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                </td>
                <td style="display: flex">
                  <input type="number" v-model="value.discount" style="width: 70%" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                  %
                </td>
                <td>{{ value.tbt }}</td>
                <td style="display: flex">
                  <input type="number" v-model="value.tax" style="width: 100px" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                  %
                </td>
                <td>{{ value.taxamount }}</td>
                <td>{{ value.total }}</td>
                <td>
                  <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div class="total">
          <h4>{{ $t("tax") }}</h4>
          <!-- <div class="total1">
            {{ $t("type") }} {{ $t("Commissions") }}
            <input type="text" disabled v-model="alldata2.comm" />
          </div> -->
          <div class="total1">
            {{ $t("Account") }} {{ $t("tax") }}
            <input type="text" disabled v-model="taxaccount" />
          </div>
        </div>
        <hr />
        <div class="total">
          <h4>{{ $t("Commissions") }}</h4>
          <!-- <div class="total1">
            {{ $t("type") }} {{ $t("Commissions") }}
            <input type="text" disabled v-model="alldata2.comm" />
          </div> -->
          <div class="total1">
            {{ $t("amount") }}
            <input type="text" disabled v-model="alldata2.commamount" />
          </div>
        </div>

        <hr />
        <div class="total">
          <h4>{{ $t("total") }}</h4>
          <div class="total1">
            المبلغ قبل الضريبة
            <input type="text" disabled v-model="alldata2.tbt" />
          </div>
          <div class="total1">
            قيمة الضريبة
            <input type="text" disabled v-model="alldata2.tax" />
          </div>
          <div class="total1">
            بعد الضريبة <input type="text" disabled v-model="alldata2.tat" />
          </div>
        </div>
        <!-- <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("branch") }}
          </label>
          <div class="col-sm-2">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.branch }}
            </div>
          </div>
          <label
            for="inputEmail3"
            class="col-sm-1 col-form-label-sm"
            v-if="alldata2.project"
            >{{ $t("project") }}
          </label>
          <div class="col-sm-2" v-if="alldata2.project">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.project }}
            </div>
          </div>
          <label
            for="inputEmail3"
            class="col-sm-1 col-form-label-sm"
            v-if="alldata2.task"
            >{{ $t("task") }}
          </label>
          <div class="col-sm-2" v-if="alldata2.task">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.task }}
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("notes") }}
          </label>
          <div class="col-sm-5">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.notes }}
            </div>
          </div>
        </div> -->

        <hr />
        <div class="user">
          <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("created") }}
          {{ $t("by") }} : {{ alldata2.username }}
          <div class="udate">
            {{ $t("date") }} : {{ alldata2.date }} - {{ $t("time") }} :
            {{ alldata2.time }}
          </div>
        </div>
        <div class="user" v-if="alldata2.review == 'confirmed'">
          <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("confirmed") }}
          {{ $t("by") }} : {{ alldata2.reviewusername }}
          <div class="udate">
            {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
            {{ alldata2.reviewtime }}
          </div>
        </div>
        <div class="user" v-if="alldata2.review == 'cancel'">
          <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("cancel") }}
          {{ $t("by") }} : {{ alldata2.reviewusername }}
          <div class="udate">
            {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
            {{ alldata2.reviewtime }}
          </div>
        </div>
      </div>
      <br />
      <div class="buttom"></div>
    </div>
    <div id="main" style="padding: 2%; display: none">
      <div style="padding: 2%; direction: rtl; font-size: small">
        <!-- <img src="./logo.png" style="height: 200px; width: 100%" /> -->
        <img :src="this.url" crossorigin="anonymous" style="height: 100px; width: 25%; float: right" />
        <qrcode-vue style="float: left" render-as="svg" :value="qrCodeString" :size="150" level="H" />

        <div class="part1" style="clear: both">
          <div class="part11">
            <h3>فاتورة ضريبية</h3>
            {{ $t("number") }} : INV-
            {{ alldata2.invid }}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {{ $t("date") }} : {{ alldata2.due }} / {{ alldata2.time }}
            <br />
          </div>
          <br />
          <div class="part12">
            <!-- <h4>معلومات البائع</h4> -->
            <div style="padding: 2%">
              <h5>{{ this.$store.state.cnum[0].cname }}</h5>
              {{ this.$store.state.cnum[0].caddress }}
              <br />
              رقم تسجيل ضريبة القيمة المضافة :
              {{ this.$store.state.cnum[0].taxnum }}
            </div>
          </div>
          <div class="part12">
            <div style="padding: 2%">
              اسم العميل :
              <h5>{{ alldata2.cname }}</h5>
              عنوان :
              {{ alldata2.caddress }}
              <br />
              رقم تسجيل ضريبة القيمة المضافة :
              {{ alldata2.ctax }}
            </div>
          </div>
        </div>
        <br />
        <div class="part2">
          <table style="width: 100%; text-align: center; font-size: small">
            <thead>
              <tr style="height: 50px">
                <th style="border: 0.5px solid black">#</th>
                <th style="width: 12%; border: 0.5px solid black">
                  {{ $t("product") }}
                </th>
                <th style="width: 12%; border: 0.5px solid black">item</th>
                <!-- <th style="border: 0.5px solid black">
                  {{ $t("description") }}
                </th> -->
                <th style="width: 10%; border: 0.5px solid black">
                  {{ $t("quantity") }}
                </th>
                <th style="width: 10%; border: 0.5px solid black">
                  {{ $t("unit type") }}
                </th>
                <th style="border: 0.5px solid black">{{ $t("price") }}</th>
                <!-- <th style="border: 0.5px solid black">{{ $t("discount") }}</th> -->
                <th style="border: 0.5px solid black">
                  {{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}
                </th>
                <th style="border: 0.5px solid black">{{ $t("tax") }}</th>
                <th style="border: 0.5px solid black">
                  {{ $t("amount") }} {{ $t("tax") }}
                </th>
                <th style="border: 0.5px solid black">{{ $t("total") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) in details" :key="value">
                <td style="border: 0.5px solid black">{{ id + 1 }}</td>
                <td style="border: 0.5px solid black; width: 20%">
                  {{ value.pronamear }}
                </td>
                <td style="border: 0.5px solid black; width: 20%">
                  {{ value.pronameen }}
                </td>
                <!-- <td style="border: 0.5px solid black">
                  {{ value.description }}
                </td> -->
                <td style="border: 0.5px solid black">
                  {{ value.quantity }}
                </td>
                <td style="border: 0.5px solid black">{{ value.unittype }}</td>
                <td style="border: 0.5px solid black">{{ value.salep }}</td>
                <!-- <td style="border: 0.5px solid black">
                  {{ value.discount }} %
                </td> -->
                <td style="border: 0.5px solid black">{{ value.tbt }}</td>
                <td style="border: 0.5px solid black">{{ value.tax }} %</td>
                <td style="border: 0.5px solid black">{{ value.taxamount }}</td>
                <td style="border: 0.5px solid black">{{ value.total }}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td colspan="10">
                  <hr />
                </td>
              </tr>
              <tr style="height: 50px">
                <td colspan="7"></td>
                <td colspan="2" style="border: 0.5px solid black">
                  {{ $t("total") }}
                </td>
                <td style="border: 0.5px solid black">{{ alldata2.tbt }}</td>
              </tr>
              <tr style="height: 50px">
                <td colspan="7"></td>
                <td colspan="2" style="border: 0.5px solid black">
                  ضريبة القيمة المضافة (15%)
                </td>
                <td style="border: 0.5px solid black">{{ alldata2.tax }}</td>
              </tr>
              <tr style="height: 50px">
                <td colspan="7"></td>
                <td colspan="2" style="border: 0.5px solid black">
                  المجموع مع الضريبة (15%)
                </td>
                <td style="border: 0.5px solid black">{{ alldata2.tat }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <hr />
        {{ $t("description") }} :
        {{ alldata2.description }}
        <br />
        <br />
        <!-- {{ $t("notes") }} :
        {{ alldata2.notes }}
        <hr /> -->
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
import QrcodeVue from "qrcode.vue";
import { Buffer } from "buffer";
import vSelect from "vue-select";

export default {
  components: {
    QrcodeVue,
    vSelect,
  },
  async mounted() {
    const data2 = await axios.get(`/data/Invoiceview/${this.$route.params.id}`);
    if (data2.status == 200) {
      const data = data2.data.data[0];
      this.alldata2 = data;
      this.details = JSON.parse(this.alldata2.details);
      this.taxaccount = JSON.parse(this.alldata2.taxaccount).namear;
      this.totaldpt = this.details.reduce(
        (acc, array) => acc + Number(array.dpt),
        0
      );
      this.totalcrd = this.details.reduce(
        (acc, array) => acc + Number(array.crd),
        0
      );
      if (data.review == "waiting") {
        for (let i = 0; i < this.details.length; i++) {
          if (this.details[i].stock == true) {
            const sn = this.details[i].sn;
            const stockvalidationbalance = await axios.get(
              `/reports/stockvalidationbalance/${sn}`
            );
            if (stockvalidationbalance.status == 200) {
              const data = stockvalidationbalance.data.data[0][0];
              const stockbalance = Number(data.dpt - data.crd).toFixed(2);
              this.details[i].stockbalance = Number(stockbalance);
              if (stockbalance > 0) {
                this.details[i].avcost = stockvalidationbalance.data.data[1];
              }
            }
          }
        }
      }
      const result2 = await axios.get(`/data/allproducts`);
      if (result2.status == 200) {
        this.products = result2.data.data;
      }
      const encoded = Buffer.concat([
        this.tlvEncode(0x01, this.$store.state.cnum[0].cname),
        this.tlvEncode(0x02, this.$store.state.cnum[0].taxnum),
        this.tlvEncode(0x03, this.alldata2.due),
        this.tlvEncode(0x04, this.alldata2.tbt),
        this.tlvEncode(0x05, this.alldata2.tax),
      ]);
      this.qrCodeString = Buffer.from(encoded).toString("base64");
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      alldata2: "",
      details: [],
      fileurl: [],
      url: "",
      taxaccount: "",
      products: [],
      product: "",
      stockbalance: "",
      avcost: ""
    };
  },
  methods: {
    async Edite() {
      Swal.fire({
        title: this.$t("Edite") + "-" + this.$t("invoice"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("Edite"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/editeinvoice", {
            id: this.alldata2.invid,
            details: JSON.stringify(this.details),
            tbt: this.alldata2.tbt,
            tax: this.alldata2.tax,
            tat: this.alldata2.tat,
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("done") + " " + this.$t("Edite"), this.$t("invoice"));
            this.redirectTo({ val: "AllInvoices" });
          }
        }
      });
    },
    async del(id) {
      this.details.splice(id, 1);
      this.calc();
    },
    tlvEncode(tag, value) {
      const valueBytes = new TextEncoder().encode(value);
      const length = valueBytes.length;
      return Buffer.from([tag, length, ...valueBytes]);
    },
    confirm() {
      Swal.fire({
        title:
          this.$t("confirm") +
          " " +
          this.$t("invoice") +
          " " +
          this.$t("sales") +
          " INV- " +
          this.alldata2.invid,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/confirminvoice", {
            id: this.alldata2.invid,
            cnum: store.state.cnum[0].cnum,
            details: this.details,
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(
              this.$t("confirm") +
              " " +
              this.$t("invoice") +
              " " +
              this.$t("sales") +
              " INV- " +
              this.alldata2.invid
            );
            this.redirectTo({ val: "AllInvoices" });
          }
        }
      });
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: `INV-${this.alldata2.invid}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print() {
      var divElements = document.getElementById("main").innerHTML;
      var WinPrint = window.open();
      WinPrint.document.write(
        "<html><head><title>ESLAM</title></head><body>" +
        divElements +
        "</body>"
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    print2() {
      this.$htmlToPaper("main");
    },
    calc() {
      this.alldata2.tbt = Number(
        this.details.reduce((acc, array) => acc + Number(array.tbt), 0)
      ).toFixed(2);
      this.alldata2.tax = Number(
        this.details.reduce((acc, array) => acc + Number(array.taxamount), 0)
      ).toFixed(2);
      this.alldata2.tat = Number(
        this.details.reduce((acc, array) => acc + Number(array.total), 0)
      ).toFixed(2);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    async product() {
      const data2 = this.details.filter(
        (x) => x.pronamear == this.product.namear
      );
      if (data2.length == 0) {
        if (this.product.stock == true) {
          const stockvalidationbalance = await axios.get(
            `/reports/stockvalidationbalance/${this.product.sn}`
          );
          const data = stockvalidationbalance.data.data[0][0];
          this.stockbalance = data.dpt - data.crd;
          if (stockvalidationbalance.data.data[1] > 0) {
            this.avcost = stockvalidationbalance.data.data[1];
          } else if (stockvalidationbalance.data.data[1] == "NaN") {
            this.avcost = this.product.buyp;
          } else {
            this.stockbalance = "ليس منتج مخزون";
            this.avcost = this.product.buyp;
          }
          this.details.push({
            proid: this.product.pid,
            pronamear: this.product.namear,
            pronameen: this.product.nameen,
            salep: this.product.salep,
            salec: this.product.salec,
            snamear: this.product.snamear,
            snameen: this.product.snameen,
            buyp: this.product.buyp,
            buyc: this.product.buyc,
            bnamear: this.product.bnamear,
            bnameen: this.product.bnameen,
            discount: this.product.discount,
            unittype: this.product.unittype,
            tax: this.product.tax,
            sn: this.product.sn,
            barcode: this.product.barcode,
            stock: this.product.stock,
            stockbalance: Number(this.stockbalance).toFixed(2),
            avcost: this.avcost,
            quantity: 1,
            tbt:
              this.product.salep -
              (this.product.salep * 1 * this.product.discount) / 100,
            taxamount:
              ((this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100) *
                this.product.tax) /
              100,
            total:
              this.product.salep -
              (this.product.salep * 1 * this.product.discount) / 100 +
              ((this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100) *
                this.product.tax) /
              100,
            costbt: this.avcost,
            cost: this.avcost,
          });
          this.calc();
        } else {
          this.details.push({
            proid: this.product.pid,
            pronamear: this.product.namear,
            pronameen: this.product.nameen,
            salep: this.product.salep,
            salec: this.product.salec,
            snamear: this.product.snamear,
            snameen: this.product.snameen,
            buyp: this.product.buyp,
            buyc: this.product.buyc,
            bnamear: this.product.bnamear,
            bnameen: this.product.bnameen,
            discount: this.product.discount,
            unittype: this.product.unittype,
            tax: this.product.tax,
            sn: this.product.sn,
            barcode: this.product.barcode,
            stock: this.product.stock,
            stockbalance: "غير مخزن",
            avcost: "غير مخزن",
            lastinvprice: this.allgetcusttrans[0].salep,
            quantity: 1,
            tbt:
              this.product.salep -
              (this.product.salep * 1 * this.product.discount) / 100,
            taxamount:
              ((this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100) *
                this.product.tax) /
              100,
            total:
              this.product.salep -
              (this.product.salep * 1 * this.product.discount) / 100 +
              ((this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100) *
                this.product.tax) /
              100,
            costbt: this.avcost,
            cost: this.avcost,
          });
          this.calc();
        }
      }
    }
  }

};

</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.ccenter button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
  text-align: center;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.part1 {
  display: flex;
  width: 100%;
}

.part11 {
  width: 50%;
  padding: 2%;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
