<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/tasks" class="active"
              >{{ $t("CRM") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("requests") }}</h3>
      </div>
      <div class="head">
        <div class="head1">
          <!-- <input type="text" :placeholder="$t('name')" v-model="name" /> -->
          <!-- <input type="text" :placeholder="$t('email')" v-model="email" /> -->
          <input type="text" :placeholder="$t('code')" v-model="code" />
          <!-- <input type="text" :placeholder="$t('mobile')" v-model="mobile" /> -->
        </div>
        <div class="head2">
          <button>
            <router-link to="/CRM/CreateNewRequest" class="btn-download">
              <span class="text">
                <i class="bi bi-clipboard-plus-fill"></i>
                {{ $t("add") }} {{ $t("request") }} {{ $t("new") }}</span
              >
            </router-link>
          </button>
        </div>
      </div>
      <div class="body">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("type") }} {{ $t("contact") }}</th>
              <th>{{ $t("assign") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("time") }}</th>
              <th>{{ $t("details") }}</th>
              <th>{{ $t("status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in data" :key="val">
              <td>
                <router-link :to="`/tasks/Requestview/${val.reqid}`">
                  {{ val.reqid }}</router-link
                >
              </td>
              <td>
                {{ $t(val.type) }}
              </td>
              <td>
                <router-link :to="`/tasks/Requestview/${val.reqid}`">
                  {{ $t(val.assign) }}</router-link
                >
              </td>
              <td>
                {{ val.date }}
              </td>
              <td>
                {{ val.time }}
              </td>
              <td>
                {{ val.details }}
              </td>
              <td>
                {{ $t(val.status) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import store from "@/store";
import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      data: [],
    };
  },
  async mounted() {
    const result = await axios.get(`/data/getallrequests`);
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    InvoiceView(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/InvoiceView/${row.invid}`,
        params: { id: row.id },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    mobile() {
      this.table.rows = this.table.data.filter((x) =>
        x.mobile.includes(this.mobile)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
  .head2 button {
    margin: 0.5%;
    padding: 3%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: xx-small;
  }
}
</style>
