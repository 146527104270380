<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#">{{ $t("reports") }}</a>
        </li>
        <li>-</li>
        <li class="active">
          <a>{{ $t("expences") }} {{ $t("operation") }} </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("from") }} :</label
          >
        </div>
        <div class="col-auto">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="from"
          />
        </div>
      </div>
      &nbsp;&nbsp;
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("to") }} :</label
          >
        </div>
        <div class="col-auto">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="to"
          />
        </div>
      </div>

      <div class="col">
        <button class="btn btn-primary" @click="submit">
          {{ $t("search") }} <i class="bi bi-search"></i>
        </button>
        &nbsp;
        <button class="btn btn-light">
          {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
        </button>
      </div>
    </div>
    <div class="body" v-if="this.alldata.length == 0">برجاء اختيار التاريخ</div>
    <div class="body" v-if="this.alldata.length != 0">
      <h3>{{ $t("expences") }} {{ $t("operation") }}</h3>
      <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("code") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("time") }}</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("amount") }}</th>
            <th>{{ $t("description") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in alldata" :key="i">
            <td>
              <router-link to="">{{ i.id }}</router-link>
            </td>
            <td>{{ i.date }}</td>
            <td>{{ i.time }}</td>
            <td>{{ i.namear }}</td>
            <td>
              {{ i.amount }}
            </td>
            <td>{{ i.description }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">{{ $t("total") }}</td>
            <td>{{ total }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import store from "@/store";
export default {
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: "",
      todate: "",
      alldata: [],
      from: "",
      to: "",
      r12: JSON.parse(store.state.randp.r12),
      total: 0,
    };
  },
  async mounted() {
    if (this.r12.create != true) {
      this.redirectTo({ val: "Home" });
    }
  },
  methods: {
    async submit() {
      const result = await axios.get(
        `/reports/operationexpenses/${this.from}/${this.to}`
      );
      if (result.status == 200) {
        this.alldata = result.data.data;
        this.fromdate = this.from;
        this.todate = this.to;
        this.total = this.alldata.reduce(
          (acc, array) => acc + Number(array.amount),
          0
        );
      }
    },
  },
  watch: {
    filter() {
      this.accounts = this.accounts.filter((x) =>
        x.namear.includes(this.filter.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
  color: black;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: #3c91e6;
  pointer-events: unset;
}

.main {
  margin: 1%;
}
.main .head {
  border-top: #3c91e6 5px solid;
  background: #040531;
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 1%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  text-align: center;
}

.col {
  padding-left: 2%;
  padding-right: 2%;
}
tr {
  text-align: v-bind("css.dirn");
}
.table {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  background-color: white;
}
.child {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  background-color: #d6d6d6;
}
.childname {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #d6d6d6;
}

.options {
  width: 300px;
  height: 150px;
  margin: 0%;
  background-color: v-bind("css.light");
  color: v-bind("css.dark");
  padding: 1%;
  overflow-y: auto;
  position: absolute;
  border: black 2px solid;
}
.table thead th {
  background-color: #c6d7e9;
}
tfoot {
  background-color: #dfdddd;
}
</style>
