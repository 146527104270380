<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("customers") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("customer") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
            <a href="#" class="btn-download">
              <span class="text">Download PDF</span>
            </a>
          </li> -->
        <li>
          <router-link to="/AdminDashboard/Customers" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("customer") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text"
              >{{ $t("name") }}
              <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span></span
            >
            <input type="text" class="form-control" v-model="name" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("mobile") }}</span>
            <input type="text" class="form-control" v-model="mobile" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("email") }}</span>
            <input type="text" class="form-control" v-model="email" />
          </div>
          <div class="input-group">
            <span class="input-group-text"
              >{{ $t("name") }} {{ $t("company") }}
              <span class="error-feedback" v-if="v$.companyname.$error">{{
                "*"
              }}</span></span
            >
            <input type="text" class="form-control" v-model="companyname" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("website") }}</span>
            <input type="text" class="form-control" v-model="website" />
          </div>
          <div class="input-group">
            <span class="input-group-text">
              {{ $t("number") }} {{ $t("tax") }}
            </span>
            <input type="text" class="form-control" v-model="taxnumber" />
          </div>
          <div class="input-group">
            <span class="input-group-text">
              {{ $t("Categorie") }}
            </span>
            <select
              type="text"
              class="form-select form-select-sm"
              v-model="categorie"
            >
              <option
                v-for="val in categories"
                :key="val"
                :value="val.categorie"
              >
                <span v-if="css.lang == 'ar'">{{ val.showar }}</span
                ><span v-if="css.lang == 'en'">{{ val.showen }}</span>
              </option>
            </select>
            <!-- <span class="input-group-text">
              {{ $t("code") }}
            </span>
            <input type="text" class="form-control" v-model="code" /> -->
          </div>
        </div>
        <div class="part2">
          <div class="part2head">{{ $t("data") }} {{ $t("deal") }}</div>
          <div class="part2body">
            <div class="row g-3">
              <div class="col-sm-12">
                <div class="input-group">
                  <span class="input-group-text">
                    {{ $t("price") }} {{ $t("delivery") }} {{ $t("inside") }}
                    {{ $t("city") }}
                  </span>
                  <input type="number" class="form-control" v-model="pinside" />
                </div>
              </div>
              <div class="col-sm-12">
                <div class="input-group">
                  <span class="input-group-text">
                    {{ $t("price") }} {{ $t("delivery") }} {{ $t("outside") }}
                    {{ $t("city") }}
                  </span>
                  <input
                    type="number"
                    class="form-control"
                    v-model="poutside"
                  />
                </div>
              </div>
              <div class="col-sm-12">
                <div class="input-group">
                  <span class="input-group-text">
                    {{ $t("price") }} {{ $t("delivery") }}
                    {{ $t("international") }}
                  </span>
                  <input type="number" class="form-control" v-model="pinter" />
                </div>
              </div>
              <div class="col-sm-12">
                <div class="input-group">
                  <span class="input-group-text">
                    {{ $t("cost") }} {{ $t("cash on delivery") }}
                  </span>
                  <input
                    type="number"
                    class="form-control"
                    v-model="costcash"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="part2">
          <div class="part2head">{{ $t("address") }} {{ $t("billing") }}</div>
          <div class="part2body">
            <div class="row g-3">
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('street')"
                  v-model="street"
                />
              </div>
              <div class="row g-2">
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('city')"
                    v-model="city"
                  />
                </div>
                <div class="col-sm">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('area')"
                    v-model="area"
                  />
                </div>
              </div>
              <div class="row g-2">
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('zipcode')"
                    v-model="zipcode"
                  />
                </div>
                <div class="col-sm">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('building')"
                    v-model="building"
                  />
                </div>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('country')"
                  v-model="country"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="part2">
          <div class="part2head">{{ $t("address") }} {{ $t("shipping") }}</div>

          <div class="part2body">
            <div class="col-6">
              <div class="form-check">
                <label class="form-check-label" for="gridCheck">
                  <span
                    >{{ $t("copy") }} {{ $t("address") }}
                    {{ $t("billing") }}</span
                  >
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  v-model="copy"
                />
              </div>
            </div>
            <div class="row g-3">
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('street')"
                  v-model="streetsh"
                />
              </div>
              <div class="row g-2">
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('city')"
                    v-model="citysh"
                  />
                </div>
                <div class="col-sm">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('area')"
                    v-model="areash"
                  />
                </div>
              </div>
              <div class="row g-2">
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('zipcode')"
                    v-model="zipcodesh"
                  />
                </div>
                <div class="col-sm">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('building')"
                    v-model="buildingsh"
                  />
                </div>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('country')"
                  v-model="countrysh"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="part2">
          <div class="input-group">
            <span class="input-group-text">{{ $t("image") }}</span>
            <input
              type="file"
              class="form-control form-control-sm"
              id="image"
              :name="this.sn"
              @change="onFileChange"
            />
          </div>
        </div>
        <div class="part2">
          <div id="preview">
            <img v-if="url" :src="url" />
          </div>
        </div>
        <div style="clear: both; margin: 5%; text-align: center">
          <button
            class="buttonconfirm"
            :disabled="disabled"
            @click.prevent="confirm"
          >
            {{ $t("save") }} <i class="bi bi-save-fill"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  async mounted() {
    this.view = this.$route.params.id;
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/allcustomercategorie");

    if (result.status == 200) {
      this.categories = result.data.data;
    }
  },
  props: ["css"],
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      name: "",
      email: "",
      mobile: "",
      companyname: "",
      website: "",
      taxnumber: "",
      street: "",
      city: "",
      area: "",
      zipcode: "",
      building: "",
      country: "",
      streetsh: "",
      citysh: "",
      areash: "",
      zipcodesh: "",
      buildingsh: "",
      countrysh: "",
      copy: false,
      categories: [],
      categorie: "",
      url: "",
      pinter: "",
      pinside: "",
      poutside: "",
      costcash: "",
      code: "",
    };
  },
  validations() {
    return {
      name: { required },
      companyname: { required },
    };
  },
  methods: {
    async confirm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disabled = true;
        const result = await axios.post(`/data/createnewcustomer`, {
          username: store.state.randp.username,
          code: this.code,
          name: this.name,
          email: this.email,
          mobile: this.mobile,
          companyname: this.companyname,
          website: this.website,
          taxnumber: this.taxnumber,
          street: this.street,
          city: this.city,
          area: this.area,
          zipcode: this.zipcode,
          building: this.building,
          country: this.country,
          streetsh: this.streetsh,
          citysh: this.citysh,
          areash: this.areash,
          zipcodesh: this.zipcodesh,
          buildingsh: this.buildingsh,
          countrysh: this.countrysh,
          categorie: this.categorie,
          pinside: this.pinside,
          poutside: this.poutside,
          pinter: this.pinter,
          costcash: this.costcash,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "Customers" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    copy() {
      if (this.copy == true) {
        this.streetsh = this.street;
        this.citysh = this.city;
        this.areash = this.area;
        this.zipcodesh = this.zipcode;
        this.buildingsh = this.building;
        this.countrysh = this.country;
      } else {
        this.streetsh = "";
        this.citysh = "";
        this.areash = "";
        this.zipcodesh = "";
        this.buildingsh = "";
        this.countrysh = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}
.head-option ul li {
  display: inline;
  padding: 0.5%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  //   display: inline-block;
}
.main .body .part1 {
  width: 50%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  float: v-bind("css.dirn");
}
.main .body .part1 .input-group {
  margin: 1%;
}
.main .body .part1 .input-group span {
  width: 25%;
  font-size: small;
}

.main .body .part2 {
  width: 48%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 3%;
  float: v-bind("css.dirn");
}
.main .body .part2 .part2head {
  background: rgba(191, 214, 219, 0.534);
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.main .body .part2 .part2body {
  box-shadow: rgba(75, 68, 68, 0.774) 0.25px 0.25px 1px;
  padding: 5%;
}
img {
  border: black 1px solid;
  padding: 0.5%;
  width: 200px;
  height: 300px;
  border-radius: 20px;
  margin-right: 25%;
}

@media screen and (max-width: 576px) {
  .main .body {
    background: v-bind("css.light");
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
    clear: both;
  }
  .main .body .part2 {
    width: 100%;
    background: v-bind("css.light");
    //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 3%;
    clear: both;
  }
  .main .body .part1 {
    width: 100%;
    background: v-bind("css.light");
    //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
    clear: both;
  }
}
</style>
