<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <h3>{{ $t("products") }}</h3>
      </div>
      <div class="head">
        <div class="head1">
          <input type="text" :placeholder="$t('code')" v-model="sn" />
          <input type="text" :placeholder="$t('name')" v-model="name" />
        </div>
        <div class="head2">
          <button>
            <router-link to="/purchases/AllProducts/NewProduct"
              >+ {{ $t("add") }} {{ $t("products") }}</router-link
            >
          </button>
        </div>
      </div>
      <div class="body">
        <div>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("code") }}</th>
                <th>{{ $t("name") }}</th>
                <th>{{ $t("sn") }}</th>
                <th>{{ $t("item") }}</th>
                <th>{{ $t("tax") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="val in data" :key="val">
                <td>
                  {{ val.pid }}
                </td>
                <td>
                  <router-link
                    :to="`/purchases/AllProducts/productview/${val.pid}`"
                    >{{ val.namear }}</router-link
                  >
                </td>
                <td>
                  {{ val.sn }}
                </td>
                <td>
                  {{ val.item }}
                </td>
                <td>
                  {{ val.tax }}
                </td>
                <td>
                  <router-link
                    :to="'/purchases/AllProducts/productedite/' + val.pid"
                    ><i class="bi bi-pencil-square"></i
                  ></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import store from "@/store";
import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  data() {
    return {
      name: "",
      sn: "",
      barcode: "",
      kind: "",
      data: [],
      data2: [],
    };
  },
  async mounted() {
    const result = await axios.get("/data/allproducts");
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    eslam(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/AllProducts/productedite/${row.id}`,
        params: { id: row.id },
      });
    },
    View(row) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllProducts/productview/${row.id}`,
        params: { id: row.id },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.data = this.data2.filter((x) =>
        x.namear.includes(this.name.toLowerCase())
      );
    },
    sn() {
      this.data = this.data2.filter((x) => x.sn.includes(this.sn));
    },
    // barcode() {
    //   this.table.rows = this.table.data.filter((x) => x.id == this.code);
    //   if (this.code == "") {
    //     this.table.rows = this.table.data;
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
