<template>
  <div class="main">
    <div class="head">
      <h3 style="padding: 1%">{{ $t("reports") }}</h3>
      <div class="option">
        <div class="supoption">
          <div class="" :class="isActive1 ? 'active' : ''">
            <a
              href="#"
              @click="
                isActive1 = true;
                isActive2 = false;
                isActive3 = false;
                isActive4 = false;
                isActive5 = false;
              "
              >{{ $t("Accounts") }}</a
            >
          </div>
        </div>
        <div class="supoption">
          <div class="" :class="isActive2 ? 'active' : ''">
            <a
              href="#"
              @click="
                isActive1 = false;
                isActive2 = true;
                isActive3 = false;
                isActive4 = false;
                isActive5 = false;
              "
              >{{ $t("sales") }} & {{ $t("purchases") }}
            </a>
          </div>
        </div>
        <div class="supoption">
          <div class="" :class="isActive3 ? 'active' : ''">
            <a
              href="#"
              @click="
                isActive1 = false;
                isActive2 = false;
                isActive3 = true;
                isActive4 = false;
                isActive5 = false;
              "
              >{{ $t("customers") }} & {{ $t("suppliers") }}</a
            >
          </div>
        </div>
        <div class="supoption">
          <div class="" :class="isActive4 ? 'active' : ''">
            <a
              href="#"
              @click="
                isActive1 = false;
                isActive2 = false;
                isActive3 = false;
                isActive4 = true;
                isActive5 = false;
              "
              >{{ $t("stock") }}</a
            >
          </div>
        </div>
        <!-- <div class="supoption">
          <div class="" :class="isActive5 ? 'active' : ''">
            <a
              href="#"
              @click="
                isActive1 = false;
                isActive2 = false;
                isActive3 = false;
                isActive4 = false;
                isActive5 = true;
              "
              >{{ $t("Accounts") }}</a
            >
          </div>
        </div> -->
      </div>
    </div>
    <div class="body">
      <div class="body2">
        <div class="body3" v-if="isActive1 == true">
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/JournalReport"
          >
            <h3>{{ $t("Journal Report") }}</h3>
            <div>تقرير يتم من خلالة عرض القيود اليومية عن فترة</div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/GeneralLedgerSummary"
          >
            <h3>{{ $t("General Ledger") }}</h3>
            <div>
              توفر قائمة التدفقات النقدية معلومات عن التغيرات التاريخية في النقد
              ومعادلات الحسابات
            </div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/TrialBalance"
          >
            <h3>{{ $t("Trial Balance") }}</h3>
            <div>تقرير يتم من خلالة عرض القيود اليومية عن فترة</div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/IncomeStatement"
          >
            <h3>{{ $t("Income Statement") }}</h3>
            <div>
              تقرير يتم من خلالة عرض ربحية الشركة خلال فترة زمنية معينة، وتتكون
              من إيرادات ومصروفات المنشأة
            </div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/BalanceSheet"
          >
            <h3>{{ $t("Balance Sheet") }}</h3>
            <div>
              هي قائمة تلخص أصول وخصوم وحقوق الملكية للمنشأة في تاريخ معين
            </div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/GeneralLedgerSummaryMonthly/0/0/0/0"
          >
            <h3>{{ $t("Account Summary") }}</h3>
            <div>
              هو عبارة عن تقرير وميزان مالي يوضح وضع الحساب ، فإما أن يكون دائنا
              بالإيداعات أو مدينا
            </div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/GeneralLedgerSummaryDaily/0/0/0/0/0/0"
          >
            <h3>{{ $t("Account Statement") }}</h3>
            <div>
              هو عبارة عن تقرير وميزان مالي يوضح وضع الحساب ، فإما أن يكون دائنا
              بالإيداعات أو مدينا
            </div>
          </router-link>
        </div>
        <div class="body3" v-if="isActive2 == true">
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/AllBuyAndSell"
          >
            <h3>
              {{ $t("reports") }} {{ $t("purchases") }} {{ $t("and") }}
              {{ $t("sales") }}
            </h3>
            <div>تقرير لعرض كشف حساب العملاء و تفصيلة المعاملات خلال فترة</div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/AllBuyAndSellP"
          >
            <h3>
              {{ $t("summary") }} {{ $t("invoices") }} {{ $t("sales") }}
              {{ $t("and") }} {{ $t("purchases") }}
            </h3>
            <div>تقرير لعرض كشف حساب العملاء و تفصيلة المعاملات خلال فترة</div>
          </router-link>
        </div>
        <div class="body3" v-if="isActive3 == true">
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/CustomerStatement"
          >
            <h3>{{ $t("Account Statement") }} {{ $t("customers") }}</h3>
            <div>تقرير لعرض كشف حساب العملاء و تفصيلة المعاملات خلال فترة</div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/SuplierStatement"
          >
            <h3>{{ $t("Account Statement") }} {{ $t("suppliers") }}</h3>
            <div>تقرير لعرض كشف حساب الموردين و تفصيلة المعاملات خلال فترة</div>
          </router-link>
        </div>
        <div class="body3" v-if="isActive4 == true">
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/StockBalance"
          >
            <h3>
              {{ $t("balance") }} {{ $t("stock") }} {{ $t("from") }}
              {{ $t("products") }}
            </h3>
            <div>تقرير لعرض كشف حساب الموردين و تفصيلة المعاملات خلال فترة</div>
          </router-link>
          <router-link
            v-if="r17 == true"
            class="body31"
            to="/AdminDashboard/AllReports/ProductStockBalance"
          >
            <h3>
              {{ $t("transactions") }} {{ $t("stock") }} {{ $t("from") }}
              {{ $t("product") }}
            </h3>
            <div>تقرير لعرض كشف حساب الموردين و تفصيلة المعاملات خلال فترة</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  props: ["css"],
  data() {
    return {
      r17: JSON.parse(store.state.randp.r17).create,
      r18: JSON.parse(store.state.randp.r18).create,
      r19: JSON.parse(store.state.randp.r19).create,
      r20: JSON.parse(store.state.randp.r20).create,
      r21: JSON.parse(store.state.randp.r21).create,
      r22: JSON.parse(store.state.randp.r22).create,
      r23: JSON.parse(store.state.randp.r23).create,
      r24: JSON.parse(store.state.randp.r24).create,
      r25: JSON.parse(store.state.randp.r25).create,
      r26: JSON.parse(store.state.randp.r26).create,
      isActive1: true,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

a {
  text-decoration: none;
  color: black;
}

.main {
  margin: 1%;
}

.main .head {
  // border-top: v-bind("css.color") 5px solid;
  background: white;
  border-radius: 3px 3px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.color");
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // padding: 1%;
}

.main .body3 {
  display: grid;
  grid-template-columns: repeat(3, 5fr);
  grid-gap: 20px;
  color: white;
  padding: 1%;
  // min-height: 500px;
}

.main .body3 .body31 {
  font-size: large;
  width: 99%;
  height: 110px;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
}

.main .body3 .body31:hover {
  font-size: large;
  width: 99%;
  height: 110px;
  padding: 2%;
  margin: 0.5%;
  background-color: v-bind("css.color");
  border-radius: 5px;
  color: white;
}

.option {
  display: flex;
}
.active {
  width: 100%;
  text-align: center;
  background: v-bind("css.color");
  color: white;
  border-radius: 4px 4px 0 0;
}
.active a {
  text-decoration: none;
  color: white;
}

.supoption {
  width: 20%;
  text-align: center;
  font-size: x-large;
  margin: 1%;
  margin-bottom: 0;
  background: white;
  color: black;
  // border-radius: 4px;
}
.supoption a {
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .main .body3 .body31 {
    font-size: xx-small;
    width: 99%;
    height: 110px;
    padding: 4%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
  }

  .main .body3 .body31 h3 {
    font-size: small;
  }

  .main .body3 .body31:hover {
    font-size: xx-small;
    width: 99%;
    height: 110px;
    padding: 2%;
    margin: 0.5%;
    background-color: v-bind("css.color");
    border-radius: 5px;
    color: white;
  }
  .supoption {
    width: 25%;
    text-align: center;
    font-size: 10px;
    margin: 1%;
    margin-bottom: 0;
    background: white;
    color: black;
    // border-radius: 4px;
  }
}
</style>
