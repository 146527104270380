<template>
  <div class="header-area header-area--absolute" :style="{ direction: lang }">
    <div class="preview-header-inner header-sticky">
      <div class="container-fluid container-fluid--cp-150">
        <div class="row">
          <div class="col-lg-12">
            <div class="header position-relative">
              <!-- brand logo -->
              <div class="header__logo">
                <a href="/">
                  <img src="../assets/logo.png" aria-label="Mitech Logo" style="width: 100%" />
                </a>
              </div>
              <div class="lang">
                <button type="button" @click="langar" style="background-color: white; border: white"
                  v-if="this.lang == 'rtl'">
                  {{ $t("EN") }}
                  <img style="width: 40%; margin: 3%" src="../assets/gbp.jpg" alt="" />
                </button>
                <button type="button" @click="langen" style="background-color: white; border: white"
                  v-if="this.lang == 'ltr'">
                  {{ $t("AR") }}
                  <img style="width: 40%; margin: 3%" src="../assets/sar.jpg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main">
    <h3>{{ $t("login") }}</h3>
    <form class="form">
      <!-- <h1>Accounting System</h1> -->

      <div class="mt-3 mb-1 form-floating" style="width: 60%; margin-left: 20%">
        <input type="text" class="form-control" id="floatingInput" placeholder="Email" v-model="username" />
        <label for="floatingInput">{{ $t("email") }}</label>
        <span class="error-feedback" v-if="v$.username.$error">{{
          v$.username.$errors[0].$message
        }}</span>
      </div>

      <div class="mt-3 mb-1 form-floating" style="width: 60%; margin-left: 20%">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password"
          @keypress.enter="Signin" v-model="password" />
        <label for="floatingPassword">{{ $t("password") }} </label>
        <span class="error-feedback" v-if="v$.password.$error">{{
          v$.password.$errors[0].$message
        }}</span>
      </div>
      <br />

      <a class="fancy" href="#" @click="Signin">
        <span class="top-key"></span>
        <span class="text">{{ $t("login") }}</span>
        <span class="bottom-key-1"></span>
        <span class="bottom-key-2"></span>
      </a>
      <h6 style="padding: 3%">
        <router-link style="color: white; text-decoration: none" to="">Forget Password</router-link>
      </h6>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import store from "@/store";
const CryptoJS = require("crypto-js");

export default {
  name: "LoginPage",

  data() {
    return {
      loading: false,
      v$: useValidate(),
      username: "",
      password: "",
      lang: store.state.lang,
      dir: "right",
    };
  },

  validations() {
    return {
      username: { required },
      password: { required },
    };
  },

  async mounted() {
    const user = this.$store.state.typeofuser;
    if (user) {
      this.redirectTo({ val: "Home" });
    }
  },

  methods: {
    langar() {
      this.$i18n.locale = "en";
      this.lang = "ltr";
      localStorage.setItem("lang", this.lang);
      this.dir = "left";
    },
    langen() {
      this.$i18n.locale = "ar";
      this.lang = "rtl";
      localStorage.setItem("lang", this.lang);
      this.dir = "right";
    },
    async Signin() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          let result = await axios.post("/user/authenticate", {
            username: this.username.toLowerCase(),
            password: this.password,
          });
          if (result.status === 200) {
            // console.log(result);

            // this.$cookies.config("120min");
            var user = [result.data.data];
            const passphrase = process.env.VUE_APP_SOCKET_ENDPOINT;
            const data = JSON.stringify(user);
            // console.log(data);
            const crept = CryptoJS.AES.encrypt(data, passphrase).toString();

            // console.log(crept);
            // this.$cookies.set("data", crept);
            localStorage.setItem("data", crept);

            this.$cookies.set("token", result.data.data.token);
            this.loading = false;

            if (user[0].typeofuser == "accountant") {
              window.location.href = `${this.$store.state.surl}/AdminDashboard/AllContracts`;
            } else if (user[0].typeofuser == "customer") {
              window.location.href = `${this.$store.state.surl}/tasks/CreateNewTask`;
            } else {
              window.location.href = `${this.$store.state.surl}/AdminDashboard/Home`;
            }

          }
        } catch (err) {
          this.loading = false;
          alert("اسم المستخدم او الرقم السرى غير صحيح");
        }
      }
      this.loading = false;
    },
    logout() {
      localStorage.clear();
      this.redirectTo({ val: "signIn" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style lang="css" scoped src="../assets2/css/style.css"></style>
<style lang="scss" scoped>
* {
  text-align: center;
}

.form {
  padding: 3%;
  text-align: center;
  position: center;
  width: 30%;
  margin-left: 35%;
  background-color: white;
  background-color: rgba(98, 153, 174, 255);
}

.error-feedback {
  color: red;
}

.fancy {
  background-color: rgba(255, 255, 255, 0.616);
  border: 2px solid #000;
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 1.25em 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 13px;
}

.fancy::before {
  content: " ";
  width: 1.5625rem;
  height: 2px;
  background: black;
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
}

.fancy .text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.fancy .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.fancy .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy:hover {
  color: white;
  background: black;
}

.fancy:hover::before {
  width: 0.9375rem;
  background: white;
}

.fancy:hover .text {
  color: white;
  padding-left: 1.5em;
}

.fancy:hover .top-key {
  left: -2px;
  width: 0px;
}

.fancy:hover .bottom-key-1,
.fancy:hover .bottom-key-2 {
  right: 0;
  width: 0;
}

.main {
  margin-top: 10%;
  width: 100%;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

input {
  color: black;
}

.lang {
  width: 60%;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .main {
    margin-top: 10%;
    width: 100%;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }

  .form {
    padding: 3%;
    text-align: center;
    position: center;
    width: 90%;
    margin-left: 5%;
    background-color: white;
    background-color: rgba(98, 153, 174, 255);
  }

  .lang {
    width: 60%;
    text-align: center;
    font-size: xx-small;
  }
}
</style>
