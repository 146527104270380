<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("customers") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#"
              >{{ $t("Categorie") }} {{ $t("customers") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <li>
          <router-link to="/AdminDashboard/Customers" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
        <li>
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {{ $t("add") }} {{ $t("Categorie") }} {{ $t("customers") }}
          </button>

          <!-- Modal -->
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    {{ $t("add") }} {{ $t("Categorie") }} {{ $t("customers") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row mb-2">
                    <label for="inputEmail3" class="col-sm-4 col-form-label-sm"
                      >{{ $t("type") }} {{ $t("Categorie") }}
                      <span class="error-feedback" v-if="v$.type.$error">{{
                        "*"
                      }}</span>
                    </label>
                    <div class="col-sm-4">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="inputEmail3"
                        v-model="type"
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="inputEmail3" class="col-sm-4 col-form-label-sm">
                      {{ $t("name") }} {{ $t("ar") }}
                      <span class="error-feedback" v-if="v$.namear.$error">{{
                        "*"
                      }}</span>
                    </label>
                    <div class="col-sm-4">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="inputEmail3"
                        v-model="namear"
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="inputEmail3" class="col-sm-4 col-form-label-sm"
                      >{{ $t("name") }} {{ $t("en") }}
                      <span class="error-feedback" v-if="v$.nameen.$error">{{
                        "*"
                      }}</span>
                    </label>
                    <div class="col-sm-4">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="inputEmail3"
                        v-model="nameen"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    id="close"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("cancel") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="confirm"
                  >
                    {{ $t("confirm") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("Categorie") }} {{ $t("customers") }}</h3>
      </div>
      <div class="body">
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('name')"
              v-model="name"
            />
          </div>

          <div class="col">
            <button class="btn btn-primary">
              {{ $t("search") }} <i class="bi bi-search"></i>
            </button>
            &nbsp;
            <button class="btn btn-light">
              {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
            </button>
          </div>
        </div>
        <div>
          <table-lite
            :is-static-mode="true"
            :columns="table.columns"
            :rows="table.rows"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
            @row-delete="rowDelete"
            @row-edite="eslam"
            @row-view="InvoiceView"
          ></table-lite>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import TableLite from "../../components/TableLite.vue";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  props: ["css"],
  components: { TableLite },
  data() {
    return {
      v$: useValidate(),
      namear: "",
      nameen: "",
      type: "",
      mobile: "",
      code: "",
      r2: store.state.randp.r2,
    };
  },
  async mounted() {
    if (this.r2 == false) {
      this.redirectTo({ val: "Home" });
    }
  },
  setup() {
    // Table config
    const table = reactive({
      columns: [
        {
          label: "code",
          field: "id",
          width: "1%",
          text: "Categorie-",
          sortable: true,
          isKey: true,
          headerClasses: ["bg-gold", "color-red"],
          columnClasses: ["bg-gray"],
        },
        {
          label: "name",
          field: "showar",
          width: "15%",
          sortable: true,
        },
        {
          label: "Categorie",
          field: "categorie",
          width: "15%",
          sortable: true,
        },
        {
          label: "option",
          // headerClasses: ["bg-gold"],
          // columnClasses: ["bg-gray"],
          // columnStyles: { background: "gray" },
          field: "quick",
          width: ".5%",
          display: function () {
            return `<button class="btn btn-light"><i class="bi bi-search"></i></button>`;
          },
        },
      ],
      rows: [],
      data: [],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {},
    });
    const rowDelete = async (row) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(`/data/delcustomer/${row.id}`);
          if (del.status == 200) {
            const indexOfObject = table.rows.findIndex((object) => {
              return object.id === row.id;
            });
            table.rows.splice(indexOfObject, 1);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        }
      });
    };
    const rowEdit = () => {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
    };
    const rowView = (row) => {
      console.log("Row rowView!", row);
    };
    /**
     * Table search event
     */
    const doSearch = (offset, limit, order, sort) => {
      table.isLoading = true;

      // Start use axios to get data from Server
      let url = "/data/allcustomercategorie";
      axios.get(url).then((response) => {
        table.rows = response.data.data;
        table.data = response.data.data;
        table.totalRecordCount = response.data.data.length;
        table.sortable.order = order;
        table.sortable.sort = sort;
      });
    };

    // Get data first
    doSearch(0, 10, "id", "asc");
    return {
      table,
      rowDelete,
      rowEdit,
      rowView,
    };
  },
  validations() {
    return {
      type: { required },
      namear: { required },
      nameen: { required },
    };
  },
  methods: {
    InvoiceView(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/InvoiceView/${row.id}`,
        params: { id: row.id },
      });
    },
    async confirm() {
      var myModalEl = document.getElementById("close");
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post(`/data/addcustomercategorie`, {
          username: store.state.randp.username,
          type: this.type,
          namear: this.namear,
          nameen: this.nameen,
        });
        if (result.status == 200) {
          myModalEl.click();
          this.redirectTo({ val: "Customers" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    mobile() {
      this.table.rows = this.table.data.filter((x) =>
        x.mobile.includes(this.mobile)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;
  padding: 0.25%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.15%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}
.modal-body {
  direction: v-bind("css.EN");
  text-align: v-bind("css.dirn");
  //   border: 1px black solid;
}
.main .head {
  border-top:  v-bind("css.color") 5px solid;
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.table {
  text-align: center;
}
.table tr {
  background: rgba(191, 214, 219, 0.534);
  line-height: 5px;
}
button {
  margin: 1%;
}

.table button :hover {
  background: black;
  color: white;
  padding: 50%;
}

.bg-gold {
  background: gold !important;
}
.bg-gray {
  background: gray !important;
}
.color-red {
  color: red !important;
}
</style>
