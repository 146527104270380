<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("supply order") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
            <a href="#" class="btn-download">
              <span class="text">Download PDF</span>
            </a>
          </li> -->
        <li>
          <router-link
            to="/AdminDashboard/SupplyOrder/CreateSupplyOrder"
            class="btn-download"
          >
            <span class="text">
              <i class="bi bi-clipboard-plus-fill"></i>
              {{ $t("add") }} {{ $t("supply order") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("supply order") }}</h3>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('name')"
              v-model="name"
            />
          </div>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('email')"
              v-model="email"
            />
          </div>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('code')"
              v-model="code"
            />
          </div>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('mobile')"
              v-model="mobile"
            />
          </div>
          <div class="col">
            <button class="btn btn-primary">
              {{ $t("search") }} <i class="bi bi-search"></i>
            </button>
            &nbsp;
            <button class="btn btn-light">
              {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
            </button>
          </div>
        </div>
        <div>
          <table-lite
            :is-static-mode="true"
            :columns="table.columns"
            :rows="table.rows"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
            @row-delete="rowDelete"
            @row-edite="eslam"
            @row-view="OfferView"
          ></table-lite>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import TableLite from "../../components/TableLite.vue";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  components: { TableLite },
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      r2: store.state.randp.r2,
    };
  },
  async mounted() {
    if (this.r2 == false) {
      this.redirectTo({ val: "Home" });
    }
  },
  setup() {
    // Table config
    const table = reactive({
      columns: [
        {
          label: "code",
          field: "soid",
          width: "1%",
          text: "SO-",
          sortable: true,
          isKey: true,
          headerClasses: ["bg-gold", "color-red"],
          columnClasses: ["bg-gray"],
        },
        {
          label: "dueon",
          field: "due",
          width: "15%",
          sortable: true,
        },
        {
          label: "name",
          field: "cname",
          width: "15%",
          sortable: true,
        },
        {
          label: "description",
          field: "description",
          width: "10%",
          sortable: true,
        },
        {
          label: "total",
          field: "tat",
          width: "10%",
          sortable: true,
        },

        {
          label: "status",
          field: "review",
          width: "10%",
          sortable: true,
        },
        {
          label: "option",
          // headerClasses: ["bg-gold"],
          // columnClasses: ["bg-gray"],
          // columnStyles: { background: "gray" },
          field: "quick",
          width: ".5%",
          display: function () {
            return `<button class="btn btn-light"><i class="bi bi-search"></i></button>`;
          },
        },
      ],
      rows: [],
      data: [],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {},
    });
    const rowDelete = async (row) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(`/data/delcustomer/${row.id}`);
          if (del.status == 200) {
            const indexOfObject = table.rows.findIndex((object) => {
              return object.id === row.id;
            });
            table.rows.splice(indexOfObject, 1);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        }
      });
    };
    const rowEdit = () => {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
    };
    const rowView = (row) => {
      console.log("Row rowView!", row);
    };
    /**
     * Table search event
     */
    const doSearch = (offset, limit, order, sort) => {
      table.isLoading = true;

      // Start use axios to get data from Server
      let url = `/data/allsupplyorder`;
      axios.get(url).then((response) => {
        table.rows = response.data.data;
        table.data = response.data.data;
        table.totalRecordCount = response.data.data.length;
        table.sortable.order = order;
        table.sortable.sort = sort;
      });
    };

    // Get data first
    doSearch(0, 10, "id", "asc");
    return {
      table,
      rowDelete,
      rowEdit,
      rowView,
    };
  },
  methods: {
    OfferView(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/SupplyOrderView/${row.soid}`,
        params: { id: row.id },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    mobile() {
      this.table.rows = this.table.data.filter((x) =>
        x.mobile.includes(this.mobile)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;
  padding: 0.25%;
}
.head-option .btn-download {
  width: 20%;
  padding: 0.15%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.table {
  text-align: center;
}
.table tr {
  background: rgba(191, 214, 219, 0.534);
  line-height: 5px;
}
button {
  margin: 1%;
}

.table button :hover {
  background: black;
  color: white;
  padding: 50%;
}

.bg-gold {
  background: gold !important;
}
.bg-gray {
  background: gray !important;
}
.color-red {
  color: red !important;
}
</style>
