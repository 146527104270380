<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link to="/accounting/home" class="active">{{
            $t("accounting")
          }}</router-link>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#">{{ $t("General Ledger") }} {{ $t("general") }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
      <div class="head2">
        <button>
          <router-link to="/accounting/JournalEntry/CreateJournalEntry">+ {{ $t("add") }} {{ $t("JL") }}</router-link>
        </button>
        <button type="submit" @click="pdf" v-if="this.data1.length > 0">
          <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
        </button>
        <button type="submit" @click="exportToExcel" v-if="this.data1.length > 0"><i
            class="bi bi-file-earmark-excel"></i> Excel </button>
      </div>
    </div>
  </div>
  <div class="body" id="main" v-if="data1.length > 0">
    <div style="direction: rtl">
      <br />
      <h3>{{ $t("General Ledger") }} {{ $t("general") }}</h3>
      <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
      <table class="table" v-for="i in data1" :key="i">
        <thead>
          <tr>
            <th>{{ i.journalid }}</th>
            <th colspan="2">{{ $t("date") }} : {{ i.trandate }}</th>
            <th colspan="3">
              {{ $t("confirmed") }} {{ $t("date") }} : {{ i.date }} -
              {{ i.time }}
            </th>
          </tr>
          <tr>
            <th>{{ $t("Account") }}</th>

            <th>{{ $t("debit") }}</th>
            <th>{{ $t("credit") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("ccenter") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="x in i.data" :key="x">
            <td style="width: 30%">
              <span v-if="css.lang == 'ar'">{{ x.namear }}</span><span v-if="css.lang == 'en'">{{ x.nameen }}</span> -
              {{ x.actnum }}
            </td>

            <td>{{ Number(x.dpt).toFixed(2) }}</td>
            <td>{{ Number(x.crd).toFixed(2) }}</td>
            <td style="width: 30%">{{ x.description }}</td>
            <td>{{ x.ccentername }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{{ $t("confirmed") }} {{ $t("by") }} : {{ i.username }}</td>

            <td>{{ i.totaldpt }}</td>
            <td>{{ i.totalcrd }}</td>
            <td colspan="3">{{ $t("total") }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div v-if="this.data1.length > 0">

  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import html2pdf from "html2pdf.js";
import * as XLSX from 'xlsx';
export default {
  async mounted() {
    const result2 = await axios.get("/data/getcostcenter");

    const result = await axios.get(
      `/reports/JournalReport/${this.fromdate}/${this.todate}`
    );
    if (result.status == 200) {
      this.branches = result2.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
      const uniqueIds = new Set();
      const alldata = result.data.data;
      const data2 = alldata.filter((element) => {
        const isDuplicate = uniqueIds.has(element.journalid);
        uniqueIds.add(element.journalid);
        if (!isDuplicate) {
          return true;
        }
        return false;
      });
      for (let i = 0; i < data2.length; i++) {
        const data = alldata.filter((x) => x.journalid == data2[i].journalid);
        const totaldpt = data.reduce(
          (acc, array) => acc + Number(array.dpt),
          0
        );
        const totalcrd = data.reduce(
          (acc, array) => acc + Number(array.crd),
          0
        );
        this.data1.push({
          trandate: data2[i].trandate,
          journalid: data2[i].journalid,
          date: data2[i].date,
          time: data2[i].time,
          data: data,
          totaldpt: Number(totaldpt).toFixed(2),
          totalcrd: Number(totalcrd).toFixed(2),
          username: data2[i].username,
        });
      }
    }
  },
  props: ["css"],
  data() {
    return {
      fromdate: moment().format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      data2: [],
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      branches: [],
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      alldata: []
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllReports/GeneralLedgerSummaryMonthly/${val.actnum}/${val.namear}/${val.nameen}/${val.typeact}`,
      });
    },
    async submit() {
      (this.parent = ""), (this.parent2 = ""), (this.data1 = []);
      const result = await axios.get(
        `/reports/JournalReport/${this.from}/${this.to}`
      );
      if (result.status == 200) {
        this.fromdate = this.from;
        this.todate = this.to;
        const uniqueIds = new Set();
        const alldata = result.data.data;
        this.alldata = result.data.data;
        const data2 = alldata.filter((element) => {
          const isDuplicate = uniqueIds.has(element.journalid);
          uniqueIds.add(element.journalid);
          if (!isDuplicate) {
            return true;
          }
          return false;
        });
        for (let i = 0; i < data2.length; i++) {
          const data = alldata.filter((x) => x.journalid == data2[i].journalid);
          const totaldpt = Number(
            data.reduce((acc, array) => acc + Number(array.dpt), 0)
          ).toFixed(2);
          const totalcrd = Number(
            data.reduce((acc, array) => acc + Number(array.crd), 0)
          ).toFixed(2);
          this.data1.push({
            trandate: data2[i].trandate,
            journalid: data2[i].journalid,
            date: data2[i].date,
            time: data2[i].time,
            data: data,
            totaldpt: Number(totaldpt).toFixed(2),
            totalcrd: Number(totalcrd).toFixed(2),
            username: data2[i].username,
          });
        }
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "JournalReport.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.alldata.length; i++) {
        const name = this.alldata[i]
        data.push({
          رقم_القيد: name.journalid,
          رقم_الحساب: name.actnum,
          اسم_الحساب: name.namear,
          المدين: name.dpt,
          الدائن: name.crd,
          اليوم: name.trandate,
          الساعة: name.time
        })
      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, 'JournalEntry.xlsx');
    }
  },
  watch: {
    async parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
      this.data1 = [];
      const result = await axios.get(
        `/reports/JournalReportcenter/${this.from}/${this.to}/${this.parent}`
      );
      if (result.status == 200) {
        this.fromdate = this.from;
        this.todate = this.to;
        const uniqueIds = new Set();
        const alldata = result.data.data;
        const data2 = alldata.filter((element) => {
          const isDuplicate = uniqueIds.has(element.journalid);
          uniqueIds.add(element.journalid);
          if (!isDuplicate) {
            return true;
          }
          return false;
        });
        for (let i = 0; i < data2.length; i++) {
          const data = alldata.filter((x) => x.journalid == data2[i].journalid);
          const totaldpt = Number(
            data.reduce((acc, array) => acc + Number(array.dpt), 0)
          ).toFixed(2);
          const totalcrd = Number(
            data.reduce((acc, array) => acc + Number(array.crd), 0)
          ).toFixed(2);
          this.data1.push({
            trandate: data2[i].trandate,
            journalid: data2[i].journalid,
            date: data2[i].date,
            time: data2[i].time,
            data: data,
            totaldpt: totaldpt,
            totalcrd: totalcrd,
            username: data2[i].username,
          });
        }
      }
    },
    async parent2() {
      this.data1 = [];
      const result = await axios.get(
        `/reports/JournalReportcenter/${this.from}/${this.to}/${this.parent2}`
      );
      if (result.status == 200) {
        this.fromdate = this.from;
        this.todate = this.to;
        const uniqueIds = new Set();
        const alldata = result.data.data;
        const data2 = alldata.filter((element) => {
          const isDuplicate = uniqueIds.has(element.journalid);
          uniqueIds.add(element.journalid);
          if (!isDuplicate) {
            return true;
          }
          return false;
        });
        for (let i = 0; i < data2.length; i++) {
          const data = alldata.filter((x) => x.journalid == data2[i].journalid);
          const totaldpt = Number(
            data.reduce((acc, array) => acc + Number(array.dpt), 0)
          ).toFixed(2);
          const totalcrd = Number(
            data.reduce((acc, array) => acc + Number(array.crd), 0)
          ).toFixed(2);
          this.data1.push({
            trandate: data2[i].trandate,
            journalid: data2[i].journalid,
            date: data2[i].date,
            time: data2[i].time,
            data: data,
            totaldpt: totaldpt,
            totalcrd: totalcrd,
            username: data2[i].username,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
