<template>
  <div class="logo">
    <img src="../assets/logo.png" alt="" />

    <div class="loading-spinner">
      <div class="spinner"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingPage",
};
</script>

<style lang="scss" scoped>
img {
  width: 60%;
  margin-right: 20%;
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgba(98, 153, 174, 255);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
