<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("Accounts") }}</a>
          </li>
          <li>-</li>
          <li>
            <a> {{ $t("fastjl") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active"> {{ $t("add") }} {{ $t("fastjl") }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <li>
          <router-link to="/AdminDashboard/AllFastJL" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("fastjl") }}</h3>
      </div>
      <div class="body">
        <form>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label"
              >{{ $t("title") }} {{ $t("JL") }}</label
            >
            <div class="col-sm-2">
              <input
                type="text"
                class="form-control"
                id="inputEmail3"
                v-model="title"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label"
              >{{ $t("description") }}
            </label>
            <div class="col-sm-5">
              <textarea
                type="text"
                class="form-control"
                id="inputEmail3"
                v-model="description"
              ></textarea>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label"
              >{{ $t("dpt") }}
            </label>
            <div class="col-sm-5">
              <v-select multiple :options="data1" label="namear" v-model="dpt">
                <template #option="option">
                  <span>{{ option.namear }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label"
              >{{ $t("crd") }}
            </label>
            <div class="col-sm-5">
              <v-select multiple :options="data1" label="namear" v-model="crd">
                <template #option="option">
                  <span>{{ option.namear }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </form>
        <div class="buttom">
          <button class="buttonconfirm" type="submit" @click="submit">
            {{ $t("save") }}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button class="buttoncancel" type="submit" @click="view">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { reactive, computed } from "vue";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";

export default {
  async mounted() {
    if (this.r13.create != true) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/getaccounttrue");
    if (result.status == 200) {
      this.data1 = result.data.data;
    }
  },
  components: {
    vSelect,
  },
  props: ["css"],

  data() {
    return {
      name: "",
      status: "",
      mobile: "",
      code: "",
      r13: JSON.parse(store.state.randp.r13),
      data1: [],
      dpt: "",
      crd: "",
      description: "",
      title: "",
    };
  },

  methods: {
    view() {
      this.$root.$router.push({
        path: `/AdminDashboard/AllFastJL`,
      });
    },
    submit() {
      Swal.fire({
        title: this.$t("confirm") + "-" + this.$t("fastjl"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/confirmfastjltemplete", {
            username: store.state.randp.username,
            title: this.title,
            description: this.description,
            dpt: JSON.stringify(this.dpt),
            crd: JSON.stringify(this.crd),
          });
          if (action.status == 200) {
            Swal.fire(
              this.$t("done") + " " + this.$t("confirm"),
              this.$t("JL")
            );
            this.redirectTo({ val: "FastJL" });
          }
        }
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.namear.includes(this.name.toLowerCase())
      );
    },

    status() {
      this.table.rows = this.table.data.filter((x) => x.review == this.status);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

a {
  text-decoration: none;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}

.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.head-title .left .breadcrumb li {
  color: #342e37;
}

.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}

.head-option ul li {
  display: inline;
  margin: 0.15%;
}

.head-option .btn-download {
  // width: 20%;
  padding: 0.3%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
</style>
