<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/taskshome" class="active">{{
              $t("CRM")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">
              {{ $t("request") }} - {{ this.$route.params.id }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("request") }} {{ this.$route.params.id }}</h3>
        </div>
        <div class="head2"></div>
      </div>
      <div class="body">
        <div class="part1">
          <form>
            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-3 col-form-label"
                >{{ $t("type") }} {{ $t("contact") }}</label
              >
              <div class="col-sm-5">
                {{ $t(type) }}
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-3 col-form-label"
                >{{ $t("assign") }}
              </label>
              <div class="col-sm-5">
                {{ $t(assign) }}
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-3 col-form-label"
                >{{ $t("applicant name") }}
              </label>
              <div class="col-sm-5">
                {{ name }}
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-3 col-form-label"
                >{{ $t("mobile") }}
              </label>
              <div class="col-sm-5">
                {{ mobile }}
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-3 col-form-label"
                >{{ $t("details") }}
              </label>
              <div class="col-sm-5">
                {{ details }}
              </div>
            </div>
          </form>

          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    اعادة تعيين
                  </h5>
                </div>
                <div class="modal-body">
                  <div class="row mb-3">
                    <label for="inputPassword3" class="col-sm-2 col-form-label"
                      >{{ $t("assign") }}
                    </label>

                    <div class="col-sm-3">
                      <select v-model="reassign">
                        <option value="financial">{{ $t("financial") }}</option>
                        <option value="maintenance">
                          {{ $t("maintenance") }}
                        </option>
                        <option value="operation">{{ $t("operation") }}</option>
                        <option value="human resources">
                          {{ $t("human resources") }}
                        </option>
                        <option value="operation">{{ $t("license") }}</option>
                        <option value="Executive management">
                          {{ $t("Executive management") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                        >{{ $t("comment") }}</label
                      >
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        v-model="comment"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="reassignaction"
                  >
                    {{ $t("save") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("cancel") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ $t("close") }} {{ $t("request") }}
                  </h5>
                </div>
                <div class="modal-body">
                  <div class="row mb-3">
                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                        >{{ $t("comment") }}</label
                      >
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        v-model="comment"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="closerequest"
                  >
                    {{ $t("save") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("cancel") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body2">
        <div>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            style="padding: 1%"
            v-if="status == 'waiting'"
          >
            {{ $t("assign") }}
          </button>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal2"
            style="padding: 1%"
            v-if="status == 'waiting'"
          >
            {{ $t("close") }} {{ $t("request") }}
          </button>
          <hr />

          <div class="activity">
            <ul v-for="x in activity" :key="x">
              <li style="width: 150px">
                {{ x.action }} <br />
                <span style="font-size: x-small"
                  >{{ x.date }} - {{ x.time }}</span
                >
                <br v-if="x.comment" />{{ x.comment }} <br />{{ x.username }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";

// import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  components: {},
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      date: "",
      assign: "",
      code: "",
      data: [],
      data2: [],
      type: "",
      name: "",
      mobile: "",
      activity: "",
      status: "",
      dep: store.state.dep,
      reassign: "",
      comment: "",
    };
  },
  validations() {
    return {};
  },
  async mounted() {
    const result2 = await axios.get(
      `/data/requestview/${this.$route.params.id}`
    );
    if (result2.status == 200) {
      const data = result2.data.data[0];
      this.assign = data.assign;
      this.type = data.type;
      this.activity = result2.data.data2;
      this.name = data.cname;
      this.mobile = data.cmobile;
      this.details = data.details;
      this.status = data.status;
    }
  },
  methods: {
    closerequest() {
      Swal.fire({
        title: this.$t("close") + " " + this.$t("request"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/closerequest", {
            id: this.$route.params.id,
            username: store.state.randp.username,
            comment: this.comment,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    createinvoice() {
      Swal.fire({
        title: this.$t("create") + " " + this.$t("invoice"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/createinvoicetask", {
            id: this.$route.params.id,
            assign: "purchase",
            data: JSON.stringify(this.data2),
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    reassignaction() {
      Swal.fire({
        title: "اعادة توجية المهمة",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/reassignrequest", {
            id: this.$route.params.id,
            assign: this.reassign,
            username: store.state.randp.username,
            comment: this.comment,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    parent() {
      this.level2 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent.actnum) &&
          x.actnum != this.parent.actnum
      );
      this.costname = this.parent.namear;
      this.costn = this.parent.actnum;
    },
    parent2() {
      this.costname = this.parent2.namear;
      this.costn = this.parent2.actnum;
    },
    ccenter() {
      if (this.ccenter == false) {
        this.costname = "";
        this.costn = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 3%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  // width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.body {
  display: flex;
  margin: 1%;
}

.part1 {
  width: 50%;
}

.body2 {
  width: 100%;
  padding: 1%;
  // border: 1px solid black;
  border-radius: 5px;
  text-align: center;
}

.activity {
  // display: flex;
  margin-top: 2%;
  width: 75%;
  font-size: small;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
