<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("supply order") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">
              {{ $t("supply order") }} - SO-{{ alldata2.soid }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                <a href="#" class="btn-download">
                  <span class="text">Download PDF</span>
                </a>
              </li> -->
        <li>
          <router-link to="/AdminDashboard/AllInvoices" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("supply order") }} - SO-{{ alldata2.soid }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div style="display: flex">
            <div class="part11">
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                  >{{ $t("number") }}
                </label>
                <div class="col-sm-7">
                  <div class="form-control form-control-sm">
                    SO-{{ alldata2.soid }}
                  </div>
                </div>
              </div>
              <div class="row mb-2" v-if="alldata2.qte != null">
                <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                  >{{ $t("offer") }} {{ $t("price") }}
                </label>
                <div class="col-sm-7">
                  <div class="form-control form-control-sm">
                    QTE-{{ alldata2.qte }}
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                  >{{ $t("date") }}
                </label>
                <div class="col-sm-7">
                  <div class="form-control form-control-sm">
                    {{ alldata2.due }}
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                  >{{ $t("description") }}
                </label>
                <div class="col-sm-7">
                  <div class="form-control form-control-sm">
                    {{ alldata2.description }}
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                  >{{ $t("date") }} {{ $t("due") }}
                </label>
                <div class="col-sm-7">
                  <div class="form-control form-control-sm">
                    {{ alldata2.duedate }}
                  </div>
                </div>
              </div>

              <!--  -->
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                  >{{ $t("date") }} {{ $t("supply") }}
                </label>
                <div class="col-sm-7">
                  <div class="form-control form-control-sm">
                    {{ alldata2.supplydate }}
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-2 col-form-label-sm"
                  >{{ $t("Account") }} {{ $t("supply order") }}
                </label>
                <div class="col-sm-7">
                  <div class="form-control form-control-sm">
                    {{ soact.namear }}
                  </div>
                </div>
              </div>
            </div>
            <div class="part12" style="width: 40%">
              <h4>{{ $t("data") }} {{ $t("customer") }}</h4>

              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                  {{ $t("name") }}
                </label>
                <div class="col-sm-7">
                  {{ alldata2.cname }}
                </div>
              </div>
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                  {{ $t("mobile") }}
                </label>
                <div class="col-sm-7">
                  {{ alldata2.cmobile }}
                </div>
              </div>
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                  {{ $t("email") }}
                </label>
                <div class="col-sm-7">
                  {{ alldata2.cmail }}
                </div>
              </div>
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                  {{ $t("tax") }}
                </label>
                <div class="col-sm-7">
                  {{ alldata2.ctax }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="part2">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th style="width: 12%">{{ $t("product") }}</th>
                <th>{{ $t("description") }}</th>
                <th style="width: 10%">{{ $t("quantity") }}</th>
                <th style="width: 10%">{{ $t("unit type") }}</th>
                <th>{{ $t("price") }}</th>
                <th>{{ $t("discount") }}</th>
                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                <th>{{ $t("tax") }}</th>
                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                <th>{{ $t("total") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) in details" :key="value">
                <td>{{ id + 1 }}</td>
                <td>{{ value.pronamear }}</td>
                <td>
                  {{ value.description }}
                </td>
                <td>
                  {{ value.quantity }}
                </td>
                <td>{{ value.unittype }}</td>
                <td>{{ value.salep }}</td>
                <td>{{ value.discount }} %</td>
                <td>{{ value.tbt }}</td>
                <td>{{ value.tax }} %</td>
                <td>{{ value.taxamount }}</td>
                <td>{{ value.total }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6"></td>
                <td>{{ $t("total") }}</td>
                <td>{{ alldata2.tbt }}</td>
                <td></td>
                <td>{{ Number(alldata2.tax).toFixed(2) }}</td>
                <td>{{ Number(alldata2.tat).toFixed(2) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("branch") }}
          </label>
          <div class="col-sm-2">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.branch }}
            </div>
          </div>
          <label
            for="inputEmail3"
            class="col-sm-1 col-form-label-sm"
            v-if="alldata2.project"
            >{{ $t("project") }}
          </label>
          <div class="col-sm-2" v-if="alldata2.project">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.project }}
            </div>
          </div>
          <label
            for="inputEmail3"
            class="col-sm-1 col-form-label-sm"
            v-if="alldata2.task"
            >{{ $t("task") }}
          </label>
          <div class="col-sm-2" v-if="alldata2.task">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.task }}
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
            >{{ $t("notes") }}
          </label>
          <div class="col-sm-5">
            <div class="form-control" id="exampleFormControlTextarea1">
              {{ alldata2.notes }}
            </div>
          </div>
        </div>

        <hr />
        <div class="user">
          <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("created") }}
          {{ $t("by") }} : {{ alldata2.username }}
          <div class="udate">
            {{ $t("date") }} : {{ alldata2.date }} - {{ $t("time") }} :
            {{ alldata2.time }}
          </div>
        </div>
        <div class="user" v-if="alldata2.review == 'confirmed'">
          <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("confirmed") }}
          {{ $t("by") }} : {{ alldata2.reviewusername }}
          <div class="udate">
            {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
            {{ alldata2.reviewtime }}
          </div>
        </div>
        <div class="user" v-if="alldata2.review == 'cancel'">
          <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("cancel") }}
          {{ $t("by") }} : {{ alldata2.reviewusername }}
          <div class="udate">
            {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
            {{ alldata2.reviewtime }}
          </div>
        </div>
      </div>
      <br />
      <div class="buttom">
        <button
          class="buttonconfirm"
          type="submit"
          @click="confirm"
          v-if="alldata2.review == 'waiting'"
        >
          <i class="bi bi-file-check-fill"></i> {{ $t("confirm") }}
        </button>
        <button class="buttonconfirm" type="submit" @click="print2">
          <i class="bi bi-printer-fill"></i> {{ $t("printer") }}
        </button>
        <button class="buttonconfirm" type="submit" @click="pdf">
          <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
        </button>
        <button class="buttoncancel" type="submit" @click="back">
          {{ $t("cancel") }}
        </button>
      </div>
    </div>
    <div id="main" style="padding: 2%; display: none">
      <div style="padding: 2%; direction: rtl; font-size: small">
        <!-- <img src="./logo.png" style="height: 200px; width: 100%" /> -->
        <img
          :src="this.url"
          crossorigin="anonymous"
          style="height: 100px; width: 25%; float: right"
        />
        <qrcode-vue
          style="float: left"
          render-as="svg"
          value="ASbYp9mE2KrZiNi12YrZhCDYp9mE2KfZhdmGINin2YTYs9ix2YrYuQIPMzEwOTAxNzEyNTAwMDAzAxMyMDI0LTAxLTA4VDExOjExOjMyBAU1NzUuMAUENzUuMA=="
          :size="150"
          level="H"
        />

        <div class="part1" style="clear: both">
          <div class="part11">
            <h3>فاتورة ضريبية</h3>
            {{ $t("number") }} : INV-
            {{ alldata2.invid }}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {{ $t("date") }} : {{ alldata2.date }} / {{ alldata2.time }}
            <br />
          </div>
          <br />
          <div class="part12">
            <h4>معلومات البائع</h4>
            <div style="padding: 2%">
              اسم البائع :
              {{ this.$store.state.cnum[0].cname }}
              &nbsp;&nbsp;&nbsp; عنوان البائع :
              {{ this.$store.state.cnum[0].adress }}
              &nbsp;&nbsp;&nbsp; رقم تسجيل ضريبة القيمة المضافة للبائع :
              {{ this.$store.state.cnum[0].taxnum }}
              &nbsp;&nbsp;&nbsp; رقم السجل التجاري :
              {{ this.$store.state.cnum[0].taxnum }}
            </div>
          </div>
          <div class="part12">
            <h4>معلومات المشتري</h4>
            <div style="padding: 2%">
              اسم البائع :
              {{ alldata2.cname }}
              &nbsp;&nbsp;&nbsp; عنوان البائع :
              {{ alldata2.caddress }}
              &nbsp;&nbsp;&nbsp; رقم السجل التجاري :
              {{ alldata2.caddress }}
              &nbsp;&nbsp;&nbsp; رقم تسجيل ضريبة القيمة المضافة للبائع :
              {{ alldata2.caddress }}
            </div>
          </div>
        </div>
        <br />
        <div class="part2">
          <table style="width: 100%; text-align: center; font-size: small">
            <thead>
              <tr style="height: 50px">
                <th style="border: 0.5px solid black">#</th>
                <th style="width: 12%; border: 0.5px solid black">
                  {{ $t("product") }}
                </th>
                <th style="border: 0.5px solid black">
                  {{ $t("description") }}
                </th>
                <th style="width: 10%; border: 0.5px solid black">
                  {{ $t("quantity") }}
                </th>
                <th style="width: 10%; border: 0.5px solid black">
                  {{ $t("unit type") }}
                </th>
                <th style="border: 0.5px solid black">{{ $t("price") }}</th>
                <th style="border: 0.5px solid black">{{ $t("discount") }}</th>
                <th style="border: 0.5px solid black">
                  {{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}
                </th>
                <th style="border: 0.5px solid black">{{ $t("tax") }}</th>
                <th style="border: 0.5px solid black">
                  {{ $t("amount") }} {{ $t("tax") }}
                </th>
                <th style="border: 0.5px solid black">{{ $t("total") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(value, id) in details"
                :key="value"
                style="height: 50px"
              >
                <td style="border: 0.5px solid black">{{ id + 1 }}</td>
                <td style="border: 0.5px solid black">{{ value.pronamear }}</td>
                <td style="border: 0.5px solid black">
                  {{ value.description }}
                </td>
                <td style="border: 0.5px solid black">
                  {{ value.quantity }}
                </td>
                <td style="border: 0.5px solid black">{{ value.unittype }}</td>
                <td style="border: 0.5px solid black">{{ value.salep }}</td>
                <td style="border: 0.5px solid black">
                  {{ value.discount }} %
                </td>
                <td style="border: 0.5px solid black">{{ value.tbt }}</td>
                <td style="border: 0.5px solid black">{{ value.tax }} %</td>
                <td style="border: 0.5px solid black">{{ value.taxamount }}</td>
                <td style="border: 0.5px solid black">{{ value.total }}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td colspan="11">
                  <hr />
                </td>
              </tr>
              <tr style="height: 50px">
                <td colspan="8"></td>
                <td colspan="2" style="border: 0.5px solid black">
                  {{ $t("total") }}
                </td>
                <td style="border: 0.5px solid black">{{ alldata2.tbt }}</td>
              </tr>
              <tr style="height: 50px">
                <td colspan="8"></td>
                <td colspan="2" style="border: 0.5px solid black">
                  ضريبة القيمة المضافة (15%)
                </td>
                <td style="border: 0.5px solid black">{{ alldata2.tax }}</td>
              </tr>
              <tr style="height: 50px">
                <td colspan="8"></td>
                <td colspan="2" style="border: 0.5px solid black">
                  المجموع مع الضريبة (15%)
                </td>
                <td style="border: 0.5px solid black">{{ alldata2.tat }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <hr />
        {{ $t("description") }} :
        {{ alldata2.description }}
        <br />
        <br />
        {{ $t("notes") }} :
        {{ alldata2.notes }}
        <hr />
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  async mounted() {
    const data2 = await axios.get(
      `/data/SupplyOrderview/${this.$route.params.id}`
    );
    if (data2.status == 200) {
      const data = data2.data.data[0];
      this.alldata2 = data;
      this.soact = JSON.parse(data.soact);
      this.details = JSON.parse(this.alldata2.details);
      this.totaldpt = this.details.reduce(
        (acc, array) => acc + Number(array.dpt),
        0
      );
      this.totalcrd = this.details.reduce(
        (acc, array) => acc + Number(array.crd),
        0
      );
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      alldata2: "",
      details: [],
      fileurl: [],
      url: "",
      soact: "",
    };
  },
  methods: {
    confirm() {
      Swal.fire({
        title:
          this.$t("confirm") +
          " " +
          this.$t("invoice") +
          " " +
          this.$t("sales") +
          " INV- " +
          this.alldata2.invid,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/confirmsupplyorder", {
            id: this.alldata2.soid,
            cnum: store.state.cnum[0].cnum,
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(
              this.$t("confirm") +
                " " +
                this.$t("invoice") +
                " " +
                this.$t("sales") +
                " INV- " +
                this.alldata2.invid
            );
            this.redirectTo({ val: "AllInvoices" });
          }
        }
      });
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.1,
        filename: `INV-${this.alldata2.invid}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print() {
      var divElements = document.getElementById("main").innerHTML;
      var WinPrint = window.open();
      WinPrint.document.write(
        "<html><head><title>ESLAM</title></head><body>" +
          divElements +
          "</body>"
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    print2() {
      this.$htmlToPaper("main");
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}

a {
  text-decoration: none;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}

.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.head-title .left .breadcrumb li {
  color: #342e37;
}

.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}

.head-option ul li {
  display: inline;
  padding: 0.5%;
}

.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  //   display: flex;
}

.part1 {
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  width: 100%;
}

.part11 {
  width: 50%;
}

.part12 {
  width: 100%;
  padding: 1%;
  //   border: solid rgba(0, 0, 0, 0.274) 00.5px;
  background-color: white;
}

.part2 {
  background: v-bind("css.light");
  padding: 1%;
  width: 100%;
}

.options {
  height: 150px;
  margin: 1%;
  background-color: v-bind("css.light");
  color: v-bind("css.dark");
  padding: 2%;
  overflow-y: auto;
}

.options ul li {
  margin: 0.5%;
}

.options ul li:hover {
  color: white;
  background-color: black;
}

.buttom {
  text-align: center;
}

button {
  margin: 0.5%;
}

.user {
  width: 50%;
  // border: black solid 0.5px;
  padding: 1%;
  border-radius: 5px;
}

.udate {
  font-size: small;
  color: #3c91e6;
}

table {
  background-color: white;
}
</style>
