<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#">{{ $t("reports") }}</a>
        </li>
        <li>-</li>
        <li>
          <a>{{ $t("summary") }} {{ $t("Account") }} </a>
        </li>
        <li>-</li>
        <li>
          <a class="active" href=""
            ><span v-if="css.lang == 'ar'">{{ namear }}</span
            ><span v-if="css.lang == 'en'">{{ nameen }}</span> {{ view }}</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="row">
        <div class="col-sm-3">
          <div class="col-auto">
            <label for="inputPassword6" class="col-form-label"
              >{{ $t("Account") }} :</label
            >
          </div>
          <v-select
            :options="accounts"
            label="namear"
            v-model="item"
            style="background-color: white; color: black"
          >
            <template #option="option">
              <span style="color: black"> {{ option.namear }}</span>
            </template>
          </v-select>
        </div>
        <div class="col-sm-2">
          <div class="col-auto">
            <label for="inputPassword6" class="col-form-label"
              >{{ $t("from") }} :</label
            >
          </div>
          <input
            type="date"
            class="form-control form-control-sm"
            :placeholder="$t('from')"
            v-model="from"
          />
        </div>
        <div class="col-sm-2">
          <div class="col-auto">
            <label for="inputPassword6" class="col-form-label"
              >{{ $t("to") }} :</label
            >
          </div>
          <input
            type="date"
            class="form-control form-control-sm"
            :placeholder="$t('to')"
            v-model="to"
          />
        </div>
      </div>
      <div class="col" style="margin: 1%">
        <button class="btn btn-primary" @click="submit">
          {{ $t("search") }} <i class="bi bi-search"></i>
        </button>
      </div>
      <!-- <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("Account") }} :</label
          >
        </div>
        <div class="col-auto">
          <v-select
            :options="accounts"
            label="namear"
            v-model="item"
            style="background-color: white; width: 400px; color: black"
          >
            <template #option="option">
              <span style="color: black"> {{ option.namear }}</span>
            </template>
          </v-select>
        </div>
      </div>
      &nbsp;&nbsp;
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("from") }} :</label
          >
        </div>
        <div class="col-auto">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="from"
          />
        </div>
      </div>
      &nbsp;&nbsp;
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="inputPassword6" class="col-form-label"
            >{{ $t("to") }} :</label
          >
        </div>
        <div class="col-auto">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="to"
          />
        </div>
      </div>

      <div class="col">
        <button class="btn btn-primary" @click="submit">
          {{ $t("search") }} <i class="bi bi-search"></i>
        </button>
        &nbsp;
        <button class="btn btn-light">
          {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
        </button>
      </div> -->
    </div>
    <div class="body" v-if="this.view == 0">برجاء اخيار الحساب</div>
    <div class="body" v-if="this.view != 0">
      <div class="chart">
        <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
      </div>
      <h3>
        {{ $t("summary") }} {{ $t("General Ledger") }} -
        <span v-if="css.lang == 'ar'">{{ namear }}</span
        ><span v-if="css.lang == 'en'">{{ nameen }}</span> {{ view }}
      </h3>
      <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("month") }}</th>
            <th>{{ $t("debit") }}</th>
            <th>{{ $t("credit") }}</th>
            <th>{{ $t("balance") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data1" :key="i">
            <td>
              <router-link to="" @click="summary(i)">{{ i.form }}</router-link>
            </td>
            <td>
              <span v-if="i.dpt == null">0</span
              ><span v-if="i.dpt != null">{{ Number(i.dpt).toFixed(2) }} </span>
            </td>
            <td>
              <span v-if="i.crd == null">0</span
              ><span v-if="i.crd != null">{{ Number(i.crd).toFixed(2) }} </span>
            </td>
            <td v-if="i.typeact == 'debit'">
              {{ Number(i.dpt - i.crd).toFixed(2) }}
            </td>
            <td v-if="i.typeact == 'credit'">
              {{ Number(i.crd - i.dpt).toFixed(2) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{{ $t("total") }}</td>
            <td>{{ totaldpt }}</td>
            <td>{{ totalcrd }}</td>
            <td v-if="typeact == 'debit'">{{ totaldpt - totalcrd }}</td>
            <td v-if="typeact == 'credit'">{{ totalcrd - totaldpt }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
import vSelect from "vue-select";
export default {
  components: { Bar, vSelect },
  async mounted() {
    this.view = this.$route.params.id;
    this.namear = this.$route.params.namear;
    this.nameen = this.$route.params.nameen;
    this.typeact = this.$route.params.typeact;
    const result2 = await axios.get("/data/getaccounttrue");
    if (result2.status == 200) {
      this.accounts = result2.data.data;
    }
    if (this.view != 0) {
      const result = await axios.get(
        `/reports/generalledgersummarymonthly/${this.fromdate}/${this.todate}/${this.view}/${this.typeact}`
      );
      if (result.status == 200) {
        this.data1 = result.data.data;
        this.totaldpt = this.data1.reduce(
          (acc, array) => acc + Number(array.dpt),
          0
        );
        this.totalcrd = this.data1.reduce(
          (acc, array) => acc + Number(array.crd),
          0
        );

        for (let i = 0; i < this.data1.length; i++) {
          const date = this.data1[i].form;
          this.chartData.labels.push(date);
          if (this.typeact == "debit") {
            const amount = this.data1[i].dpt - this.data1[i].crd;
            this.chartData.datasets[0].data[i] = amount;
          }
        }
      }
    }
  },
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: moment().startOf("year").format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      view: 0,
      namear: "",
      nameen: "",
      typeact: "",
      accounts: [],
      selection: false,
      filter: "",
      chartData: {
        labels: [],
        datasets: [{ label: "Balance", data: [] }],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllReports/GeneralLedgerSummaryDaily/${this.view}/${this.namear}/${this.nameen}/${val.start}/${val.end}/${this.typeact}`,
      });
    },
    async submit() {
      const result = await axios.get(
        `/reports/generalledgersummarymonthly/${this.from}/${this.to}/${this.item.actnum}/${this.item.typeact}`
      );
      if (result.status == 200) {
        this.data1 = result.data.data;
        this.view = this.item.actnum;
        this.namear = this.item.namear;
        this.nameen = this.item.nameen;
        this.fromdate = this.from;
        this.todate = this.to;
        this.typeact = this.item.typeact;
        this.totaldpt = this.data1.reduce(
          (acc, array) => acc + Number(array.dpt),
          0
        );
        this.totalcrd = this.data1.reduce(
          (acc, array) => acc + Number(array.crd),
          0
        );
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        for (let i = 0; i < this.data1.length; i++) {
          const date = this.data1[i].form;
          this.chartData.labels.push(date);
          if (this.item.typeact == "debit") {
            const amount = this.data1[i].dpt - this.data1[i].crd;
            this.chartData.datasets[0].data[i] = amount;
          }
        }
      }
    },
  },
  watch: {
    filter() {
      this.accounts = this.accounts.filter((x) =>
        x.namear.includes(this.filter.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
  color: black;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.main {
  margin: 1%;
}
.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 1%;
  // display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  text-align: center;
}

.col {
  padding-left: 2%;
  padding-right: 2%;
}
tr {
  text-align: v-bind("css.dirn");
}
.table {
  width: 75%;
  margin-right: 10%;
  margin-left: 10%;
  background-color: white;
}
.child {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  background-color: #d6d6d6;
}
.childname {
  //   border-top: solid 1px black;
  //   border-bottom: solid 2px black;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #d6d6d6;
}

.options {
  width: 300px;
  height: 150px;
  margin: 0%;
  background-color: v-bind("css.light");
  color: v-bind("css.dark");
  padding: 1%;
  overflow-y: auto;
  position: absolute;
  border: black 2px solid;
}
.chart {
  width: 50%;
  text-align: center;
  margin-left: 25%;
  margin-right: 25%;
}
.table thead th {
  background-color: #c6d7e9;
}
tfoot {
  background-color: #dfdddd;
}
@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
