<template>
  <Navbar />
</template>
<script>
import Navbar from "@/components/AllNavBar.vue";
import store from "@/store";
export default {
  components: { Navbar },
  data() {
    return {
      r1: JSON.parse(store.state.randp.r1).create,
    };
  },
  async mounted() {
    if (this.r1 != true) {
      this.$root.$router.push({
        path: "/SignIn",
      });
    }
  },

  methods: {},
  watch: {},
};
</script>