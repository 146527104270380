<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("Property") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allproperties" class="active">{{ $t("all") }} {{ $t("real estate") }} /
              {{ $t("units") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ $t("real estate") }} / {{ $t("units") }} :
              {{ name }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ name }}</h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" @click="Edite">
            {{ $t("Edite") }}
          </button>
        </div>
      </div>
      <br />
      <div class="body">
        <div class="body3">
          <div class="selection">
            <button class="" :class="select1 ? 'active' : ''" @click="
              (this.select1 = true),
              (this.select2 = false),
              (this.select3 = false),
              (this.select4 = false),
              (this.select5 = false)
              ">
              {{ $t("contracts") }}
            </button>
            <button class="" :class="select2 ? 'active' : ''" @click="
              (this.select1 = false),
              (this.select2 = true),
              (this.select3 = false),
              (this.select4 = false),
              (this.select5 = false)
              ">
              {{ $t("payment") }} / {{ $t("collection") }}
            </button>
            <button class="" :class="select3 ? 'active' : ''" @click="
              (this.select1 = false),
              (this.select2 = false),
              (this.select3 = true),
              (this.select4 = false),
              (this.select5 = false)
              ">
              {{ $t("expences") }}
            </button>
            <button class="" :class="select4 ? 'active' : ''" @click="
              (this.select1 = false),
              (this.select2 = false),
              (this.select3 = false),
              (this.select4 = true),
              (this.select5 = false)
              ">
              {{ $t("maintenance") }}
            </button>
            <button class="" :class="select5 ? 'active' : ''" @click="
              (this.select1 = false),
              (this.select2 = false),
              (this.select3 = false),
              (this.select4 = false),
              (this.select5 = true)
              ">
              عروض الاسعاء
            </button>
            <!-- <button @click="this.select = 4">{{ $t("other") }}</button> -->
          </div>
          <div class="group2" v-if="select1 == true">
            <div style="margin: 1%">
              <button class="buttonconfirm" type="submit" @click="create">
                {{ $t("add") }} {{ $t("contract") }}
              </button>
              <!-- Button trigger modal -->
              <!-- <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                {{ $t("add") }} {{ $t("contract") }}
              </button> -->

              <!-- Modal -->
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {{ $t("add") }} {{ $t("contract") }}
                      </h5>
                    </div>
                    <div class="modal-body">
                      <div class="group2">
                        <div class="part">
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("date") }} {{ $t("conclusion") }}
                              {{ $t("contract") }}
                            </label>
                            <div class="col-sm-7">
                              <input type="date" class="form-control" v-model="contconclusion" />
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("date") }} {{ $t("start") }}
                              {{ $t("contract") }}
                            </label>
                            <div class="col-sm-7">
                              <input type="date" class="form-control" v-model="contstart" />
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("duration") }}
                              {{ $t("contract") }}
                            </label>
                            <div class="col-sm-7" style="display: flex">
                              <input type="number" class="form-control" v-model="contduration" @change="
                                (this.contamount = 0), (this.unite = '')
                                " />
                              &nbsp; شهر
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("date") }} {{ $t("end") }}
                              {{ $t("contract") }}
                            </label>
                            <div class="col-sm-7">
                              <input type="date" class="form-control" disabled v-model="contend" />
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("renewal") }}
                              {{ $t("contract") }}
                            </label>
                            <div class="col-sm-7">
                              <select class="form-control" v-model="renewal">
                                <option value="false">بدون تجديد</option>
                                <option value="true">لمدة مماثلة</option>
                              </select>
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">رقم عقد منصة ايجار
                            </label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="igarnumber" />
                            </div>
                          </div>
                        </div>
                        <div class="part">
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("unit") }}
                            </label>
                            <div class="col-sm-7">
                              <select class="form-control" v-model="unite" @change="
                              (this.withouttax =
                                (this.unite.annualrent * 85) / 100),
                                (this.contamount = Number(
                                  (this.unite.annualrent / 12) *
                                  this.contduration
                                ).toFixed(2)),
                                (this.dueamount = Number(
                                  (this.unite.annualrent / 12) *
                                  this.unite.due
                                ).toFixed(2)),
                                (this.duenum = this.unite.due)
                                ">
                                <option v-for="x in prodata" :key="x" :value="x">
                                  {{ x.type }} رقم {{ x.unitnum }} - الدور :
                                  {{ x.role }} - العقد السنوى :
                                  {{ x.annualrent }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("renter") }}
                            </label>
                            <div class="col-sm-7">
                              <v-select :options="owners" label="name" v-model="renter">
                                <template #option="option">
                                  <div class="option">
                                    <span>{{ option.name }}</span>
                                  </div>
                                </template>
                              </v-select>
                            </div>
                          </div>

                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("type") }} {{ $t("contract") }}
                            </label>
                            <div class="col-sm-7">
                              <select class="form-control" v-model="conttype">
                                <option value="residential">سكنى</option>
                                <option value="commercial">تجارى</option>
                              </select>
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("amount") }} {{ $t("contract") }}
                            </label>
                            <div class="col-sm-7">
                              {{ contamount }}
                            </div>
                          </div>
                          <div class="row mb-1">
                            <label class="col-sm-3 col-form-label">{{ $t("amount") }} {{ $t("payment") }}
                            </label>
                            <div class="col-sm-7">
                              {{ dueamount }}
                              <span v-if="duenum == 12">شهرى</span>
                              <span v-if="duenum == 3">ربع سنوي</span>
                              <span v-if="duenum == 6">نصف سنوى</span>
                              <span v-if="duenum == 1">سنويا</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="group2">
                        <div class="part">
                          <h5>العمولات</h5>
                          <div style="width: 25%">
                            <v-select :options="allaccounts" label="name" v-model="allcommission">
                              <template #option="option">
                                <div style="text-align: center">
                                  <span>{{ option.name }}</span>
                                </div>
                              </template>
                            </v-select>
                          </div>
                          <table class="table">
                            <thead>
                              <tr>
                                <th>العمولة</th>
                                <th>حساب العمولة</th>
                                <th>نسبة العمولة</th>
                                <th>مبلغ العمولة</th>
                                <th>حساب الضريبة</th>
                                <th>ضريبة العمولة</th>
                                <th>المبلغ</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(y, id) in commissions" :key="y">
                                <td style="width: 20%">
                                  {{ y.name }}
                                </td>
                                <td style="width: 30%">
                                  <v-select label="namear" v-model="y.act" disabled>
                                    <template #option="option">
                                      <span>{{ option.namear }}</span>
                                    </template>
                                  </v-select>
                                </td>
                                <td>
                                  <div style="display: flex">
                                    <input type="text" v-model="y.num" @change="
                                    (y.comamount = Number(
                                      (this.unite.annualrent * y.num) / 100
                                    ).toFixed(2)),
                                      (y.amount = Number(
                                        (this.unite.annualrent * y.num) /
                                        100 +
                                        (((this.unite.annualrent *
                                          y.num) /
                                          100) *
                                          y.tax) /
                                        100
                                      ).toFixed(2))
                                      " />&nbsp;%
                                  </div>
                                </td>
                                <td>
                                  <div style="display: flex; width: 120%">
                                    <input type="text" v-model="y.comamount" @change="
                                    (y.num = Number(
                                      (y.comamount /
                                        this.unite.annualrent) *
                                      100
                                    ).toFixed(2)),
                                      (y.amount = Number(
                                        Number(y.comamount) +
                                        (Number(y.comamount) * y.tax) /
                                        100
                                      ).toFixed(2))
                                      " />
                                  </div>
                                </td>
                                <td style="width: 30%">
                                  <v-select :options="allaccounts" label="namear" v-model="y.taxact" disabled>
                                    <template #option="option">
                                      <span>{{ option.namear }}</span>
                                    </template>
                                  </v-select>
                                </td>
                                <td style="display: flex">
                                  <div style="display: flex">
                                    <input type="text" v-model="y.tax" @change="
                                      y.amount = Number(
                                        (this.unite.annualrent * y.num) /
                                        100 +
                                        (((this.unite.annualrent * y.num) /
                                          100) *
                                          y.tax) /
                                        100
                                      ).toFixed(2)
                                      " />&nbsp;%
                                  </div>
                                </td>
                                <td>{{ y.amount }}</td>
                                <td>
                                  <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <h5>{{ $t("notes") }}</h5>
                      <textarea v-model="notes" id="" style="width: 100%" rows="3"></textarea>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" :disabled="disabled" @click="submite()">
                        {{ $t("confirm") }}
                      </button>
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        style="background-color: red">
                        {{ $t("cancel") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="group2" v-if="select1 == true">
            <div class="part">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("number") }} {{ $t("contract") }}</th>
                    <th>{{ $t("name") }} {{ $t("renter") }}</th>
                    <th>{{ $t("status") }}</th>
                    <th>
                      {{ $t("date") }} {{ $t("start") }} {{ $t("contract") }}
                    </th>
                    <th>
                      {{ $t("date") }} {{ $t("end") }} {{ $t("contract") }}
                    </th>
                    <th>{{ $t("duration") }} {{ $t("contract") }}</th>
                    <th>{{ $t("unit") }}</th>
                    <th>{{ $t("amount") }} {{ $t("contract") }}</th>
                    <th>{{ $t("number") }} {{ $t("contract") }}</th>
                    <th>رقم عقد منصة ايجار</th>
                    <th></th>
                    <th v-if="hasPermission('property_delete')"> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="x in allcontracts" :key="x">
                    <td>
                      <router-link :to="`/property/ContactView/${x.conid}`" class="active">{{ x.conid }}</router-link>
                    </td>
                    <td>
                      <router-link :to="`/property/ContactView/${x.conid}`" class="active">{{ x.rentername
                        }}</router-link>
                    </td>

                    <td>{{ $t(x.status) }}</td>
                    <td>{{ x.contstart }}</td>
                    <td>{{ x.contend }}</td>
                    <td>{{ x.contduration }} شهر</td>
                    <td>{{ $t("number") }} {{ x.unite }}</td>
                    <td>{{ Number(x.contamount).toLocaleString() }}</td>
                    <td>{{ x.conid }}</td>
                    <td>{{ x.igarnumber }}</td>
                    <td>
                      <button @click="pay(x.conid)">
                        <i class="bi bi-credit-card-2-back-fill"></i>
                      </button>
                    </td>
                    <td>
                      <button @click="del(x.conid)" v-if="hasPermission('property_delete')">
                        <i class="bi bi-trash3-fill"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="group2" v-if="select2 == true">
            <div class="part">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("number") }} {{ $t("payment") }}</th>
                    <th>{{ $t("number") }} {{ $t("contract") }}</th>
                    <th>{{ $t("name") }} {{ $t("renter") }}</th>
                    <th>{{ $t("status") }}</th>
                    <th>{{ $t("description") }}</th>
                    <th>{{ $t("amount") }}</th>
                    <th>{{ $t("type") }} {{ $t("payment") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="x in allpayments" :key="x">
                    <td>{{ x.collid }}</td>
                    <td>{{ x.conid }}</td>
                    <td>{{ x.rentername }}</td>
                    <td>{{ $t(x.status) }}</td>
                    <td>{{ x.description }}</td>
                    <td>{{ x.amount }}</td>
                    <td>{{ x.paytype }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="group2" v-if="select5 == true">
            <div class="part"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import vSelect from "vue-select";
import moment from "moment";
export default {
  props: ["css"],
  components: {
    vSelect,
  },
  data() {
    return {
      cnum: store.state.cnum[0].cnum,
      disabled: false,
      v$: useValidate(),
      url: "",
      select1: true,
      select2: false,
      select3: false,
      select4: false,
      select5: false,
      name: "",
      renewal: "",
      prodata: [],
      contamount: 0,
      contduration: 0,
      unite: {},
      notes: "",
      renter: "",
      allcontracts: [],
      commissions: [],
      allcommission: "",
      allaccounts: [],
      categories: [
        { category: "عمولة السعى", value: "1" },
        { category: "عمولة الصيانة", value: "2" },
      ],
      catitem: {},
      owner: "",
      contend: "",
      contstart: "",
      dueamount: "",
      duenum: "",
      allpayments: [],
      withouttax: 0,
      igarnumber: "",
      conttype: "",
      commissiontype: "",
    };
  },
  validations() {
    return {
      name: { required },
    };
  },
  async mounted() {
    const result = await axios.get("/data/customerowner/renter");
    if (result.status == 200) {
      this.owners = result.data.data;
    }
    const result2 = await axios.get(
      `/data/getproperty/${this.$route.params.id}`
    );
    if (result2.status == 200) {
      const data = result2.data.data[0];
      this.url = store.state.imgurl + `/property/`;
      this.name = data.name;
      this.owner = data.ownerid;
      this.type = data.type;
      this.servie = JSON.parse(data.servie);
      this.conductor = data.conductor;
      this.comtype = JSON.parse(data.comtype);
      this.commission = data.commission;
      this.prodata = JSON.parse(data.prodata);
      this.notes = data.notes;
      this.docdata = JSON.parse(data.docdata);
      this.doctype = data.doctype;
      this.docnum = data.docnum;
      this.docdate = data.docdate;
      this.totalspace = JSON.parse(data.totalspace);
      this.payment = data.payment;
      this.commissiontype = data.commissiontype;
    }
    const result3 = await axios.get(
      `/data/getpropertycontracts/${this.$route.params.id}`
    );
    if (result3.status == 200) {
      this.allcontracts = result3.data.data;
    }
    const result4 = await axios.get(
      `/data/getpropertypayment/${this.$route.params.id}`
    );
    if (result4.status == 200) {
      this.allpayments = result4.data.data;
    }
    const resul3 = await axios.get("/data/AllCommissions");
    if (resul3.status == 200) {
      this.allaccounts = resul3.data.data;
    }
  },
  async created() { },
  methods: {
    pay(id) {
      this.$root.$router.push({
        path: `/property/createpayment/${id}/${this.$route.params.id}`,
      });
    },
    Edite() {
      this.$root.$router.push({
        path: `/property/propertyview/${this.$route.params.id}`,
      });
    },
    create() {
      this.$root.$router.push({
        path: `/property/createcontract/${this.$route.params.id}/${this.name}`,
      });
    },
    async del(id) {
      Swal.fire({
        title: this.$t("confirm") + "-" + this.$t("delete"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.get(`/reports/getnumberofpayment/${id}`);
          if (action.status == 200) {
            const number = action.data.data[0].count;
            if (number > 0) {
              Swal.fire("لا يمكن الغاء العقد لوجود معاملات");
              // this.redirectTo({ val: "allpayments" });
            } else {
              const action2 = await axios.patch(
                `/reports/deletecontract/${id}`
              );
              if (action2.status == 200) {
                Swal.fire("تم مسح العقد");
                this.$router.go();
              }
            }
          }
        }
      });
    },
    async submite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          this.disabled = true;
          const result = await axios.post(`/data/propertycontract`, {
            propertyid: this.$route.params.id,
            propertyname: this.name,
            username: store.state.randp.username,
            contconclusion: this.contconclusion,
            contstart: this.contstart,
            contend: this.contend,
            contduration: this.contduration,
            renewal: this.renewal,
            unite: this.unite.unitnum,
            renterid: this.renter.cid,
            rentername: this.renter.name,
            account: this.renter.account,
            conttype: this.conttype,
            contamount: Number(this.contamount).toFixed(2),
            notes: this.notes,
            commission: JSON.stringify(this.commissions),
            ownerid: this.owner,
            duenum: this.duenum,
            dueamount: this.dueamount,
            igarnumber: this.igarnumber,
          });
          if (result.status == 200) {
            // this.redirectTo({ val: "allproperties" });
            this.$router.go();
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Data Entry successfully",
            });
          } else if (result.status == 401) {
            alert("Account Already Created Before");
          }
        } catch (error) {
          alert("Error");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    conttype() {
      this.commissions = [];
    },
    contduration() {
      const currentTime = moment(this.contstart);
      this.contend = moment(currentTime)
        .add(this.contduration, "month")
        .format("YYYY-MM-DD");
      this.commissions = [];
    },
    allcommission() {
      const amount = this.unite.annualrent;
      if (this.unite.annualrent > 0 && this.conttype == "commercial") {
        const withouttax = (amount * 85) / 100;
        this.commissions.push({
          comid: this.allcommission.comid,
          name: this.allcommission.name,
          act: {
            actnum: this.allcommission.actnum,
            namear: this.allcommission.namear,
            nameen: this.allcommission.nameen,
          },
          taxact: {
            actnum: this.allcommission.taxnum,
            namear: this.allcommission.taxnamear,
            nameen: this.allcommission.taxnameen,
          },
          num: this.allcommission.rate,
          comamount: Number(
            (withouttax * this.allcommission.rate) / 100
          ).toFixed(2),
          tax: 15,
          amount: Number(
            (withouttax * this.allcommission.rate) / 100 +
            (((withouttax * this.allcommission.rate) / 100) * 15) / 100
          ).toFixed(2),
        });
      } else if (this.unite.annualrent > 0 && this.conttype == "residential") {
        const withouttax = amount;
        this.commissions.push({
          comid: this.allcommission.comid,
          name: this.allcommission.name,
          act: {
            actnum: this.allcommission.actnum,
            namear: this.allcommission.namear,
            nameen: this.allcommission.nameen,
          },
          taxact: {
            actnum: this.allcommission.taxnum,
            namear: this.allcommission.taxnamear,
            nameen: this.allcommission.taxnameen,
          },
          num: this.allcommission.rate,
          comamount: Number(
            (withouttax * this.allcommission.rate) / 100
          ).toFixed(2),
          tax: 15,
          amount: Number(
            (withouttax * this.allcommission.rate) / 100 +
            (((withouttax * this.allcommission.rate) / 100) * 15) / 100
          ).toFixed(2),
        });
      } else {
        alert("برجاء اختيار الوحدة");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  // border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 50%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.group2 button {
  margin: 0.5%;
  padding: 5%;
  width: auto;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}

.table button {
  padding: 20%;
}
</style>
