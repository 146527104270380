<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#">{{ $t("reports") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#"
            >{{ $t("transactions") }} {{ $t("stock") }} {{ $t("from") }}
            {{ $t("product") }}</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <div style="display: flex">
          <span>{{ $t("product") }}</span>

          <!-- <select type="date" v-model="product">
          <option v-for="val in products" :key="val.id" :value="val">
            {{ val.sn }} - <span v-if="css.lang == 'ar'">{{ val.namear }}</span
            ><span v-if="css.lang == 'en'">{{ val.nameen }}</span>
          </option>
        </select> -->
          <v-select
            :options="products"
            label="namear"
            v-model="product"
            style="width: 20%"
          >
            <template #option="option">
              <span v-if="css.lang == 'ar'">{{ option.namear }}</span
              ><span v-if="css.lang == 'en'">{{ option.nameen }}</span>
            </template>
          </v-select>
        </div>
        <span>{{ $t("branch") }}</span>
        <select v-model="branch">
          <option value="all">{{ $t("all") }} {{ $t("branches") }}</option>
          <option v-for="val in branches" :key="val.id" :value="val.bid">
            {{ val.bid }} - <span v-if="css.lang == 'ar'">{{ val.namear }}</span
            ><span v-if="css.lang == 'en'">{{ val.nameen }}</span>
          </option>
        </select>
        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
        <!-- <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select> -->
      </div>
      <div class="head2"></div>
    </div>
    <div class="body">
      <div id="main">
        <table class="table" :style="{ direction: css.EN }">
          <thead>
            <tr style="text-align: center">
              <th colspan="5">
                {{ $t("transactions") }} {{ $t("stock") }} {{ $t("product") }}
                {{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}
              </th>
            </tr>
            <tr>
              <th colspan="2">
                {{ $t("name") }} {{ $t("product") }} :
                {{ product.namear }}
              </th>
              <th colspan="1">
                {{ $t("sn") }} :
                {{ product.sn }}
              </th>
              <th colspan="2">
                {{ $t("balance") }} {{ $t("opening") }} : {{ openbalance }}
                {{ product.unittype }}
              </th>
            </tr>
            <tr>
              <th>الرقم</th>
              <th>{{ $t("date") }}</th>
              <th>اضافة</th>
              <th>خصم</th>
              <th>التفاصيل</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in data1" :key="x">
              <td>{{ x.transnum }}</td>
              <td>{{ x.transdate }}</td>
              <td>{{ x.qdpt }}</td>
              <td>{{ x.qcrd }}</td>
              <td>{{ x.details }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                {{ $t("balance") }} {{ $t("final") }} : {{ closebalance }}
                {{ product.unittype }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <button class="btn btn-primary" type="submit" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import html2pdf from "html2pdf.js";
import vSelect from "vue-select";
export default {
  title: "Produst Stock Balance",
  components: {
    vSelect,
  },
  async mounted() {
    // const result = await axios.get(
    //   `/reports/stockbalanceall/${this.fromdate}/${this.todate}`
    // );
    // if (result.status == 200) {
    //   this.data1 = result.data.data;
    // }
    const result2 = await axios.get("/data/allbranches");
    if (result2.status == 200) {
      this.branches = result2.data.data;
    }
    const result3 = await axios.get("/data/allproducts");
    if (result3.status == 200) {
      this.products = result3.data.data;
    }
  },
  props: ["css"],
  data() {
    return {
      fromdate: moment().format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      data2: [],
      branches: [],
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      purchasenum: 0,
      type: "",
      branch: "all",
      products: "",
      product: "",
      openbalance: "",
      closebalance: "",
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/AdminDashboard/AllReports/GeneralLedgerSummaryMonthly/${val.actnum}/${val.namear}/${val.nameen}/${val.typeact}`,
      });
    },
    async submit() {
      this.data1 = [];
      this.data2 = [];
      try {
        const result = await axios.get(
          `/reports/productstockbalance/${this.from}/${this.to}/${this.branch.bid}/${this.product.sn}`
        );
        if (result.status == 200) {
          this.openbalance = Number(result.data.data[0].openbala).toFixed(2);
          this.data1 = result.data.data[0].trans;
          const dpt = this.data1.reduce(
            (acc, array) => acc + Number(array.qdpt),
            0
          );
          const crd = this.data1.reduce(
            (acc, array) => acc + Number(array.qcrd),
            0
          );
          this.closebalance = Number(
            Number(this.openbalance) + Number(dpt) - Number(crd)
          ).toFixed(2);
          this.fromdate = this.from;
          this.todate = this.to;
        }
      } catch (error) {
        console.log();
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "barcode.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
  // display: flex;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
