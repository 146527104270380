<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/accounting/home" class="active">{{
              $t("accounting")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/accounting/JournalEntry" class="active">{{
              $t("JL")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/accounting/JournalEntry/CreateJournalEntry" class="active">{{ $t("add") }} {{ $t("JL")
              }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("JL") }} - {{ id }}</h3>
        </div>
        <div class="head2">
          <button @click="submit" :disabled="disabled">{{ $t("save") }}</button>
        </div>
      </div>
      <div class="body">
        <div class="body1" style="display: flex">
          <div class="group">
            <label for="basic-url" class="form-label">{{ $t("type") }} {{ $t("journal") }}
              <span class="error-feedback" v-if="v$.jltype.$error">{{
                "*"
              }}</span>
            </label>
            <div>
              <input type="text" v-model="jltype" />
            </div>
            <label for="basic-url" class="form-label">{{ $t("date") }}
              <span class="error-feedback" v-if="v$.date.$error">{{
                "*"
              }}</span>
            </label>
            <div>
              <input type="date" v-model="date" />
            </div>
          </div>
          <div class="group">
            <label for="basic-url" class="form-label">{{ $t("from") }} {{ $t("fastjl") }}
            </label>
            <div>
              <select></select>
            </div>
            <label for="basic-url" class="form-label">{{ $t("ccenter") }} {{ $t("parent") }}
            </label>
            <div>
              <select v-model="parent">
                <option v-for="x in level1" :key="x" :value="x.actnum">
                  {{ x.namear }}
                </option>
              </select>
            </div>

            <label for="basic-url" class="form-label">{{ $t("ccenter") }} {{ $t("secondary") }}
            </label>
            <div>
              <select v-model="parent2">
                <option v-for="x in level2" :key="x" :value="x.actnum">
                  {{ x.namear }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <hr />
        <h6>{{ $t("details") }} {{ $t("JL") }}</h6>
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th scope="col">{{ $t("dpt") }}</th>
              <th scope="col">{{ $t("crd") }}</th>
              <th scope="col">{{ $t("Account") }}</th>
              <td scope="col">{{ $t("ccenter") }}</td>
              <td scope="col">{{ $t("details") }}</td>
              <td scope="col">{{ $t("Cancel") }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, id) of data" :key="value">
              <td>{{ id + 1 }}</td>
              <td scope="row" style="width: 10%">
                <input class="form-control form-control-sm" type="number" @change="cal" v-model="value.dpt" />
              </td>
              <td scope="row" style="width: 10%">
                <input class="form-control form-control-sm" type="number" @change="cal" v-model="value.crd" />
              </td>
              <td scope="row" style="width: 20%">
                <v-select :options="data1" label="namear" v-model="value.act">
                  <template #option="option">
                    <span>{{ option.namear }}</span>
                  </template>
                </v-select>
              </td>
              <td>
                <select v-model="value.costcenter">
                  <option v-for="x in level3" :key="x" :value="x">
                    {{ x.namear }}
                  </option>
                </select>
              </td>
              <td scope="row" style="width: 30%"><input type="text" v-model="value.details" style="width: 100%;"></td>
              <td>
                <a href="#" @click.prevent="del(value)"><i class="bi bi-x-circle-fill"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="add" style="padding: 0.25%">
          {{ $t("add") }} <i class="bi bi-plus"></i>
        </button>
        <hr />
        <div class="body1" style="display: flex">
          <div class="group">
            <label for="basic-url" class="form-label">{{ $t("total") }} {{ $t("dpt") }}
            </label>
            <div>
              <input type="number" v-model="totaldpt" />
            </div>
            <label for="basic-url" class="form-label">{{ $t("total") }} {{ $t("crd") }}
            </label>
            <div>
              <input type="number" v-model="totalcrd" />
            </div>
          </div>
          <div class="group">
            <label for="basic-url" class="form-label">{{
              $t("description")
            }}</label>
            <div class="input-group mb-3">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="description"></textarea>
            </div>
          </div>
        </div>
        <!-- <div class="row mb-2">
          <label class="col-sm-1 col-form-label-sm"
            >{{ $t("customer") }}
          </label>
          <div class="col-sm-3">
            <select
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              v-model="project"
            >
              <option v-for="val in allprojects" :key="val" :value="val.name">
                {{ val.name }}
              </option>
            </select>
          </div>
        </div> -->
        <!-- <div class="row mb-2">
            <label class="col-sm-1 col-form-label-sm"
              >{{ $t("delegate") }}
            </label>
            <div class="col-sm-3">
              <select
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                v-model="task"
              >
                <option v-for="val in alltasks" :key="val.id" :value="val.name">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div> -->
        <br />
        <div class="row mb-2">
          <label class="col-sm-2 col-form-label-sm">
            <i class="bi bi-pin-angle-fill"></i> {{ $t("add") }}
            {{ $t("attachments") }}
          </label>
          <div class="col-sm-8">
            <div>
              <div>
                <input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()" />
              </div>
              <br />
              <!-- <div v-if="files.length > 0">
                    <div v-for="(file, key) in files" :key="file">
                      {{ file.name }} &nbsp;
                      <a href="#" class="remove-file" v-on:click="removeFile(key)"
                        >Remove</a
                      >
                      </div>
                    </div> -->
              <table class="table" style="width: 25%">
                <thead>
                  <tr>
                    <th>{{ $t("name") }}</th>
                    <th>{{ $t("cancel") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(file, key) in files" :key="file">
                    <td>{{ file.name }}</td>
                    <td>
                      <a href="#" class="remove-file" v-on:click="removeFile(key)"><i
                          class="bi bi-x-circle-fill"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      data: [
        {
          dpt: 0,
          crd: 0,
          actnum: 0,
          namear: "",
          nameen: "",
          details: "",
          costcenter: { actnum: "", namear: "" },
        },
        {
          dpt: 0,
          crd: 0,
          actnum: 0,
          namear: "",
          nameen: "",
          details: "",
          costcenter: { actnum: "", namear: "" },
        },
      ],
      data1: [],
      date: "",
      branches: [],
      allprojects: [],
      alltasks: [],
      project: "",
      task: "",
      branch: "",
      description: "",
      totaldpt: 0,
      totalcrd: 0,
      addto: "",
      url: null,
      files: [],
      fileURL: [],
      id: "",
      type: "",
      jltype: "",
      level1: [],
      level2: [],
      level3: [],
      parent: "",
      parent2: "",
      err: false
    };
  },
  validations() {
    return {
      date: { required },
      description: { required },
      jltype: { required },
    };
  },
  async mounted() {
    const result = await axios.get("/data/getaccounttrue");
    const result2 = await axios.get("/data/getcostcenter");
    const result4 = await axios.get("/data/allcustomer");
    const result6 = await axios.get("/data/JournalEntryID");
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data;
      this.branches = result2.data.data;
      this.allprojects = result4.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
      this.id = Number(result6.data.data[0].count) + Number(1);
    }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/accounting/JournalEntry",
      });
    },
    cal() {
      this.totaldpt = Number(
        this.data.reduce((acc, array) => acc + Number(array.dpt), 0)
      ).toFixed(2);
      this.totalcrd = Number(
        this.data.reduce((acc, array) => acc + Number(array.crd), 0)
      ).toFixed(2);
    },
    async del(value) {
      const indexOfObject = this.data.findIndex((object) => {
        return (
          object.namear === value.namear &&
          object.nameen === value.nameen &&
          object.details === value.details
        );
      });
      this.data.splice(indexOfObject, 1);
    },
    add() {
      this.data.push({
        dpt: 0,
        crd: 0,
        act: "",
        actnum: "",
        namear: "",
        nameen: "",
        details: "",
        totaldpt: 0,
        totalcrd: 0,
        costcenter: { actnum: "", namear: "" },
      });
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        for (let i = 0; i < this.data.length; i++) {
          const element = this.data[i];
          if (!element.act) {
            this.err = true
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "error",
              title: "برجاء مراجعة القيد",
            });
            break
          } else {
            this.err = false
          }
        }
        if (this.totalcrd == this.totaldpt && this.totalcrd != 0 && this.err == false) {
          this.disabled = true;
          for (var i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            let formData = new FormData();
            let name = this.files[i].name.split(".");
            let newname = this.id + "_" + i + "." + name[name.length - 1];
            this.fileURL.push({ name: newname });
            formData.append("file", file, newname);
            await axios.patch(`/uploads/${i}`, formData);
          }
          const result = await axios.post("/data/createnewjournalenty", {
            username: store.state.randp.username,
            type: this.jltype,
            trandate: this.date,
            branch: this.branch,
            description: this.description,
            details: JSON.stringify(this.data),
            project: this.project,
            task: this.task,
            totaldpt: this.totaldpt,
            totalcrd: this.totalcrd,
            fileurl: JSON.stringify(this.fileURL),
          });
          if (result.status == 200) {
            this.redirectTo({ val: "JournalEntry" });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Data Entry successfully",
            });
            // this.redirectTo({ val: "Home" });
          } else if (result.status == 401) {
            alert("Account Already Created Before");
          }
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "برجاء مراجعة القيد",
          });
        }
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    submitFiles() {
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        let formData = new FormData();
        let name = this.files[i].name.split(".");
        let newname = i + "." + name[name.length - 1];
        this.fileURL.push({ name: i + "." + newname });
        formData.append("file", file, newname);
        axios.patch(`/uploads/${i}`, formData);
      }
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data1 = this.data2.filter((x) =>
        x.nameact.includes(this.filter.toLowerCase())
      );
    },
    parent() {
      this.level2 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent) &&
          x.actnum != this.parent &&
          x.sup == "false"
      );
      this.level3 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent) &&
          x.actnum != this.parent &&
          x.sup == "true"
      );
    },
    parent2() {
      this.level3 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent2) &&
          x.actnum != this.parent2 &&
          x.sup == "true"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.main .body {
  padding: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.group {
  padding: 1%;
  width: 50%;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}
</style>
