<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("Property") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allproperties" class="active"
              >{{ $t("offer") }} {{ $t("price") }} - {{ this.$route.params.id }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            {{ $t("offer") }} {{ $t("price") }} -
            {{ this.$route.params.id }}
          </h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" @click="submite">
            {{ $t("create") }} {{ $t("contract") }}
          </button>
        </div>
      </div>
      <br />
      <div class="body">
        <div class="body3">
          <div class="modal-body">
            <div class="group2">
              <div class="part">
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("date") }} {{ $t("conclusion") }}
                    {{ $t("contract") }}
                  </label>
                  <div class="col-sm-7">
                    <input
                      type="date"
                      class="form-control"
                      v-model="contconclusion"
                    />
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("date") }} {{ $t("start") }}
                    {{ $t("contract") }}
                  </label>
                  <div class="col-sm-7">
                    <input
                      type="date"
                      class="form-control"
                      v-model="contstart"
                    />
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("duration") }}
                    {{ $t("contract") }}
                  </label>
                  <div class="col-sm-7" style="display: flex">
                    <input
                      type="number"
                      class="form-control"
                      v-model="contduration"
                      @change="(this.contamount = 0), (this.unite = '')"
                    />
                    &nbsp; شهر
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("date") }} {{ $t("end") }}
                    {{ $t("contract") }}
                  </label>
                  <div class="col-sm-7">
                    <input
                      type="date"
                      class="form-control"
                      disabled
                      v-model="contend"
                    />
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("renewal") }}
                    {{ $t("contract") }}
                  </label>
                  <div class="col-sm-7">
                    <select class="form-control" v-model="renewal">
                      <option value="false">بدون تجديد</option>
                      <option value="true">لمدة مماثلة</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >رقم عقد منصة ايجار
                  </label>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control"
                      v-model="igarnumber"
                    />
                  </div>
                </div>
              </div>
              <div class="part">
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("unit") }}
                  </label>
                  <div class="col-sm-7">
                    {{ unite }}
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("renter") }}
                  </label>
                  <div class="col-sm-7">
                    {{ rentername }}
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("type") }} {{ $t("contract") }}
                  </label>
                  <div class="col-sm-7">
                    <select class="form-control" v-model="conttype">
                      <option value="residential">سكنى</option>
                      <option value="commercial">تجارى</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label">العقد السنوى </label>
                  <div class="col-sm-7">
                    {{ annualrent }}
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-3 col-form-label"
                    >{{ $t("amount") }} {{ $t("contract") }}
                  </label>
                  <div class="col-sm-7">
                    {{ contamount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="group2">
              <div class="part">
                <h5>العمولات</h5>
                <div style="width: 25%">
                  <v-select
                    :options="allaccounts"
                    label="name"
                    v-model="allcommission"
                  >
                    <template #option="option">
                      <div style="text-align: center">
                        <span>{{ option.name }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>العمولة</th>
                      <th>حساب العمولة</th>
                      <th>نسبة العمولة</th>
                      <th>مبلغ العمولة</th>
                      <th>حساب الضريبة</th>
                      <th>ضريبة العمولة</th>
                      <th>المبلغ</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(y, id) in commissions" :key="y">
                      <td style="width: 20%">
                        {{ y.name }}
                      </td>
                      <td style="width: 30%">
                        <v-select label="namear" v-model="y.act" disabled>
                          <template #option="option">
                            <span>{{ option.namear }}</span>
                          </template>
                        </v-select>
                      </td>
                      <td>
                        <div style="display: flex">
                          <input
                            type="text"
                            v-model="y.num"
                            @change="
                              (y.comamount = Number(
                                (annualrent * y.num) / 100
                              ).toFixed(2)),
                                (y.amount = Number(
                                  (annualrent * y.num) / 100 +
                                    (((annualrent * y.num) / 100) * y.tax) / 100
                                ).toFixed(2))
                            "
                          />&nbsp;%
                        </div>
                      </td>
                      <td>
                        <div style="display: flex; width: 120%">
                          <input
                            type="text"
                            v-model="y.comamount"
                            @change="
                              (y.num = Number(
                                (y.comamount / contamount) * 100
                              ).toFixed(2)),
                                (y.amount = Number(
                                  Number(y.comamount) +
                                    (Number(y.comamount) * y.tax) / 100
                                ).toFixed(2))
                            "
                          />
                        </div>
                      </td>
                      <td style="width: 30%">
                        <v-select
                          :options="allaccounts"
                          label="namear"
                          v-model="y.taxact"
                          disabled
                        >
                          <template #option="option">
                            <span>{{ option.namear }}</span>
                          </template>
                        </v-select>
                      </td>
                      <td style="display: flex">
                        <div style="display: flex">
                          <input
                            type="text"
                            v-model="y.tax"
                            @change="
                              y.amount = Number(
                                (annualrent * y.num) / 100 +
                                  (((annualrent * y.num) / 100) * y.tax) / 100
                              ).toFixed(2)
                            "
                          />&nbsp;%
                        </div>
                      </td>
                      <td>{{ y.amount }}</td>
                      <td>
                        <a href="#" @click.prevent="del(id)"
                          ><i class="bi bi-x-circle-fill"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="group2">
              <div class="part">
                <h5>{{ $t("notes") }}</h5>
                <textarea
                  v-model="notes"
                  id=""
                  style="width: 100%"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="group2">
          <div class="part">
            <h5>{{ $t("attachments") }}</h5>
            <div style="display: flex">
              <div v-for="(file, key) in files" :key="file" style="width: 25%">
                <span>{{ key + 1 }} - </span>
                &nbsp;
                <a
                  :href="
                    url +
                    this.$route.params.id +
                    '.' +
                    file.number +
                    '.' +
                    file.type
                  "
                  target="_blank"
                  :download="file.name"
                  >{{ file.name }}
                </a>
              </div>
              {{ fileURL }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import vSelect from "vue-select";
import moment from "moment";
export default {
  props: ["css"],
  components: {
    vSelect,
  },
  data() {
    return {
      cnum: store.state.cnum[0].cnum,
      disabled: false,
      v$: useValidate(),
      url: "",
      select1: true,
      select2: false,
      select3: false,
      select4: false,
      name: "",
      renewal: "",
      prodata: [],
      contamount: 0,
      contduration: 0,
      unite: "",
      notes: "",
      renter: "",
      allcontracts: [],
      commissions: [],
      allcommission: "",
      allaccounts: [],
      categories: [
        { category: "عمولة السعى", value: "1" },
        { category: "عمولة الصيانة", value: "2" },
      ],
      catitem: {},
      owner: "",
      contend: "",
      contstart: "",
      dueamount: "",
      duenum: "",
      allpayments: [],
      withouttax: 0,
      igarnumber: "",
      rentername: "",
      conttype: "",
      propertyname: "",
      files: [],
    };
  },
  validations() {
    return {
      name: { required },
    };
  },
  async mounted() {
    const result2 = await axios.get(
      `/data/getpriceoffer/${this.$route.params.id}`
    );
    if (result2.status == 200) {
      const data = result2.data.data[0];
      this.contconclusion = data.contstart;
      this.contstart = data.contstart;
      this.contduration = data.contduration;
      this.renewal = data.renewal;
      this.igarnumber = data.igarnumber;
      this.unite = data.unite;
      this.rentername = data.rentername;
      this.conttype = data.conttype;
      this.contamount = data.contamount;
      this.propertyname = data.propertyname;
      this.commissions = JSON.parse(data.commission);
      this.annualrent = (data.contamount / data.contduration) * 12;
      this.notes = data.notes;
      this.renterid = data.renterid;
      this.ownerid = data.ownerid;
      this.propertyid = data.propertyid;
    }
    // const result3 = await axios.get(
    //   `/data/getpropertycontracts/${this.$route.params.id}`
    // );
    // if (result3.status == 200) {
    //   this.allcontracts = result3.data.data;
    // }
    // const result4 = await axios.get(
    //   `/data/getpropertypayment/${this.$route.params.id}`
    // );
    // if (result4.status == 200) {
    //   this.allpayments = result4.data.data;
    // }
    const resul3 = await axios.get("/data/AllCommissions");
    if (resul3.status == 200) {
      this.allaccounts = resul3.data.data;
    }
  },
  async created() {},
  methods: {
    Edite() {
      this.$root.$router.push({
        path: `/property/propertyview/${this.$route.params.id}`,
      });
    },
    async del(id) {
      this.commissions.splice(id, 1);
    },
    async submite() {
      try {
        this.disabled = true;
        const result = await axios.post(`/data/updatepropertycontract`, {
          conid: this.$route.params.id,
          propertyname: this.propertyname,
          contconclusion: this.contconclusion,
          contstart: this.contstart,
          contend: this.contend,
          contduration: this.contduration,
          renewal: this.renewal,
          unite: this.unite,
          renterid: this.renterid,
          rentername: this.rentername,
          conttype: this.conttype,
          contamount: Number(this.contamount).toFixed(2),
          notes: this.notes,
          commission: JSON.stringify(this.commissions),
          ownerid: this.ownerid,
          duenum: this.duenum,
          dueamount: this.dueamount,
          igarnumber: this.igarnumber,
          propertyid: this.propertyid,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "allproperties" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      } catch (error) {
        alert("Error");
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    contduration() {
      const currentTime = moment(this.contstart);
      this.contend = moment(currentTime)
        .add(this.contduration, "month")
        .format("YYYY-MM-DD");
    },
    allcommission() {
      const amount = this.annualrent;
      if (this.conttype == "commercial") {
        const withouttax = (amount * 85) / 100;
        this.commissions.push({
          comid: this.allcommission.comid,
          name: this.allcommission.name,
          act: {
            actnum: this.allcommission.actnum,
            namear: this.allcommission.namear,
            nameen: this.allcommission.nameen,
          },
          taxact: {
            actnum: this.allcommission.taxnum,
            namear: this.allcommission.taxnamear,
            nameen: this.allcommission.taxnameen,
          },
          num: this.allcommission.rate,
          comamount: Number(
            (withouttax * this.allcommission.rate) / 100
          ).toFixed(2),
          tax: 15,
          amount: Number(
            (withouttax * this.allcommission.rate) / 100 +
              (((withouttax * this.allcommission.rate) / 100) * 15) / 100
          ).toFixed(2),
        });
      } else if (this.conttype == "residential") {
        const withouttax = amount;
        this.commissions.push({
          comid: this.allcommission.comid,
          name: this.allcommission.name,
          act: {
            actnum: this.allcommission.actnum,
            namear: this.allcommission.namear,
            nameen: this.allcommission.nameen,
          },
          taxact: {
            actnum: this.allcommission.taxnum,
            namear: this.allcommission.taxnamear,
            nameen: this.allcommission.taxnameen,
          },
          num: this.allcommission.rate,
          comamount: Number(
            (withouttax * this.allcommission.rate) / 100
          ).toFixed(2),
          tax: 15,
          amount: Number(
            (withouttax * this.allcommission.rate) / 100 +
              (((withouttax * this.allcommission.rate) / 100) * 15) / 100
          ).toFixed(2),
        });
      } else {
        alert("برجاء اختيار الوحدة");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  //   padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  // border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 50%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.group2 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}
</style>
