<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a>{{ $t("unit type") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("unit type") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                  <a href="#" class="btn-download">
                    <span class="text">Download PDF</span>
                  </a>
                </li> -->

        <li>
          <router-link
            to="/AdminDashboard/AllProducts/UnitTypes"
            class="btn-download"
          >
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i>
              {{ $t("back") }}</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("unit type") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("unit") }}
              <span class="error-feedback" v-if="v$.unit.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="unit"
                @change="checkname()"
              />
              <span class="error-feedback" v-if="alert == true">{{
                "تم تسجيل المنتج من قبل"
              }}</span>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
              >{{ $t("show") }}
              <span class="error-feedback" v-if="v$.show.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <input
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="show"
              />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">
              {{ $t("kind") }} {{ $t("unit") }}
              <span class="error-feedback" v-if="v$.unitkind.$error">{{
                "*"
              }}</span>
            </label>
            <div class="col-sm-3">
              <select
                type="text"
                class="form-control form-control-sm"
                id="inputEmail3"
                v-model="unitkind"
              >
                <option value="unit">{{ $t("unit type") }}</option>
                <option value="weight">{{ $t("weight") }}</option>
                <option value="working hours">{{ $t("working hours") }}</option>
                <option value="distance">
                  {{ $t("length") }} / {{ $t("distance") }}
                </option>
                <option value="space">{{ $t("space") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="buttom">
          <button class="buttonconfirm" type="submit" @click="submit">
            {{ $t("save") }}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button class="buttoncancel" type="submit" @click="back">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      unit: "",
      parent: "parent",
      unitkind: "",
      code: "",
      show: "",
      r2: store.state.randp.r2,
      data: [],
      data2: [],
      filter: "",
      selection: false,
      alert: false,
    };
  },
  validations() {
    return {
      unit: { required },
      show: { required },
      unitkind: { required },
    };
  },
  async mounted() {
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/allproductcategoriesparent");
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllProducts/UnitTypes",
      });
    },
    async checkname() {
      const result = await axios.get(`/data/productcheckname/${this.name}`);
      if (result.status == 200) {
        const data = result.data.data;
        if (data.length > 0) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      }
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error && this.alert == false) {
        const result = await axios.post("/data/createnewunittype", {
          username: store.state.randp.username,
          unit: this.unit,
          show: this.show,
          unitkind: this.unitkind,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "UnitTypes" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;

  margin: 0.15%;
}
.head-option .btn-download {
  // width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  border-top:  v-bind("css.color") 5px solid;
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.part1 {
  //   width: 80%;
  background: v-bind("css.light");
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.buttom {
  text-align: center;
}
</style>
