<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/sales/home" class="active">{{
              $t("sales")
            }}</router-link>
          </li>
          <li>-</li>

          <li>
            <router-link to="/sales/AllInvoices" class="active">{{ $t("invoices") }} {{ $t("sales") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("invoices") }} {{ $t("sales") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("invoice") }} {{ $t("sales") }}</h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submit">
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="part1">
          <div class="part11">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }}
              </label>
              <div class="col-sm-7">INV-{{ id }}</div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="date" />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("description") }}
              </label>
              <div class="col-sm-7">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="description" />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("customer") }}
              </label>
              <div class="col-sm-7">
                <v-select :options="customers" label="name" v-model="cdata">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("due") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="duedate" />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("supply") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="supplydate" />
              </div>
            </div>
          </div>
          <div class="part11">
            <h4>{{ $t("data") }} {{ $t("customer") }}</h4>

            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.name }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }} {{ $t("company") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.companyname }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("mobile") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.mobile }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("email") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.email }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("tax") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.taxnumber }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("address") }}
              </label>
              <div class="col-sm-7">
                {{ cdata.street }} {{ cdata.city }} {{ cdata.area }}
                {{ cdata.country }}
              </div>
            </div>
          </div>
        </div>
        <div class="ccenter">
          <button @click="ccenter = !ccenter">
            {{ $t("add") }} {{ $t("ccenter") }}
          </button>
          <div v-if="ccenter == true">
            <span>{{ $t("ccenter") }}</span>
            <select v-model="parent">
              <option v-for="x in level1" :key="x" :value="x">
                {{ x.namear }}
              </option>
            </select>
            <select v-model="parent2" v-if="level2.length > 0">
              <option v-for="x in level2" :key="x" :value="x">
                {{ x.namear }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="part2">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("barcode") }}
            </label>
            <div class="col-sm-2">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" @change="getbarcode"
                v-model="barcode" />
            </div>
            <div style="width: 10%">
              <!-- Button trigger modal -->
              <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="this.cam = !this.cam">
                Scanner
              </button>

              <!-- Modal -->
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Scanner
                      </h5>
                    </div>
                    <div class="modal-body" v-if="cam == true">
                      <qrcode-stream @detect="onDetect"></qrcode-stream>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        @click="this.cam = !this.cam">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("product") }}
            </label>
            <div class="col-sm-3">
              <v-select :options="products" label="namear" v-model="product">
                <template #option="option">
                  <span>{{ option.barcode }} - {{ option.namear }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr style="font-size: small">
                <th>#</th>
                <th style="width: 20%">{{ $t("product") }}</th>
                <th>{{ $t("stock") }}</th>
                <th>{{ $t("average") }} {{ $t("cost") }}</th>
                <th>{{ $t("last") }} {{ $t("invoice") }}</th>
                <!-- <th>{{ $t("description") }}</th> -->
                <th style="width: 10%">{{ $t("quantity") }}</th>
                <th style="width: 10%">{{ $t("unit type") }}</th>
                <th>{{ $t("price") }}</th>
                <th>{{ $t("discount") }}</th>
                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                <th>{{ $t("tax") }}</th>
                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                <th>{{ $t("total") }}</th>
                <th>{{ $t("cancel") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) in data" :key="value">
                <td>{{ id + 1 }}</td>
                <td style="width: 12%">
                  <span>{{ value.barcode }} - {{ value.pronamear }}</span>
                </td>
                <td style="background-color: red; color: white" v-if="value.stockbalance <= 0">
                  {{ value.stockbalance }}
                </td>
                <td style="background-color: green; color: white;font-size: xx-small;" v-if="value.stockbalance > 0">
                  {{ value.stockbalance }} {{ value.unittype }}
                </td>
                <!-- <td style="
                    background-color: green;
                    color: white;
                    font-size: smaller;
                  " v-if="typeof value.stockbalance == 'string'">
                  {{ value.stockbalance }}
                </td> -->
                <td>
                  <span v-if="value.stock == true">{{ value.avcost }}</span>
                </td>
                <td>{{ value.lastinvprice }}</td>
                <!-- <td>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="value.description"
                  />
                </td> -->
                <td>
                  <input class="form-control form-control-sm" type="text" v-model="value.quantity" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                </td>

                <td>{{ value.unittype }}</td>
                <td>
                  <input type="number" v-model="value.salep" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                </td>
                <td style="display: flex">
                  <input type="number" v-model="value.discount" style="width: 70%" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                  %
                </td>
                <td>{{ value.tbt }}</td>
                <td style="display: flex">
                  <input type="number" v-model="value.tax" style="width: 100px" @change="
                    value.tbt = Number(
                      value.salep * value.quantity -
                      (value.salep * value.quantity * value.discount) / 100
                    ).toFixed(2);
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.costbt = Number(
                    value.buyp * value.quantity
                  ).toFixed(2);
                  value.cost = Number(
                    value.avcost * value.quantity
                  ).toFixed(2);
                  this.calc();
                  " />
                  %
                </td>
                <td>{{ value.taxamount }}</td>
                <td>{{ value.total }}</td>
                <td>
                  <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                </td>
                <td>
                  <!-- Button trigger modal -->
                  <button type="button" style="font-size: xx-small" class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#exampleModal2" @click="getcusttrans(value)">
                    {{ $t("lastinv") }}
                  </button>

                  <!-- Modal -->
                  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            {{ $t("lastinv") }}
                            {{ $t("product") }}
                          </h5>
                        </div>
                        <div class="modal-body">
                          <div class="part11">
                            <div class="row mb-2">
                              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  INV-{{ alldata2.invid }}
                                </div>
                              </div>
                            </div>
                            <div class="row mb-2" v-if="alldata2.qte != null">
                              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("offer") }} {{
                                $t("price") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  QTE-{{ alldata2.qte }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.due }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("description") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.description }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("due")
                                }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.duedate }}
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row mb-2">
                              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{
                                $t("supply") }}
                              </label>
                              <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                  {{ alldata2.supplydate }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <table class="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">
                                  {{ $t("unit type") }}
                                </th>
                                <th>{{ $t("price") }}</th>
                                <th>{{ $t("discount") }}</th>
                                <th>
                                  {{ $t("total") }} {{ $t("befor") }}
                                  {{ $t("tax") }}
                                </th>
                                <th>{{ $t("tax") }}</th>
                                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(value, id) in allgetcusttrans" :key="value">
                                <td>{{ id + 1 }}</td>
                                <td>{{ value.pronamear }}</td>
                                <td>
                                  {{ value.description }}
                                </td>
                                <td>
                                  {{ value.quantity }}
                                </td>
                                <td>{{ value.unittype }}</td>
                                <td>{{ value.salep }}</td>
                                <td>{{ value.discount }} %</td>
                                <td>{{ value.tbt }}</td>
                                <td>{{ value.tax }} %</td>
                                <td>{{ value.taxamount }}</td>
                                <td>{{ value.total }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div class="total" v-if="taxamount > 0">
          <h4>{{ $t("tax") }}</h4>
          <div class="total1">
            {{ $t("Account") }} {{ $t("tax") }}
            <span class="error-feedback" v-if="v$.taxaccount.$error">{{
              "*"
            }}</span>
            <select v-model="taxaccount">
              <option v-for="x in alltasks" :key="x" :value="x">
                {{ x.namear }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="total">
          <h4>{{ $t("Commissions") }}</h4>
          <div class="total1">
            {{ $t("type") }} {{ $t("Commissions") }}
            <select v-model="comm" @change="this.commamount = (this.tbt * this.comm) / 100">
              <option value="5">{{ $t("Commissions") }} ايجار</option>
            </select>
          </div>
          <div class="total1">
            {{ $t("amount") }} {{ $t("Commissions") }}
            <input type="text" disabled v-model="commamount" />
          </div>
        </div>
        <hr />
        <div class="total">
          <h4>{{ $t("total") }}</h4>
          <div class="total1">
            المبلغ قبل الضريبة <input type="text" disabled v-model="tbt" />
          </div>
          <div class="total1">
            قيمة الضريبة <input type="text" disabled v-model="taxamount" />
          </div>
          <div class="total1">
            بعد الضريبة <input type="text" disabled v-model="total" />
          </div>
        </div>
        <!-- <div class="col-sm-3">
              <button
                class="btn btn-primary"
                @click="Addproduct"
                style="padding: 0.25%"
              >
                {{ $t("add") }} <i class="bi bi-plus"></i>
              </button>
            </div> -->
        <!-- <div class="row mb-2">
          <label class="col-sm-1 col-form-label-sm">{{ $t("branch") }} </label>
          <div class="col-sm-2">
            <select class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="branchno">
              <option v-for="val in branches" :key="val" :value="val.bid">
                {{ val.namear }}
              </option>
            </select>
          </div>
        </div> -->
        <!-- <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("add") }} {{ $t("to") }}
          </label>

          <div class="col-sm-1">
            <input class="form-check-input" type="radio" value="project" v-model="addto" />
            &nbsp;
            <label class="form-check-label" for="gridRadios2">
              {{ $t("project") }}
            </label>
          </div>
          <div class="col-sm-1">
            <input class="form-check-input" type="radio" v-model="addto" value="task" />
            &nbsp;
            <label class="form-check-label" for="gridRadios2">
              {{ $t("task") }}
            </label>
          </div>
          <div class="row mb-2" v-if="addto == 'project'">
            <label class="col-sm-1 col-form-label-sm">{{ $t("project") }}
            </label>
            <div class="col-sm-2">
              <select class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="projectno">
                <option v-for="val in allprojects" :key="val" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="addto == 'task'">
            <label class="col-sm-1 col-form-label-sm">{{ $t("task") }} </label>
            <div class="col-sm-2">
              <select class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="taskno" @change="task">
                <option v-for="val in alltasks" :key="val.id" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
          </div>
        </div> -->
        <hr />
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("notes") }}
          </label>
          <div class="col-sm-5">
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="notes"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import moment from "moment";
import QrcodeStream from "../../QR.vue";

export default {
  title: `Create Invoice`,
  components: {
    vSelect,
    QrcodeStream,
  },

  props: ["css"],
  async mounted() {
    const result3 = await axios.get(`/data/allcustomer`);
    if (result3.status == 200) {
      this.customers = result3.data.data;
    }
    const result2 = await axios.get(`/data/allproducts`);
    if (result2.status == 200) {
      this.products = result2.data.data;
    }
    const result4 = await axios.get(`/data/allprojects`);
    const result5 = await axios.get(`/data/getaccounttax`);
    // const result1 = await axios.get(`/data/allbranches`);
    const result7 = await axios.get("/data/getcostcenter");
    const result6 = await axios.get(`/data/InvoiceID`);
    if (result4.status == 200) {
      this.branches = result7.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
      this.allprojects = result4.data.data;
      this.alltasks = result5.data.data;
      // this.branches = result1.data.data;
      this.id = Number(result6.data.data[0].count) + Number(1);
    }
  },
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      id: "",
      data: [],
      number: "",
      date: moment().format("YYYY-MM-DD"),
      description: "",
      customers: [],
      products: [],
      product: "",
      selection: false,
      customerid: "",
      customername: "",
      customerdata: "",
      taxamount: 0,
      tbt: 0,
      total: 0,
      addto: "",
      allprojects: [],
      alltasks: [],
      taskno: "",
      projectno: "",
      branchno: "",
      duedate: moment().format("YYYY-MM-DD"),
      supplydate: moment().format("YYYY-MM-DD"),
      barcode: "",
      cdata: "",
      avcost: "",
      allgetcusttrans: "",
      alldata2: "",
      cam: false,
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
      ccenter: false,
      costn: "",
      costname: "",
      comm: "",
      commamount: 0,
      taxaccount: {
        actnum: "2105",
        namear: "ضريبة القيمة المضافة المستحقة",
        nameen: "VAT payable",
        typeact: "credit",
      },
    };
  },
  validations() {
    return {
      date: { required },
      cdata: { required },
      taxaccount: { required },
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllInvoices/CreateInvoice",
      });
    },
    calc() {
      this.tbt = Number(
        this.data.reduce((acc, array) => acc + Number(array.tbt), 0)
      ).toFixed(2);
      this.taxamount = Number(
        this.data.reduce((acc, array) => acc + Number(array.taxamount), 0)
      ).toFixed(2);
      this.total = Number(
        this.data.reduce((acc, array) => acc + Number(array.total), 0)
      ).toFixed(2);
    },
    async del(id) {
      this.data.splice(id, 1);
      this.calc();
    },
    task() {
      const project = this.alltasks.filter((x) => x.id == this.taskno)[0]
        .projectid;
      this.projectno = project;
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disabled = true;
        const result = await axios.post("/data/CreateInvoice", {
          username: store.state.randp.username,
          due: this.date,
          description: this.description,
          duedate: this.duedate,
          supplydate: this.supplydate,
          cid: this.cdata.cid,
          cname: this.cdata.companyname,
          cmobile: this.cdata.mobile,
          cmail: this.cdata.email,
          ctax: this.cdata.taxnumber,
          details: JSON.stringify(this.data),
          tbt: this.tbt,
          tax: Number(this.taxamount).toFixed(2),
          tat: Number(this.total).toFixed(2),
          branch: this.branchno,
          project: this.projectno,
          task: this.taskno,
          notes: this.notes,
          cnum: store.state.cnum[0].cnum,
          costn: this.costn,
          costname: this.costname,
          comm: this.comm,
          commamount: this.commamount,
          taxaccount: this.taxaccount,
          caddress:
            this.cdata.street +
            " " +
            this.cdata.city +
            " " +
            this.cdata.area +
            " " +
            this.cdata.country,
        });
        if (result.status == 200) {
          this.$root.$router.push({
            path: `/sales/InvoiceView/${this.id}`,
          });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    async getbarcode() {
      const result = await axios.get(`/data/getbarcode/${this.barcode}`);
      if (result.status == 200) {
        const val2 = result.data.data[0];
        this.product = val2;
        this.data.push({
          proid: val2.pid,
          pronamear: val2.namear,
          pronameen: val2.nameen,
          salep: val2.salep,
          salec: val2.salec,
          snamear: val2.snamear,
          snameen: val2.snameen,
          buyp: val2.buyp,
          buyc: val2.buyc,
          bnamear: val2.bnamear,
          bnameen: val2.bnameen,
          discount: val2.discount,
          unittype: val2.unittype,
          tax: val2.tax,
          sn: val2.sn,
          barcode: val2.barcode,
          stock: val2.stock,
          quantity: 1,
          tbt: Number(
            val2.salep - (val2.salep * 1 * val2.discount) / 100
          ).toFixed(2),
          taxamount: Number(
            ((val2.salep - (val2.salep * 1 * val2.discount) / 100) * val2.tax) /
            100
          ).toFixed(2),
          total: Number(
            val2.salep -
            (val2.salep * 1 * val2.discount) / 100 +
            ((val2.salep - (val2.salep * 1 * val2.discount) / 100) *
              val2.tax) /
            100
          ).toFixed(2),
          costbt: val2.buyp,
          cost: val2.buyp,
        });
        this.calc();
      }
    },
    async getcusttrans(value) {
      if (this.cdata.cid != null) {
        const result3 = await axios.get(
          `/data/getcusttrans/${this.cdata.cid}/${value.sn}`
        );
        if (result3.status == 200) {
          this.allgetcusttrans = JSON.parse(result3.data.data[0].details);
          this.alldata2 = result3.data.data[0];
        }
      }
      if (this.cdata.cid == null) {
        alert("برجاء اختيار العميل");
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    parent() {
      this.level2 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent.actnum) &&
          x.actnum != this.parent.actnum
      );
      this.costname = this.parent.namear;
      this.costn = this.parent.actnum;
    },
    parent2() {
      this.costname = this.parent2.namear;
      this.costn = this.parent2.actnum;
    },
    ccenter() {
      if (this.ccenter == false) {
        this.costname = "";
        this.costn = "";
      }
    },
    async customerid() {
      const result3 = await axios.get(`/data/delcustomer/${this.customerid}`);
      if (result3.status == 200) {
        this.customerdata = result3.data.data[0];
      }
    },
    addto() {
      this.projectno = null;
      this.taskno = null;
    },

    async product() {
      if (this.cdata.cid != null) {
        const result3 = await axios.get(
          `/data/getcusttrans/${this.cdata.cid}/${this.product.sn}`
        );
        if (result3.status == 200) {
          if (result3.data.data.length > 0) {
            this.allgetcusttrans = JSON.parse(result3.data.data[0].details);
            this.alldata2 = result3.data.data[0];
          }
          if (result3.data.data.length == 0) {
            this.allgetcusttrans = [{ salep: 0 }];
          }
        }
        if (this.cdata.cid == null) {
          alert("برجاء اختيار العميل");
        }

        const data2 = this.data.filter(
          (x) => x.pronamear == this.product.namear
        );
        if (data2.length == 0) {
          if (this.product.stock == true) {
            const stockvalidationbalance = await axios.get(
              `/reports/stockvalidationbalance/${this.product.sn}`
            );
            const data = stockvalidationbalance.data.data[0][0];
            this.stockbalance = data.dpt - data.crd;
            if (stockvalidationbalance.data.data[1] > 0) {
              this.avcost = stockvalidationbalance.data.data[1];
            } else if (stockvalidationbalance.data.data[1] == "NaN") {
              this.avcost = this.product.buyp;
            } else {
              this.stockbalance = "ليس منتج مخزون";
              this.avcost = this.product.buyp;
            }
            this.data.push({
              proid: this.product.pid,
              pronamear: this.product.namear,
              pronameen: this.product.nameen,
              salep: this.product.salep,
              salec: this.product.salec,
              snamear: this.product.snamear,
              snameen: this.product.snameen,
              buyp: this.product.buyp,
              buyc: this.product.buyc,
              bnamear: this.product.bnamear,
              bnameen: this.product.bnameen,
              discount: this.product.discount,
              unittype: this.product.unittype,
              tax: this.product.tax,
              sn: this.product.sn,
              barcode: this.product.barcode,
              stock: this.product.stock,
              stockbalance: Number(this.stockbalance).toFixed(2),
              avcost: this.avcost,
              lastinvprice: this.allgetcusttrans[0].salep,
              quantity: 1,
              tbt:
                this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100,
              taxamount:
                ((this.product.salep -
                  (this.product.salep * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                100,
              total:
                this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100 +
                ((this.product.salep -
                  (this.product.salep * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                100,
              costbt: this.avcost,
              cost: this.avcost,
            });
            this.calc();
          } else {
            this.data.push({
              proid: this.product.pid,
              pronamear: this.product.namear,
              pronameen: this.product.nameen,
              salep: this.product.salep,
              salec: this.product.salec,
              snamear: this.product.snamear,
              snameen: this.product.snameen,
              buyp: this.product.buyp,
              buyc: this.product.buyc,
              bnamear: this.product.bnamear,
              bnameen: this.product.bnameen,
              discount: this.product.discount,
              unittype: this.product.unittype,
              tax: this.product.tax,
              sn: this.product.sn,
              barcode: this.product.barcode,
              stock: this.product.stock,
              stockbalance: "غير مخزن",
              avcost: "غير مخزن",
              lastinvprice: this.allgetcusttrans[0].salep,
              quantity: 1,
              tbt:
                this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100,
              taxamount:
                ((this.product.salep -
                  (this.product.salep * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                100,
              total:
                this.product.salep -
                (this.product.salep * 1 * this.product.discount) / 100 +
                ((this.product.salep -
                  (this.product.salep * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                100,
              costbt: this.avcost,
              cost: this.avcost,
            });
            this.calc();
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.ccenter button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 20%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.part1 {
  display: flex;
  width: 100%;
}

.part11 {
  width: 50%;
  padding: 2%;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
