<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("customers") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
          <a href="#" class="btn-download">
            <span class="text">Download PDF</span>
          </a>
        </li> -->
        <li>
          <router-link
            to="/AdminDashboard/Customers/NewCustomer"
            class="btn-download"
          >
            <span> {{ $t("add") }} {{ $t("customer") }} [+] </span>
          </router-link>
        </li>
        <!-- <li>
          <router-link
            to="/AdminDashboard/Customers/CustomerCategorie"
            class="btn-download"
          >
            <span> [+] {{ $t("Categorie") }} {{ $t("customers") }} </span>
          </router-link>
        </li> -->
        <li>
          <router-link
            to="/AdminDashboard/Customers/ImportCustomerData"
            class="btn-download"
          >
            <span>
              <i class="bi bi-upload"></i>&nbsp; {{ $t("import") }}
              {{ $t("data") }} {{ $t("customers") }}
            </span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("customers") }}</h3>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control form-control-sm"
              :placeholder="$t('name')"
              @keypress.enter="search"
              v-model="name"
            />
          </div>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control form-control-sm"
              :placeholder="$t('email')"
              @keypress.enter="search"
              v-model="email"
            />
          </div>
          <div class="col-sm-1">
            <input
              type="text"
              class="form-control form-control-sm"
              :placeholder="$t('code')"
              @keypress.enter="bycode"
              v-model="code"
            />
          </div>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control form-control-sm"
              :placeholder="$t('mobile')"
              @keypress.enter="search"
              v-model="mobile"
            />
          </div>
          <!-- <div class="col-sm-2">
            <select
              type="text"
              class="form-select form-control-sm"
              v-model="categorie"
            >
              <option
                class="form-control form-control-sm"
                v-for="val in categories"
                :key="val"
                :value="val.categorie"
              >
                <span v-if="css.lang == 'ar'">{{ val.showar }}</span
                ><span v-if="css.lang == 'en'">{{ val.showen }}</span>
              </option>
            </select>
          </div> -->
          <div class="col">
            <button class="btn btn-primary" @click="search">
              {{ $t("search") }} <i class="bi bi-search"></i>
            </button>
            &nbsp;
            <button class="btn btn-light" @click="reset">
              {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
            </button>
          </div>
        </div>
        <div>
          <table-lite
            :is-static-mode="true"
            :columns="table.columns"
            :rows="table.rows"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
            @row-delete="Delete"
            @row-edite="eslam"
            @row-view="details"
          ></table-lite>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import TableLite from "../../components/TableLite.vue";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  async mounted() {
    this.view = this.$route.params.id;
    if (this.r1.create != true) {
      this.redirectTo({ val: "Home" });
    }
    const result = await axios.get("/data/allcustomercategorie");

    if (result.status == 200) {
      this.categories = result.data.data;
    }
  },
  props: ["css"],
  components: { TableLite },
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      r1: JSON.parse(store.state.randp.r1),
      categories: [],
      categorie: "",
    };
  },
  setup() {
    // Table config
    const table = reactive({
      columns: [
        {
          label: "code",
          field: "cid",
          width: "1%",
          text: "cust-",
          sortable: true,
          isKey: true,
          headerClasses: ["bg-gold", "color-red"],
          columnClasses: ["bg-gray"],
        },
        {
          label: "name",
          field: "name",
          width: "15%",
          sortable: true,
        },
        {
          label: "company",
          field: "companyname",
          width: "15%",
          sortable: true,
        },
        {
          label: "mobile",
          field: "mobile",
          width: "10%",
          sortable: true,
        },
        {
          label: "email",
          field: "email",
          width: "10%",
          sortable: true,
        },

        {
          label: "country",
          field: "country",
          width: "10%",
          sortable: true,
        },
        {
          label: "Categorie",
          field: "categorie",
          width: "10%",
          sortable: true,
        },
        {
          label: "status",
          field: "review",
          width: "10%",
          sortable: true,
        },
        {
          label: "option",
          // headerClasses: ["bg-gold"],
          // columnClasses: ["bg-gray"],
          // columnStyles: { background: "gray" },
          field: "quick",
          width: ".5%",
          display: function () {
            return `<button class="btn btn-light"><i class="bi bi-pencil-square"></i></button>`;
          },
        },
      ],
      rows: [],
      data: [],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
    });
    const rowDelete = async () => {
      this.delete();
    };
    const rowEdit = () => {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
    };
    const rowView = (row) => {
      console.log("Row rowView!", row);
    };
    /**
     * Table search event
     */
    const doSearch = (offset, limit, order, sort) => {
      table.isLoading = true;

      // Start use axios to get data from Server
      let url = `/data/allcustomer`;
      axios.get(url).then((response) => {
        table.rows = response.data.data;
        table.data = response.data.data;
        table.totalRecordCount = response.data.data.length;
        table.sortable.order = order;
        table.sortable.sort = sort;
      });
    };

    // Get data first
    doSearch(0, 10, "id", "asc");
    return {
      table,
      rowDelete,
      rowEdit,
      rowView,
    };
  },
  methods: {
    eslam(row) {
      if (this.r1.edite == true) {
        this.$root.$router.push({
          path: `/AdminDashboard/Customers/editecustomer/${row.cid}`,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "You Can't Do This Action",
        });
      }
    },
    details(row) {
      this.$root.$router.push({
        path: `/AdminDashboard/Customers/customerdetails/${row.cid}`,
        params: { id: row.id },
      });
    },
    Delete(row) {
      if (this.r1.del == true) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const del = await axios.delete(`/data/delcustomer/${row.cid}`);
            if (del.status == 200) {
              const indexOfObject = this.table.rows.findIndex((object) => {
                return object.id === row.id;
              });
              this.table.rows.splice(indexOfObject, 1);
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
          }
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "You Can't Do This Action",
        });
      }
    },
    async search() {
      const result = await axios.post(`/data/customersearch`, {
        name: this.name,
        email: this.email,
        mobile: this.mobile,
        cnum: store.state.cnum[0].cnum,
      });
      if (result.status == 200) {
        this.table.rows = result.data.data;
      }
    },
    async bycode() {
      const result = await axios.get(`/data/delcustomer/${this.code}`);
      if (result.status == 200) {
        this.table.rows = result.data.data;
      }
    },
    reset() {
      this.name = "";
      this.email = "";
      this.code = "";
      this.mobile = "";
      this.categorie = "";
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    // name() {
    //   this.table.rows = this.table.data.filter((x) =>
    //     x.name.includes(this.name.toLowerCase())
    //   );
    // },
    // mobile() {
    //   this.table.rows = this.table.data.filter((x) =>
    //     x.mobile.includes(this.mobile)
    //   );
    // },
    // code() {
    //   this.table.rows = this.table.data.filter((x) => x.id == this.code);
    //   if (this.code == "") {
    //     this.table.rows = this.table.data;
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}
a {
  text-decoration: none;
}
.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}
.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
.head-title .left .breadcrumb li {
  color: #342e37;
}
.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}
.head-title .left .breadcrumb li a.active {
  color:  v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}
.head-option ul li {
  display: inline;
  padding: 0.25%;
}

.head-option .btn-download {
  padding-right: 0.5%;
  padding-left: 0.5%;
  border-radius: 5px;
  background:  v-bind("css.color");
  color: #f9f9f9;
}
.main .head {
  background:  v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}
.table {
  text-align: center;
}
.table tr {
  background: rgba(191, 214, 219, 0.534);
  line-height: 5px;
}
button {
  margin: 1%;
}

.table button :hover {
  background: black;
  color: white;
  padding: 50%;
}

.bg-gold {
  background: gold !important;
}
.bg-gray {
  background: gray !important;
}
.color-red {
  color: red !important;
}
</style>
