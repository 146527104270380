<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link to="/accounting/home" class="active">{{
            $t("accounting")
          }}</router-link>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#">{{ $t("Financial Center") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("from") }}</span>
        <input
          type="date"
          :placeholder="$t('from')"
          v-model="from"
          @change="submit"
        />
        <span>{{ $t("to") }}</span>
        <input
          type="date"
          :placeholder="$t('to')"
          v-model="to"
          @change="submit"
        />
        <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
      </div>
      <div class="head2"></div>
    </div>
    <div class="body" id="main">
      <br />
      <div :style="{ direction: css.EN }">
        <h3>{{ $t("Financial Center") }}</h3>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead style="position: static">
            <tr>
              <th colspan="2">{{ todate }}</th>
            </tr>
            <tr>
              <th style="width: 50%">{{ $t("Account") }}</th>
              <th>{{ $t("amount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in data1" :key="i">
              <td v-if="i.sup == 'true'" class="childname">
                <router-link to="" @click="summary(i)">
                  {{ i.actnum }} -
                  <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                  ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
                </router-link>
              </td>

              <td v-if="i.sup == 'false'">
                {{ i.actnum }} -
                <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ i.amount
                }}<span v-if="i.amount == null">{{ (i.amount = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.amount != 0">{{ i.amount }}</span>
              </td>
            </tr>
            <tr v-for="i in data2" :key="i">
              <td v-if="i.sup == 'true'" class="childname">
                <router-link to="" @click="summary(i)">
                  {{ i.actnum }} -
                  <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                  ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
                </router-link>
              </td>

              <td v-if="i.sup == 'false'">
                {{ i.actnum }} -
                <span v-if="css.lang == 'ar'">{{ i.namear }}</span
                ><span v-if="css.lang == 'en'">{{ i.nameen }}</span>
              </td>
              <td v-if="i.sup == 'true'" class="child">
                {{ i.amount
                }}<span v-if="i.amount == null">{{ (i.amount = 0) }}</span>
              </td>
              <td v-if="i.sup == 'false'">
                <span v-if="i.amount != 0">{{ i.amount }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="buttom">
      <button class="btn btn-primary" type="submit" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import html2pdf from "html2pdf.js";
export default {
  async mounted() {
    const result2 = await axios.get("/data/getcostcenter");
    const result = await axios.get(
      `/reports/FinancialCenter/${this.fromdate}/${this.todate}`
    );
    if (result.status == 200) {
      this.branches = result2.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
      this.data1 = result.data.data;
    }
  },
  props: ["css"],
  data() {
    return {
      fromdate: moment().startOf("year").format("YYYY-MM-DD"),
      todate: moment().format("YYYY-MM-DD"),
      data1: [],
      data2: [],
      tincome: 0,
      tincomenot: 0,
      tincomet: 0,
      from: moment().startOf("year").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
    };
  },
  methods: {
    summary(val) {
      this.$root.$router.push({
        path: `/accounting/GeneralLedgerSummaryMonthly/${val.actnum}/${val.namear}/${val.nameen}/${val.typeact}`,
      });
    },
    async submit() {
      const result = await axios.get(
        `/reports/FinancialCenter/${this.from}/${this.to}`
      );
      if (result.status == 200) {
        (this.parent = ""), (this.parent2 = "");
        this.todate = this.to;
        this.fromdate = this.from;
        this.data1 = result.data.data;
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "incomestatement.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
  },
  watch: {
    async parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
      const result = await axios.get(
        `/reports/IncomeStatementcenter/${this.from}/${this.to}/${this.parent}`
      );
      if (result.status == 200) {
        this.todate = this.to;
        this.data1 = result.data.data;
        this.data2 = result.data.data2;
        this.data3 = result.data.data3;
        this.data4 = result.data.data4;
        this.data5 = result.data.data5;
        this.tincome = Number(
          this.data1[0].amount - this.data2[0].amount
        ).toFixed(2);
        this.tincomenot = Number(
          Number(this.tincome) +
            Number(this.data3[0].amount) -
            this.data4[0].amount
        ).toFixed(2);
        this.tincomet = Number(this.tincomenot - this.data5[0].amount).toFixed(
          2
        );
      }
    },
    async parent2() {
      const result = await axios.get(
        `/reports/IncomeStatementcenter/${this.from}/${this.to}/${this.parent2}`
      );
      if (result.status == 200) {
        this.todate = this.to;
        this.data1 = result.data.data;
        this.data2 = result.data.data2;
        this.data3 = result.data.data3;
        this.data4 = result.data.data4;
        this.data5 = result.data.data5;
        this.tincome = Number(
          this.data1[0].amount - this.data2[0].amount
        ).toFixed(2);
        this.tincomenot = Number(
          Number(this.tincome) +
            Number(this.data3[0].amount) -
            this.data4[0].amount
        ).toFixed(2);
        this.tincomet = Number(this.tincomenot - this.data5[0].amount).toFixed(
          2
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
