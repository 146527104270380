<template>
    <div class="error-page">
      <h1>403 - Forbidden</h1>
      <p>You do not have permission to access this page.</p>
      <router-link to="/AdminDashboard/Home">Go back to Home</router-link>
    </div>
  </template>

  <script>
  export default {
    name: "Error-403"
  };
  </script>

  <style scoped>
  .error-page {
    text-align: center;
    margin-top: 50px;
  }
  </style>
