<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("Accounts") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active"> {{ $t("fastjl") }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <li>
          <router-link to="/accounting/AllFastJL/CreateJEntryTemplete" class="btn-download">
            <span class="text">[+] {{ $t("add") }} {{ $t("fastjl") }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("fastjl") }}</h3>
      </div>
      <div class="body">
        <div v-for="x in alldata" :key="x" class="select">
          <a :href="'/accounting/CreateJournalEntryFast' + '/' + x.tid">
            <div>{{ x.title }} : {{ x.description }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { reactive, computed } from "vue";
import store from "@/store";
import axios from "axios";
// import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],

  data() {
    return {
      name: "",
      status: "",
      mobile: "",
      code: "",
      r13: JSON.parse(store.state.randp.r13),
      alldata: [],
    };
  },
  async mounted() {
    // if (this.r13.create != true) {
    //   this.redirectTo({ val: "Home" });
    // }
    const result = await axios.get(`/data/allfastjltemplete`);
    if (result.status == 200) {
      this.alldata = result.data.data;
    }
  },

  methods: {
    view(row) {
      this.$root.$router.push({
        path: `/AdminDashboard/JournalEntry/JournalEntryReview/${row.jid}`,
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.namear.includes(this.name.toLowerCase())
      );
    },

    status() {
      this.table.rows = this.table.data.filter((x) => x.review == this.status);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

a {
  text-decoration: none;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}

.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.head-title .left .breadcrumb li {
  color: #342e37;
}

.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.25%;
}

.head-option ul li {
  display: inline;
  margin: 0.15%;
}

.head-option .btn-download {
  // width: 20%;
  padding: 0.3%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.select {
  background-color: v-bind("css.color");
  color: white;
  margin: 0.5%;
  padding: 0.5%;
  width: 100%;
}

.select:hover {
  background-color: black;
  color: white;
  margin: 0.5%;
  padding: 0.5%;
  width: 100%;
}

a {
  color: white;
  font-size: large;
}

a:hover {
  color: white;
  font-size: large;
}
</style>
