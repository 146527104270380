<template>
    <div class="page">
      <div class="head-title">
        <div class="left">
          <ul class="breadcrumb">
            <li>
              <router-link to="/tasks" class="active"
                >{{ $t("management") }} {{ $t("tasks") }}</router-link
              >
            </li>
            <li>-</li>
            <li>
              <router-link to="" class="active">
                {{ $t("add") }} {{ $t("requests") }} {{ $t("purchases") }} </router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="main">
        <div class="head">
          <div class="head1">
            <h6>
              {{ $t("request") }} {{ $t("purchases") }} {{ $t("new") }}
            </h6>
          </div>
          <div class="head2">
            <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submite">
            {{ $t("save") }}
          </button>
          </div>
        </div>
        <div class="body">
          <div class="part1"></div>
        </div>
        <div class="body2">
          <div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-2 col-form-label"
                >{{ $t("date") }}              <span class="error-feedback" >{{
                "*"
              }}</span>
              </label>
              <div class="col-sm-3" style="display: flex"><input type="date" class="form-control" id="inputPassword3" v-model="date" /></div>

            </div>
            <!-- <div class="row mb-2">
              <label for="inputPassword3" class="col-sm-2 col-form-label"
                >{{ $t("ccenter") }}
              </label>
              <div class="col-sm-5" style="display: flex">
                {{ costname }}
              </div>
            </div> -->
            <div class="row mb-2">
              <label for="inputPassword3" class="col-sm-2 col-form-label"
                >{{ $t("add") }} {{ $t("product") }}
              </label>
              <div class="col-sm-3">
                <v-select :options="products" label="namear" v-model="product">
                  <template #option="option">
                    <div style="text-align: center">
                      <span
                        >{{ option.namear }} - {{ option.barcode }} -
                        {{ option.unittype }}
                      </span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div style="overflow-x: auto;min-height:300px;">
            <table class="table" >
              <thead>
                <tr>
                  <td colspan="11">{{ $t("details") }} {{ $t("materials") }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{{ $t("materials") }}</td>
                  <td>{{ $t("unit") }}</td>
                  <td>
                    {{ $t("quantity") }}
                  </td>
                  <!-- <td>{{ $t("request") }} {{ $t("purchase") }}</td> -->
                  <td>{{ $t("suppliers") }}</td>
                  <td>{{ $t("quantity") }} {{ $t("purchases") }}</td>
                  <td>{{ $t("type") }} {{ $t("payment") }}</td>
  
                  <td>{{ $t("price") }} {{ $t("unit") }}</td>
                  <td>{{ $t("tax") }}</td>
                  <td>{{ $t("total") }}</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, id) in data2" :key="x">
                  <td>{{ id + 1 }}</td>
                  <td style="width: 25%">{{ x.name }} - {{ x.sn }}</td>
                  <td>
                    {{ x.unittype }}
                  </td>
                  <td>
                    <input type="number" v-model="x.quantity" />
  
                  </td>
                  <!-- <td>
                    {{ x.purchase }}
                  </td> -->
                  <td style="width: 25%">
                    <v-select
                      class="select"
                      :options="allsupplier"
                      label="name"
                      v-model="x.supplier"
                    >
                      <template #option="option">
                        <div style="text-align: center">
                          <span>{{ option.name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </td>
                  <td style="width: 10%">
                    <input type="number" v-model="x.qpurchases" />
                  </td>
                  <td style="width: 10%">
                    <select v-model="x.typepay">
                      <option value="كاش">كاش</option>
                      <option value="اجل">اجل</option>
                    </select>
                  </td>
  
                  <td style="width: 10%">
                    <input type="number" v-model="x.price" />
                  </td>
                  <!-- <td style="width: 10%">
                    <input type="text" v-model="x.tax" />
                  </td> -->
                  <td style="width: 10%">
                    <select v-model="x.tax">
                      <option value="true">يوجد</option>
                      <option value="false">لا يوجد</option>
                    </select>
                  </td>
                  <td>
                  <span v-if="x.tax == 'true'">{{
                    ((
                      Number(x.price) +
                      Number(Number(x.price) * 15) / 100
                    )*x.qpurchases).toFixed(2)
                  }}</span>
                  <span v-if="x.tax == 'false'">{{
                    Number(x.price*x.qpurchases).toFixed(2)
                  }}</span>
                </td>
                  <td>
                    <a href="#" @click.prevent="del(id)"
                      ><i class="bi bi-x-circle-fill"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <button
              @click="purchaseorder"
              style="padding: 1%"
              v-if="dep == 'purchases' && status == 'waiting'"
            >
              انهاء الطلب
            </button>
            <hr />
            <div class="activity">
              <ul v-for="x in activity" :key="x">
                <li style="width: 150px">
                  {{ x.action }} <br />
                  <span style="font-size: x-small"
                    >{{ x.date }} - {{ x.time }}</span
                  >
                  <br v-if="x.comment" />{{ x.comment }} <br />{{ x.username }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import store from "@/store";
  import axios from "axios";
  import Swal from "sweetalert2";
  import { mapActions } from "vuex";
  import vSelect from "vue-select";
  import { required } from "@vuelidate/validators";
  import useValidate from "@vuelidate/core";
  export default {
    components: {
      vSelect,
    },
    props: ["css"],
    data() {
      return {
        date: "",
        time: "",
        assign: "",
        mobile: "",
        code: "",
        data: [],
        data2: [],
        type: "supplyorder",
        v$: useValidate(),
        parent: "",
        parent2: "",
        level1: [],
        allsupplier: [],
        branches: [],
        costn: "",
        costname: "",
        address: "",
        activity: "",
        status: "",
        dep: store.state.dep,
        reassign: "",
        comment: "",
        products: [],
        product: {},
      };
    },
    validations() {
      return {
        date:{required}
      };
    },
    async mounted() {
      const result = await axios.get(`/data/allsupplier`);
      if (result.status == 200) {
        this.allsupplier = result.data.data;
      }
      const result3 = await axios.get(`/data/allproducts`);
      if (result3.status == 200) {
        this.products = result3.data.data;
      }
    },
    methods: {
        submite2() {
        Swal.fire({
          title:
            this.$t("create") +
            " " +
            this.$t("request") ,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            const action = await axios.post("/data/createnewpo", {
              date: this.date,
              data: JSON.stringify(this.data2),
              username: store.state.randp.username,
            });
            if (action.status == 200) {
              Swal.fire(this.$t("send") + " " + this.$t("request"));
              this.redirectTo({ val: "taskshome" });
            }
          }
        });
      },
      submite() {
      for (let i = 0; i < this.data2.length; i++) {
        const element = this.data2[i];
        if (
          element.price == 0 ||
          element.price == null ||
          isNaN(element.price)
        ) {
          alert("برجاء مراجعة كميات المنتجات");
          break;
        } 
      }

          this.v$.$validate();
          if (!this.v$.$error) {
            Swal.fire({
              title:
            this.$t("create") +
            " " +
            this.$t("request") ,
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.$t("confirm"),
            }).then(async (result) => {
              if (result.isConfirmed) {
                const action = await axios.post("/data/createnewpo", {
                  date: this.date,
                  type: this.type,
                  assign: this.assign,
                  data: JSON.stringify(this.data2),
                  username: store.state.randp.username,
                  costn: this.costn,
                  costname: this.costname,
                  address: this.address,
                  description: `طلب مقدم من ${store.state.randp.username}`,
                });
                if (action.status == 200) {
                  const tid = action.data.data[0].soid;
                  Swal.fire(
                    this.$t("create") + " " + this.$t("request") + " " + tid
                  );
                  this.redirectTo({ val: "AllSupplyOrder" });
                }
              }
            });
          
        }
      
    },
      async del(id) {
        this.data2.splice(id, 1);
        this.calc();
      },
      ...mapActions(["redirectTo"]),
    },
    watch: {
      parent() {
        this.level2 = this.branches.filter(
          (x) =>
            x.actnum.startsWith(this.parent.actnum) &&
            x.actnum != this.parent.actnum
        );
        this.costname = this.parent.namear;
        this.costn = this.parent.actnum;
      },
      parent2() {
        this.costname = this.parent2.namear;
        this.costn = this.parent2.actnum;
      },
      ccenter() {
        if (this.ccenter == false) {
          this.costname = "";
          this.costn = "";
        }
      },
      product() {
        // const filter = this.data2.filter((x) => x.sn == this.product.sn);
        // if (filter.length == 0) {
          this.data2.push({
            name: this.product.namear,
            nameen: this.product.nameen,
            sn: this.product.sn,
            unittype: this.product.unittype,
            quantity: 0,
          });
        // } else {
        //   alert("تم اضافة المنتج من قبل");
        // }

      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
  }
  
  .main {
    width: 98%;
    margin: 1%;
    padding: 1%;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    border: ridge 0.5px;
  }
  
  .head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
  }
  
  .head1 {
    width: 70%;
  }
  
  .head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
  }
  
  .head2 {
    width: 30%;
    text-align: center;
  }
  
  button {
    margin: 0.5%;
    padding: 3%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
  }
  
  .head2 button a {
    color: white;
  }
  
  .table {
    margin-top: 1%;
  }
  
  table thead tr {
    border-radius: 5px;
    background-color: #eee;
  }
  
  select {
    // width: 15%;
    margin: 0.5%;
    // padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
  }
  
  .body {
    display: flex;
    margin: 1%;
  }
  
  .part1 {
    width: 50%;
  }
  
  .body2 {
    width: 100%;
    padding: 1%;
    // border: 1px solid black;
    border-radius: 5px;
    text-align: center;
  }
  
  .activity {
    // display: flex;
    margin-top: 2%;
    width: 75%;
    font-size: small;
  }
  
  table input {
    text-align: center;
  }
  
  @media screen and (max-width: 576px) {
    .page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
    font-size: x-small;
    
  }
    .table {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      background-color: white;
      font-size: xx-small;
    }
  }
  </style>
  