<template>
  <LoadingPage v-if="loading"></LoadingPage>
  <div class="page2">
    <form @submit.prevent="Signin">
      <h1>Change Password</h1>
      <div class="form-floating mt-3 mb-3">
        <input
          type="password"
          class="form-control"
          id="floatingPassword"
          placeholder="password"
          v-model="password"
        />
        <label for="floatingPassword">Current Password</label>
        <span class="error-feedback" v-if="v$.password.$error">{{
          v$.password.$errors[0].$message
        }}</span>
      </div>

      <div class="form-floating mt-3 mb-3">
        <input
          type="password"
          class="form-control"
          id="floatingPassword"
          placeholder="Confirm Password"
          v-model="newpassword"
        />
        <label for="floatingPassword">New Password</label>
        <span class="error-feedback" v-if="v$.newpassword.$error">{{
          v$.newpassword.$errors[0].$message
        }}</span>
      </div>
      <div class="form-floating mt-3 mb-3">
        <input
          type="password"
          class="form-control"
          id="floatingPassword"
          placeholder="New Password"
          v-model="confirmnewpassword"
        />
        <label for="floatingPassword">Confirm New Password</label>
        <span class="error-feedback" v-if="v$.confirmnewpassword.$error">{{
          v$.confirmnewpassword.$errors[0].$message
        }}</span>
      </div>
      <br />
      <button class="buttonconfirm" type="submit">Confirm</button
      >&nbsp;&nbsp;&nbsp;
      <button class="buttoncancel" @click.prevent="cancel">Cancel</button>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import LoadingPage from "../components/LoadingPage.vue";
import store from "@/store";
export default {
  name: "ChangePassword",
  components: {
    LoadingPage,
  },

  data() {
    return {
      loading: false,
      v$: useValidate(),
      password: "",
      newpassword: "",
      confirmnewpassword: "",
    };
  },

  validations() {
    return {
      password: { required },
      newpassword: { required, minLength: minLength(6) },
      confirmnewpassword: { required, minLength: minLength(6) },
    };
  },
  methods: {
    async Signin() {
      this.loading = true;
      this.v$.$validate();
      if (this.newpassword != this.confirmnewpassword) {
        alert("برجاء التاكيد على الرقم السرى بشكل صحيح");
      }
      if (!this.v$.$error && this.newpassword == this.confirmnewpassword) {
        try {
          let result = await axios.post("/user/changepassword", {
            username: store.state.username,
            password: this.password,
            newpassword: this.newpassword,
          });
          this.loading = false;
          if (result.status === 200) {
            alert("تم تغيير الرقم السرى ");
            localStorage.setItem("firstlogin", "false");
            this.redirectTo({ val: "signIn" });
          }
        } catch (err) {
          this.loading = false;
          alert(" الرقم السرى غير صحيح");
        }
      }
      this.loading = false;
    },
    cancel() {
      this.redirectTo({ val: "signIn" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style lang="scss" scoped>
form {
  width: 50%;
  height: 50%;
  margin-left: 10%;
  margin-top: 3%;
  text-align: center;
  position: center;
}
.error-feedback {
  color: red;
}
.page2 {
  width: 80%;
  margin-left: 10%;
  margin-top: 5%;
  font-size: 15px;
  padding: 5%;
  background-color: rgba(255, 255, 255, 0.849);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  // float: right;
  background-image: url("../assets/logo.png");
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: right;
}
label {
  font-size: large;
  text-align: right;
}
@media screen and (max-width: 576px) {
  form {
    width: 50%;
    height: 50%;
    margin-left: 10%;
    margin-top: 3%;
    text-align: center;
    position: center;
  }
  .error-feedback {
    color: red;
  }
  .page2 {
    width: 100%;
    margin-left: 0%;
    margin-top: 5%;
    font-size: 10px;
    padding: 3%;
    background-color: rgba(255, 255, 255, 0.849);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    // float: right;
    background-image: url("../assets/logo.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
  }
  label {
    font-size: xx-small;
    text-align: right;
  }
}
</style>
